import dayjs from "dayjs";

import { TWO_DIGITS_HOURS } from "constants/time";

/**
 * 日付をフォーマットする
 * @param value
 * @param format
 * @returns
 */
export const dateFormat = (value: string | number, format = "YYYY/M/D") => {
  return dayjs(value).format(format);
};

/**
 * firestoreのTimestamp(ミリ秒)の日付をフォーマットする
 * @param timestampSeconds (ミリ秒)
 * @returns
 * @example
 * const timestampSeconds = 1614556800;
 * timestampToDate(timeStampSeconds); // 2021/03/01
 */
export const timestampToDate = (timestampSeconds: number, format = "YYYY/MM/DD") => {
  return dayjs.unix(timestampSeconds).format(format);
};

/**
 * 時間をフォーマットする
 * @param hour
 * @param minute
 * @returns 00:00
 */
export const timeFormat = (hour: string, minute: string) => {
  return `${(0 + hour).slice(-2)}:${minute}`;
};

/**
 * 時間をDayjsのインスタンスに変換
 * @param hour
 * @param minute
 * @returns
 */
export const timeToDayjs = (hour: string | number, minute: string | number) => {
  return dayjs().set("hour", Number(hour)).set("minute", Number(minute));
};

/**
 * Cursorの時間より後の時間をスライス
 * @example
 * const cursorHour = "10";
 * getNextHours(cursorHour);
 * => ["11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21","22", "23", "24"]
 */
export const getNextHours = (cursorHour: string) => {
  const index = TWO_DIGITS_HOURS.findIndex((hour) => hour === cursorHour);
  return TWO_DIGITS_HOURS.slice(index + 1);
};

/**
 * 入力されている日付が存在するかを判定
 * @example
 * const date = { year: 2021, month: 2, day: 29 };
 * isDateValid(date); // false
 */
export const isDateValid = (date: { year: number | null; month: number | null; day: number | null }): boolean => {
  // 日付を作成
  const dayjsDate = dayjs(`${date.year}-${date.month}-${date.day}`);

  // dayjs が認識した日付と入力の日付が一致するかを確認
  const isValid =
    dayjsDate.isValid() &&
    dayjsDate.year() === date.year &&
    dayjsDate.month() + 1 === date.month && // dayjs は 0-indexed の月を使用するため +1 する
    dayjsDate.date() === date.day;

  return isValid;
};

/**
 * 時間フォーマット（HH:mm）かどうか
 * @example
 * isTimeFormat("10:00"); // true
 * isTimeFormat("10:0"); // false
 * isTimeFormat("10:60"); // false
 *
 */
export const isTimeFormat = (time?: string): boolean => {
  if (time == null) return false;

  const isValid = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(time);

  return isValid;
};

/**
 * 日本語の日付をISO形式に変換
 * @example
 * toISO("2021年1月1日"); // "2021-01-01"
 * toISO("20210101"); // "2021-01-01"
 * toISO("2021-01-01"); // "2021-01-01"
 * toISO("2021-01-32"); // null（不正値）
 */
export const toISO = (value: string): string | null => {
  // 日本語の日付の場合
  if (/^(\d{4})年(\d{1,2})月(\d{1,2})日$/.test(value)) {
    const [year, month, day] = value.split(/[年月日]/).map(Number);
    if (month < 1 || month > 12 || day < 1 || day > 31) return null;

    if (dayjs(`${year}-${month}-${day}`, "YYYY-MM-DD", true).isValid()) {
      return dayjs(`${year}-${month}-${day}`).format("YYYY-MM-DD");
    }
  }

  // 数字の日付の場合
  if (/^\d+$/.test(value)) {
    if (/^\d{8}$/.test(value)) {
      const { month, day } = {
        month: Number(value.slice(4, 6)),
        day: Number(value.slice(6, 8)),
      };
      if (month < 1 || month > 12 || day < 1 || day > 31) return null;

      return dayjs(value, "YYYYMMDD", true).format("YYYY-MM-DD");
    }
    return null;
  }

  // 日付の場合
  if (dayjs(value, "YYYY-MM-DD", true).isValid()) {
    return dayjs(value).format("YYYY-MM-DD");
  }

  return null;
};
