import { isJSON } from "class-validator";

export const AIMO_PARKING_ERROR_MESSAGE = {
  /**
   * メールアドレスが既に存在する場合のエラーメッセージとエラーコード
   */
  EMAIL_ALREADY_EXISTS: {
    ERROR_CODE: "V024",
    ERROR_MESSAGE: "Email address already exists",
  },
} as const;

type ErrorCode = typeof AIMO_PARKING_ERROR_MESSAGE[keyof typeof AIMO_PARKING_ERROR_MESSAGE]["ERROR_CODE"];
type ErrorMessage = typeof AIMO_PARKING_ERROR_MESSAGE[keyof typeof AIMO_PARKING_ERROR_MESSAGE]["ERROR_MESSAGE"];

type AimoParkingAxiosError = {
  status: number;
  data: {
    statusCode: number;
    message: string; // JSON形式のエラーメッセージ
  };
};

/**
 * AimoParkingのAxiosエラーをパースしてエラーメッセージを取得する関数
 * @param axiosError エラーオブジェクト
 * @returns エラーメッセージ
 * @example
 * const axiosError = {
 *  status: 400,
 * data: {
 *  statusCode: 400,
 *  message: '{"errorCode":"V000","errorMessage":"Example error message"}',
 * },
 * const errorMessage = extractAimoParkingAxiosError(axiosError); // => "Example error message"
 **/
export const extractAimoParkingAxiosError = (axiosError: AimoParkingAxiosError): string | undefined => {
  return axiosError?.data?.message;
};

/**
 * JSON形式のエラーメッセージをパースしてエラーコードを取得する関数
 * @param axiosError エラーメッセージ
 * @returns エラーコード
 * @example
 * const axiosError = {
 * status: 400,
 * data: {
 * statusCode: 400,
 * message: '{"errorCode":"V000","errorMessage":"Example error message"}',
 * },
 * const errorCode = extractErrorCode(axiosError); // => "V000"
 **/
export const extractErrorCode = (axiosError: AimoParkingAxiosError) => {
  if (!axiosError) return undefined;
  const message = extractAimoParkingAxiosError(axiosError);
  if (!message) return undefined;
  if (!isJSON(message)) return undefined;
  const { errorCode, errorMessage } = JSON.parse(message)[0] as {
    errorCode: ErrorCode | undefined;
    errorMessage: ErrorMessage | undefined;
  };
  return { errorCode, errorMessage };
};
