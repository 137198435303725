import { isEmail, isPhoneNumber } from "class-validator";
import { useEffect } from "react";
import styled from "styled-components";

import { FormRow, Title, TitleArea, ContentArea } from "components/LayoutParts";
import RadioButton from "components/RadioButton";
import TextForm from "components/TextForm";

const Label = styled.div`
  min-width: 160px;
  margin: 0 5px;
`;

const CreateCompany = ({
  company,
  setCompany,
  setRadioValue,
  selectedPropertyIds,
}: {
  company: CreateCompany;
  setCompany: React.Dispatch<React.SetStateAction<CreateCompany>>;
  setRadioValue: React.Dispatch<React.SetStateAction<number>>;
  selectedPropertyIds: number[];
}) => {
  const changeName = (e: React.ChangeEvent<HTMLInputElement>) => setCompany({ ...company, name: e.target.value });
  const changeAddress = (e: React.ChangeEvent<HTMLInputElement>) => setCompany({ ...company, address: e.target.value });
  const changePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCompany({ ...company, phoneNumber: e.target.value });
  const changeEmail = (e: React.ChangeEvent<HTMLInputElement>) => setCompany({ ...company, email: e.target.value });
  const changeType = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCompany({ ...company, type: Number(e.target.value) });

  useEffect(() => {
    setCompany((currentCompany) => ({ ...currentCompany, propertySpaceIds: selectedPropertyIds }));
  }, [selectedPropertyIds, setCompany]);

  const isErrorCompanyUserName = !(company.name.length > 0);
  const isErrorCompanyUserAddress = !(company.address.length > 0);
  const isErrorPhoneNumber = !isPhoneNumber(company.phoneNumber, "JP") || company.phoneNumber.includes("-");
  const isErrorCompanyUserEmail = !isEmail(company.email);

  return (
    <>
      <TitleArea>
        <Title>請求先</Title>
      </TitleArea>
      <ContentArea>
        <FormRow>
          <div style={{ marginRight: "134px" }}>会社名</div>
          <TextForm
            type="text"
            width="350px"
            value={company.name}
            placeholder="例）ニースン不動産"
            onChange={changeName}
            required
            isError={isErrorCompanyUserName}
            errorMessage="会社名を入力してください"
          />
        </FormRow>
        <FormRow>
          <div style={{ marginRight: "150px" }}>住所</div>
          <TextForm
            type="text"
            width="540px"
            value={company.address}
            placeholder="例）京都府京都市東山区三条通南二筋目白川筋西入ル二丁目北木之元"
            onChange={changeAddress}
            required
            isError={isErrorCompanyUserAddress}
            errorMessage="住所を入力してください"
          />
        </FormRow>
        <FormRow>
          <div style={{ marginRight: "118px" }}>電話番号</div>
          <TextForm
            type="text"
            width="350px"
            value={company.phoneNumber}
            placeholder="例）0368224474"
            onChange={changePhoneNumber}
            required
            isError={isErrorPhoneNumber}
            errorMessage="電話番号をハイフンなしで入力してください"
          />
        </FormRow>
        <FormRow>
          <div style={{ marginRight: "24px" }}>請求先メールアドレス</div>
          <TextForm
            type="text"
            width="350px"
            value={company.email}
            placeholder="例）info@landit.co.jp"
            onChange={changeEmail}
            required
            isError={isErrorCompanyUserEmail}
            errorMessage="請求先メールアドレスを入力してください"
          />
        </FormRow>
        <FormRow>
          <Label>種別</Label>
          <RadioButton
            id={`company-type`}
            value={Number(company.type)}
            choices={[
              {
                label: "一般",
                value: 0,
              },
              {
                label: "限定",
                value: 1,
              },
              {
                label: "併用",
                value: 2,
              },
            ]}
            onChange={(e) => {
              changeType(e);
              setRadioValue(Number(e.target.value));
            }}
          />
        </FormRow>
      </ContentArea>
    </>
  );
};

export default CreateCompany;
