import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import styled from "styled-components";

import color from "constants/color";
import { range } from "utils/arrayUtils";

const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 30px;
`;
const PrevNextButton = styled.button<{ disabled: boolean }>`
  width: 80px;
  height: 40px;
  border-radius: 4px;
  position: relative;
  background: ${({ disabled }) => (disabled ? color.text.disabled : color.white)};
  border: 1px solid ${({ disabled }) => (disabled ? color.text.disabled : color.text.gray)};
  color: ${({ disabled }) => (disabled ? color.white : color.text.gray)};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  &:hover {
    background: ${({ disabled }) => (disabled ? color.text.disabled : color.background)};
  }
`;
const PrevButton = styled(PrevNextButton)`
  padding-left: 16px;
`;
const NextButton = styled(PrevNextButton)`
  padding-right: 16px;
`;
const LeftIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 12px;
  left: 16px;
`;
const RightIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 12px;
  right: 16px;
`;
const PageLink = styled.div<{ disabled: boolean }>`
  padding: 0 8px;
  color: ${({ disabled }) => (disabled ? color.text.disabled : color.text.gray)};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
`;

const PaginationForGraphql = ({
  currentPageNumber,
  setCurrentPageNumber,
  totalPageCount,
  setPagination,
  startCursor,
  endCursor,
  hasNextPage,
  hasPreviousPage,
}: {
  currentPageNumber: number;
  setCurrentPageNumber: React.Dispatch<React.SetStateAction<number>>;
  totalPageCount: number;
  setPagination: React.Dispatch<React.SetStateAction<GraphqlPageOption>>;
  startCursor: string;
  endCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}) => {
  const displayNumberArray = useMemo(() => {
    if (currentPageNumber <= 0 || totalPageCount <= 1 || currentPageNumber > totalPageCount) return [];

    // 1ページ目の場合は、[1, 2]を返す
    // Nページ目の場合は、[N-1, N, N+1]を返す
    // 最終ページの場合は、[N-1, N]を返す
    return currentPageNumber === 1
      ? [1, 2]
      : 3 >= totalPageCount
      ? [...range(1, totalPageCount)]
      : currentPageNumber === 2
      ? [...range(1, 3)]
      : currentPageNumber === totalPageCount - 1
      ? [...range(totalPageCount - 2, totalPageCount)]
      : currentPageNumber === totalPageCount
      ? [...range(totalPageCount - 1, totalPageCount)]
      : [...range(currentPageNumber - 1, currentPageNumber + 1)];
  }, [currentPageNumber, totalPageCount]);

  // 表示すべきものがない場合は、React.Fragmentを返す
  if (displayNumberArray.length === 0) return <></>;

  const onBackPage = () => {
    setPagination({
      after: undefined,
      before: startCursor,
    });
    setCurrentPageNumber((currentPageNumber) => currentPageNumber - 1);
  };
  const onNextPage = () => {
    setPagination({
      after: endCursor,
      before: undefined,
    });
    setCurrentPageNumber((currentPageNumber) => currentPageNumber + 1);
  };
  const onMovePage = (movePage: number, currentPage: number) => {
    if (movePage === currentPage) return;
    setPagination(movePage > currentPage ? { after: endCursor } : { before: startCursor });
    setCurrentPageNumber(movePage);
  };

  return (
    <Wrap>
      <PrevButton disabled={!hasPreviousPage} onClick={onBackPage}>
        <LeftIcon icon={faChevronLeft} />
        前へ
      </PrevButton>
      {currentPageNumber > 2 && <div>...</div>}
      {displayNumberArray.map((p, index) => (
        <PageLink
          key={`link_${index}`}
          disabled={currentPageNumber === p}
          onClick={() => onMovePage(p, currentPageNumber)}
        >
          {p}
        </PageLink>
      ))}
      {currentPageNumber <= totalPageCount - 2 && <div>...</div>}
      <NextButton disabled={!hasNextPage} onClick={onNextPage}>
        次へ
        <RightIcon icon={faChevronRight} />
      </NextButton>
    </Wrap>
  );
};

export default PaginationForGraphql;
