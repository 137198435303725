import { useCallback } from "react";

import { usePitPortClient } from "hooks/api/usePitPortClient";

export const useCreatePitPortUser = () => {
  const { client } = usePitPortClient();

  const create = useCallback(
    async (user: CreatePitPortUser) => {
      const { status } = await client({
        method: "post",
        url: `/user`,
        data: user,
      });
      return status;
    },
    [client]
  );
  return { create };
};

export const useUpdatePitPortUser = () => {
  const { client } = usePitPortClient();

  const update = useCallback(
    async (userId: number, data: UpdatePitPortUser) => {
      const { status } = await client({
        method: "put",
        url: `/user/${userId}`,
        data,
      });
      return status;
    },
    [client]
  );
  return { update };
};

export const useVerifyPitPortUser = () => {
  const { client } = usePitPortClient();

  const verify = useCallback(
    /**
     *
     * @param userId
     * @param status 承認 | 拒否
     * @returns
     */
    async (userId: number, verifyStatus: 1 | 3) => {
      const { status } = await client({
        method: "put",
        url: `/user/${userId}/verify`,
        data: { status: verifyStatus },
      });
      return status;
    },
    [client]
  );
  return { verify };
};

export const useUpdatePitPortUserRole = () => {
  const { client } = usePitPortClient();
  const updateRole = useCallback(
    async (userId, companyId, role) => {
      const { status } = await client({
        method: "PUT",
        url: `/user/${userId}/update-role`,
        data: { companyId, role },
      });
      return status;
    },
    [client]
  );
  return { updateRole };
};

export const useReactivatePitPortUser = () => {
  const { client } = usePitPortClient();
  const reactivate = useCallback(
    async (userId: number, companyId: number) => {
      const { status } = await client({
        method: "put",
        url: `/user/${userId}/reactivate`,
        data: { companyId },
      });
      return status;
    },
    [client]
  );
  return { reactivate };
};

export const useRemovePitPortUser = () => {
  const { client } = usePitPortClient();

  const remove = useCallback(
    async (userId: number) => {
      const { status } = await client({
        method: "delete",
        url: `/user/${userId}`,
      });
      return status;
    },
    [client]
  );
  return { remove };
};

type RemoveOrBlockErrorResponse = {
  status: number;
  data: {
    message: string;
  };
};

export const useRemoveOrBlockPitPortUser = () => {
  const { client } = usePitPortClient();
  const removeOrBlock = useCallback(
    async (userId: number, status: RemoveOrBlockPitPortUserStatus) => {
      const { status: responseStatus, data }: RemoveOrBlockErrorResponse = await client({
        method: "put",
        url: `/user/${userId}/remove-or-block`,
        data: { status },
      });
      return { responseStatus, message: data.message };
    },
    [client]
  );
  return { removeOrBlock };
};
