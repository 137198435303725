import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import styled from "styled-components";

import color from "constants/color";
import font from "constants/font";

const IconLabel = styled.div`
  font-size: ${font.size14};
`;
const PopupHelper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${color.text.link};
`;

const Wrap = styled.div`
  cursor: pointer;
`;

const CopyOnClipboard = ({
  beforeLabel,
  afterLabel,
  onClick,
}: {
  beforeLabel: string;
  afterLabel: string;
  onClick: () => void;
}) => {
  const [copied, setCopied] = useState(false);

  const handleClick = () => {
    onClick();
    if (copied) return;
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  return (
    <Wrap onClick={handleClick}>
      <PopupHelper>
        <FontAwesomeIcon icon={!copied ? (faClipboard as IconProp) : faClipboardCheck} width={13} height={17} />
        <IconLabel>{copied ? afterLabel : beforeLabel}</IconLabel>
      </PopupHelper>
    </Wrap>
  );
};

export default CopyOnClipboard;
