import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faTableCellsLarge } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import TabBar, { TabBarType } from "components/TabBar";
import font from "constants/font";

const TitleWrap = styled.div`
  font-weight: 600;
  font-size: ${font.size20};
  padding-bottom: 12px;
`;

const Wrap = styled.div`
  padding-bottom: 16px;
`;

const TitleTabArea = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const isSpaceGroup = location.pathname.includes("space_group");

  const TAB_OPTIONS: TabBarType[] = [
    {
      Icon: <FontAwesomeIcon icon={faSquare as IconProp} size="2x" width={16} height={18} />,
      label: "区画",
      onClick: () => navigate(`/sync_port/company/${params.id}/property/${params.propertyId}/space`, { replace: true }),
      width: 53,
      gap: 6,
      matchUrlKey: ["company/", "property/", "space/"],
    },
    {
      Icon: <FontAwesomeIcon icon={faTableCellsLarge} size="2x" width={18} height={18} />,
      label: "区画グループ",
      onClick: () =>
        navigate(`/sync_port/company/${params.id}/property/${params.propertyId}/space_group`, { replace: true }),
      width: 105,
      matchUrlKey: ["company/", "property/", "space_group/"],
      gap: 7,
    },
  ];

  return (
    <Wrap>
      <TitleWrap>{isSpaceGroup ? "区画グループ" : "区画"}</TitleWrap>
      <TabBar options={TAB_OPTIONS} />
    </Wrap>
  );
};

export default TitleTabArea;
