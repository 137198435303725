import { memo } from "react";
import styled, { keyframes } from "styled-components";

const offset = 187;
const duration = 1.4;

const Rotator = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
`;
const Colors = keyframes`
  0% { stroke: #0062B1; }
  33% { stroke: #EE8F00; }
  66% { stroke: #00AEC4; }
  100% { stroke: #CB1706; }
`;
const Dash = keyframes`
  0% { stroke-dashoffset: ${offset}; }
  50% {
    stroke-dashoffset: ${offset / 4};
    transform:rotate(135deg);
  }
  100% {
    stroke-dashoffset: ${offset};
    transform:rotate(450deg);
  }
`;
const Svg = styled.svg`
  animation: ${Rotator} ${duration + "s"} linear infinite;
`;
const Circle = styled.circle`
  stroke-dasharray: ${offset};
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: ${Dash} ${duration + "s"} ease-in-out infinite, ${Colors} ${duration * 4 + "s"} ease-in-out infinite;
`;
const LoadingWrap = styled.div`
  display: flex;
  height: 50vh;
  justify-content: center;
  align-items: center;
`;

const sizeWidth = (size: "s" | "m") => {
  switch (size) {
    case "s":
      return "25px";
      break;
    case "m":
      return "40px";
      break;
    default:
      return "40px";
  }
};

const Loading = ({ size = "m" }: { size?: "s" | "m" }) => {
  return (
    <LoadingWrap>
      <Svg width={sizeWidth(size)} height={sizeWidth(size)} viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <Circle fill="none" strokeWidth="10" strokeLinecap="round" cx="33" cy="33" r="27"></Circle>
      </Svg>
    </LoadingWrap>
  );
};

export default memo(Loading);
