import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import { sortDirection } from "constants/direction";
import font from "constants/font";

const SortArrowIcon = styled(FontAwesomeIcon)`
  margin-left: 8px;
  font-size: ${font.size16};
  cursor: pointer;
`;

/**
 * ソートボタン
 * @param currentSortOption
 * @param sortKey
 * @param setSortOption
 * @returns
 */
const SortButton = <T extends Record<never, never> | undefined | null>({
  currentSortOption,
  sortByKey,
  setSortOption,
}: {
  currentSortOption: SortOption<T>;
  sortByKey: T;
  setSortOption: (sortOption: SortOption<T>) => void;
}) => {
  const isAsc = currentSortOption.sortByColumn === sortByKey && currentSortOption.direction === sortDirection.asc;
  return (
    <SortArrowIcon
      icon={isAsc ? faAngleUp : faAngleDown}
      onClick={() => {
        setSortOption({
          sortByColumn: sortByKey,
          direction: isAsc ? sortDirection.desc : sortDirection.asc,
        });
      }}
    />
  );
};

export default SortButton;
