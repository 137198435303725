export const templateHeader = [
  "駐車場名",
  "住所",
  "物件No（管理ナンバー）",
  "駐車場の備考",
  "担当者",
  "賃料",
  "賃料 課税 / 非課税",
  "その他月額賃料",
  "仲介手数料",
  "敷金（返却あり）",
  "敷金（返却あり）課税 / 非課税",
  "礼金（返却あり）",
  "礼金  課税 / 非課税",
  "その他費用",
  "対応車両",
  "識別",
  "屋根",
  "舗装",
  "時間",
  "貸与物",
  "高さ(mm)",
  "長さ（mm）",
  "車幅（mm）",
  "地上高（mm）",
  "タイヤ幅（mm）",
  "重さ(kg)",
  "契約期間",
  "更新料",
  "日割",
  "区画の備考",
  "区画名",
  "利用開始日",
  "利用終了日",
  "契約者",
  "電話番号",
];
