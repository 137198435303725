export const switchUserStatusDisplayValue = (status: PitportUserStatusNumber): PitPortUserDisplayStatus => {
  switch (status) {
    case 0:
      return "無効";
    case 1:
      return "-";
    case 2:
      return "承認待ち";
    case 3:
      return "承認NG";
    case 4:
      return "利用停止";
    case 5:
      return "削除済み";
    default:
      return "";
  }
};

export const switchCompanyStatusDisplayValue = (status: PitPortCompanyStatusNumber): PitPortCompanyDisplayStatus => {
  switch (status) {
    case 0:
      return "利用停止";
    case 1:
      return "-";
    case 5:
      return "削除済み";
    default:
      return "";
  }
};
