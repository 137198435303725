import { useParams } from "react-router-dom";

import Loading from "components/Loading";
import CompanyInfo from "pages/SyncPort/Company/component/CompanyInfo";
import { useFindCompanyById } from "pages/SyncPort/Company/features/api";

// パーツ定義
const CompanyDetail = () => {
  const { id } = useParams();

  const { data: companyDetail } = useFindCompanyById({
    companyId: id ?? "",
  });

  return (
    <>
      {companyDetail == null ? (
        <Loading />
      ) : (
        <>
          <CompanyInfo companyDetail={companyDetail} />
        </>
      )}
    </>
  );
};

export default CompanyDetail;
