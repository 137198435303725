import styled from "styled-components";

import color from "constants/color";
import font from "constants/font";

// ページの親要素
export const Board = styled.div`
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  position: relative;
`;
// ページトップの要素（トップへ戻る用要素）
export const PageTop = styled.div.attrs({ id: "page-top" })`
  width: 0;
  position: absolute;
  top: 0;
`;
// ページタイトルのrow
export const TitleArea = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0px 0 10px 0;
  align-items: center;
  width: 100%;
`;
// ページタイトル
export const Title = styled.span`
  font-size: ${font.size24};
  font-weight: bold;
  line-height: 40px;
`;

// テーブル要素
export const Table = styled.div`
  width: 100%;
`;
export const Heading = styled.div`
  display: flex;
  border: solid 1px ${color.border};
  background-color: ${color.table.headerBackground};
  align-items: center;
`;
export const Row = styled(Heading)<{ warning?: boolean; isDeleted?: boolean }>`
  border-top: none;
  background-color: ${(props) => (props.warning ? color.attentionLight : color.white)};
  &:hover {
    cursor: pointer;
  }
  ${(props) =>
    props.isDeleted &&
    `
    background-color: rgba(214, 211, 208, 0.1);
  `}
  align-items: stretch;
`;
export const FieldValue = styled.span<{ flex: number }>`
  display: flex;
  flex: ${(props) => props.flex};
  padding: 10px 1%;
  font-size: ${font.size14};
  word-break: break-all;
`;
export const FieldValueWithBorder = styled(FieldValue)<{ lastColumn?: boolean }>`
  border-right: ${(props) => (props.lastColumn ? "none" : `solid 1px ${color.border}`)};
  min-height: 41px;
  padding: 0px 1%;
  align-items: center;
`;
export const FieldName = styled(FieldValue)<{ isActive?: boolean; isSort?: boolean; lastColumn?: boolean }>`
  color: ${(props) => (props.isActive ? color.form.border : color.form.border)};
  border-bottom: ${(props) => (props.isActive ? `solid 1px ${color.form.border}` : "none")};
  border-right: ${(props) => (props.lastColumn ? "none" : `solid 1px ${color.border}`)};
  white-space: nowrap;
  color: ${(props) => (props.isSort ? color.text.black : color.form.border)};
  &:hover {
    cursor: ${(props) => (props.isSort ? "pointer" : "default")};
  }
`;

// フォームのrow
export const FormRow = styled.div<{ minHeight?: string }>`
  width: 100%;
  padding: 16px 0px;
  border-bottom: solid 1px ${color.border};
  display: flex;
  flex-direction: row;
  align-items: center;
  &:last-child {
    border-bottom: none;
  }
  min-height: ${(props) => props.minHeight};
`;

// アラート
export const AlertArea = styled.div`
  padding: 24px 0 24px 48px;
  margin-bottom: 20px;
  border: 1px solid ${color.attention};
  color: ${color.attention};
  background-color: ${color.attentionLight};
`;

// コンテントのエリア
export const ContentArea = styled.div`
  padding: 20px 48px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${color.border};
  background-color: ${color.white};
`;

// フッターのエリア
export const FooterArea = styled.div`
  position: relative;
  bottom: 0;
  padding: 16px 0;
  border-top: 1px solid ${color.border};
  display: flex;
  flex-direction: row;
  background-color: ${color.background};
`;
