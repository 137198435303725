import { faBuilding, faParking, faUserFriends } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { TitleArea, Title, Board } from "components/LayoutParts";
import TabBar from "components/TabBar";
import { useFindCompanyById } from "pages/SyncPort/Company/features/api";

const TabBarWrap = styled.div`
  padding: 0 0 37px 0;
`;

const TitleTabArea = () => {
  const { id } = useParams();

  const { data: companyDetail } = useFindCompanyById({
    companyId: id ?? "",
  });
  const navigate = useNavigate();
  return (
    <Board>
      <TitleArea>
        <Title>
          {companyDetail == null ? "読み込み中..." : `${companyDetail.name} ${companyDetail.branchName ?? ""}`}
        </Title>
      </TitleArea>
      <TabBarWrap>
        <TabBar
          options={[
            {
              label: "会社情報",
              onClick: () => navigate(`/sync_port/company/${id}/info`),
              Icon: <FontAwesomeIcon icon={faBuilding} width={12} height={16} />,
              width: 80,
              matchUrlKey: ["company/", "info/"],
            },
            {
              label: "ユーザ情報",
              onClick: () => navigate(`/sync_port/company/${id}/user`),
              Icon: <FontAwesomeIcon icon={faUserFriends} width={18} height={14} />,
              width: 96,
              matchUrlKey: ["company/", "user/"],
            },
            {
              label: "物件情報",
              onClick: () => navigate(`/sync_port/company/${id}/property`),
              Icon: <FontAwesomeIcon icon={faParking} width={16} height={18} />,
              width: 80,
              matchUrlKey: ["company/", "property/"],
            },
          ]}
        />
      </TabBarWrap>
      <Outlet />
    </Board>
  );
};

export default TitleTabArea;
