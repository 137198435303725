import { useNavigate, useParams } from "react-router-dom";

import EmptyTable from "components/EmptyTable";
import { Table, Heading, FieldName, Row, FieldValueWithBorder } from "components/LayoutParts";
import Pagination from "components/Pagination";
import SortButton from "components/SortButton";
import { aimoParkingCompanyStatus } from "pages/AimoParking/Company/DataTable/utils";
import { AIMO_PARKING_USER_ROLE_LABEL } from "pages/AimoParking/constants/aimoParkingUserRole";

const userTableColumn = (type: "user" | "companyUser"): { label: string; flex: number; sortKey?: string }[] =>
  type === "user"
    ? [
        {
          label: "会社名",
          flex: 23,
        },
        {
          label: "名前",
          flex: 23,
        },
        {
          label: "メールアドレス",
          flex: 23,
        },
        {
          label: "役割",
          flex: 13,
        },
        {
          label: "ステータス",
          flex: 11,
        },
      ]
    : [
        {
          label: "名前",
          flex: 23,
        },
        {
          label: "フリガナ",
          flex: 23,
        },
        {
          label: "メールアドレス",
          flex: 23,
        },
        {
          label: "役割",
          flex: 13,
        },
        {
          label: "ステータス",
          flex: 11,
        },
      ];

const DataTable = ({
  userList,
  totalPages,
  pageIndex,
  sortOption,
  setPageIndex,
  setSortOption,
  type,
  companyDeleted = false,
}: {
  userList: AimoParkingUser[];
  totalPages: number;
  pageIndex: number;
  sortOption: SortOption<SortByAimoParkingCompany>;
  setPageIndex: React.Dispatch<React.SetStateAction<number>>;
  setSortOption: React.Dispatch<React.SetStateAction<SortOption<SortByAimoParkingCompany>>>;
  type: "user" | "companyUser";
  companyDeleted?: boolean;
}) => {
  const navigate = useNavigate();
  const { id: companyId } = useParams();

  const onClickRow = (userId: string, companyId: string) =>
    navigate(`/aimo_parking/company/${companyId}/company_user/${userId}/edit`);

  const onClickAddUser = () => navigate(`/aimo_parking/company/${companyId}/company_user/create`);

  const handleSetSort = (sortOption: SortOption<SortByAimoParkingCompany>) => {
    setPageIndex(1);
    setSortOption(sortOption);
  };

  return (
    <>
      <Table>
        <Heading>
          {userTableColumn(type).map((column, i) => (
            <FieldName
              key={column.label}
              flex={column.flex}
              isSort={sortOption.sortByColumn === column.sortKey}
              lastColumn={i === userTableColumn(type).length - 1}
            >
              {column.label}
              {column.sortKey && (
                <SortButton
                  currentSortOption={sortOption}
                  sortByKey={column.sortKey as SortByAimoParkingCompany}
                  setSortOption={handleSetSort}
                />
              )}
            </FieldName>
          ))}
        </Heading>
        {userList.length > 0 ? (
          userList.map((user) => (
            <Row key={user.id} onClick={() => onClickRow(user.id, user.company.id)} isDeleted={!!user.deletedAt}>
              {type === "user" ? (
                <>
                  <FieldValueWithBorder flex={userTableColumn(type)[0].flex}>{user.company.name}</FieldValueWithBorder>
                  <FieldValueWithBorder flex={userTableColumn(type)[1].flex}>
                    {user.lastName + user.firstName}
                  </FieldValueWithBorder>
                  <FieldValueWithBorder flex={userTableColumn(type)[2].flex}>{user.email}</FieldValueWithBorder>
                  <FieldValueWithBorder flex={userTableColumn(type)[3].flex}>
                    {AIMO_PARKING_USER_ROLE_LABEL[user.role]}
                  </FieldValueWithBorder>
                  <FieldValueWithBorder flex={userTableColumn(type)[4].flex}>
                    {aimoParkingCompanyStatus(user.deletedAt)}
                  </FieldValueWithBorder>
                </>
              ) : (
                <>
                  <FieldValueWithBorder flex={userTableColumn(type)[0].flex}>
                    {user.lastName + user.firstName}
                  </FieldValueWithBorder>
                  <FieldValueWithBorder flex={userTableColumn(type)[1].flex}>
                    {user.lastNameFurigana + user.firstNameFurigana}
                  </FieldValueWithBorder>
                  <FieldValueWithBorder flex={userTableColumn(type)[2].flex}>{user.email}</FieldValueWithBorder>
                  <FieldValueWithBorder flex={userTableColumn(type)[3].flex}>
                    {AIMO_PARKING_USER_ROLE_LABEL[user.role]}
                  </FieldValueWithBorder>
                  <FieldValueWithBorder flex={userTableColumn(type)[4].flex}>
                    {aimoParkingCompanyStatus(user.deletedAt)}
                  </FieldValueWithBorder>
                </>
              )}
            </Row>
          ))
        ) : (
          <EmptyTable
            label="ユーザを追加"
            message="ユーザが見つかりません"
            handleClick={type === "companyUser" && !companyDeleted ? onClickAddUser : undefined}
          />
        )}
      </Table>
      <Pagination totalPages={totalPages} pageIndex={pageIndex} setPageIndex={setPageIndex} />
    </>
  );
};

export default DataTable;
