import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled, { keyframes } from "styled-components";

import Button from "components/Button";
import Modal from "components/Modal";
import color from "constants/color";
import font from "constants/font";

const Body = styled.div`
  width: 250px;
  height: 250px;
  background-color: ${color.white};
  position: relative;
  display: flex;
  justify-content: center;
`;

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoadingProgress = styled.img`
  animation: ${rotateAnimation} 2s linear infinite;
`;

const LoadingWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  will-change: transform;
`;

const LoadingEnd = styled.img``;

const ParkingLogo = styled.img`
  position: absolute;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 19px;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 20px;
`;

const Message = styled.p`
  color: ${color.text.green};
  font-weight: 600;
  font-size: ${font.size14};
  position: relative;
`;

const ErrorMessage = styled.p`
  color: ${color.attention};
  font-weight: 600;
  font-size: ${font.size14};
  position: relative;
`;

const FaWrap = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 150px;
  height: 180px;
  bottom: 40px;
  flex-direction: column;
  gap: 22px;
`;

export type SyncPortPropertyUploadModalConfig = Readonly<{
  type: "loading" | "complete" | "failed";
  onClickOutSide?: () => void;
  onClose?: () => void;
}>;

const SyncPortPropertyUploadModal = ({
  isVisible,
  config,
}: {
  isVisible: boolean;
  config: SyncPortPropertyUploadModalConfig;
}) => {
  return (
    <Modal isVisible={isVisible} onClickOutside={() => config?.onClickOutSide && config.onClickOutSide()}>
      <Body>
        {config.type === "loading" && (
          <Wrap>
            <LoadingWrap>
              <LoadingProgress src="/loading_progress.svg" alt="ローディング進捗ロゴ" width={150} height={150} />
              <ParkingLogo src="/parking_logo.svg" alt="parkingロゴ" width={50.63} height={50.63} />
            </LoadingWrap>
            <Message>アップロード中</Message>
          </Wrap>
        )}
        {config.type === "complete" && (
          <Wrap>
            <LoadingWrap>
              <LoadingEnd src="/loading_end.svg" alt="ローディング完了ロゴ" width={150} height={150} />
              <ParkingLogo src="/parking_logo.svg" alt="parkingロゴ" width={50.63} height={50.63} />
            </LoadingWrap>
            <Message>完了</Message>
          </Wrap>
        )}
        {config.type === "failed" && (
          <Wrap>
            <FaWrap>
              <FontAwesomeIcon icon={faExclamationCircle} color={color.attention} size="3x" />
              <ErrorMessage>完了できませんでした</ErrorMessage>
            </FaWrap>
            <Button label="閉じる" onClick={() => config.onClose && config.onClose()} type="secondary" width="160px" />
          </Wrap>
        )}
      </Body>
    </Modal>
  );
};

export default SyncPortPropertyUploadModal;
