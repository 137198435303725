import { faPlusCircle, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";

import { ErrorArea, ErrorMessage } from "components/TextForm";
import ZipPlaceHolder from "components/ZipPlaceHolder";
import color from "constants/color";

const areaWidth = "120px";
const areaHeight = "108px";

// パーツ定義
const Wrapper = styled.div`
  position: relative;
`;
const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
`;
const FileArea = styled.div`
  position: relative;
`;
const FileAreaInner = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;
const NotFileUpload = styled.div<{
  isDrag: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: ${areaWidth};
  height: ${areaHeight};
  color: ${(props) => (props.isDrag ? color.text.link : color.text.gray)};
  background-color: ${(props) => (props.isDrag ? color.text.linkLight : color.form.background)};
  &:after {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(
        to right,
        ${(props) => (props.isDrag ? color.text.link : color.text.gray)} 4px,
        transparent 2px
      ),
      linear-gradient(to bottom, ${(props) => (props.isDrag ? color.text.link : color.text.gray)} 4px, transparent 2px),
      linear-gradient(to left, ${(props) => (props.isDrag ? color.text.link : color.text.gray)} 4px, transparent 2px),
      linear-gradient(to top, ${(props) => (props.isDrag ? color.text.link : color.text.gray)} 5px, transparent 2px);
    background-size: 10px 2px, 2px 10px, 10px 2px, 2px 10px;
    background-repeat: repeat-x, repeat-y, repeat-x, repeat-y;
    background-position: left top, right bottom, right bottom, left top;
    content: "";
    transition: background-image 0.3s ease;
  }
  &:hover {
    color: ${(props) => (props.isDrag ? color.text.link : color.text.black)};
    background-color: ${(props) => (props.isDrag ? color.text.linkLight : color.form.background)};
    &:after {
      background-image: linear-gradient(
          to right,
          ${(props) => (props.isDrag ? color.text.link : color.text.black)} 4px,
          transparent 2px
        ),
        linear-gradient(
          to bottom,
          ${(props) => (props.isDrag ? color.text.link : color.text.black)} 4px,
          transparent 2px
        ),
        linear-gradient(to left, ${(props) => (props.isDrag ? color.text.link : color.text.black)} 4px, transparent 2px),
        linear-gradient(to top, ${(props) => (props.isDrag ? color.text.link : color.text.black)} 5px, transparent 2px);
      background-size: 10px 2px, 2px 10px, 10px 2px, 2px 10px;
      background-repeat: repeat-x, repeat-y, repeat-x, repeat-y;
      background-position: left top, right bottom, right bottom, left top;
    }
  }
`;
const Uploaded = styled.div<{ twoRows: boolean }>`
  position: relative;
  box-sizing: border-box;
  border: 1px solid ${color.form.border};
  width: ${areaWidth};
  height: ${areaHeight};
  margin-right: 20px;
  display: inline-flex;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  margin-bottom: ${(props) => (props.twoRows ? "10px" : "0")};
`;
const DeleteButton = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: ${color.white};
  border-radius: 50%;
  line-height: 1;
  transition: opacity 0.3s ease;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const DisplayImage = ({ src, isZip, size }: { src: string; isZip: boolean; size?: number }) => {
  if (isZip) return <ZipPlaceHolder size={size} />;
  return <img src={src} alt="" />;
};

// 本体
const UploadForm = ({
  showImages = [],
  unUploadedImages,
  setShowImages,
  setUnUploadedImages,
  isError = false,
  errorMessage = "",
  twoRows,
  single = false,
  accept = "image/*",
}: {
  showImages?: Image[];
  unUploadedImages: File[];
  setShowImages?: React.Dispatch<React.SetStateAction<Image[]>>;
  setUnUploadedImages: React.Dispatch<React.SetStateAction<File[]>>;
  isError?: boolean;
  errorMessage?: string;
  twoRows?: boolean;
  single?: boolean;
  accept?: "image/*" | "application/zip";
}) => {
  const plusAllowed = (showImages.length === 0 && unUploadedImages.length === 0) || !single;

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setUnUploadedImages((prevImages) => [...prevImages, ...acceptedFiles]);
    },
    [setUnUploadedImages]
  );
  const onShowImageDelete = useCallback(
    (imageId) => {
      if (setShowImages) {
        setShowImages((prevImages) => prevImages.filter((image) => imageId !== image.id));
      }
    },
    [setShowImages]
  );
  const onUnuploadedImageDelete = useCallback(
    (index) => {
      setUnUploadedImages((prevImages) => prevImages.filter((_, i) => i !== index));
    },
    [setUnUploadedImages]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      [accept]: [],
    },
    multiple: true,
    onDrop,
  });

  return (
    <Wrapper>
      <FileArea>
        <FileAreaInner>
          {showImages &&
            showImages.map((image) => (
              <Uploaded key={image.id} twoRows={!!twoRows}>
                <DisplayImage src={image.url} isZip={image.url.endsWith(".zip")} />
                <DeleteButton onClick={() => onShowImageDelete(image.id)}>
                  <FontAwesomeIcon icon={faCircleXmark} size="lg" />
                </DeleteButton>
              </Uploaded>
            ))}
          {unUploadedImages.map((image, index) => (
            <Uploaded key={`${image.name}_index_${index}`} twoRows={!!twoRows}>
              <DisplayImage
                src={URL.createObjectURL(image)}
                isZip={image.type === "application/zip"}
                size={image.size}
              />
              <DeleteButton onClick={() => onUnuploadedImageDelete(index)}>
                <FontAwesomeIcon icon={faCircleXmark} size="lg" />
              </DeleteButton>
            </Uploaded>
          ))}
          {plusAllowed && (
            <NotFileUpload isDrag={isDragActive} {...getRootProps({ refKey: "innerRef" })}>
              <FontAwesomeIcon icon={faPlusCircle} size="lg" />
            </NotFileUpload>
          )}
        </FileAreaInner>
        <Input {...getInputProps()} />
      </FileArea>
      <ErrorArea isErrorDisplay={isError}>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </ErrorArea>
    </Wrapper>
  );
};

export default UploadForm;
