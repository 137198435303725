import React from "react";
import styled from "styled-components";

import color from "constants/color";

// パーツ定義
const Overlay = styled.div<{ isVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${(props) => (props.isVisible ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  z-index: 2;
`;
const Content = styled.div`
  background-color: ${color.white};
`;

const stopPropagation = (e: React.MouseEvent<HTMLElement>) => e.stopPropagation();

// 本体
const Modal = ({
  isVisible,
  children,
  onClickOutside,
}: {
  isVisible: boolean;
  children: JSX.Element;
  onClickOutside: () => void;
}) => {
  return (
    <Overlay onClick={onClickOutside} isVisible={isVisible}>
      <Content onClick={stopPropagation}>{children}</Content>
    </Overlay>
  );
};

export default Modal;
