import { signInWithPopup } from "firebase/auth";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Button from "components/Button";
import color from "constants/color";
import { useFirebaseContext } from "contexts/FirebaseContext";
import { ReactComponent as LanditSvg } from "images/landit.svg";

// パーツ定義
const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Area = styled.div`
  width: 450px;
  background-color: ${color.white};
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const LanditLogo = styled(LanditSvg)`
  width: 250px;
  padding: 40px 100px 40px 100px;
  border-bottom: solid 1px ${color.border};
`;
const ButtonWrap = styled.div`
  width: 70%;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Login = () => {
  const { auth, provider } = useFirebaseContext();
  const navigate = useNavigate();

  const login = async () => {
    await signInWithPopup(auth, provider);
    navigate("../", { replace: true });
  };

  return (
    <Wrapper>
      <Area>
        <LanditLogo />
        <ButtonWrap>
          <Button type="primary" onClick={login} label="Googleログイン" />
        </ButtonWrap>
      </Area>
    </Wrapper>
  );
};

export default Login;
