import axios, { AxiosError } from "axios";
import { Auth } from "firebase/auth";
import useSWR from "swr";
import { BareFetcher, SWRConfiguration } from "swr/dist/types";

import { useFirebaseContext } from "contexts/FirebaseContext";

const isDevelopEnv = ["local", "dev", "staging"].includes(process.env.REACT_APP_ENV ?? "");

const fetcher = async (url: string | null, auth: Auth) => {
  if (!url) return;

  axios.interceptors.request.use((request) => {
    if (isDevelopEnv)
      console.log("リクエストデータ：", {
        url: request.url,
        method: request.method,
        headers: request.headers,
        data: request.data,
      });
    return request;
  });

  axios.interceptors.response.use(
    (response) => {
      if (isDevelopEnv) console.log("レスポンスデータ：", response.data.pageInfo);
      return response;
    },
    (error: AxiosError) => {
      return Promise.reject(error);
    }
  );

  const token = await auth.currentUser?.getIdToken();
  const response = await axios
    .get(`${process.env.REACT_APP_LANDIT_ADMIN_API_URL}/aimoParking${url}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error: AxiosError) => {
      if (isDevelopEnv) console.error("エラー：", error?.response?.data ?? error);
      throw error;
    });

  return response.data;
};

export const useSwr = <T,>(url: string | null, config?: SWRConfiguration<T, Error, BareFetcher<T>>) => {
  const { auth } = useFirebaseContext();
  const { data, error, isValidating } = useSWR<T>([url, auth], fetcher, {
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: false,
    ...config,
  });
  return { data, error, isValidating };
};
