import React, { useEffect, useState } from "react";

import { Board, TitleArea, Title, PageTop } from "components/LayoutParts";
import { useDebounce } from "hooks/useDebounce";
import ActionArea from "pages/SyncPort/Company/features/DataTable/ActionArea";
import { useFindAllUser } from "pages/SyncPort/User/features/api";
import DataTable from "pages/SyncPort/User/features/DataTable";
import { jumpUpToElement } from "utils/animationUtils";

const User = () => {
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [pagination, setPagination] = useState<GraphqlPageOption>({});
  const [searchKeyword, setSearchKeyword] = useState("");
  const { debouncedValue: debouncedSearchKeyword } = useDebounce(searchKeyword, 500);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPageIndex(1);
    setSearchKeyword(e.target.value);
  };

  const { data, pageInfo } = useFindAllUser({
    searchKeyword: debouncedSearchKeyword,
    after: pagination?.after,
    before: pagination?.before,
    first: pagination?.first,
    last: pagination?.last,
  });

  useEffect(() => {
    setTotalPages(pageInfo?.totalPages ?? 1);
    return () => {
      jumpUpToElement("root");
    };
  }, [pageInfo?.totalPages]);

  return (
    <>
      <Board>
        <PageTop />
        <TitleArea>
          <Title>ユーザ</Title>
        </TitleArea>
        <ActionArea searchKeyword={searchKeyword} onSearchChange={onSearchChange} />
        <DataTable
          userList={data}
          totalPages={totalPages}
          pageIndex={currentPageIndex}
          setPageIndex={setCurrentPageIndex}
          pageInfo={pageInfo}
          setPagination={setPagination}
        />
      </Board>
    </>
  );
};

export default User;
