import { useCallback } from "react";

import { useAimoParkingClient } from "hooks/aimoParking/useAimoParkingClient";
import { useSwr } from "hooks/aimoParking/useSwr";
import { AIMO_PARKING_PAGINATION_DEFAULT_SIZE } from "pages/AimoParking/constants/pagination";
import { formatQueryParams } from "utils/stringUtils";

export const useFindAllCompany = ({
  pageIndex,
  orderBy,
  order,
  keyword,
  pageSize = AIMO_PARKING_PAGINATION_DEFAULT_SIZE,
}: {
  pageIndex?: number;
  orderBy?: string;
  order?: string;
  keyword?: string;
  pageSize?: number;
}) => {
  const { data, error, isValidating } = useSwr<AimoParkingCompaniesResponse>(
    `/company?${formatQueryParams({ keyword, pageSize, pageIndex, order, orderBy })}`
  );

  return {
    data: data?.data,
    pageInfo: data?.pagination && {
      hasMore: data.pagination.hasMore,
      pageIndex: data.pagination.pageIndex,
      totalPages: data.pagination.totalPages,
      totalItems: data.pagination.totalItems,
    },
    error,
    isValidating,
  };
};

export const useFindCompanyById = ({ id, revalidateOnFocus = true }: { id: string; revalidateOnFocus?: boolean }) => {
  const { data, error, isValidating } = useSwr<AimoParkingCompany>(`/company/${id}`, {
    revalidateOnFocus,
  });

  return { data, error, isValidating };
};

export const useCreateCompany = () => {
  const client = useAimoParkingClient();

  const create = useCallback(
    async (data: AimoParkingCreateCompanyParameter) => {
      return await client.execute({
        method: "POST",
        url: "/company",
        data,
      });
    },
    [client]
  );

  return { create };
};

export const useUpdateCompany = () => {
  const client = useAimoParkingClient();

  const update = useCallback(
    async (id: string, data: AimoParkingUpdateCompanyParameter) => {
      return await client.execute({
        method: "PUT",
        url: `/company/${id}`,
        data,
      });
    },
    [client]
  );

  return { update };
};

export const useRemoveCompany = () => {
  const client = useAimoParkingClient();

  const remove = useCallback(
    async (id: string) => {
      return await client.execute({
        method: "DELETE",
        url: `/company/${id}`,
      });
    },
    [client]
  );

  return { remove };
};
