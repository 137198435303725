import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

import color from "constants/color";
import font from "constants/font";

const CurrentPageWrap = styled.span`
  font-size: ${font.size24};
  font-weight: bold;
  line-height: 40px;
`;
const LinkWrap = styled.span`
  font-size: ${font.size24};
  color: ${color.text.link};
  font-weight: bold;
  line-height: 40px;
  &:hover {
    cursor: pointer;
  }
  flex-shrink: 0;
`;

type BreadcrumbItem = {
  pageName: string;
  onClick?: () => void;
};

/**
 * パンくずリスト
 *
 * @param currentPageName 現在のページ名
 * @param breadcrumbItems パンくずリストに表示するページ名とリンク先の配列
 * @returns
 */
const Breadcrumb = ({
  currentPageName,
  breadcrumbItems,
}: {
  currentPageName: string;
  breadcrumbItems: BreadcrumbItem[];
}) => {
  return (
    <>
      {breadcrumbItems.map((breadcrumbItem, index) => (
        <React.Fragment key={index}>
          <LinkWrap onClick={breadcrumbItem.onClick}>{breadcrumbItem.pageName}</LinkWrap>
          <FontAwesomeIcon
            icon={faAngleRight}
            style={{
              fontSize: font.size20,
              color: color.text.gray,
              margin: "0 10px",
            }}
          />
        </React.Fragment>
      ))}
      <CurrentPageWrap>{currentPageName}</CurrentPageWrap>
    </>
  );
};

export default Breadcrumb;
