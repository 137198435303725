import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Button from "components/Button";
import { Board, TitleArea, FormRow, ContentArea as BaseContextArea, Title } from "components/LayoutParts";
import font from "constants/font";
import { useFindAiSoftwareVersion } from "pages/AimoParking/AiSoftware/api";
import { dateFormat } from "utils/dateTimeUtils";

const Label = styled.div`
  min-width: 160px;
  margin: 0 5px;
`;

const Value = styled.div<{ break?: boolean }>`
  font-size: ${font.size16};
  word-break: ${(props) => (props.break ? "break-all" : "normal")};
`;

const FormWrap = styled.div`
  flex: 1;
`;

const ContentArea = styled(BaseContextArea)`
  flex-direction: row;
  margin-bottom: 0;
`;

const ButtonWrap = styled.div`
  display: flex;
  position: relative;
  margin: 16px 12px 0 -12px;
  right: -32px;
`;

const AiSoftware = () => {
  const { data: aiSoftware } = useFindAiSoftwareVersion();
  const navigate = useNavigate();

  return (
    <Board>
      <TitleArea>
        <Title>AIバージョン</Title>
      </TitleArea>
      {aiSoftware && (
        <>
          <ContentArea>
            <FormWrap>
              <FormRow>
                <Label>現在のバージョン</Label>
                <Value>{aiSoftware.version}</Value>
              </FormRow>
              <FormRow>
                <Label>ダウンロードパス</Label>
                <Value break>{aiSoftware.downloadPath}</Value>
              </FormRow>
              <FormRow>
                <Label>リリース日時</Label>
                <Value>{dateFormat(aiSoftware.releaseDate, "YYYY/MM/DD")}</Value>
              </FormRow>
              <FormRow>
                <Label>更新日時</Label>
                <Value>{dateFormat(aiSoftware.updatedAt, "YYYY/MM/DD")}</Value>
              </FormRow>
            </FormWrap>
            <ButtonWrap>
              <Button
                label="AIバージョンを更新"
                onClick={() => navigate(`/aimo_parking/ai_software/update`)}
                type="primary"
                width="177px"
              />
            </ButtonWrap>
          </ContentArea>
        </>
      )}
    </Board>
  );
};

export default AiSoftware;
