import { useNavigate, useParams } from "react-router-dom";

import { FieldName, FieldValueWithBorder, Heading, Row, Table } from "components/LayoutParts";
import Loading from "components/Loading";
import PaginationForGraphql from "components/PaginationForGraphql";
import SortButton from "components/SortButton";
import { SyncPortCompanyPropertySortKey } from "pages/SyncPort/Company/Property/features/api";

const propertyTableColumn: {
  label: string;
  flex: number;
  sortKey: SyncPortCompanyPropertySortKey;
}[] = [
  {
    label: "駐車場",
    flex: 24.8,
    sortKey: "name",
  },
  {
    label: "所在地",
    flex: 24.6,
    sortKey: "address",
  },
  {
    label: "物件No.",
    flex: 14,
    sortKey: "controlNumber",
  },
  {
    label: "空車",
    flex: 14,
    sortKey: "nmberOfFree",
  },
  {
    label: "空き予定",
    flex: 14,
    sortKey: "nmberOfFreeSchedule",
  },
  {
    label: "担当",
    flex: 14,
    sortKey: "personInCharge",
  },
];

const DataTable = ({
  propertyList,
  totalPages,
  pageIndex,
  setPageIndex,
  pageInfo,
  setPagination,
  sortOption,
  setSortOption,
}: {
  propertyList?: SyncPortPropertyList;
  totalPages: number;
  pageIndex: number;
  setPageIndex: React.Dispatch<React.SetStateAction<number>>;
  pageInfo?: GraphqlPageInfo;
  setPagination: React.Dispatch<React.SetStateAction<GraphqlPageOption>>;
  sortOption: SortOption<SyncPortCompanyPropertySortKey>;
  setSortOption: React.Dispatch<React.SetStateAction<SortOption<SyncPortCompanyPropertySortKey>>>;
}) => {
  const navigate = useNavigate();
  const { id: companyId } = useParams();
  const onClickRow = (propertyId: string) => navigate(`/sync_port/company/${companyId}/property/${propertyId}`);

  const handleSetSort = (sortOption: SortOption<SyncPortCompanyPropertySortKey>) => {
    setPagination((prev) => ({ ...prev, after: undefined, before: undefined }));
    setPageIndex(1);
    setSortOption(sortOption);
  };

  return (
    <>
      <Table>
        <Heading>
          {propertyTableColumn.map((column, i) => (
            <FieldName
              key={column.label}
              flex={column.flex}
              isSort={sortOption.sortByColumn === column.sortKey}
              lastColumn={i === propertyTableColumn.length - 1}
            >
              {column.label}
              <SortButton currentSortOption={sortOption} sortByKey={column.sortKey} setSortOption={handleSetSort} />
            </FieldName>
          ))}
        </Heading>
        {propertyList == null ? (
          <Loading />
        ) : propertyList.length > 0 ? (
          propertyList.map((property) => (
            <Row key={property.id} onClick={() => onClickRow(property.id)}>
              <FieldValueWithBorder flex={propertyTableColumn[0].flex}>{`${property.name}`}</FieldValueWithBorder>
              <FieldValueWithBorder flex={propertyTableColumn[1].flex}>{property.address}</FieldValueWithBorder>
              <FieldValueWithBorder flex={propertyTableColumn[2].flex}>{property.controlNumber}</FieldValueWithBorder>
              <FieldValueWithBorder
                flex={propertyTableColumn[3].flex}
              >{`${property.availability.numberOfFree}台`}</FieldValueWithBorder>
              <FieldValueWithBorder
                flex={propertyTableColumn[4].flex}
              >{`${property.availability.numberOfFreeSchedule}台`}</FieldValueWithBorder>
              <FieldValueWithBorder flex={propertyTableColumn[5].flex} lastColumn>
                {property.officer.name}
              </FieldValueWithBorder>
            </Row>
          ))
        ) : (
          <p>該当する物件が見つかりませんでした</p>
        )}
      </Table>
      <PaginationForGraphql
        totalPageCount={totalPages}
        currentPageNumber={pageIndex}
        setCurrentPageNumber={setPageIndex}
        setPagination={setPagination}
        startCursor={pageInfo?.startCursor ?? ""}
        endCursor={pageInfo?.endCursor ?? ""}
        hasNextPage={pageInfo?.hasNextPage ?? false}
        hasPreviousPage={pageInfo?.hasPreviousPage ?? false}
      />
    </>
  );
};

export default DataTable;
