import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import Button from "components/Button";
import { PageTop, TitleArea } from "components/LayoutParts";
import Loading from "components/Loading";
import { sortDirection } from "constants/direction";
import { useDebounce } from "hooks/useDebounce";
import { useFindCompanyById } from "pages/AimoParking/Company/api";
import { AIMO_PARKING_USER_ROLE, AimoParkingUserRole } from "pages/AimoParking/constants/aimoParkingUserRole";
import { useFindAllUser } from "pages/AimoParking/User/api";
import DataTable from "pages/AimoParking/User/DataTable";
import ActionArea from "pages/AimoParking/User/DataTable/ActionArea";
import { jumpUpToElement } from "utils/animationUtils";

const Title = styled.div`
  font-size: 24px;
  margin-bottom: 8px;
`;

const OverWrapTitleArea = styled(TitleArea)`
  margin-bottom: -8px;
`;

const ButtonWrap = styled.div`
  margin-left: auto;
`;

const CompanyUser = () => {
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(1);
  const [sortOption, setSortOption] = useState<SortOption<SortByAimoParkingCompany>>({
    sortByColumn: "name",
    direction: sortDirection.desc,
  });
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedFilter, setSelectedFilter] = useState<AimoParkingUserRole>(AIMO_PARKING_USER_ROLE.ALL);

  const { debouncedValue: debouncedSearchKeyword } = useDebounce(searchKeyword, 500);
  const { id: companyId } = useParams();
  const { data: company } = useFindCompanyById({ id: companyId as string, revalidateOnFocus: false });
  const navigate = useNavigate();
  const { data, pageInfo } = useFindAllUser({
    keyword: debouncedSearchKeyword,
    pageIndex: currentPageIndex,
    orderBy: sortOption.sortByColumn,
    order: sortOption.direction,
    role: selectedFilter === "ALL" ? undefined : selectedFilter,
    companyId,
  });

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPageIndex(1);
    setSearchKeyword(e.target.value);
  };

  const onClickUserAdd = () => navigate(`/aimo_parking/company/${companyId}/company_user/create`);

  const isCompanyDeleted = !!company?.deletedAt;

  useEffect(() => {
    setTotalPages(pageInfo?.totalPages ?? 1);
    return () => {
      jumpUpToElement("root");
    };
  }, [pageInfo?.totalPages]);

  return (
    <>
      <PageTop />
      <OverWrapTitleArea>
        <Title>ユーザ情報</Title>
        {!isCompanyDeleted && (
          <ButtonWrap>
            <Button label="ユーザ情報を追加" onClick={onClickUserAdd} type="primary" />
          </ButtonWrap>
        )}
      </OverWrapTitleArea>
      <ActionArea
        searchKeyword={searchKeyword}
        onSearchChange={onSearchChange}
        selectedFilter={selectedFilter}
        onFilterButtonClick={setSelectedFilter}
      />
      {data ? (
        <DataTable
          userList={data}
          totalPages={totalPages}
          pageIndex={currentPageIndex}
          sortOption={sortOption}
          setPageIndex={setCurrentPageIndex}
          setSortOption={setSortOption}
          type="companyUser"
          companyDeleted={isCompanyDeleted}
        />
      ) : (
        <Loading />
      )}
    </>
  );
};

export default CompanyUser;
