import { useCallback } from "react";

import { useAimoParkingClient } from "hooks/aimoParking/useAimoParkingClient";
import { useSwr } from "hooks/aimoParking/useSwr";
import { useUploadAimoParkingFile } from "pages/AimoParking/api";

export const useFindAiSoftwareVersion = () => {
  const { data, error } = useSwr<AimoParkingAiSoftwareResponse>(`/ai-software`);

  return {
    data,
    error,
  };
};

export const useUpdateAiSoftwareVersion = () => {
  const client = useAimoParkingClient();
  const { uploadFiles } = useUploadAimoParkingFile({ uploadDestination: "ai-software" });

  const update = useCallback(
    async ({ file, releaseDate, version }: { version: string; file: File; releaseDate: Date }) => {
      const gsPath = await uploadFiles({ files: [file] });
      return await client.execute({
        method: "POST",
        url: `/ai-software`,
        data: {
          version,
          downloadPath: gsPath[0],
          releaseDate: releaseDate.toISOString(),
        },
      });
    },
    [client, uploadFiles]
  );

  return { update };
};
