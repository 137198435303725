import styled from "styled-components";

import color from "constants/color";
import font from "constants/font";

const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: 115px;
`;
const Label = styled.label<{ checked: boolean }>`
  width: 100%;
  padding-left: 34px;
  margin: 7px 24px 7px 0;
  &:before {
    position: absolute;
    top: 10px;
    left: 5px;
    width: 14px;
    height: 14px;
    border-radius: 100px;
    content: "";
    background-color: ${color.white};
    z-index: 1;
  }
  &:after {
    position: absolute;
    top: 5px;
    left: 0;
    width: 22px;
    height: 22px;
    border-radius: 100px;
    content: "";
    border: solid 1px ${color.form.border};
    background-color: ${color.white};
  }
  &:hover {
    cursor: pointer;
  }
  &:hover:before {
    cursor: pointer;
  }
  &:hover:after {
    cursor: pointer;
    border: 1px solid ${color.text.black};
  }
  &:not(:target):hover:after {
    border: 1px solid ${color.text.black};
  }
  ${({ checked }) =>
    checked &&
    `
      :before {
        background-color: ${color.text.link};
      }
      :after {
        border: 1px solid ${color.text.link};
        background-color: ${color.text.linkLight};
      }
      :hover:before {
        background-color: ${color.text.linkDark};
      }
      :hover:after {
        border: 1px solid ${color.text.linkLight};
        background-color: ${color.text.linkLight};
      }
      &:not(:target):hover:after {
        border: 1px solid ${color.text.linkDark};
      }
    `}
`;
const HiddenInput = styled.input.attrs({ type: "radio" })`
  display: none;
`;

const Span = styled.span`
  font-size: ${font.size14};
`;

type Choices = {
  label: string;
  value: string | number;
};

const RadioButton = ({
  id,
  value,
  choices,
  onChange,
}: {
  id: string;
  value: string | number;
  choices: Choices[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <>
      {choices.map((choice, index) => (
        <Wrap key={`${id}_${index}`}>
          <HiddenInput id={`${id}_${index}`} name={id} value={choice.value} onChange={onChange} />
          <Label checked={value === choice.value} htmlFor={`${id}_${index}`}>
            <Span>{choice.label}</Span>
          </Label>
        </Wrap>
      ))}
    </>
  );
};

export default RadioButton;
