import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import color from "constants/color";
import font from "constants/font";

export type TabBarType = {
  label: string;
  Icon: JSX.Element;
  width: number;
  onClick: () => void;
  matchUrlKey?: string[];
  gap?: number;
  isCurrent?: boolean;
};

const Wrap = styled.div`
  width: 100%;
  height: 32px;
  border-bottom: 1px solid ${color.border};
  padding-bottom: 5px;
`;

const TabLinkWrap = styled.div`
  display: flex;
  width: fit-content;
  height: 100%;
  gap: 40px;
`;

const TabLink = styled.div<{ width: number; selected: boolean; gap?: number }>`
  width: ${({ width }) => width}px;
  display: flex;
  height: 32px;
  height: 100%;
  padding: 0 0 5px 0;
  align-items: center;
  gap: ${({ gap }) => gap + "px"};
  white-space: nowrap;
  border-bottom: ${({ selected }) => (selected ? `2px solid ${color.text.black}` : color.text.gray)};
  color: ${({ selected }) => (selected ? color.text.black : color.text.gray)};
  cursor: pointer;
`;

const Label = styled.div`
  font-size: ${font.size14};
`;

const TabBar = ({ options }: { options: TabBarType[] }) => {
  const location = useLocation();

  // pathnameの最後にスラッシュを付与することで、類似した単語をmatchUrlKeyに使った場合に誤判定を防ぐ
  const pathnameAttachSlash = location.pathname + "/";

  const isMatchPathname = useCallback(
    (option: TabBarType) => {
      return option.matchUrlKey
        ? option.matchUrlKey?.filter((key) => !pathnameAttachSlash.includes(key)).length === 0
        : !!option.isCurrent;
    },
    [pathnameAttachSlash]
  );

  const onClick = ({ onClick }: { onClick: () => void; label: string }) => {
    onClick();
  };
  return (
    <Wrap>
      <TabLinkWrap>
        {options.map((option, i) => {
          return (
            <TabLink
              gap={option.gap ?? 10}
              key={i}
              width={option.width}
              selected={isMatchPathname(option)}
              onClick={() => onClick({ label: option.label, onClick: option.onClick })}
            >
              {option.Icon}
              <Label>{option.label}</Label>
            </TabLink>
          );
        })}
      </TabLinkWrap>
    </Wrap>
  );
};

export default TabBar;
