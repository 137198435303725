import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

import { AlertArea } from "components/LayoutParts";
import color from "constants/color";

const Message = styled.span`
  color: ${color.text.black};
  margin-left: 8px;
`;

const DeletedAlert = ({ message }: { message: string }) => {
  return (
    <AlertArea>
      <FontAwesomeIcon icon={faCircleExclamation} />
      <Message>{message}</Message>
    </AlertArea>
  );
};

export default DeletedAlert;
