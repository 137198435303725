import styled from "styled-components";

import color from "constants/color";
import font from "constants/font";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: -8px;
`;
const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: 115px;
`;
const Label = styled.label<{ checked: boolean; disabled?: boolean }>`
  width: 100%;
  padding-left: 34px;
  margin: 7px 24px 7px 0;
  &:before {
    position: absolute;
    top: 10px;
    left: 5px;
    width: 14px;
    height: 14px;
    border-radius: 100px;
    content: "";
    background-color: ${color.white};
    z-index: 1;
  }
  &:after {
    position: absolute;
    top: 5px;
    left: 0;
    width: 22px;
    height: 22px;
    border-radius: 100px;
    content: "";
    border: solid 1px ${color.form.border};
    background-color: ${color.white};
  }
  &:hover {
    cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  }
  &:hover:before {
    cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  }
  &:hover:after {
    cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
    border: 1px solid ${color.text.black};
  }
  &:not(:target):hover:after {
    border: 1px solid ${color.text.black};
  }
  ${({ checked }) =>
    checked &&
    `
      :before {
        background-color: ${color.text.link};
      }
      :after {
        border: 1px solid ${color.text.link};
        background-color: ${color.text.linkLight};
      }
      :hover:before {
        background-color: ${color.text.linkDark};
      }
      :hover:after {
        border: 1px solid ${color.text.linkLight};
        background-color: ${color.text.linkLight};
      }
      &:not(:target):hover:after {
        border: 1px solid ${color.text.linkDark};
      }
    `}
`;
const HiddenInput = styled.input.attrs({ type: "radio" })`
  display: none;
`;

const Span = styled.span<{ disabled?: boolean }>`
  font-size: ${font.size14};
  color: ${({ disabled }) => (disabled ? color.text.disabled : color.text.black)};
`;

type Choices = {
  label: string;
  value: string | number;
  disabled?: boolean;
};

const VerticalRadioButton = ({
  id,
  value,
  choices,
  onChange,
}: {
  id: string;
  value?: string | number;
  choices: Choices[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <Container>
      {choices.map((choice, index) => (
        <Wrap key={`${id}_${index}`}>
          <HiddenInput
            id={`${id}_${index}`}
            name={id}
            value={choice.value}
            onChange={onChange}
            disabled={choice.disabled}
          />
          <Label
            disabled={choice.disabled}
            checked={value === choice.value}
            htmlFor={`${id}_${index}`}
            aria-disabled={choice.disabled}
          >
            <Span disabled={choice.disabled}>{choice.label}</Span>
          </Label>
        </Wrap>
      ))}
    </Container>
  );
};

export default VerticalRadioButton;
