/**
 * 文字列をスネークケースに変換する
 */
const toUpperSnakeCase = (input: string): string => {
  if (!input) return "";
  const value = input.replace(/([A-Z])/g, (match) => `_${match}`).toUpperCase();
  return value;
};

export const buildSortKey = <T extends string | null>(sortOption?: SortOption<T>) => {
  if (!sortOption) return undefined;
  if (!sortOption.sortByColumn) return undefined;
  if (!sortOption.direction) return undefined;

  return `${toUpperSnakeCase(sortOption.sortByColumn)}_${sortOption.direction.toUpperCase()}`;
};

/**
 *  文字列に数字が含まれている場合、数字の前にもアンダースコアを追加するPatch関数
 */
const toUpperSnakeCasePatch = (input: string): string => {
  if (!input) return "";
  const value = input
    .replace(/([A-Z])/g, (match) => `_${match}`)
    .replace(/\d+/g, (match) => `_${match}`)
    .toUpperCase();
  return value;
};

export const buildSortKeyPatch = <T extends string | null>(sortOption?: SortOption<T>) => {
  if (!sortOption) return undefined;
  if (!sortOption.sortByColumn) return undefined;
  if (!sortOption.direction) return undefined;

  return `${toUpperSnakeCasePatch(sortOption.sortByColumn)}_${sortOption.direction.toUpperCase()}`;
};
