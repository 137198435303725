import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Button from "components/Button";
import { Board, TitleArea, Title, PageTop } from "components/LayoutParts";
import Loading from "components/Loading";
import TextButton from "components/TextButton";
import UserModal, { UserModalConfig } from "components/UserModal";
import { sortDirection } from "constants/direction";
import { useSwr } from "hooks/useSwr";
import DataTable from "pages/PitPort/ControlBox/DataTable";
import ActionArea from "pages/PitPort/ControlBox/DataTable/ActionArea";
import BrokenBoxes from "pages/PitPort/ControlBox/DataTable/BrokenBoxes";
import {
  displayControlBoxFilterStatus,
  DISPLAY_CONTROLBOX_FILTER_KEY,
} from "pages/PitPort/ControlBox/DataTable/displayFilter";
import ProgramVersion from "pages/PitPort/ControlBox/DataTable/ProgramVersion";
import { jumpUpToElement } from "utils/animationUtils";
import { formatQueryParams } from "utils/stringUtils";

// パーツ定義
const CreateWrap = styled.div`
  display: flex;
  flex: auto;
  justify-content: flex-end;
  gap: 16px;
  align-items: center;
`;
const AvtionAreaWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

// 本体
const ControlBox = () => {
  const [originList, setOriginList] = useState<ControlBox[]>([]);
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [sortOption, setSortOption] = useState<SortOption<SortByPitPortControlBox>>({
    sortByColumn: "registeredNumber",
    direction: sortDirection.desc,
  });
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedFilter, setSelectedFilter] = useState<string>(displayControlBoxFilterStatus());
  const [offlineBoxesInUse, setOfflineBoxesInUse] = useState<BrokenBox[]>([]);
  const [isModalShow, setIsModalShow] = useState(false);
  const hideModal = () => setIsModalShow(false);
  const showModal = () => setIsModalShow(true);

  const userModalConfig: UserModalConfig = {
    onClickOutside: hideModal,
    subtitle: "送信に失敗しました",
    main: {
      buttonType: "secondary",
      onClick: hideModal,
      label: "閉じる",
    },
    errorMessage: "時間をおいてもう一度お試しください",
  };

  const navigate = useNavigate();
  const navCreateProperty = () => navigate("/pit_port/control_box/create");

  const onFilterButtonClick = (status: string) => {
    setSelectedFilter(status);
    localStorage.setItem(DISPLAY_CONTROLBOX_FILTER_KEY, JSON.stringify(status));
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPageIndex(1);
    setSearchKeyword(e.target.value);
  };

  // FIXME: 対象の制御BOX全てのバージョンを取得する関数を実装する
  const confirmAllVersion = () => {
    console.log("confirmAllVersion");
  };

  // FIXME: 対象の制御BOX全てのバージョンを更新する関数を実装する
  const updateAllVersion = () => {
    console.log("updateAllVersion");
  };

  const { data, error } = useSwr<PitPortControlBoxesResponse>(
    `/control-box?${formatQueryParams({
      pageIndex: currentPageIndex,
      pageSize: 100,
      status: selectedFilter,
      orderBy: sortOption.sortByColumn,
      orderType: sortOption.direction,
      keyword: searchKeyword,
    })}`
  );

  const updateOfflineBoxesInUse = (data: ControlBox[]): BrokenBox[] => {
    return data
      .filter((box) => box.propertyName != null)
      .filter((box) => box.status === 0)
      .map((box) => ({ propertyName: box.propertyName, id: box.id }));
  };

  useEffect(() => {
    jumpUpToElement("page-top");
    if (error) {
      showModal();
      return;
    }
    if (!data) return;
    setTotalPages(data.totalPages);
    setOriginList(data.data);
    setOfflineBoxesInUse(updateOfflineBoxesInUse(data.data));
  }, [data, error]);

  return (
    <>
      <Board>
        <PageTop />
        <TitleArea>
          <Title>制御BOX</Title>
          <CreateWrap>
            <TextButton label="全てのバージョンを取得" type="normal" onClick={confirmAllVersion} />
            <TextButton label="全てのバージョンを更新" type="normal" onClick={updateAllVersion} />
            <Button type="primary" onClick={navCreateProperty} label="制御BOXを追加" width="160px" />
          </CreateWrap>
        </TitleArea>
        {offlineBoxesInUse.length > 0 && BrokenBoxes(offlineBoxesInUse)}
        <AvtionAreaWrap>
          <ActionArea
            searchKeyword={searchKeyword}
            selectedFilter={selectedFilter}
            onSearchChange={onSearchChange}
            onFilterButtonClick={onFilterButtonClick}
          />
          {data && (
            <ProgramVersion
              mainVersion={data.mainLatestVersion ? data.mainLatestVersion : ""}
              standVersion={data.standLatestVersion ? data.standLatestVersion : ""}
            />
          )}
        </AvtionAreaWrap>
        {data ? (
          <DataTable
            controlBoxList={originList}
            totalPages={totalPages}
            pageIndex={currentPageIndex}
            sortOption={sortOption}
            setPageIndex={setCurrentPageIndex}
            setSortOption={setSortOption}
          />
        ) : (
          <Loading />
        )}
      </Board>
      <UserModal isVisible={isModalShow} config={userModalConfig}></UserModal>
    </>
  );
};

export default ControlBox;
