import { useNavigate, useParams } from "react-router-dom";

import { FieldName, FieldValueWithBorder, Heading, Row, Table } from "components/LayoutParts";
import Loading from "components/Loading";
import PaginationForGraphql from "components/PaginationForGraphql";
import PublicTag from "components/PublicTag";
import SortButton from "components/SortButton";
import { SyncPortSpaceGroupSortKey } from "pages/SyncPort/Company/Property/SpaceGroup/features/api";
import { convertParkingType } from "pages/SyncPort/Company/Property/utils/convertParkingType";
import { convertSupportCarModelEnToJa } from "pages/SyncPort/Company/Property/utils/supportCarModelHelper";
import { convertNumberToLocaleString } from "utils/stringUtils";

const propertySpaceGroupColumn: { label: string; flex: number; sortKey?: SyncPortSpaceGroupSortKey }[] = [
  {
    label: "月額費用",
    flex: 11,
    sortKey: "monthlyRent",
  },
  {
    label: "対応車両",
    flex: 13.7,
  },
  {
    label: "識別",
    flex: 13.7,
  },
  {
    label: "備考",
    flex: 13.7,
  },
  {
    label: "区画数",
    flex: 9,
    sortKey: "nmber0fSpaces",
  },
  {
    label: "空車",
    flex: 9,
    sortKey: "nmber0fFree",
  },
  {
    label: "空き予定",
    flex: 9,
    sortKey: "nmber0fFreeSchedule",
  },
  {
    label: "満車",
    flex: 9,
    sortKey: "nmber0fOccupied",
  },
  {
    label: "利用状況",
    flex: 9,
    sortKey: "isAvailabilityPublic",
  },
  {
    label: "公開設定",
    flex: 9,
    sortKey: "isPublic",
  },
];

const DataTable = ({
  spaceGroupList,
  totalPages,
  pageIndex,
  setPageIndex,
  pageInfo,
  setPagination,
  setSortOption,
  sortOption,
}: {
  spaceGroupList?: SyncPortSpaceGroupList;
  totalPages: number;
  pageIndex: number;
  setPageIndex: React.Dispatch<React.SetStateAction<number>>;
  pageInfo?: GraphqlPageInfo;
  setPagination: React.Dispatch<React.SetStateAction<GraphqlPageOption>>;
  sortOption: SortOption<SyncPortSpaceGroupSortKey>;
  setSortOption: React.Dispatch<React.SetStateAction<SortOption<SyncPortSpaceGroupSortKey>>>;
}) => {
  const navigate = useNavigate();
  const { id, propertyId } = useParams();
  const onClickRow = (spaceGroupId: string) =>
    navigate(`/sync_port/company/${id}/property/${propertyId}/${spaceGroupId}`);

  const handleSetSort = (sortOption: SortOption<SyncPortSpaceGroupSortKey>) => {
    setPagination((prev) => ({ ...prev, after: undefined, before: undefined }));
    setPageIndex(1);
    setSortOption(sortOption);
  };

  return (
    <>
      <Table>
        <Heading>
          {propertySpaceGroupColumn.map((column) => (
            <FieldName
              key={column.label}
              flex={column.flex}
              lastColumn={column.label === propertySpaceGroupColumn[propertySpaceGroupColumn.length - 1].label}
            >
              {column.label}
              {column.sortKey && (
                <SortButton currentSortOption={sortOption} sortByKey={column.sortKey} setSortOption={handleSetSort} />
              )}
            </FieldName>
          ))}
        </Heading>
        {spaceGroupList == null ? (
          <Loading />
        ) : spaceGroupList.length > 0 ? (
          spaceGroupList.map((spaceGroup) => (
            <Row key={spaceGroup.id} onClick={() => onClickRow(spaceGroup.id)}>
              <FieldValueWithBorder flex={propertySpaceGroupColumn[0].flex}>
                {convertNumberToLocaleString(spaceGroup.monthlyRent.value)}
              </FieldValueWithBorder>
              <FieldValueWithBorder flex={propertySpaceGroupColumn[1].flex}>
                {convertSupportCarModelEnToJa(spaceGroup.supportCarModel)}
              </FieldValueWithBorder>
              <FieldValueWithBorder flex={propertySpaceGroupColumn[2].flex}>
                {convertParkingType(spaceGroup.feature.type)}
              </FieldValueWithBorder>
              <FieldValueWithBorder flex={propertySpaceGroupColumn[3].flex}>{spaceGroup.remarks}</FieldValueWithBorder>
              <FieldValueWithBorder flex={propertySpaceGroupColumn[4].flex}>
                {`${spaceGroup.availability.totalCount}台`}
              </FieldValueWithBorder>
              <FieldValueWithBorder flex={propertySpaceGroupColumn[5].flex}>
                {`${spaceGroup.availability.numberOfFree}台`}
              </FieldValueWithBorder>
              <FieldValueWithBorder flex={propertySpaceGroupColumn[6].flex}>
                {`${spaceGroup.availability.numberOfFreeSchedule}台`}
              </FieldValueWithBorder>
              <FieldValueWithBorder flex={propertySpaceGroupColumn[7].flex}>
                {`${spaceGroup.availability.numberOfOccupied}台`}
              </FieldValueWithBorder>
              <FieldValueWithBorder flex={propertySpaceGroupColumn[8].flex}>
                <PublicTag isPublic={spaceGroup.isAvailabilityPublic} />
              </FieldValueWithBorder>
              <FieldValueWithBorder flex={propertySpaceGroupColumn[9].flex} lastColumn>
                <PublicTag isPublic={spaceGroup.isPublic} />
              </FieldValueWithBorder>
            </Row>
          ))
        ) : (
          <p>該当する区画グループが見つかりませんでした</p>
        )}
      </Table>
      <PaginationForGraphql
        totalPageCount={totalPages}
        currentPageNumber={pageIndex}
        setCurrentPageNumber={setPageIndex}
        setPagination={setPagination}
        startCursor={pageInfo?.startCursor ?? ""}
        endCursor={pageInfo?.endCursor ?? ""}
        hasNextPage={pageInfo?.hasNextPage ?? false}
        hasPreviousPage={pageInfo?.hasPreviousPage ?? false}
      />
    </>
  );
};

export default DataTable;
