import { useNavigate } from "react-router-dom";

import { FieldName, FieldValue, Heading, Row, Table } from "components/LayoutParts";
import Pagination from "components/Pagination";
import SortButton from "components/SortButton";

const companyTableColumn = [
  {
    label: "ID",
    sortKey: "id",
    flex: 1,
  },
  {
    label: "会社名",
    sortKey: "name",
    flex: 8,
  },
  {
    label: "電話番号",
    sortKey: "phoneNumber",
    flex: 4,
  },
  {
    label: "請求先メールアドレス",
    sortKey: "email",
    flex: 6,
  },
  {
    label: "ユーザ",
    sortKey: "totalMembers",
    flex: 1,
  },
  {
    label: "状態",
    sortKey: "status",
    flex: 2,
  },
] as const;

const statusDisplayValue = (status: number): string => {
  switch (status) {
    case 0:
      return "利用停止";
    case 1:
      return "-";
    case 5:
      return "削除済み";
    default:
      return "";
  }
};

const DataTable = ({
  companyList,
  totalPages,
  pageIndex,
  sortOption,
  setPageIndex,
  setSortOption,
}: {
  companyList: Company[];
  totalPages: number;
  pageIndex: number;
  sortOption: SortOption<SortByPitPortCompany>;
  setPageIndex: React.Dispatch<React.SetStateAction<number>>;
  setSortOption: React.Dispatch<React.SetStateAction<SortOption<SortByPitPortCompany>>>;
}) => {
  const navigate = useNavigate();
  const onClickRow = (id: number) => navigate(`/pit_port/company/${id}`);
  return (
    <>
      <Table>
        <Heading>
          {companyTableColumn.map((column) => (
            <FieldName key={column.label} flex={column.flex} isSort={sortOption.sortByColumn === column.sortKey}>
              {column.label}
              <SortButton currentSortOption={sortOption} sortByKey={column.sortKey} setSortOption={setSortOption} />
            </FieldName>
          ))}
        </Heading>
        {companyList.map((company) => (
          <Row key={company.id} onClick={() => onClickRow(company.id)}>
            <FieldValue flex={companyTableColumn[0].flex}>{company.id}</FieldValue>
            <FieldValue flex={companyTableColumn[1].flex}>{company.name}</FieldValue>
            <FieldValue flex={companyTableColumn[2].flex}>{company.phoneNumber}</FieldValue>
            <FieldValue flex={companyTableColumn[3].flex}>{company.email}</FieldValue>
            <FieldValue flex={companyTableColumn[4].flex}>{company.totalMembers}</FieldValue>
            <FieldValue flex={companyTableColumn[5].flex}>{statusDisplayValue(company.status)}</FieldValue>
          </Row>
        ))}
      </Table>
      <Pagination totalPages={totalPages} pageIndex={pageIndex} setPageIndex={setPageIndex} />
    </>
  );
};

export default DataTable;
