import { useCallback } from "react";

import { useSwr } from "hooks/syncPort/useSwr";
import { useSyncPortClient } from "hooks/syncPort/useSyncPortClient";
import { SyncPortCreateProperty } from "pages/SyncPort/Company/Property/utils/parseProperties";
import { formatQueryParams } from "utils/stringUtils";

export const SYNC_PORT_COMPANY_LIST_DEFAULT_SIZE = 25;

export const useFindCompanies = ({
  searchKeyword,
  after,
  before,
  first,
  last,
}: {
  searchKeyword: string;
  after?: string;
  before?: string;
  first?: number;
  last?: number;
}) => {
  const { data, error } = useSwr<SyncPortCompaniesResponse>(
    `/company?${formatQueryParams({
      keyword: searchKeyword,
      after,
      before,
      first,
      last,
    })}`
  );

  return {
    data: data?.data,
    pageInfo: data?.pageInfo,
    error,
  };
};

export const useCreateCompany = () => {
  const client = useSyncPortClient();

  const createCompany = useCallback(
    async ({
      name,
      branchName,
      address,
      phoneNumber,
      faxNumber,
      companyHp,
      openingHour,
      openingMinute,
      closingHour,
      closingMinute,
    }: CreateCompanyParameter) => {
      const openingTimeNotSet =
        openingHour === "" && openingMinute === "" && closingHour === "" && closingMinute === "";
      const response = await client
        .client<SyncPortCompany>({
          method: "POST",
          url: "/company",
          data: {
            name,
            branchName,
            address,
            phoneNumber,
            faxNumber: faxNumber != "" ? faxNumber : null,
            url: companyHp != "" ? companyHp : undefined,
            openingTime: openingTimeNotSet ? undefined : `${openingHour}:${openingMinute}:00`,
            closingTime: openingTimeNotSet ? undefined : `${closingHour}:${closingMinute}:00`,
          },
        })
        .catch((e) => {
          throw e;
        });
      return {
        data: response.data,
        status: response.status,
      };
    },
    [client]
  );

  return {
    createCompany,
  };
};

export const useFindCompanyById = ({ companyId }: { companyId?: string }) => {
  const { data, error } = useSwr<SyncPortCompany>(companyId != null ? `/company/${companyId}` : null);

  return {
    data,
    error,
  };
};

export const useFindCompanyMembers = ({ companyId }: { companyId: string }) => {
  const { data, error } = useSwr<SyncPortCompanyMembersResponse>(`/user?${formatQueryParams({ companyId })}`);

  return {
    data: data?.data,
    error,
  };
};

export const useUpdateCompany = () => {
  const client = useSyncPortClient();

  const updateCompany = useCallback(
    async ({
      id,
      name,
      branchName,
      address,
      phoneNumber,
      faxNumber,
      companyHp,
      openingHour,
      openingMinute,
      closingHour,
      closingMinute,
    }: UpdateCompanyParameter) => {
      const openingTimeNotSet =
        openingHour === "" && openingMinute === "" && closingHour === "" && closingMinute === "";
      const response = await client
        .client<SyncPortCompany>({
          method: "PUT",
          url: `/company/${id}`,
          data: {
            name,
            branchName,
            address,
            phoneNumber,
            faxNumber: faxNumber != "" ? faxNumber : null,
            url: companyHp,
            openingTime: openingTimeNotSet ? undefined : `${openingHour}:${openingMinute}:00`,
            closingTime: openingTimeNotSet ? undefined : `${closingHour}:${closingMinute}:00`,
          },
        })
        .catch((e) => {
          throw e;
        });
      return {
        data: response.data,
        status: response.status,
      };
    },
    [client]
  );

  return {
    updateCompany,
  };
};

export const useDeleteCompany = () => {
  const client = useSyncPortClient();

  const deleteCompany = useCallback(
    async ({ id }: { id: string }) => {
      const response = await client
        .client<{
          id: string;
          deletedAt: string;
        }>({
          method: "DELETE",
          url: `/company/${id}`,
        })
        .catch((e) => {
          throw e;
        });
      return {
        data: response.data,
        status: response.status,
      };
    },
    [client]
  );

  return {
    deleteCompany,
  };
};

export const useBulkInsertProperty = () => {
  const client = useSyncPortClient();

  const bulkInsertProperty = useCallback(
    async ({ companyId, properties }: { companyId: string; properties: SyncPortCreateProperty[] }) => {
      const response = await client
        .client<SyncPortProperty[]>({
          method: "POST",
          url: `/company/${companyId}/property/bulk-insert`,
          data: {
            properties,
          },
        })
        .catch((e) => {
          throw e;
        });
      return {
        ok: response.status == 201,
        data: response.data,
        status: response.status,
      };
    },
    [client]
  );

  return {
    bulkInsertProperty,
  };
};
