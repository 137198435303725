/**
 * 全角数字を半角数字に変換
 * @example
 * zenkakuToHankaku("１２３４５６７８９０"); // "1234567890"
 *
 */
export const zenkakuToHankaku = (str: string): string => {
  return str.replace(/[０-９]/g, (s) => {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });
};

/**
 * 引数valueが数値に変換できる場合は数値に変換し、変換できない場合はundefinedを返す
 * @example
 * safeCast(" "); // undefined
 * safeCast(""); // undefined
 * safeCast("1"); // 1
 * safeCast("", true); // 0
 */
export const saleCast = (value: string, undefinedWhenEmpty = false): number | undefined => {
  const hankakuValue = zenkakuToHankaku(value);
  const numberValue = Number(hankakuValue);
  return isNaN(numberValue) ? (undefinedWhenEmpty ? undefined : 0) : numberValue;
};

/**
 * 引数valueが数値に変換できる場合は数値に変換し、変換できない場合はundefinedを返す
 * @returns number
 */
export const validateContractYears = (years: number): number => {
  if (1 > years) return 1;
  return years;
};

export const validatePhoneNumber = (phoneNumber: string): string => {
  // 空文字の場合は空文字を返す
  if (!phoneNumber) return "";

  // -を削除
  const replacedPhoneNumber = phoneNumber.replace(/-/g, "");

  // 070, 080, 090で始まる11桁の電話番号（携帯番号）
  if (/^(070|080|090)[0-9]{8}$/.test(replacedPhoneNumber)) {
    return replacedPhoneNumber;
  }

  // 0から始まる10桁の電話番号（固定電話）
  if (/^0[0-9]{9}$/.test(replacedPhoneNumber)) {
    return replacedPhoneNumber;
  }

  return "";
};
