import useClient from "hooks/atPort/useClient";
import { useSwr } from "hooks/atPort/useSwr";

export const useFindPlaceCodes = () => {
  const { data } = useSwr<DandoriWorksPlaceCode[]>("/dandori-works/integrations");

  return { data };
};

export const useFindPlaceCode = (id: string) => {
  const { data } = useSwr<DandoriWorksPlaceCode>(`/dandori-works/integrations/${id}`);

  return { data };
};

export const useCreatePlaceCode = () => {
  const client = useClient();

  const create = async (data: { placeCode: string; accountId: string; companyName: string; contactId: string }) => {
    const { status } = await client.client({
      method: "POST",
      url: "/dandori-works/integrations",
      data,
    });
    return status;
  };

  return { create };
};

export const useUpdatePlaceCode = () => {
  const client = useClient();

  const update = async (
    id: string,
    data: { placeCode: string; accountId: string; companyName: string; contactId: string }
  ) => {
    const { status } = await client.client({
      method: "PUT",
      url: `/dandori-works/integrations/${id}`,
      data,
    });
    return status;
  };

  return { update };
};

export const useRemovePlaceCode = () => {
  const client = useClient();

  const remove = async (id: string) => {
    const { status } = await client.client({
      method: "DELETE",
      url: `/dandori-works/integrations/${id}`,
    });
    return status;
  };

  return { remove };
};
