import { isEmail } from "class-validator";
import React, { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import Breadcrumb from "components/Breadcrumb";
import Button from "components/Button";
import { TitleArea, FooterArea, ContentArea as BaseContentArea, FormRow, Board } from "components/LayoutParts";
import Loading from "components/Loading";
import TextForm from "components/TextForm";
import UserModal, { UserModalConfig } from "components/UserModal";
import VerticalRadioButton from "components/VerticalRadioButton";
import color from "constants/color";
import font from "constants/font";
import { useFindCompanyById } from "pages/AimoParking/Company/api";
import { AIMO_PARKING_USER_ROLE_LABEL_CHOICES } from "pages/AimoParking/constants/aimoParkingUserRole";
import { AIMO_PARKING_REGEX_RULES } from "pages/AimoParking/constants/validation";
import { FAILED_MODAL_CONFIG, INITIAL_MODAL_CONFIG, SUCCEED_MODAL_CONFIG } from "pages/AimoParking/modalTemplate";
import { useCreateUser } from "pages/AimoParking/User/api";
import { detectIsVendor } from "pages/AimoParking/utils/detectIsVendorUtil";
import { AIMO_PARKING_ERROR_MESSAGE, extractErrorCode } from "pages/AimoParking/utils/extractErrorCodeUtil";

// パーツ定義
const CancelWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;
const Wrap = styled.div`
  margin-top: 10px;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  flex: 1;
`;
const ContentWrap = styled.div`
  gap: 16px;
`;
const ContentArea = styled(BaseContentArea)`
  display: flex;
`;
const SaveWrap = styled(CancelWrap)`
  align-items: center;
  justify-content: flex-end;
`;
const Annotation = styled.span`
  font-size: ${font.size12};
  color: ${color.text};
  margin-right: 16px;
`;
const Label = styled.div<{ top?: boolean }>`
  min-width: 200px;
  margin: 0 5px;
  padding-right: 18px;
  align-self: ${({ top }) => (top ? "flex-start" : "center")};
  margin-top: ${({ top }) => (top ? "14px" : "0")};
`;
const SubLabelLeft = styled.div`
  width: 32px;
  margin-right: 24px;
`;
const SubLabelRight = styled.div`
  width: 32px;
  margin: 0 24px;
`;

const CreateUser = () => {
  // フォームの値
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastNameKana, setLastNameKana] = useState("");
  const [firstNameKana, setFirstNameKana] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState<AimoParkingUserRole | undefined>();
  const [modalConfig, setModalConfig] = useState<UserModalConfig>(INITIAL_MODAL_CONFIG);
  const [isVisible, setIsVisible] = useState(false);

  const navigate = useNavigate();
  const { create } = useCreateUser();
  const { id: companyId } = useParams();
  const { data: company, isValidating: isLoadingCompany } = useFindCompanyById({
    id: companyId as string,
    revalidateOnFocus: false,
  });

  const changeLastName = (e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value);
  const changeFirstName = (e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value);
  const changeLastNameKana = (e: React.ChangeEvent<HTMLInputElement>) => setLastNameKana(e.target.value);
  const changeFirstNameKana = (e: React.ChangeEvent<HTMLInputElement>) => setFirstNameKana(e.target.value);
  const changeEmail = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);

  // バリデーション
  const isErrorLastName = lastName.length === 0 || !AIMO_PARKING_REGEX_RULES.FULL_WIDTH_CHARACTER.REGEX.test(lastName);
  const isErrorFirstName =
    firstName.length === 0 || !AIMO_PARKING_REGEX_RULES.FULL_WIDTH_CHARACTER.REGEX.test(firstName);
  const isErrorLastNameKana = lastNameKana.length === 0 || !AIMO_PARKING_REGEX_RULES.KATAKANA.REGEX.test(lastNameKana);
  const isErrorFirstNameKana =
    firstNameKana.length === 0 || !AIMO_PARKING_REGEX_RULES.KATAKANA.REGEX.test(firstNameKana);
  const isErrorEmail = !(email.length > 0) || !isEmail(email);
  const isErrorRole = role === undefined;
  const canSubmit =
    !isErrorLastName &&
    !isErrorFirstName &&
    !isErrorLastNameKana &&
    !isErrorFirstNameKana &&
    !isErrorEmail &&
    !isErrorRole;
  const isVendor = detectIsVendor(company?.vendors);

  const onSubmit = useCallback(async () => {
    // roleが0の場合に!roleでtrueになるため、role === undefinedの使用
    if (!companyId || role === undefined) return;

    const response = await create({
      companyId,
      email,
      firstName,
      firstNameFurigana: firstNameKana,
      lastName,
      lastNameFurigana: lastNameKana,
      role: role,
    }).catch((e) => {
      const error = extractErrorCode(e);
      if (error?.errorCode == AIMO_PARKING_ERROR_MESSAGE.EMAIL_ALREADY_EXISTS.ERROR_CODE) {
        setModalConfig(
          FAILED_MODAL_CONFIG({ onClose: () => setIsVisible(false), message: "既に登録されているメールアドレスです" })
        );
      } else {
        setModalConfig(FAILED_MODAL_CONFIG({ onClose: () => setIsVisible(false) }));
      }
      setIsVisible(true);
      throw e;
    });

    if (response.status === 201) {
      setModalConfig(
        SUCCEED_MODAL_CONFIG({
          message: "追加しました",
          onClose: () => navigate(`/aimo_parking/company/${companyId}/user`),
        })
      );
      setIsVisible(true);
    }
  }, [create, navigate, companyId, email, firstName, firstNameKana, lastName, lastNameKana, role]);

  return (
    <Board>
      <TitleArea>
        <Breadcrumb
          currentPageName="ユーザを追加"
          breadcrumbItems={[
            {
              pageName: `${company?.name ?? "読み込み中"}`,
              onClick: () => navigate(`/aimo_parking/company/${companyId}`),
            },
          ]}
        />
      </TitleArea>
      <Wrap>
        <ContentWrap>
          <ContentArea>
            <FormRow>
              <Label>名前</Label>
              <SubLabelLeft>姓</SubLabelLeft>
              <TextForm
                type="text"
                key="lastName"
                width="200px"
                value={lastName}
                placeholder="例）工藤"
                required
                onChange={changeLastName}
                isError={isErrorLastName}
                errorMessage="姓を入力してください"
              />
              <SubLabelRight>名</SubLabelRight>
              <TextForm
                type="text"
                key="firstName"
                width="200px"
                value={firstName}
                placeholder="例）敬三"
                required
                onChange={changeFirstName}
                isError={isErrorFirstName}
                errorMessage="名を入力してください"
              />
            </FormRow>
            <FormRow>
              <Label>名前 （カナ）</Label>
              <SubLabelLeft>セイ</SubLabelLeft>
              <TextForm
                key="lastNameKana"
                type="text"
                width="200px"
                value={lastNameKana}
                placeholder="例）クドウ"
                required
                onChange={changeLastNameKana}
                isError={isErrorLastNameKana}
                errorMessage="セイを入力してください"
              />
              <SubLabelRight>メイ</SubLabelRight>
              <TextForm
                key="firstNameKana"
                type="text"
                width="200px"
                value={firstNameKana}
                placeholder="例）ケイゾウ"
                required
                onChange={changeFirstNameKana}
                isError={isErrorFirstNameKana}
                errorMessage="メイを入力してください"
              />
            </FormRow>
            <FormRow>
              <Label>メールアドレス</Label>
              <TextForm
                type="email"
                width="350px"
                value={email}
                placeholder="例）info@landit.co.jp"
                required
                onChange={changeEmail}
                errorMessage="メールアドレスを入力してください"
                isError={isErrorEmail}
              />
            </FormRow>
            {!isLoadingCompany ? (
              <FormRow>
                <Label top>役割</Label>
                <FormRow>
                  <VerticalRadioButton
                    id="状態"
                    value={role}
                    choices={AIMO_PARKING_USER_ROLE_LABEL_CHOICES(isVendor)}
                    onChange={(e) => setRole(Number(e.target.value) as AimoParkingUserRole)}
                  />
                </FormRow>
              </FormRow>
            ) : (
              <Loading />
            )}
          </ContentArea>
        </ContentWrap>
        <FooterArea>
          <CancelWrap>
            <Button
              type="secondary"
              onClick={() => navigate(`/aimo_parking/company/${companyId}/user`)}
              label="キャンセル"
              width="160px"
            />
          </CancelWrap>
          <SaveWrap>
            <Annotation>追加するまで変更内容は反映されません</Annotation>
            <Button type="primary" onClick={onSubmit} label="このユーザを追加" disabled={!canSubmit} width="160px" />
          </SaveWrap>
        </FooterArea>
      </Wrap>

      <UserModal isVisible={isVisible} config={modalConfig} />
    </Board>
  );
};

export default CreateUser;
