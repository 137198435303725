import { isEmail, isPhoneNumber } from "class-validator";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Breadcrumb from "components/Breadcrumb";
import Button from "components/Button";
import { Board, FormRow, ContentArea, TitleArea, FooterArea } from "components/LayoutParts";
import PasswordForm from "components/PasswordForm";
import TextForm from "components/TextForm";
import UserModal, { UserModalConfig } from "components/UserModal";
import color from "constants/color";
import font from "constants/font";
import { useDebounce } from "hooks/useDebounce";
import { useSwr } from "hooks/useSwr";
import CreateCompany from "pages/PitPort/Company/CreateCompany";
import { useCreatePitPortUser } from "pages/PitPort/User/api";
import PropertySpacesTable from "pages/PitPort/User/DataTable/PropertySpacesTable";
import SearchArea from "pages/PitPort/User/DataTable/SearchArea";
import { formatQueryParams } from "utils/stringUtils";

const Label = styled.div`
  min-width: 120px;
  margin: 0 5px;
`;
const CancelWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;
const SaveWrap = styled(CancelWrap)`
  align-items: center;
  justify-content: flex-end;
`;
const Annotation = styled.span`
  font-size: ${font.size12};
  color: ${color.text};
  margin-right: 16px;
`;
export const LimitedPropertySpaceArea = styled.div`
  display: flex;
  justify-content: start;
  margin-bottom: 10px;
`;
export const Title = styled.span`
  font-size: ${font.size24};
  font-weight: bold;
  line-height: 40px;
`;
export const Table = styled.div`
  width: 100%;
`;
enum propertyType {
  NORMAL = 0,
  LIMITED = 1,
}

const CreateUser = () => {
  const [originList, setOriginList] = useState<SpaceWithProperty[]>([]); //TODO state使って変数に渡す必要がない。修正が必要。
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastNameFurigana, setLastNameFurigana] = useState("");
  const [firstNameFurigana, setFirstNameFurigana] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [radioValue, setRadioValue] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const { debouncedValue: debouncedSearchKeyword } = useDebounce(searchKeyword, 500);

  const [isModalShow, setIsModalShow] = useState(false);
  const [ModalConfig, setModalConfig] = useState<UserModalConfig>({
    onClickOutside: () => {
      return;
    },
    subtitle: "完了しました",
    main: {
      buttonType: "secondary",
      onClick: () => {
        return;
      },
      label: "閉じる",
    },
  });

  const showModal = (config: UserModalConfig) => {
    setModalConfig(config);
    setIsModalShow(true);
  };
  const hideModal = () => {
    setIsModalShow(false);
  };

  // TODO: 書き方的によくない。修正が必要。
  const requestUrl = debouncedSearchKeyword
    ? `/property-space?${formatQueryParams({
        type: propertyType.LIMITED,
        keyword: debouncedSearchKeyword,
      })}`
    : null;

  const { data } = useSwr<{ data: SpaceWithProperty[] }>(requestUrl);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(e.target.value);
  };

  useEffect(() => {
    if (!data) return;
    setOriginList(data.data);
  }, [data]);

  const navigate = useNavigate();
  const { create } = useCreatePitPortUser();

  const changeLastName = (e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value);
  const changeFirstName = (e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value);
  const changeLastNameFurigana = (e: React.ChangeEvent<HTMLInputElement>) => setLastNameFurigana(e.target.value);
  const changeFirstNameFurigana = (e: React.ChangeEvent<HTMLInputElement>) => setFirstNameFurigana(e.target.value);
  const changePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => setPhoneNumber(e.target.value);
  const changeEmail = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);
  const changePassword = (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);

  const isErrorLastName = !(lastName.length > 0);
  const isErrorFirstName = !(firstName.length > 0);
  const isErrorLastNameFurigana = !/^[ァ-ヶー]+$/.test(lastNameFurigana);
  const isErrorFirstNameFurigana = !/^[ァ-ヶー]+$/.test(firstNameFurigana);
  const isErrorPhoneNumber = !isPhoneNumber(phoneNumber, "JP") || phoneNumber.includes("-");
  const isErrorEmail = !isEmail(email);
  const regexp = /.{6,}/;
  const isErrorPassword = !regexp.test(password);

  const [company, setCompany] = useState<CreateCompany>({
    name: "",
    address: "",
    phoneNumber: "",
    email: "",
    type: 0,
    propertySpaceIds: [],
  });

  const [selectedPropertyIds, setSelectedPropertyIds] = useState<number[]>([]);

  const isErrorCompanyUserName = !(company.name.length > 0);
  const isErrorCompanyUserAddress = !(company.address.length > 0);
  const isErrorCompanyPhoneNumber = !isPhoneNumber(company.phoneNumber, "JP") || company.phoneNumber.includes("-");
  const isErrorCompanyUserEmail = !isEmail(company.email);

  const canSubmit =
    !isErrorLastName &&
    !isErrorFirstName &&
    !isErrorLastNameFurigana &&
    !isErrorFirstNameFurigana &&
    !isErrorPhoneNumber &&
    !isErrorEmail &&
    !isErrorPassword &&
    !isErrorCompanyUserName &&
    !isErrorCompanyUserAddress &&
    !isErrorCompanyPhoneNumber &&
    !isErrorCompanyUserEmail;

  const prepareCompanyData = useCallback(() => {
    setCompany((prevCompany) => ({
      ...prevCompany,
      propertySpaceIds: selectedPropertyIds,
    }));
  }, [selectedPropertyIds]);

  const save = useCallback(async () => {
    prepareCompanyData();
    await create({
      lastName,
      firstName,
      lastNameFurigana,
      firstNameFurigana,
      phoneNumber,
      email,
      password,
      company,
    })
      .then((status: number) => {
        status === 201
          ? showModal({
              onClickOutside: hideModal,
              subtitle: "追加しました",
              main: {
                buttonType: "secondary",
                onClick: () => navigate("/pit_port/user"),
                label: "閉じる",
              },
            })
          : showModal({
              onClickOutside: hideModal,
              subtitle: "送信に失敗しました",
              main: {
                buttonType: "secondary",
                onClick: hideModal,
                label: "閉じる",
              },
              errorMessage: "時間をおいてもう一度お試しください",
            });
      })
      .catch((e) => {
        e.data.errorCode === "Email_Already_Exist"
          ? showModal({
              onClickOutside: hideModal,
              subtitle: "送信に失敗しました",
              main: {
                buttonType: "secondary",
                onClick: hideModal,
                label: "閉じる",
              },
              errorMessage: "既に登録されてるメールアドレスです",
            })
          : showModal({
              onClickOutside: hideModal,
              subtitle: "送信に失敗しました",
              main: {
                buttonType: "secondary",
                onClick: hideModal,
                label: "閉じる",
              },
              errorMessage: "時間をおいてもう一度お試しください",
            });
      });
  }, [
    prepareCompanyData,
    lastName,
    firstName,
    lastNameFurigana,
    firstNameFurigana,
    phoneNumber,
    email,
    password,
    company,
    create,
    navigate,
  ]);

  return (
    <Board>
      <TitleArea>
        <Breadcrumb
          currentPageName="ユーザを追加"
          breadcrumbItems={[{ pageName: "ユーザ", onClick: () => navigate("/pit_port/user") }]}
        />
      </TitleArea>
      <ContentArea>
        <FormRow>
          <Label>名前</Label>
          <div style={{ marginRight: "24px" }}>姓</div>
          <TextForm
            type="text"
            width="200px"
            value={lastName}
            placeholder="例）工藤"
            required
            onChange={changeLastName}
            isError={isErrorLastName}
            errorMessage="姓を入力してください"
          />
          <div style={{ marginLeft: "32px", marginRight: "24px" }}>名</div>
          <TextForm
            type="text"
            width="200px"
            value={firstName}
            placeholder="例）敬三"
            required
            onChange={changeFirstName}
            isError={isErrorFirstName}
            errorMessage="名を入力してください"
          />
        </FormRow>
        <FormRow>
          <Label>フリガナ</Label>
          <div style={{ marginRight: "8px" }}>セイ</div>
          <TextForm
            type="text"
            width="200px"
            value={lastNameFurigana}
            placeholder="例）クドウ"
            required
            onChange={changeLastNameFurigana}
            isError={isErrorLastNameFurigana}
            errorMessage="セイを入力してください"
          />
          <div style={{ marginLeft: "32px", marginRight: "8px" }}>メイ</div>
          <TextForm
            type="text"
            width="200px"
            value={firstNameFurigana}
            placeholder="例）ケイゾウ"
            required
            onChange={changeFirstNameFurigana}
            isError={isErrorFirstNameFurigana}
            errorMessage="メイを入力してください"
          />
        </FormRow>
        <FormRow>
          <Label>携帯番号</Label>
          <TextForm
            type="text"
            width="350px"
            value={phoneNumber}
            placeholder="例）09000000000"
            required
            onChange={changePhoneNumber}
            isError={isErrorPhoneNumber}
            errorMessage="電話番号をハイフンなしで入力してください"
          />
        </FormRow>
        <FormRow>
          <Label>メールアドレス</Label>
          <TextForm
            type="text"
            width="350px"
            value={email}
            placeholder="例）info@landit.co.jp"
            required
            onChange={changeEmail}
            isError={isErrorEmail}
            errorMessage="メールアドレスを入力してください"
          />
        </FormRow>
        <FormRow>
          <Label>パスワード</Label>
          <PasswordForm
            width="350px"
            value={password}
            placeholder="例）123456"
            required
            onChange={changePassword}
            isError={isErrorPassword}
            errorMessage="パスワードを入力してください"
          />
        </FormRow>
      </ContentArea>
      <CreateCompany
        company={company}
        setCompany={setCompany}
        setRadioValue={setRadioValue}
        selectedPropertyIds={selectedPropertyIds}
      ></CreateCompany>

      {radioValue !== 0 && (
        <>
          <LimitedPropertySpaceArea>
            <Title>限定区画</Title>
          </LimitedPropertySpaceArea>
          <PropertySpacesTable spacesWithProperty={{ data: originList }} selectedPropertyIds={selectedPropertyIds} />
          <SearchArea
            searchKeyword={searchKeyword}
            onSearchChange={onSearchChange}
            spacesWithProperty={originList}
            setSelectedPropertyIds={setSelectedPropertyIds}
            isLoading={!originList}
          />
        </>
      )}
      <FooterArea>
        <CancelWrap>
          <Button type="secondary" onClick={() => navigate("/pit_port/user")} label="キャンセル" width="160px" />
        </CancelWrap>
        <SaveWrap>
          <Annotation>追加するまで変更内容は反映されません</Annotation>
          <Button type="primary" onClick={save} label="このユーザを追加" disabled={!canSubmit} width="160px" />
        </SaveWrap>
      </FooterArea>
      <UserModal isVisible={isModalShow} config={ModalConfig}></UserModal>
    </Board>
  );
};

export default CreateUser;
