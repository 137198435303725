import React from "react";
import styled from "styled-components";

import color from "constants/color";
import {
  AIMO_PARKING_EDGE_DEVICE_STATUS,
  generateAimoParkingEdgeDeviceStatusJa,
} from "pages/AimoParking/utils/aimoParkingEdgeDeviceStatusUtil";

const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;

const StatusIcon = styled.div<{ color: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

const StatusText = styled.div``;

const changeColorByStatus = (status: AimoParkingEdgeDeviceStatus) => {
  switch (status) {
    case AIMO_PARKING_EDGE_DEVICE_STATUS.NORMAL:
      return color.green;
    case AIMO_PARKING_EDGE_DEVICE_STATUS.ABNORMAL:
      return color.warning;
    case AIMO_PARKING_EDGE_DEVICE_STATUS.NO_RESPONSE:
      return color.attention;
    default:
      return color.text.disabled;
  }
};

const AimoParkingEdgeDeviceStatusSwitcher = ({ status }: { status: AimoParkingEdgeDeviceStatus }) => {
  return (
    <Wrap>
      <StatusIcon color={changeColorByStatus(status)} />
      <StatusText>{generateAimoParkingEdgeDeviceStatusJa(status)}</StatusText>
    </Wrap>
  );
};

export default AimoParkingEdgeDeviceStatusSwitcher;
