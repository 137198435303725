import { faBuilding, faUserFriends } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { TitleArea, Title, Board } from "components/LayoutParts";
import TabBar from "components/TabBar";
import { useFindCompanyById } from "pages/AimoParking/Company/api";

const TabBarWrap = styled.div`
  padding: 0 0 37px 0;
`;

const TitleTabArea = () => {
  const { id: companyId } = useParams();
  const { data: companyDetail } = useFindCompanyById({ id: companyId as string });
  const navigate = useNavigate();

  return (
    <Board>
      <TitleArea>
        <Title>{companyDetail == null ? "読み込み中..." : `${companyDetail.name ?? ""}`}</Title>
      </TitleArea>
      <TabBarWrap>
        <TabBar
          options={[
            {
              label: "会社情報",
              onClick: () => navigate(`/aimo_parking/company/${companyId}/info`),
              Icon: <FontAwesomeIcon icon={faBuilding} width={12} height={16} />,
              width: 80,
              matchUrlKey: ["company/", "info/"],
            },
            {
              label: "ユーザ情報",
              onClick: () => navigate(`/aimo_parking/company/${companyId}/user`),
              Icon: <FontAwesomeIcon icon={faUserFriends} width={18} height={14} />,
              width: 96,
              matchUrlKey: ["company/", "user/"],
            },
          ]}
        />
      </TabBarWrap>
      <Outlet />
    </Board>
  );
};

export default TitleTabArea;
