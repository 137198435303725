export type SyncPortPaveMent = "ASPHALT" | "CONCRETE" | "GRAVEL" | "OTHERS";

export const PAVEMENT_MAP: { [key: string]: SyncPortPaveMent } = {
  アスファルト: "ASPHALT",
  コンクリート: "CONCRETE",
  砂利: "GRAVEL",
  その他: "OTHERS",
};

/**
 * 舗装タイプを翻訳
 * @param type PavementType
 * @return
 */
export const convertPavementType = (type: PavementType): PavementTypeText => {
  const map = {
    ASPHALT: "アスファルト",
    CONCRETE: "コンクリート",
    GRAVEL: "砂利",
    OTHERS: "その他",
  } as const;
  return map[type] ?? "その他";
};

/**
 * 舗装タイプを取得
 * @example
 * getPaveMent("アスファルト")
 * => 'ASPHALT'
 * getPaveMent("コンクリート")
 * => 'CONCRETE'
 * getPaveMent("") // 空文字の場合、'OTHERS'
 * => 'OTHERS'
 * getPaveMent("ｱｽﾌｧﾙﾄ") // 不正値の場合、null
 * => null
 */
export const getPaveMent = (paveMent: string): SyncPortPaveMent => {
  if (!paveMent) return "OTHERS";
  return PAVEMENT_MAP[paveMent] ?? null;
};
