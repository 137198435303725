import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import styled from "styled-components";

import color from "constants/color";
import { range } from "utils/arrayUtils";

const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

const PrevNextButton = styled.button<{ disabled: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${color.white};
  border: 1px solid ${color.text.link};
  color: ${color.text.link};
  border-radius: 4px;
  font-weight: "bold";
  position: relative;
  &:hover {
    color: ${color.white};
    background: ${color.text.link};
    border: 1px solid ${color.text.link};
  }
  ${({ disabled }) =>
    disabled &&
    `
      background: ${color.text.disabled};
      border: 1px solid ${color.text.disabled};
      color: ${color.white};
      cursor: default;
      &:hover {
        background: ${color.text.disabled};
        border: 1px solid ${color.text.disabled};
      }
    `}
`;

const PrevButton = styled(PrevNextButton)`
  padding: 7px 16px 7px 24px;
  margin-right: 10px;
`;

const NextButton = styled(PrevNextButton)`
  padding: 7px 24px 7px 16px;
  margin-left: 10px;
`;

const PageLink = styled.div<{ disabled: boolean }>`
  margin: 0 10px;
  color: ${color.text.link};
  cursor: pointer;
  &:hover {
    color: ${color.text.linkDark};
  }
  ${({ disabled }) =>
    disabled &&
    `
      color: ${color.text.disabled};
      cursor: default;
    `}
`;

const Pagination = ({
  totalPages,
  pageIndex,
  setPageIndex,
}: {
  totalPages: number;
  pageIndex: number;
  setPageIndex: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const displayNumberArray = useMemo(() => {
    if (pageIndex <= 0 || totalPages <= 1 || pageIndex > totalPages) return [];

    return totalPages <= 3
      ? [...range(1, totalPages)]
      : pageIndex <= 2
      ? [1, 2, 3]
      : pageIndex >= totalPages - 1
      ? [totalPages - 2, totalPages - 1, totalPages]
      : [pageIndex - 1, pageIndex, pageIndex + 1];
  }, [pageIndex, totalPages]);

  // 表示すべきものがない場合は、React.Fragmentを返す
  if (displayNumberArray.length === 0) return <></>;

  const onBackPage = () => setPageIndex((prev) => prev - 1);
  const onNextPage = () => setPageIndex((prev) => prev + 1);
  const onMovePage = (movePage: number, currentPage: number) => {
    if (movePage === currentPage) return;
    setPageIndex(movePage);
  };

  return (
    <Wrap>
      <PrevButton disabled={pageIndex === 1} onClick={onBackPage}>
        <FontAwesomeIcon icon={faChevronLeft} style={{ position: "absolute", top: "8px", left: "10px" }} />
        前へ
      </PrevButton>
      {displayNumberArray.map((p, index) => (
        <PageLink key={`link_${index}`} disabled={pageIndex === p} onClick={() => onMovePage(p, pageIndex)}>
          {p}
        </PageLink>
      ))}
      <NextButton disabled={pageIndex === totalPages} onClick={onNextPage}>
        次へ
        <FontAwesomeIcon icon={faChevronRight} style={{ position: "absolute", top: "8px", right: "10px" }} />
      </NextButton>
    </Wrap>
  );
};

export default Pagination;
