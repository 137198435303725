import React, { useEffect, useState } from "react";

import { sortDirection } from "constants/direction";
import { SyncPortSpaceGroupSortKey, useFindSpaceGroups } from "pages/SyncPort/Company/Property/SpaceGroup/features/api";
import DataTable from "pages/SyncPort/Company/Property/SpaceGroup/features/DataTable";
import { jumpUpToElement } from "utils/animationUtils";

const SpaceGroup = () => {
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [pagination, setPagination] = useState<GraphqlPageOption>({});
  const [sortOption, setSortOption] = useState<SortOption<SyncPortSpaceGroupSortKey>>({
    sortByColumn: null,
    direction: sortDirection.desc,
  });

  const { data, pageInfo } = useFindSpaceGroups({
    after: pagination?.after,
    before: pagination?.before,
    first: pagination?.first,
    last: pagination?.last,
    sortOption,
  });

  useEffect(() => {
    setTotalPages(pageInfo?.totalPages ?? 1);
    return () => {
      jumpUpToElement("root");
    };
  }, [pageInfo?.endCursor, pageInfo?.startCursor, pageInfo?.totalPages]);

  return (
    <>
      <DataTable
        spaceGroupList={data}
        totalPages={totalPages}
        pageIndex={currentPageIndex}
        setPageIndex={setCurrentPageIndex}
        pageInfo={pageInfo}
        setPagination={setPagination}
        setSortOption={setSortOption}
        sortOption={sortOption}
      />
    </>
  );
};

export default SpaceGroup;
