import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import Breadcrumb from "components/Breadcrumb";
import Button from "components/Button";
import { TitleArea, ContentArea, Board as BaseBoard, FormRow, FooterArea } from "components/LayoutParts";
import Loading from "components/Loading";
import TextForm from "components/TextForm";
import UserModal, { UserModalConfig } from "components/UserModal";
import color from "constants/color";
import font from "constants/font";
import { FAILED_MODAL_CONFIG } from "pages/AimoParking/modalTemplate";
import { useFindPlaceCode, useRemovePlaceCode, useUpdatePlaceCode } from "pages/AtPort/DandoriWorks/api";
import { DELETE_MODAL_CONFIG, INITIAL_MODAL_CONFIG, SUCCEED_MODAL_CONFIG } from "pages/AtPort/modalTemplate";

// パーツ定義
const Wrap = styled.div`
  margin-top: 10px;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  flex: 1;
`;

const CancelWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;

const SaveWrap = styled(CancelWrap)`
  align-items: center;
  justify-content: flex-end;
`;

const Annotation = styled.span`
  font-size: ${font.size12};
  color: ${color.text};
  margin-right: 16px;
`;

const Label = styled.div`
  min-width: 160px;
  margin: 0 5px;
`;

const DeleteButtonWrap = styled.div`
  margin-left: auto;
`;

const EditPlaceCode = () => {
  const [companyName, setCompanyName] = useState("");
  const [accountId, setAccountId] = useState("");
  const [contactId, setContactId] = useState("");
  const [placeCode, setPlaceCode] = useState("");
  const [modalConfig, setModalConfig] = useState<UserModalConfig>(INITIAL_MODAL_CONFIG);
  const [isVisible, setIsVisible] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const { data } = useFindPlaceCode(id as string);
  const { update } = useUpdatePlaceCode();
  const { remove } = useRemovePlaceCode();

  const onChangeCompanyName = (e: React.ChangeEvent<HTMLInputElement>) => setCompanyName(e.target.value);
  const onChangeAccountId = (e: React.ChangeEvent<HTMLInputElement>) => setAccountId(e.target.value);
  const onChangeContactId = (e: React.ChangeEvent<HTMLInputElement>) => setContactId(e.target.value);
  const onChangePlaceCode = (e: React.ChangeEvent<HTMLInputElement>) => setPlaceCode(e.target.value);

  const isErrorCompanyName = companyName === "";
  const isErrorAccountId = accountId === "";
  const isErrorContactId = contactId === "";
  const isErrorPlaceCode = placeCode === "";
  const isChanged =
    data?.companyName !== companyName ||
    data?.accountId !== accountId ||
    data?.contactId !== contactId ||
    data?.placeCode !== placeCode;
  const canSubmit = !isErrorCompanyName && !isErrorAccountId && !isErrorContactId && !isErrorPlaceCode && isChanged;

  const onSubmit = async () => {
    const status = await update(id as string, { accountId, companyName, contactId, placeCode }).catch(() => {
      setModalConfig(FAILED_MODAL_CONFIG({ onClose: () => setIsVisible(false) }));
      setIsVisible(true);
    });
    if (status === 200) {
      setModalConfig(
        SUCCEED_MODAL_CONFIG({
          message: "変更内容を保存しました",
          onClose: () => navigate(`/at_port/dandori_works/place`),
        })
      );
      setIsVisible(true);
    }
  };

  const onRemove = async () => {
    const status = await remove(id as string).catch(() => {
      setModalConfig(FAILED_MODAL_CONFIG({ onClose: () => setIsVisible(false) }));
      setIsVisible(true);
    });
    if (status === 200) {
      setModalConfig(
        SUCCEED_MODAL_CONFIG({
          message: "削除しました",
          onClose: () => navigate(`/at_port/dandori_works/place`),
        })
      );
      setIsVisible(true);
    }
  };

  const handleClickRemove = async () => {
    setModalConfig(DELETE_MODAL_CONFIG({ positive: onRemove, negative: () => setIsVisible(false) }));
    setIsVisible(true);
  };

  useEffect(() => {
    setCompanyName(data?.companyName ?? "");
    setAccountId(data?.accountId ?? "");
    setContactId(data?.contactId ?? "");
    setPlaceCode(data?.placeCode ?? "");
  }, [data?.accountId, data?.companyName, data?.contactId, data?.placeCode]);

  return (
    <BaseBoard>
      <TitleArea>
        <Breadcrumb
          currentPageName="連携プレースを編集"
          breadcrumbItems={[
            {
              pageName: "連携プレース一覧",
              onClick: () => navigate(`/at_port/dandori_works/place`),
            },
            {
              pageName: `${id}`,
              onClick: () => null,
            },
          ]}
        />
        <DeleteButtonWrap>
          <Button type="danger" onClick={handleClickRemove} label="この連携プレースを削除" width="200px" />
        </DeleteButtonWrap>
      </TitleArea>
      {data ? (
        <Wrap>
          <ContentArea>
            <FormRow>
              <Label>プレイスコード</Label>
              <TextForm
                width="832px"
                type="text"
                value={placeCode}
                isError={isErrorPlaceCode}
                onChange={onChangePlaceCode}
                placeholder="例）P0001"
                errorMessage="プレイスコードを入力してください"
              />
            </FormRow>
            <FormRow>
              <Label>顧客ID</Label>
              <TextForm
                width="832px"
                type="text"
                value={accountId}
                isError={isErrorAccountId}
                onChange={onChangeAccountId}
                placeholder="例）A0001"
                errorMessage="顧客IDを入力してください"
              />
            </FormRow>
            <FormRow>
              <Label>顧客名</Label>
              <TextForm
                width="832px"
                type="text"
                value={companyName}
                isError={isErrorCompanyName}
                onChange={onChangeCompanyName}
                placeholder="例）株式会社〇〇"
                errorMessage="顧客名を入力してください"
              />
            </FormRow>
            <FormRow>
              <Label>担当者ID</Label>
              <TextForm
                width="832px"
                type="text"
                value={contactId}
                isError={isErrorContactId}
                onChange={onChangeContactId}
                placeholder="例）C0001"
                errorMessage="担当者IDを入力してください"
              />
            </FormRow>
          </ContentArea>
          <FooterArea>
            <CancelWrap>
              <Button
                type="secondary"
                onClick={() => navigate("/at_port/dandori_works/place")}
                label="キャンセル"
                width="160px"
              />
            </CancelWrap>
            <SaveWrap>
              <Annotation>保存するまで変更内容は反映されません</Annotation>
              <Button type="primary" onClick={onSubmit} label="変更を保存" disabled={!canSubmit} width="160px" />
            </SaveWrap>
          </FooterArea>
        </Wrap>
      ) : (
        <Loading />
      )}

      <UserModal isVisible={isVisible} config={modalConfig} />
    </BaseBoard>
  );
};

export default EditPlaceCode;
