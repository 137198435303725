import { faExclamationCircle, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import styled from "styled-components";

import { Wrapper, Input, ErrorArea, ErrorMessage } from "components/TextForm";

const SearchInput = styled(Input)`
  padding-left: 40px;
`;

const CenterWrapper = styled(Wrapper)`
  display: flex;
  position: relative;
  align-items: center;
`;

// 本体
const SearchForm = ({
  width = "200px",
  value = "",
  placeholder = "",
  required = false,
  readOnly = false,
  onChange = () => null,
  onBlur = () => null,
  onFocus = () => null,
  isError = false,
  errorMessage = "",
  height = "48px",
}: {
  width?: string;
  value?: string;
  placeholder?: string;
  required?: boolean;
  readOnly?: boolean;
  pattern?: RegExp;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  isError?: boolean;
  errorMessage?: string;
  height?: string;
}) => {
  const [isErrorDetectable, setIsErrorDetectable] = useState(false);

  // フォーカスが外れたらエラーを検出可能にする
  const onBlurForm = () => {
    setIsErrorDetectable(true);
    onBlur();
  };

  return (
    <CenterWrapper>
      <SearchInput
        type="search"
        width={width}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlurForm}
        required={required}
        readOnly={readOnly}
        isErrorDisplay={isError && isErrorDetectable}
        height={height}
      />
      <FontAwesomeIcon
        icon={faSearch}
        style={{
          position: "absolute" as const,
          zIndex: 2,
          left: "12px",
        }}
      />
      {errorMessage && (
        <ErrorArea isErrorDisplay={isError && isErrorDetectable}>
          <FontAwesomeIcon icon={faExclamationCircle} />
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </ErrorArea>
      )}
    </CenterWrapper>
  );
};

export default SearchForm;
