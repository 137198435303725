import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import { AlertArea } from "components/LayoutParts";
import color from "constants/color";

const RowWrap = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const BrokenBoxes = (offlineBoxes: BrokenBox[]) => {
  return (
    <AlertArea>
      {offlineBoxes.length > 0 &&
        offlineBoxes.map((box) => (
          <RowWrap key={box.id}>
            <FontAwesomeIcon icon={faCircleExclamation} color={color.emphasis} />
            <div
              style={{ color: color.text.black, marginLeft: "8px" }}
            >{`${box.propertyName}の制御BOX(${box.id}) がオフラインになっています。`}</div>
          </RowWrap>
        ))}
    </AlertArea>
  );
};

export default BrokenBoxes;
