import styled from "styled-components";

import RoundedButton from "components/RoundedButton";
import SearchForm from "components/SearchForm";

const Area = styled.div`
  display: flex;
  margin: 20px 0;
`;
const FilterWrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
`;
const Text = styled.span`
  font-weight: bold;
  margin-right: 5px;
`;

const PropertyStatusFilter = {
  all: "all",
  running: "running",
  offline: "offline",
  update: "not-latest",
};

const ActionArea = ({
  searchKeyword,
  selectedFilter,
  onSearchChange,
  onFilterButtonClick,
}: {
  searchKeyword: string;
  selectedFilter: string;
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFilterButtonClick: (status: string) => void;
}) => {
  return (
    <Area>
      <SearchForm width="300px" value={searchKeyword} placeholder="検索" onChange={onSearchChange} />
      <FilterWrap>
        <Text>絞り込み</Text>
        <RoundedButton
          label="すべて"
          isSelected={selectedFilter === PropertyStatusFilter.all}
          onClick={() => onFilterButtonClick(PropertyStatusFilter.all)}
          width="110px"
        />
        <RoundedButton
          label="稼働中"
          isSelected={selectedFilter === PropertyStatusFilter.running}
          onClick={() => onFilterButtonClick(PropertyStatusFilter.running)}
          width="110px"
        />
        <RoundedButton
          label="オフライン"
          isSelected={selectedFilter === PropertyStatusFilter.offline}
          onClick={() => onFilterButtonClick(PropertyStatusFilter.offline)}
          width="110px"
        />
        <RoundedButton
          label="要更新"
          isSelected={selectedFilter === PropertyStatusFilter.update}
          onClick={() => onFilterButtonClick(PropertyStatusFilter.update)}
          width="110px"
        />
      </FilterWrap>
    </Area>
  );
};

export default ActionArea;
