export type SyncPortParkingType = "FLAT" | "SOLID" | "MECHANICAL";

export type ParkingTypeText = "平置き" | "立体" | "機械式";

export const SPACE_TYPE_MAP: { [key: string]: SyncPortParkingType } = {
  平面: "FLAT",
  立体: "SOLID",
  機械式: "MECHANICAL",
};

/**
 * 駐車場タイプを翻訳
 * @param type ParkingType
 * @return
 */
export const convertParkingType = (type: SyncPortParkingType): ParkingTypeText => {
  switch (type) {
    case "FLAT":
      return "平置き";
    case "SOLID":
      return "立体";
    case "MECHANICAL":
      return "機械式";
  }
};

/**
 * 駐車場タイプを取得
 * @example
 * getParkingType("平面")
 * => 'FLAT'
 * getParkingType("立体")
 * => 'SOLID'
 * getParkingType("")
 * => 'FLAT'
 */
export const getParkingType = (spaceType: string): SyncPortParkingType => {
  return SPACE_TYPE_MAP[spaceType] ?? SPACE_TYPE_MAP["平面"];
};
