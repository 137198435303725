import axios from "axios";

/**
 * 署名付きURLでファイルをアップロード関数
 * @param signedUrl 署名付きURL
 * @param file アップロードするファイル
 * @returns void
 */
export const uploadFileCloudStorage = async ({ signedUrl, file }: { signedUrl: string; file: File }): Promise<void> => {
  await axios.put(signedUrl, file, { headers: { "Content-Type": file.type } }).catch((error) => {
    throw error;
  });
};

/**
 * ファイル名から拡張子を抽出して返す関数
 * @param fileName ファイル名
 * @returns 拡張子
 * @example
 * extractFileExtension("sample.jpg") // => "jpg"
 */
export const extractFileExtension = (fileName: string): string => {
  return fileName.split(".").pop() ?? "";
};
