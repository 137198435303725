import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";

import Breadcrumb from "components/Breadcrumb";
import Button from "components/Button";
import { ContentArea as BaseContextArea, Board as BaseBoard } from "components/LayoutParts";
import Loading from "components/Loading";
import color from "constants/color";
import font from "constants/font";
import PropertyImagesPreview from "pages/SyncPort/Company/component/PropertyImagesPreview";
import SpaceGroupInfo from "pages/SyncPort/Company/component/SpaceGroupInfo";
import { useFindPropertyById, useFindSpaceGroupById } from "pages/SyncPort/Company/Property/features/api";

const PropertyTitleArea = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 0 10px 0;
  align-items: center;
  width: 100%;
`;

const ContentArea = styled(BaseContextArea)`
  display: flex;
  flex-direction: row;
  padding: 0;
  gap: 10px;
  background-color: ${color.background};
  border: none;
`;

const SpaceGroupInfoArea = styled.div`
  flex: 7.3;
`;

const PropertyImagesArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 16px 32px 32px 32px;
  background-color: ${color.white};
  border: solid 1px ${color.border};
  width: 28%;
  overflow-y: scroll;
  height: fit-content;
`;

const ImageTitleArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ImageTitle = styled.div`
  font-size: ${font.size16};
`;

const ImagesArea = styled.div`
  margin-top: 16px;
  background-color: ${color.white};
  border: none;
  flex-direction: column;
`;

const OverWrapBoard = styled(BaseBoard)`
  overflow: visible;
  padding: 0;
`;

// パーツ定義
const SpaceGroupDetail = () => {
  const { id, propertyId, spaceGroupId } = useParams();

  const { data: property } = useFindPropertyById({ propertyId });

  const navigate = useNavigate();
  const { data } = useFindSpaceGroupById({ spaceGroupId });

  return (
    <>
      {data == null ? (
        <Loading />
      ) : (
        <OverWrapBoard>
          <PropertyTitleArea>
            <Breadcrumb
              currentPageName="区画グループ"
              breadcrumbItems={[
                {
                  pageName: property?.name ?? "",
                  onClick: () => navigate(`/sync_port/company/${id}/property/${propertyId}`),
                },
              ]}
            />
          </PropertyTitleArea>
          <ContentArea>
            <SpaceGroupInfoArea>
              <SpaceGroupInfo spaceGroupDetail={data} />
            </SpaceGroupInfoArea>
            <PropertyImagesArea>
              <ImageTitleArea>
                <ImageTitle>区画図</ImageTitle>
                <Button
                  label="区画図を登録・変更"
                  onClick={() => navigate(`/sync_port/company/${id}/property/${propertyId}/${spaceGroupId}/image`)}
                  type="secondary"
                />
              </ImageTitleArea>
              <ImagesArea>
                <PropertyImagesPreview images={data.images} />
              </ImagesArea>
            </PropertyImagesArea>
          </ContentArea>
        </OverWrapBoard>
      )}
    </>
  );
};

export default SpaceGroupDetail;
