import styled from "styled-components";

import SearchForm from "components/SearchForm";

const Area = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0;
`;

const ActionArea = ({
  searchKeyword,
  onSearchChange,
}: {
  searchKeyword: string;
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <Area>
      <SearchForm width="350px" value={searchKeyword} placeholder="検索" onChange={onSearchChange} />
    </Area>
  );
};

export default ActionArea;
