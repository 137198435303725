import axios from "axios";
import { Auth } from "firebase/auth";
import useSWR from "swr";

import { useFirebaseContext } from "contexts/FirebaseContext";

/**
 * swrでPromiseを返す関数
 * @param url
 * @param auth
 * @returns
 */
const fetcher = async (url: string | null, auth: Auth) => {
  const token = await auth?.currentUser?.getIdToken();
  if (!token || !url) return;

  const response = await axios
    .request({
      method: "get",
      url: `${process.env.REACT_APP_LANDIT_ADMIN_API_URL}/pitport${url}`,
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((e) => {
      throw e;
    });
  return response.data;
};

/**
 * swrでデータを取得
 * クエリパラメータがある場合は、URLに含める
 * @param apiUrl
 * @returns
 */
export const useSwr = <T>(
  apiUrl: string | null
): {
  data: T;
  error: Error;
} => {
  const { auth } = useFirebaseContext();
  const { data, error } = useSWR([apiUrl, auth], fetcher, {
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: false,
  });
  return { data, error };
};
