import { isTimeFormat } from "utils/dateTimeUtils";
import { zenkakuToHankaku } from "utils/number.Utils";
import { isEmptyString, safeString } from "utils/stringUtils";

const DEFAULT_TIMES = { START_DATE: "00:00", END_DATE: "23:45", ALL: "24時間" };

/**
 * 利用可能時間を計算しx時間y分の形式で返す
 * @example
 * calculateAvailableTime(undefined)
 * => "24時間"
 * calculateAvailableTime({ startDate: "", endDate: "" })
 * => "24時間"
 * calculateAvailableTime({ startDate: "00:00", endDate: "23:59" })
 * => "00:00 ~ 23:59"
 */
export const calculateAvailableTime = (time?: { startDate: string; endDate?: string }): string => {
  if (time == null || (time.startDate === "" && time.endDate === "")) {
    return DEFAULT_TIMES.ALL;
  }

  const { startDate, endDate } = time;
  return `${startDate} ~ ${endDate}`;
};

/**
 * 時間をhh:mm形式にフォーマット
 * @example
 * formatToTime("0900")
 * => "09:00"
 */
const formatToTime = (str: string): string => {
  const hours = str.substring(0, 2);
  const minutes = str.substring(2);
  return `${hours}:${minutes}`;
};

/**
 * 時間の入力形式を判定
 * @example
 * convertTimeInput("09:00~18:00")
 * => { startDate: "09:00", endDate: "18:00" }
 * convertTimeInput("09:00～18:00")
 * => { startDate: "09:00", endDate: "18:00" }
 * convertTimeInput("09001800")
 * => { startDate: "09:00", endDate: "18:00" }
 * convertTimeInput("")
 * => {}
 * convertTimeInput("アイウエオ")
 * => {}
 */
const convertTimeInput = (
  time: string
):
  | {
      startDate?: undefined;
      endDate?: undefined;
    }
  | {
      startDate: string;
      endDate: string;
    } => {
  const hanakakuTime = zenkakuToHankaku(time);

  if (hanakakuTime === "") {
    return {};
  }

  const separators = ["~", "～"];
  const separator = separators.find((separator) => hanakakuTime.includes(separator));

  if (separator) {
    const [startDate, endDate] = hanakakuTime.split(separator);
    return { startDate, endDate };
  }

  if (hanakakuTime.length === 8) {
    const startDate = formatToTime(hanakakuTime.substring(0, 4));
    const endDate = formatToTime(hanakakuTime.substring(4));
    return { startDate, endDate };
  }

  return {};
};

/**
 * 開始時間と終了時間から時間帯を生成
 * @example
 * getFeatureTime("09:00～18:00")
 * => { startDate: "09:00", endDate: "18:00" }
 * getFeatureTime("24時間")
 * => {}
 * getFeatureTime("")
 * => {}
 */
export const getFeatureTime = (time: string): { startDate?: string; endDate?: string } => {
  const trimmedTime = time.trim();
  if (isEmptyString(trimmedTime) || trimmedTime === DEFAULT_TIMES.ALL) return {};

  const { startDate, endDate } = convertTimeInput(trimmedTime);
  // 不正値の場合は空オブジェクトを返す
  if (!(startDate && endDate)) return {};
  // 開始時間と終了時間が同じ場合は、24時間として扱う
  if (startDate === endDate) return {};

  return {
    startDate: isTimeFormat(safeString(startDate, true)) ? safeString(startDate, true) : DEFAULT_TIMES.START_DATE,
    endDate: isTimeFormat(safeString(endDate, true)) ? safeString(endDate, true) : DEFAULT_TIMES.END_DATE,
  };
};
