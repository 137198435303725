import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Button from "components/Button";
import { ContentArea, FormRow, Title, TitleArea } from "components/LayoutParts";

const EditWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  padding: 14px 0px;
`;

const ConfirmCompany = ({ company, isVisibleEditButton }: { company: UpdateCompany; isVisibleEditButton: boolean }) => {
  const navigate = useNavigate();
  return (
    <>
      <TitleArea>
        <Title>請求先</Title>
      </TitleArea>
      <ContentArea>
        <FormRow>
          <div style={{ marginRight: "134px" }}>会社名</div>
          <div>{company.name}</div>
        </FormRow>
        <FormRow>
          <div style={{ marginRight: "150px" }}>住所</div>
          <div>{company.address}</div>
        </FormRow>
        <FormRow>
          <div style={{ marginRight: "118px" }}>電話番号</div>
          <div>{company.phoneNumber}</div>
        </FormRow>
        <FormRow>
          <div style={{ marginRight: "24px" }}>請求先メールアドレス</div>
          <div>{company.email}</div>
        </FormRow>
        {isVisibleEditButton && (
          <EditWrap>
            <Button
              type="secondary"
              onClick={() => navigate(`/pit_port/company/${company.id}`)}
              label="この請求先を編集"
              width="160px"
            />
          </EditWrap>
        )}
      </ContentArea>
    </>
  );
};

export default ConfirmCompany;
