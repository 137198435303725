import React, { Dispatch, SetStateAction } from "react";
import styled from "styled-components";

import { ErrorArea, ErrorMessage } from "components/TextForm";
import font from "constants/font";
import AimoParkingSelect from "pages/AimoParking/component/AimoParkingSelect";
import { getDayChoices, getMonthChoices, getYearChoices } from "pages/AimoParking/utils/aimoParkingDateChoicesUtil";

export type AimoParkingSelectDateType = {
  year: string | null;
  month: string | null;
  day: string | null;
};

const SelectWrap = styled.div``;

const Wrap = styled.div`
  display: flex;
  font-size: ${font.size16};
  gap: 40px;
`;

const YearWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const MonthDayWrap = styled.div`
  display: flex;
  gap: 4px;
`;

const MonthWrap = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const DayWrap = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const AimoParkingSelectDate = ({
  date,
  setDate,
  errorMessage,
  isError,
}: {
  date: AimoParkingSelectDateType;
  setDate: Dispatch<SetStateAction<AimoParkingSelectDateType>>;
  errorMessage?: string;
  isError?: boolean;
}) => {
  const isErrorDetectable = date.year && date.month && date.day;

  const handleChangeYear = (year: string) => setDate({ ...date, year });
  const handleChangeMonth = (month: string) => setDate({ ...date, month });
  const handleChangeDay = (day: string) => setDate({ ...date, day });

  return (
    <SelectWrap>
      <Wrap>
        <YearWrap>
          <AimoParkingSelect
            choices={getYearChoices().map((year) => ({ key: year, label: year, value: year }))}
            width="120px"
            onChange={(value) => handleChangeYear(value.value)}
            value={date.year ? { key: date.year, label: date.year.toString(), value: date.year.toString() } : null}
          />
          年
        </YearWrap>
        <MonthDayWrap>
          <MonthWrap>
            <AimoParkingSelect
              choices={getMonthChoices().map((year) => ({ key: year, label: year, value: year }))}
              width="100px"
              onChange={(value) => handleChangeMonth(value.value)}
              value={
                date.month ? { key: date.month, label: date.month.toString(), value: date.month.toString() } : null
              }
            />
            月
          </MonthWrap>
          <DayWrap>
            <AimoParkingSelect
              choices={getDayChoices().map((year) => ({ key: year, label: year, value: year }))}
              width="100px"
              onChange={(value) => handleChangeDay(value.value)}
              value={date.day ? { key: date.day, label: date.day.toString(), value: date.day.toString() } : null}
            />
            日
          </DayWrap>
        </MonthDayWrap>
      </Wrap>
      <ErrorArea isErrorDisplay={!!(isError && isErrorDetectable)}>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </ErrorArea>
    </SelectWrap>
  );
};

export default AimoParkingSelectDate;
