import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Button from "components/Button";
import { Board, TitleArea, Title, PageTop } from "components/LayoutParts";
import { useDebounce } from "hooks/useDebounce";
import { useFindCompanies } from "pages/SyncPort/Company/features/api";
import DataTable from "pages/SyncPort/Company/features/DataTable";
import ActionArea from "pages/SyncPort/Company/features/DataTable/ActionArea";
import { jumpUpToElement } from "utils/animationUtils";

const ButtonWrap = styled.div`
  margin-left: auto;
`;

const Company = () => {
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [pagination, setPagination] = useState<GraphqlPageOption>({});
  const [searchKeyword, setSearchKeyword] = useState("");
  const { debouncedValue: debouncedSearchKeyword } = useDebounce(searchKeyword, 500);

  const router = useNavigate();

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPageIndex(1);
    setSearchKeyword(e.target.value);
  };

  const { data, pageInfo } = useFindCompanies({
    searchKeyword: debouncedSearchKeyword,
    after: pagination?.after,
    before: pagination?.before,
    first: pagination?.first,
    last: pagination?.last,
  });

  useEffect(() => {
    setTotalPages(pageInfo?.totalPages ?? 1);
    return () => {
      jumpUpToElement("root");
    };
  }, [pageInfo?.endCursor, pageInfo?.startCursor, pageInfo?.totalPages]);

  const onClickCompanyAdd = () => {
    router("/sync_port/company/create");
  };

  return (
    <>
      <Board>
        <PageTop />
        <TitleArea>
          <Title>管理会社</Title>
          <ButtonWrap>
            <Button label="管理会社を追加" onClick={onClickCompanyAdd} type="primary" />
          </ButtonWrap>
        </TitleArea>
        <ActionArea searchKeyword={searchKeyword} onSearchChange={onSearchChange} />
        <DataTable
          companyList={data}
          totalPages={totalPages}
          pageIndex={currentPageIndex}
          setPageIndex={setCurrentPageIndex}
          pageInfo={pageInfo}
          setPagination={setPagination}
        />
      </Board>
    </>
  );
};

export default Company;
