import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

import Button from "components/Button";
import UserModal, { UserModalConfig } from "components/UserModal";
import { SAMPLE_CSV } from "constants/file";
import { useImportPitPortCompanyUsers } from "pages/PitPort/Company/api";
import { handleReadFileExcel, csvToJson, getKeysCsv, checkArraySubset } from "utils/dataFileUtil";

const ImportCompanyUsers = ({ companyId, label }: { companyId: number | null; label: string }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalShow, setIsModalShow] = useState(false);
  const [modalConfig, setModalConfig] = useState<UserModalConfig>({
    onClickOutside: () => {
      return;
    },
    subtitle: "完了しました",
    main: {
      buttonType: "secondary",
      onClick: () => {
        return;
      },
      label: "閉じる",
    },
  });

  const { importCompanyUsers } = useImportPitPortCompanyUsers();

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file: File) => {
        const reader = new FileReader();

        reader.onload = async (e: ProgressEvent<FileReader>) => {
          setIsLoading(true);
          const users = handleReadFileExcel(e);
          if (typeof users === "string") {
            const usersJson = csvToJson(users);

            const keysCsvImported = getKeysCsv(users);
            const keysSample = Object.keys(SAMPLE_CSV);

            const invalidField = !checkArraySubset(keysSample, keysCsvImported);

            if (invalidField) {
              setModalConfig({
                onClickOutside: () => {
                  setIsModalShow(false);
                },
                subtitle: "エラーが発生しました",
                main: {
                  buttonType: "secondary",
                  onClick: () => {
                    setIsModalShow(false);
                  },
                  label: "閉じる",
                },
                errorMessage: "CSVファイルのフォーマットが正しくありません。",
              });
              setIsModalShow(true);
            }

            const transformedData: ImportPitPortCompanyUser[] = usersJson.map((item) => {
              return {
                lastName: item["姓を入力"]?.trim(),
                firstName: item["名を入力"]?.trim(),
                lastNameFurigana: item["セイを入力"]?.trim(),
                firstNameFurigana: item["メイを入力"]?.trim(),
                phoneNumber: item["携帯電話"]?.trim(),
                email: item["メールアドレス"]?.trim(),
                role: item["管理権限"] === "管理者" ? 2 : 1,
                numberPlate: {
                  region: item["地名（車ナンバー）"]?.trim(),
                  regionCode: item["分類番号（車ナンバー）"]?.trim(),
                  hiragana: item["ひらがな（車ナンバー）"]?.trim(),
                  number: item["登録番号（車ナンバー）"].trim(),
                },
              };
            });

            if (companyId === null) {
              throw Error;
            }
            const status = await importCompanyUsers(companyId, transformedData);
            setIsLoading(false);
            status === 201
              ? setModalConfig({
                  onClickOutside: () => {
                    setIsModalShow(false);
                  },
                  subtitle: "完了しました",
                  main: {
                    buttonType: "secondary",
                    onClick: () => {
                      setIsModalShow(false);
                    },
                    label: "閉じる",
                  },
                })
              : setModalConfig({
                  onClickOutside: () => {
                    setIsModalShow(false);
                  },
                  subtitle: "エラーが発生しました",
                  main: {
                    buttonType: "secondary",
                    onClick: () => {
                      setIsModalShow(false);
                    },
                    label: "閉じる",
                  },
                  errorMessage: "開発チームにお問合せください。",
                });
            setIsModalShow(true);
          }
        };
        reader.readAsText(file);
      });
    },
    [companyId, importCompanyUsers]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
  });

  return (
    <>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Button label={label} onClick={open} width="200px" type="secondary" isLoading={isLoading} />
      </div>
      <UserModal isVisible={isModalShow} config={modalConfig}></UserModal>
    </>
  );
};

export default ImportCompanyUsers;
