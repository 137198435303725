import { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";

import Breadcrumb from "components/Breadcrumb";
import Button from "components/Button";
import {
  ContentArea as BaseContentArea,
  FooterArea as BaseFooterArea,
  Board as BaseBoard,
} from "components/LayoutParts";
import UploadForm from "components/UploadForm";
import UserModal, { UserModalConfig } from "components/UserModal";
import color from "constants/color";
import font from "constants/font";
import { ERROR, SUCCESS } from "pages/SyncPort/Company/features/utils/modalTemplate";
import { useFindPropertyById, useUpdateSyncPortImage } from "pages/SyncPort/Company/Property/features/api";

const Board = styled(BaseBoard)`
  overflow-y: clip;
  padding: 0px;
  position: relative;
  border: none;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;
const PropertyTitleArea = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0px 0 10px 0;
  align-items: center;
  width: 100%;
`;
const ContentArea = styled(BaseContentArea)`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  padding: 32px 32px 22px 32px;
  gap: 10px;
  background-color: ${color.white};
`;
const FooterArea = styled(BaseFooterArea)`
  bottom: 0;
`;
const CancelWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;
const SaveWrap = styled(CancelWrap)`
  align-items: center;
  justify-content: flex-end;
`;
const Annotation = styled.span`
  font-size: ${font.size12};
  color: ${color.text.gray};
  margin-right: 16px;
  gap: 4px;
  display: flex;
  align-items: center;
`;

// パーツ定義
const UploadPropertyImage = () => {
  const navigate = useNavigate();
  const { id: companyId, propertyId } = useParams();
  const { data: property, mutate } = useFindPropertyById({ propertyId });
  const { updateImages } = useUpdateSyncPortImage("property");

  const [newImages, setNewImages] = useState<File[]>([]);
  const [propertyImages, setPropertyImages] = useState<Image[]>(property?.images ?? []);
  const [isVisible, setIsVisible] = useState(false);
  const [modalConfig, setModalConfig] = useState<UserModalConfig>({
    onClickOutside: () => {
      return;
    },
    subtitle: "変更しました",
    main: {
      buttonType: "secondary",
      onClick: () => {
        return;
      },
      label: "閉じる",
    },
  });

  const imageTotalLength = propertyImages.length + newImages.length;
  const imageLengthSame = imageTotalLength === property?.images.length;
  const imageSame = JSON.stringify(property?.images) === JSON.stringify(propertyImages);
  const submitDisabled = imageLengthSame && imageSame;

  const showModal = (modalConfig?: UserModalConfig) => {
    modalConfig != null ? setModalConfig(modalConfig) : null;
    setIsVisible(true);
  };

  const hideModal = () => {
    setIsVisible(false);
  };

  const handleSubmit = async () => {
    const res = await updateImages({
      id: propertyId ?? "",
      currentImageUrls: propertyImages.map((image) => image.url),
      newImages,
    }).catch(() => {
      showModal(ERROR(() => hideModal()));
    });
    if (res) {
      showModal(
        SUCCESS(async () => {
          await mutate();
          hideModal();
          navigate(`/sync_port/company/${companyId}/property/${propertyId}`);
        })
      );
    }
  };

  const setUp = useCallback(() => {
    newImages.length === 0 && setPropertyImages(property?.images ?? []);
  }, [property, newImages]);

  useEffect(() => {
    setUp();
  }, [setUp]);

  return (
    <Board>
      <div>
        <PropertyTitleArea>
          <Breadcrumb
            currentPageName="画像の登録"
            breadcrumbItems={[
              {
                pageName: property?.name ?? "物件詳細",
                onClick: () => navigate(`/sync_port/company/${companyId}/property/${propertyId}`),
              },
            ]}
          />
        </PropertyTitleArea>
        <ContentArea>
          <UploadForm
            showImages={propertyImages}
            setShowImages={setPropertyImages}
            unUploadedImages={newImages}
            setUnUploadedImages={setNewImages}
            twoRows
          />
        </ContentArea>
      </div>
      <FooterArea>
        <CancelWrap>
          <Button
            type="secondary"
            onClick={() => navigate(`/sync_port/company/${companyId}/property/${propertyId}`)}
            label="キャンセル"
            width="160px"
          />
        </CancelWrap>
        <SaveWrap>
          <Annotation>保存するまで変更内容は反映されません</Annotation>
          <Button
            type="primary"
            onClick={handleSubmit}
            label="変更内容を保存"
            width="180px"
            disabled={submitDisabled}
          />
        </SaveWrap>
      </FooterArea>
      <UserModal isVisible={isVisible} config={modalConfig} />
    </Board>
  );
};

export default UploadPropertyImage;
