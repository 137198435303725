import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import AcceptableTag from "components/AcceptableTag";
import { FieldName, FieldValueWithBorder, Heading, Row, Table } from "components/LayoutParts";
import Loading from "components/Loading";
import PaginationForGraphql from "components/PaginationForGraphql";
import ParkingAvailabilityTag from "components/ParkingAvailabilityTag";
import SortButton from "components/SortButton";
import color from "constants/color";
import {
  SPACE_EMPTY_USAGE,
  SyncPortCompanyPropertySpaceSortKey,
  SyncPortSpace,
} from "pages/SyncPort/Company/Property/Space/features/api";
import EmptyUsage from "pages/SyncPort/Company/Property/Space/features/DataTable/EmptyUsage";
import { findLargestSupportCarModelFromObject } from "pages/SyncPort/Company/Property/utils/supportCarModelHelper";
import { dateFormat } from "utils/dateTimeUtils";
import { convertNumberToLocaleString } from "utils/stringUtils";

export const spaceTableColumn: {
  label: string;
  flex: number;
  sortKey?: SyncPortCompanyPropertySpaceSortKey;
}[] = [
  {
    label: "区画名",
    flex: 10,
    sortKey: "name",
  },
  {
    label: "利用状況",
    flex: 9,
    sortKey: "spaceAvailableStatus",
  },
  {
    label: "利用開始日",
    flex: 11,
  },
  {
    label: "利用終了日",
    flex: 11,
  },
  {
    label: "契約者",
    flex: 15.9,
  },
  {
    label: "電話番号",
    flex: 15.9,
  },
  {
    label: "申込受付",
    flex: 9.5,
    sortKey: "status",
  },
  {
    label: "区画グループ",
    flex: 23.9,
  },
];

export const CELL_HEIGHT = 41;

export const Wrap = styled.div<{ lastColumn?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: fit-content;
  height: 100%;
`;

export const OverWrapFieldValue = styled(FieldValueWithBorder)`
  display: flex;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  border: none;
  border-right: ${(props) => (props.lastColumn ? "none" : `solid 1px ${color.border}`)};
  cursor: default;
`;

const Item = styled.div<{ lastColumn?: boolean; columnCount?: number }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding-left: 14px;
  height: 100%;
  width: 100%;
  height: ${(props) => (props.columnCount ? props.columnCount * CELL_HEIGHT : CELL_HEIGHT)}px;
`;

export const InnerItem = styled.div<{ lastColumn?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 14px;
  height: 100%;
  position: relative;
  left: 10px;
`;

export const InnerField = styled.div<{ lastColumn?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: ${(props) => (props.lastColumn ? "none" : `solid 1px ${color.border}`)};
  height: ${CELL_HEIGHT}px;
`;

const OverWrapFieldName = styled(FieldName)<{ isFirst?: boolean }>`
  margin: 0;
  padding: 0;
  position: relative;
  border: none;
  border-left: ${(props) => (props.isFirst ? "none" : `solid 1px ${color.border}`)};
  height: 100%;
  display: flex;
  align-items: center;
`;

const OverWrapHeading = styled(Heading)`
  display: flex;
  flex: 1;
  height: 34px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
`;

const OverWrapRow = styled(Row)`
  display: flex;
  position: relative;
  box-sizing: border-box;
`;

const FieldWrap = styled.div`
  margin-left: 12px;
`;

const SpaceGroupContent = styled.div`
  background-color: ${color.background};
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const SpaceGroupInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const SpaceGroupItemLeft = styled.div`
  display: flex;
  flex: 1;
`;
const SpaceGroupItemRight = styled.div`
  display: flex;
  flex: 2;
  height: 100%;
`;

const SupportCarModelText = styled.div`
  margin: 20px 0;
  border-left: solid 1px ${color.border};
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 14px;
`;

type Space = SyncPortSpace;

const spaceUsageLength = (space: Space) => space.usages?.length ?? 1;
const spaceUsage = (space: Space) => space.usages ?? SPACE_EMPTY_USAGE;
const usageEmpty = (space: Space) => !space.usages || space.usages.length === 0;

const DataTable = ({
  spaceList,
  totalPages,
  pageIndex,
  setPageIndex,
  pageInfo,
  setPagination,
  sortOption,
  setSortOption,
}: {
  spaceList?: SyncPortSpace[];
  totalPages: number;
  pageIndex: number;
  setPageIndex: React.Dispatch<React.SetStateAction<number>>;
  pageInfo?: GraphqlPageInfo;
  setPagination: React.Dispatch<React.SetStateAction<GraphqlPageOption>>;
  sortOption: SortOption<SyncPortCompanyPropertySpaceSortKey>;
  setSortOption: React.Dispatch<React.SetStateAction<SortOption<SyncPortCompanyPropertySpaceSortKey>>>;
}) => {
  const navigate = useNavigate();
  const { id, propertyId } = useParams();
  const onClickCell = (spaceGroupId: string) =>
    navigate(`/sync_port/company/${id}/property/${propertyId}/${spaceGroupId}`);

  const handleSetSort = (sortOption: SortOption<SyncPortCompanyPropertySpaceSortKey>) => {
    setPagination((prev) => ({ ...prev, after: undefined, before: undefined }));
    setPageIndex(1);
    setSortOption(sortOption);
  };

  return (
    <>
      <Table>
        <OverWrapHeading>
          {spaceTableColumn.map((column, i) => (
            <OverWrapFieldName
              key={column.label}
              isFirst={i === 0}
              flex={column.flex}
              isSort={sortOption.sortByColumn === column.sortKey}
            >
              <FieldWrap>
                {column.label}
                {column.sortKey && (
                  <SortButton currentSortOption={sortOption} sortByKey={column.sortKey} setSortOption={handleSetSort} />
                )}
              </FieldWrap>
            </OverWrapFieldName>
          ))}
        </OverWrapHeading>
        {spaceList == null ? (
          <Loading />
        ) : spaceList.length > 0 ? (
          spaceList.map((space) => {
            return (
              <OverWrapRow key={space.id}>
                <OverWrapFieldValue flex={spaceTableColumn[0].flex}>
                  <Item columnCount={spaceUsageLength(space)}>{`${space.name}`}</Item>
                </OverWrapFieldValue>
                <OverWrapFieldValue flex={spaceTableColumn[1].flex}>
                  <Item columnCount={spaceUsageLength(space)}>
                    <ParkingAvailabilityTag status={space.spaceAvailableStatus} />
                  </Item>
                </OverWrapFieldValue>
                <>
                  {usageEmpty(space) ? (
                    <EmptyUsage />
                  ) : (
                    <>
                      <OverWrapFieldValue flex={spaceTableColumn[2].flex}>
                        <Wrap>
                          {spaceUsage(space).map((usage, i) => {
                            return (
                              <InnerField lastColumn={i === spaceUsageLength(space) - 1} key={i}>
                                <InnerItem>{dateFormat(usage.useStartDate)}</InnerItem>
                              </InnerField>
                            );
                          })}
                        </Wrap>
                      </OverWrapFieldValue>
                      <OverWrapFieldValue flex={spaceTableColumn[3].flex}>
                        <Wrap>
                          {spaceUsage(space).map((usage, i) => {
                            return (
                              <InnerField key={i} lastColumn={i === spaceUsageLength(space) - 1}>
                                <InnerItem>{usage.terminateDate ? dateFormat(usage.terminateDate) : "未定"}</InnerItem>
                              </InnerField>
                            );
                          })}
                        </Wrap>
                      </OverWrapFieldValue>
                      <OverWrapFieldValue flex={spaceTableColumn[4].flex}>
                        <Wrap>
                          {spaceUsage(space).map((usage, i) => {
                            return (
                              <InnerField lastColumn={i === spaceUsageLength(space) - 1} key={i}>
                                <InnerItem>{usage.contractor.name}</InnerItem>
                              </InnerField>
                            );
                          })}
                        </Wrap>
                      </OverWrapFieldValue>
                      <OverWrapFieldValue flex={spaceTableColumn[5].flex}>
                        <Wrap>
                          {spaceUsage(space).map((usage, i) => {
                            return (
                              <InnerField lastColumn={i === spaceUsageLength(space) - 1} key={i}>
                                <InnerItem>{usage.contractor.phoneNumber}</InnerItem>
                              </InnerField>
                            );
                          })}
                        </Wrap>
                      </OverWrapFieldValue>
                    </>
                  )}
                </>
                <OverWrapFieldValue flex={spaceTableColumn[6].flex}>
                  <Item columnCount={spaceUsageLength(space)}>
                    <AcceptableTag isAcceptable={space.status === 1} />
                  </Item>
                </OverWrapFieldValue>
                <OverWrapFieldValue
                  flex={spaceTableColumn[7].flex}
                  lastColumn
                  onClick={() => onClickCell(space.spaceGroup.id)}
                >
                  <SpaceGroupContent>
                    <Item columnCount={spaceUsageLength(space)}>
                      <SpaceGroupInfo>
                        <SpaceGroupItemLeft>
                          {`${convertNumberToLocaleString(space.spaceGroup.monthlyRent.value)}`}
                        </SpaceGroupItemLeft>
                        <SpaceGroupItemRight>
                          <SupportCarModelText>
                            {findLargestSupportCarModelFromObject(space.spaceGroup.supportCarModel)}
                          </SupportCarModelText>
                        </SpaceGroupItemRight>
                      </SpaceGroupInfo>
                    </Item>
                  </SpaceGroupContent>
                </OverWrapFieldValue>
              </OverWrapRow>
            );
          })
        ) : (
          <p>該当する区画が見つかりませんでした</p>
        )}
      </Table>
      <PaginationForGraphql
        totalPageCount={totalPages}
        currentPageNumber={pageIndex}
        setCurrentPageNumber={setPageIndex}
        setPagination={setPagination}
        startCursor={pageInfo?.startCursor ?? ""}
        endCursor={pageInfo?.endCursor ?? ""}
        hasNextPage={pageInfo?.hasNextPage ?? false}
        hasPreviousPage={pageInfo?.hasPreviousPage ?? false}
      />
    </>
  );
};

export default DataTable;
