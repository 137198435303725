/**
 * 使用可能な色の設定
 */
const color = {
  // brand
  landit: "#231815",
  atPort: "#0062B1",
  pitPort: "#00AEC4",
  // main
  primary: "#231815",
  secondary: "#575A5A",
  white: "#FFF",
  // accent
  attention: "#EE8F00",
  attentionLight: "#FDF4E5",
  warning: "#DE4335",
  emphasis: "#CB1706",
  green: "#3EA32D",
  // 文字
  text: {
    black: "#23221F",
    gray: "#706D65",
    link: "#0071C1",
    linkDark: "#075A9A",
    linkLight: "#F2F8FC",
    disabled: "#C1BDB7",
    green: "#009984",
  },
  tag: {
    empty: "#0062B1",
    full: "#AA4A41",
    availableSchedule: "#3EA32D",
  },
  border: "#D6D3D0",
  background: "#F8F7F6",
  footer: {
    background: "#EBECED",
  },
  form: {
    border: "#A7A6A5",
    background: "#FBFBFB",
    disabledBackground: "#E6E5E4",
  },
  table: {
    headerBackground: "#EDEBE8",
  },
};

export default color;
