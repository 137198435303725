import { useState, useEffect } from "react";

import { useFirebaseContext } from "contexts/FirebaseContext";
import UserContext from "contexts/UserContext";

// 本体
const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const { auth } = useFirebaseContext();
  const [isUserLoaded, setIsUserLoaded] = useState(false);

  // setUserは入れない onAuthStateChangedで検出する
  const value = { isUserLoaded };

  useEffect(() => {
    const unsubscribed = auth.onAuthStateChanged(() => {
      setIsUserLoaded(true);
    });
    return unsubscribed;
  }, [auth]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;
