import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { FieldName, FieldValue, Heading, Row, Table } from "components/LayoutParts";
import Pagination from "components/Pagination";
import SortButton from "components/SortButton";
import WarningLabel from "components/WarningLabel";
import color from "constants/color";

const RowWrap = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const controlBoxTableColumn = [
  {
    label: "登録番号",
    sortKey: "registeredNumber",
    flex: 2,
  },
  {
    label: "制御BOX ID",
    sortKey: "id",
    flex: 4,
  },
  {
    label: "駐車場",
    sortKey: "propertyName",
    flex: 7,
  },
  {
    label: "区画",
    sortKey: "propertySpaceName",
    flex: 3,
  },
  {
    label: "スタンド状態",
    sortKey: "standStatus",
    flex: 3,
  },
  {
    label: "スタンドモード",
    sortKey: "standMode",
    flex: 6,
  },
  {
    label: "メインプログラム",
    sortKey: "mainVersion",
    flex: 4,
  },
  {
    label: "スタンドプログラム",
    sortKey: "standVersion",
    flex: 4,
  },
  {
    label: "ステータス",
    sortKey: "status",
    flex: 2,
  },
] as const;

const displayControlBoxStatus = (status: number) => {
  switch (status) {
    case 0:
      return "オフライン";
    case 1:
      return "稼働中";
    case 2:
      return "利用停止"; //ステータスは取得中だが、稼働中と表示する。
    default:
      return "オフライン";
  }
};

const displayStandStatus = (standStatus: number) => {
  switch (standStatus) {
    case 0:
      return "DOWN";
    case 1:
      return "UP";
    default:
      return "";
  }
};

const displayStandMode = (standMode: number) => {
  switch (standMode) {
    case 1:
      return "誤駐車防止モード";
    case 2:
      return "駐車場時間設定モード";
    case 3:
      return "メンテナンスモード";
    case 4:
      return "押しボタンモード";
    case 5:
      return "ハイブリッドモード";
    case 6:
      return "キャッシュレスモード";
    default:
      return "";
  }
};

const isBrokenBox = (box: ControlBox): boolean => {
  return box.propertyId != null && (box.status === 0 || box.status === 4);
};

const VersionField = ({ label, color, isLatest }: { label: string | null; color: string; isLatest: boolean }) => {
  return (
    <FieldValue flex={controlBoxTableColumn[7].flex} style={{ color: color }}>
      <RowWrap>
        {label}
        {!isLatest && <WarningLabel label="要更新" />}
      </RowWrap>
    </FieldValue>
  );
};

const MainVersionField = ({ box }: { box: ControlBox }) => {
  switch (box.versionStatus) {
    case 1:
      return VersionField({ label: "取得中", color: color.attention, isLatest: false });
    case 2:
      return VersionField({ label: "更新中", color: color.attention, isLatest: false });
    default:
      return VersionField({ label: box.mainVersion, color: color.text.black, isLatest: box.isMainLatest });
  }
};

const StandVersionField = ({ box }: { box: ControlBox }) => {
  switch (box.versionStatus) {
    case 1:
      return VersionField({ label: "取得中", color: color.attention, isLatest: false });
    case 2:
      return VersionField({ label: "更新中", color: color.attention, isLatest: false });
    default:
      return VersionField({ label: box.standVersion, color: color.text.black, isLatest: box.isStandLatest });
  }
};

// 本体
const DataTable = ({
  controlBoxList,
  totalPages,
  pageIndex,
  sortOption,
  setPageIndex,
  setSortOption,
}: {
  controlBoxList: ControlBox[];
  totalPages: number;
  pageIndex: number;
  sortOption: SortOption<SortByPitPortControlBox>;
  setPageIndex: React.Dispatch<React.SetStateAction<number>>;
  setSortOption: React.Dispatch<React.SetStateAction<SortOption<SortByPitPortControlBox>>>;
}) => {
  const navigate = useNavigate();
  const onClickRow = (controlBoxId: string) => navigate(`/pit_port/control_box/${controlBoxId}`);

  return (
    <>
      <Table>
        <Heading>
          {controlBoxTableColumn.map((column) => (
            <FieldName key={column.label} flex={column.flex} isSort={sortOption.sortByColumn === column.sortKey}>
              {column.label}
              <SortButton currentSortOption={sortOption} sortByKey={column.sortKey} setSortOption={setSortOption} />
            </FieldName>
          ))}
        </Heading>
        {controlBoxList.map((controlBox) => (
          <Row key={controlBox.id} onClick={() => onClickRow(controlBox.id)} warning={isBrokenBox(controlBox)}>
            <FieldValue flex={controlBoxTableColumn[0].flex}>{controlBox.registeredNumber}</FieldValue>
            <FieldValue flex={controlBoxTableColumn[1].flex}>{controlBox.id}</FieldValue>
            <FieldValue flex={controlBoxTableColumn[2].flex}>{controlBox.propertyName}</FieldValue>
            <FieldValue flex={controlBoxTableColumn[3].flex}>{controlBox.propertySpaceName}</FieldValue>
            <FieldValue flex={controlBoxTableColumn[4].flex}>{displayStandStatus(controlBox.standStatus)}</FieldValue>
            <FieldValue flex={controlBoxTableColumn[5].flex}>{displayStandMode(controlBox.standMode)}</FieldValue>
            <MainVersionField box={controlBox} />
            <StandVersionField box={controlBox} />
            <FieldValue flex={controlBoxTableColumn[8].flex}>{displayControlBoxStatus(controlBox.status)}</FieldValue>
          </Row>
        ))}
      </Table>
      <Pagination totalPages={totalPages} pageIndex={pageIndex} setPageIndex={setPageIndex} />
    </>
  );
};

export default DataTable;
