/**
 * 現在の年から10年未来までの西暦の配列を返却する関数
 * @returns string[] 年の配列
 * @example
 * const yearChoices = getYearChoices();
 * console.log(yearChoices); // ["2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031"]
 */
export const getYearChoices = (): string[] => {
  const currentYear = new Date().getFullYear();
  const yearChoices = Array.from({ length: 11 }, (_, i) => {
    return String(currentYear + i);
  });

  return yearChoices;
};

/**
 * 月の配列を返却する関数
 * @returns string[] 月の配列
 * @example
 * const monthChoices = getMonthChoices();
 * console.log(monthChoices); // ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"]
 */
export const getMonthChoices = (): string[] => {
  const monthChoices = Array.from({ length: 12 }, (_, i) => {
    return String(i + 1).padStart(2, "0");
  });

  return monthChoices;
};

/**
 * 日の配列を返却する関数
 * @returns string[] 日の配列
 * @example
 * const dayChoices = getDayChoices();
 * console.log(dayChoices); // ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", ...]
 */
export const getDayChoices = (): string[] => {
  const dayChoices = Array.from({ length: 31 }, (_, i) => {
    return String(i + 1).padStart(2, "0");
  });

  return dayChoices;
};
