import { useSwr } from "hooks/atPort/useSwr";

const useFindById = (userId?: string) => {
  const { data, error, mutate } = useSwr<SalesforceUser>(userId != null ? `/user/${userId}` : null, {
    revalidateIfStale: true,
    revalidateOnFocus: false,
  });
  return {
    data,
    error,
    mutate,
  };
};

export default useFindById;
