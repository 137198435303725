import { useCallback } from "react";

import { useAimoParkingClient } from "hooks/aimoParking/useAimoParkingClient";
import { useSwr } from "hooks/aimoParking/useSwr";
import { AimoParkingEdgeDeviceDetection } from "pages/AimoParking/constants/aimoParkingDetection";
import { formatQueryParams } from "utils/stringUtils";

export const useFindAllEdgeDevice = ({ pageIndex, pageSize = 25 }: { pageIndex?: number; pageSize?: number }) => {
  const { data, error } = useSwr<AimoParkingEdgeDevicesResponse>(
    `/edge-device?${formatQueryParams({ pageSize, pageIndex })}`
  );

  return {
    data: data?.data,
    pageInfo: data?.pagination && {
      hasMore: data.pagination.hasMore,
      pageIndex: data.pagination.pageIndex,
      totalPages: data.pagination.totalPages,
      totalItems: data.pagination.totalItems,
    },
    error,
  };
};

export const useFindEdgeDevice = ({
  edgeDeviceId,
  revalidateOnFocus = true,
}: {
  edgeDeviceId: string;
  revalidateOnFocus?: boolean;
}) => {
  const { data, error } = useSwr<AimoParkingEdgeDeviceResponse>(`/edge-device/${edgeDeviceId}`, {
    revalidateOnFocus,
  });

  return {
    data,
    error,
  };
};

export const useFindLogEdgeDevice = () => {
  const client = useAimoParkingClient();

  return async (edgeDeviceId?: string) =>
    await client.execute<{
      log: string;
    }>({
      method: "GET",
      url: `/edge-device/${edgeDeviceId}/log`,
    });
};

export const useUpdateSetting = () => {
  const client = useAimoParkingClient();

  const updateSetting = useCallback(
    async ({
      edgeDeviceId,
      serialNumber,
      nvr,
      setting,
    }: {
      edgeDeviceId: string;
      serialNumber: string;
      nvr: string;
      setting: object;
    }) => {
      return await client.execute({
        method: "PUT",
        url: `/edge-device/${edgeDeviceId}`,
        data: {
          serialNumber,
          setting,
          nvr,
        },
      });
    },
    [client]
  );

  return { updateSetting };
};

export const useCreateEdgeDevice = () => {
  const client = useAimoParkingClient();

  const create = useCallback(
    async ({
      serialNumber,
      setting,
      detectionType,
      apiKey,
      nvr,
    }: {
      serialNumber: string;
      detectionType: AimoParkingEdgeDeviceDetection;
      apiKey: string;
      setting: object;
      nvr: string;
    }) => {
      return await client.execute({
        method: "POST",
        url: `/edge-device`,
        data: {
          serialNumber,
          detectionType,
          setting,
          apiKey,
          nvr,
        },
      });
    },
    [client]
  );

  return { create };
};
