import styled from "styled-components";

import RoundedButton from "components/RoundedButton";
import SearchForm from "components/SearchForm";

const Area = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0;
`;
const FilterWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
`;
const Text = styled.span`
  font-weight: bold;
  margin-right: 5px;
`;

const CompanyStatusFilter = {
  all: ["0", "1", "2", "3", "4", "5"],
  enabled: ["1"],
  disabled: ["0", "3", "4", "5"],
};

const ActionArea = ({
  searchKeyword,
  selectedFilter,
  onSearchChange,
  onFilterButtonClick,
}: {
  searchKeyword: string;
  selectedFilter: string[];
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFilterButtonClick: (status: string[]) => void;
}) => {
  return (
    <Area>
      <SearchForm width="350px" value={searchKeyword} placeholder="検索" onChange={onSearchChange} />
      <FilterWrap>
        <Text>絞り込み</Text>
        <RoundedButton
          label="すべて"
          isSelected={selectedFilter === CompanyStatusFilter.all}
          onClick={() => onFilterButtonClick(CompanyStatusFilter.all)}
        />
        <RoundedButton
          label="利用中"
          isSelected={selectedFilter === CompanyStatusFilter.enabled}
          onClick={() => onFilterButtonClick(CompanyStatusFilter.enabled)}
        />
        <RoundedButton
          label="利用停止"
          isSelected={selectedFilter === CompanyStatusFilter.disabled}
          onClick={() => onFilterButtonClick(CompanyStatusFilter.disabled)}
        />
      </FilterWrap>
    </Area>
  );
};

export default ActionArea;
