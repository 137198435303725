// DOCS: https://drive.google.com/file/d/1STpclv9XHySTuaVb6hdOgw9VNVNlt6p2/view?usp=sharing
export const PITPORT_AUTO_STAND_MODE_LABEL = {
  ACCIDENTAL_PARKING_PREVENTION: "誤駐車防止モード",
  PARKING_TIME_SETTING: "駐車時間設定モード",
  MAINTENANCE: "メンテナンスモード",
  PUSH_BUTTON: "押しボタンモード",
  HYBRID: "ハイブリッドモード",
  CASHLESS: "キャッシュレスモード",
};

export const PITPORT_AUTO_STAND_STATUS_LABEL = {
  DOWN: "DOWN",
  UP: "UP",
};
