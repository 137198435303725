import React, { useMemo } from "react";
import styled from "styled-components";

import { FormRow, Title, TitleArea, ContentArea } from "components/LayoutParts";
import RadioButton from "components/RadioButton";
import TextButton from "components/TextButton";
import TextForm from "components/TextForm";

const Label = styled.div`
  min-width: 160px;
  margin: 0 5px;
`;
const DeleteWrap = styled.div`
  display: flex;
  flex: auto;
  justify-content: flex-end;
`;

// 本体
const SpaceForm = ({
  index,
  space,
  spaces,
  setSpaces,
}: {
  index: number;
  space: Space;
  spaces: Space[];
  setSpaces: React.Dispatch<React.SetStateAction<Space[]>>;
}) => {
  const isErrorSpaceName = !(space.name.length > 0);

  const changeField = (field: keyof Space) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setSpaces((prev) => {
      const copy = [...prev];
      let fieldValue: string | number | boolean = e.target.value;
      if (["width", "length", "height"].includes(field)) {
        fieldValue = Number(e.target.value);
      }
      if (["status"].includes(field)) {
        fieldValue = Number(e.target.value) === 0 ? 0 : 1;
      }
      if (["type"].includes(field)) {
        fieldValue = Number(e.target.value) === 0 ? 0 : 1;
      }
      if (field == "2tShort") {
        fieldValue = Number(e.target.value) === 1;
      }
      if (field === "standId") {
        if (e.target.value.match("^[a-zA-Z0-9!-/:-@¥[-`{-~]+$")) {
          fieldValue = e.target.value;
        } else {
          fieldValue = e.target.value.slice(0, -1);
        }
      }
      if (field === "contId") {
        if (e.target.value.match("^[0-9A-Za-z]+$")) {
          fieldValue = e.target.value;
        } else {
          fieldValue = e.target.value.slice(0, -1);
        }
      }
      copy[index] = {
        ...copy[index],
        [field]: fieldValue,
      };
      return copy;
    });
  };
  const deleteSpaces = () => {
    setSpaces((prev) => {
      const copy = [...prev];
      copy.splice(index, 1);
      return copy;
    });
  };

  const isDuplicated = useMemo(() => {
    const filteredSpacesByStandId = spaces.filter((space) => space.standId);
    const filteredSpacesByContId = spaces.filter((space) => space.contId);
    const isErrorStandIdDuplicated =
      new Set(filteredSpacesByStandId.map((space) => space.standId)).size !== filteredSpacesByStandId.length;
    const isErrorContIdDuplicated =
      new Set(filteredSpacesByContId.map((space) => space.contId)).size !== filteredSpacesByContId.length;
    return { isErrorContIdDuplicated, isErrorStandIdDuplicated };
  }, [spaces]);

  const isContIdLengthError = useMemo(() => {
    if (!space.contId) return false;
    return space.contId?.length !== 12;
  }, [space]);

  return (
    <>
      <TitleArea>
        <Title>{index + 1}番目の区画</Title>
        <DeleteWrap>
          <TextButton label="この区画を削除" onClick={deleteSpaces} type="danger" />
        </DeleteWrap>
      </TitleArea>
      <ContentArea>
        <FormRow>
          <Label>区画名</Label>
          <TextForm
            type="text"
            width="350px"
            value={space.name}
            placeholder="例）A区画"
            onChange={changeField("name")}
            isError={isErrorSpaceName}
            errorMessage="区画名を入力してください"
          />
        </FormRow>
        <FormRow>
          <Label>AUTOSTAND ID</Label>
          <TextForm
            type="text"
            width="350px"
            value={space.standId ? space.standId : undefined}
            placeholder="a1:b2:c3:d4:e5:f6"
            onChange={changeField("standId")}
            isError={isDuplicated.isErrorStandIdDuplicated}
            errorMessage="AUTOSTAND IDが重複しています"
          />
        </FormRow>
        <FormRow>
          <Label>制御BOX ID</Label>
          <TextForm
            type="text"
            width="350px"
            value={space.contId ? space.contId : undefined}
            placeholder="a1b2c3d4e5f6"
            onChange={changeField("contId")}
            isError={isDuplicated.isErrorContIdDuplicated || isContIdLengthError}
            errorMessage={
              isDuplicated.isErrorContIdDuplicated ? "制御BOX IDが重複しています" : "制御BOX IDは12桁の英数字です"
            }
          />
        </FormRow>
        <FormRow>
          <Label>サイズ制限</Label>
          <div style={{ marginRight: "8px" }}>車幅</div>
          <TextForm
            type="number"
            width="100px"
            value={space.width}
            placeholder="例）300"
            onChange={changeField("width")}
          />
          <div style={{ marginLeft: "8px" }}>cm</div>
          <div style={{ marginLeft: "32px", marginRight: "8px" }}>車長</div>
          <TextForm
            type="number"
            width="100px"
            value={space.length}
            placeholder="例）500"
            onChange={changeField("length")}
          />
          <div style={{ marginLeft: "8px" }}>cm</div>
          <div style={{ marginLeft: "32px", marginRight: "8px" }}>車高</div>
          <TextForm
            type="number"
            width="100px"
            value={space.height ? space.height : undefined}
            placeholder="例）400"
            onChange={changeField("height")}
          />
          <div style={{ marginLeft: "8px" }}>cm</div>
        </FormRow>
        <FormRow>
          <Label>2tショート</Label>
          <RadioButton
            id={`2tShort_${index}`}
            value={space["2tShort"] ? 1 : 0}
            choices={[
              {
                label: "駐車可能",
                value: 1,
              },
              {
                label: "駐車不可能",
                value: 0,
              },
            ]}
            onChange={changeField("2tShort")}
          />
        </FormRow>
        <FormRow>
          <Label>状態</Label>
          <RadioButton
            id={`status_${index}`}
            value={Number(space.status)}
            choices={[
              {
                label: "利用可",
                value: 1,
              },
              {
                label: "利用不可",
                value: 0,
              },
            ]}
            onChange={changeField("status")}
          />
        </FormRow>
        <FormRow>
          <Label>種別</Label>
          <RadioButton
            id={`type_${index}`}
            value={space.type}
            choices={[
              {
                label: "一般",
                value: 0,
              },
              {
                label: "限定",
                value: 1,
              },
            ]}
            onChange={changeField("type")}
          />
        </FormRow>
      </ContentArea>
    </>
  );
};

export default SpaceForm;
