import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import Breadcrumb from "components/Breadcrumb";
import Button from "components/Button";
import DeletedAlert from "components/DeletedAlert";
import { TitleArea, FooterArea, ContentArea as BaseContentArea, FormRow, Board } from "components/LayoutParts";
import TextForm from "components/TextForm";
import UploadForm from "components/UploadForm";
import UserModal, { UserModalConfig } from "components/UserModal";
import color from "constants/color";
import font from "constants/font";
import { useUploadAimoParkingFile } from "pages/AimoParking/api";
import { useRemoveCompany, useFindCompanyById, useUpdateCompany } from "pages/AimoParking/Company/api";
import {
  DELETE_MODAL_CONFIG,
  FAILED_MODAL_CONFIG,
  INITIAL_MODAL_CONFIG,
  SUCCEED_MODAL_CONFIG,
} from "pages/AimoParking/modalTemplate";
import { detectIsVendor } from "pages/AimoParking/utils/detectIsVendorUtil";
import { dateFormat } from "utils/dateTimeUtils";
import { getGCSPathFromSignedURL } from "utils/imageFileUtil";

// パーツ定義
const CancelWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;
const Wrap = styled.div`
  margin-top: 10px;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  flex: 1;
`;
const ContentWrap = styled.div`
  gap: 16px;
`;
const ContentArea = styled(BaseContentArea)`
  display: flex;
`;
const SaveWrap = styled(CancelWrap)`
  align-items: center;
  justify-content: flex-end;
`;
const Annotation = styled.span`
  font-size: ${font.size12};
  color: ${color.text};
  margin-right: 16px;
`;
const Label = styled.div<{ top?: boolean }>`
  min-width: 200px;
  margin: 0 5px;
  padding-right: 18px;
  align-self: ${({ top }) => (top ? "flex-start" : "center")};
  margin-top: ${({ top }) => (top ? "14px" : "0")};
`;

const UploadWrap = styled.div`
  width: 520px;
`;

const DeleteWrap = styled.div`
  display: flex;
  flex: auto;
  justify-content: flex-end;
`;

const SbPaymentTitle = styled.div`
  font-size: ${font.size16};
  color: ${color.text};
  margin: 32px 0 16px 4px;
`;

const EditCompany = () => {
  // フォームの値
  const [name, setName] = useState("");
  const [images, setImages] = useState<File[]>([]);
  const [logo, setLogo] = useState<Image[]>([]);
  const [merchantId, setMerchantId] = useState("");
  const [serviceId, setServiceId] = useState("");
  const [hashKey, setHashKey] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [modalConfig, setModalConfig] = useState<UserModalConfig>(INITIAL_MODAL_CONFIG);
  const [isVisible, setIsVisible] = useState(false);

  const { id: companyId } = useParams();
  const navigate = useNavigate();
  const { data: company } = useFindCompanyById({ id: companyId as string, revalidateOnFocus: false });
  const { update } = useUpdateCompany();
  const { uploadFiles } = useUploadAimoParkingFile({ uploadDestination: "company" });
  const { remove } = useRemoveCompany();

  const changeName = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value);
  const changeMerchantId = (e: React.ChangeEvent<HTMLInputElement>) => setMerchantId(e.target.value);
  const changeServiceId = (e: React.ChangeEvent<HTMLInputElement>) => setServiceId(e.target.value);
  const changeHashKey = (e: React.ChangeEvent<HTMLInputElement>) => setHashKey(e.target.value);
  const changeInvoiceNumber = (e: React.ChangeEvent<HTMLInputElement>) => setInvoiceNumber(e.target.value);

  // バリデーション
  const isVendor = detectIsVendor(company?.vendors);
  const isErrorName = name === "";
  const isErrorLogo = images.length === 0 && logo.length === 0;
  const isErrorMerchantId = isVendor && merchantId?.length === 0;
  const isErrorServiceId = isVendor && serviceId?.length === 0;
  const isErrorHashKey = isVendor && hashKey?.length === 0;
  const isErrorInvoiceNumber = !/^T\d{13}$/.test(invoiceNumber);
  const isError =
    isErrorName || isErrorInvoiceNumber || isErrorLogo || isErrorMerchantId || isErrorServiceId || isErrorHashKey;
  const isChanged =
    name !== company?.name ||
    invoiceNumber !== company?.invoiceNumber ||
    logo[0]?.url !== company?.logo ||
    (merchantId && merchantId !== company?.paymentInfo?.merchantId) ||
    (serviceId && serviceId !== company?.paymentInfo?.serviceId) ||
    (hashKey && hashKey !== company?.paymentInfo?.hashKey);

  const canSubmit = !isError && isChanged && !company?.deletedAt;

  const onSubmit = useCallback(async () => {
    const uploadImageResponse = await uploadFiles({ files: images }).catch((e) => {
      setModalConfig(FAILED_MODAL_CONFIG({ onClose: () => setIsVisible(false) }));
      setIsVisible(true);
      throw e;
    });

    if (!companyId) return;

    const response = await update(companyId, {
      invoiceNumber,
      logo: uploadImageResponse[0] ?? getGCSPathFromSignedURL(logo[0].url),
      name,
      paymentInfo: merchantId && serviceId && hashKey ? { merchantId, serviceId, hashKey } : undefined,
    }).catch((e) => {
      setModalConfig(FAILED_MODAL_CONFIG({ onClose: () => setIsVisible(false) }));
      setIsVisible(true);
      throw e;
    });

    if (response.status === 200) {
      setModalConfig(
        SUCCEED_MODAL_CONFIG({ message: "変更内容を保存しました", onClose: () => navigate("/aimo_parking/company") })
      );
      setIsVisible(true);
    }
  }, [update, invoiceNumber, name, navigate, images, uploadFiles, logo, companyId, merchantId, serviceId, hashKey]);

  const handleRemoveCompany = async () => {
    if (!companyId) return;
    const response = await remove(companyId).catch((e) => {
      setModalConfig(FAILED_MODAL_CONFIG({ onClose: () => setIsVisible(false) }));
      setIsVisible(true);
      throw e;
    });
    if (response.status === 200) {
      setModalConfig(
        SUCCEED_MODAL_CONFIG({ message: "削除しました", onClose: () => navigate("/aimo_parking/company") })
      );
      setIsVisible(true);
    }
  };

  const handleClickRemove = async () => {
    setModalConfig(DELETE_MODAL_CONFIG({ positive: handleRemoveCompany, negative: () => setIsVisible(false) }));
    setIsVisible(true);
  };

  const setUp = useCallback(() => {
    if (images.length === 1) return;
    if (company) {
      setName(company.name);
      setMerchantId(company.paymentInfo?.merchantId ?? "");
      setServiceId(company.paymentInfo?.serviceId ?? "");
      setHashKey(company.paymentInfo?.hashKey ?? "");
      setInvoiceNumber(company.invoiceNumber);
      setLogo([{ url: company.logo }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  useEffect(() => {
    setUp();
  }, [setUp]);

  if (!company) return null;

  return (
    <Board>
      <TitleArea>
        <Breadcrumb
          currentPageName="会社情報を編集"
          breadcrumbItems={[
            { pageName: `${company.name}`, onClick: () => navigate(`/aimo_parking/company/${company.id}`) },
          ]}
        />
        {!company.deletedAt && (
          <DeleteWrap>
            <Button label="この管理会社を削除" onClick={handleClickRemove} type="danger" />
          </DeleteWrap>
        )}
      </TitleArea>
      {company?.deletedAt && (
        <DeletedAlert message={`この管理会社は削除済みです。 削除日: ${dateFormat(company?.deletedAt)}`} />
      )}
      <Wrap>
        <ContentWrap>
          <ContentArea>
            <FormRow>
              <Label>会社名</Label>
              <TextForm
                type="text"
                width="832px"
                value={name}
                placeholder="例）ランドイット不動産"
                required
                onChange={changeName}
                errorMessage="会社名を入力してください"
                isError={isErrorName}
              />
            </FormRow>
            <FormRow>
              <Label top>ロゴ</Label>
              <UploadWrap>
                <UploadForm
                  unUploadedImages={images}
                  setUnUploadedImages={setImages}
                  showImages={logo}
                  setShowImages={setLogo}
                  single
                />
              </UploadWrap>
            </FormRow>
            <FormRow>
              <Label>企業インボイス番号</Label>
              <TextForm
                type="text"
                width="350px"
                value={invoiceNumber}
                placeholder="例）T0123456789012"
                required
                onChange={changeInvoiceNumber}
                errorMessage="T以後13桁の英数字で入力して下さい"
                isError={isErrorInvoiceNumber}
              />
            </FormRow>
            {isVendor && (
              <>
                {/* TODO sbペイメント情報についてはデザインがわかり次第に修正する */}
                <SbPaymentTitle>SBペイメント情報</SbPaymentTitle>
                <FormRow>
                  <Label>企業識別ID</Label>
                  <TextForm
                    type="text"
                    width="350px"
                    value={merchantId}
                    placeholder="例）h8Xz3bW9v2F7Yk4mNpQ6L1VrTs5A8G0d"
                    required
                    onChange={changeMerchantId}
                    errorMessage="企業識別IDを入力してください"
                    isError={isErrorMerchantId}
                  />
                </FormRow>
                <FormRow>
                  <Label>サービス識別ID</Label>
                  <TextForm
                    type="text"
                    width="350px"
                    value={serviceId}
                    placeholder="例）h8Xz3bW9v2F7Yk4mNpQ6L1VrTs5A8G0d"
                    required
                    onChange={changeServiceId}
                    errorMessage="サービス識別IDを入力してください"
                    isError={isErrorServiceId}
                  />
                </FormRow>
                <FormRow>
                  <Label>ハッシュキー</Label>
                  <TextForm
                    type="text"
                    width="350px"
                    value={hashKey}
                    placeholder="例）h8Xz3bW9v2F7Yk4mNpQ6L1VrTs5A8G0d"
                    required
                    onChange={changeHashKey}
                    errorMessage="ハッシュキーを入力してください"
                    isError={isErrorHashKey}
                  />
                </FormRow>
              </>
            )}
          </ContentArea>
        </ContentWrap>
        <FooterArea>
          <CancelWrap>
            <Button
              type="secondary"
              onClick={() => navigate(`/aimo_parking/company/${companyId}`)}
              label="キャンセル"
              width="160px"
            />
          </CancelWrap>
          <SaveWrap>
            <Annotation>保存するまで変更内容は反映されません</Annotation>
            <Button type="primary" onClick={onSubmit} label="変更内容を保存" disabled={!canSubmit} width="160px" />
          </SaveWrap>
        </FooterArea>
      </Wrap>
      <UserModal isVisible={isVisible} config={modalConfig} />
    </Board>
  );
};

export default EditCompany;
