export const SAMPLE_CSV: ImportPitPortUserCsvFormat = {
  ["姓を入力"]: "", // lastName
  ["名を入力"]: "", // firstName
  ["セイを入力"]: "", // lastNameFurigana
  ["メイを入力"]: "", // firstNameFurigana
  ["携帯電話"]: "", //phoneNumber
  ["メールアドレス"]: "", //email
  ["管理権限"]: "", //role 1->normal, 2->admin
  ["地名（車ナンバー）"]: "", // numberPlate_region
  ["分類番号（車ナンバー）"]: "", //numberPlate_regionCode
  ["ひらがな（車ナンバー）"]: "", //numberPlate_hiragana
  ["登録番号（車ナンバー）"]: "", //numberPlate_number
};

export const ErrorImportCSV = {
  EMAIL_ALREADY_EXIST: "Email_Already_Exist",
  DUPLICATE_EMAIL_ADDRESS: "Duplicate_Email_Address",
} as const;
