export const SYNC_PORT_IMPORT_CSV_ERROR = {
  INVALID_HEADER: {
    errorCode: "Invalid header",
    message: "不正なヘッダーです",
  },
  NO_DATA: {
    errorCode: "No data",
    message: "データがありません",
  },
  INVALID_ADDRESS: {
    errorCode: "Invalid address",
    message: "不正な住所が含まれています",
  },
  INVALID_SUPPORT_CAR_MODEL: {
    errorCode: "Invalid support car model",
    message: "不正な対応車両が含まれています",
  },
  TOO_LARGE_FEE: {
    errorCode: "Too large fee",
    message: "費用に最大値より大きい値が含まれています",
  },
  TOO_LARGE_SIZE: {
    errorCode: "Too large size",
    message: "サイズに最大値より大きい値が含まれています",
  },
  INVALID_NUMBER: {
    errorCode: "Invalid number",
    message: "不正な数値が含まれています",
  },
  INVALID_STRING_LENGTH: {
    errorCode: "Invalid string length",
    message: "不正な文字列の長さがあります",
  },
  INVALID_TIME_FORMAT: {
    errorCode: "Invalid time format",
    message: "不正な時間の形式です",
  },
  USAGE_TIME_OVERLAP: {
    errorCode: "Usage time not valid",
    message: "不正な使用期間が含まれています",
  },
  INVALID_DATE_TIME: {
    errorCode: "Invalid date time",
    message: "不正な日付が含まれています",
  },
} as const;

export type SYNC_PORT_IMPORT_CSV_ERROR_CODE =
  typeof SYNC_PORT_IMPORT_CSV_ERROR[keyof typeof SYNC_PORT_IMPORT_CSV_ERROR]["errorCode"];

export type SYNC_PORT_ERROR_KEYS = keyof typeof SYNC_PORT_IMPORT_CSV_ERROR;
