import React, { useState } from "react";
import styled from "styled-components";

import color from "constants/color";
import font from "constants/font";

// パーツ定義
export const Wrapper = styled.div<{ wrapWidth?: string }>`
  position: relative;
  ${(props) => props.wrapWidth && `width: ${props.wrapWidth};`}
`;
export const Input = styled.input<{
  isErrorDisplay: boolean;
  width: string;
  height?: string;
}>`
  display: block;
  font-size: ${font.size14};
  box-sizing: border-box;
  width: ${(props) => props.width};
  padding: 12px 16px;
  height: ${(props) => props.height};
  appearance: none;
  border: 1px solid ${(props) => (props.isErrorDisplay ? color.attention : color.form.border)};
  background-color: ${(props) => (props.isErrorDisplay ? color.attentionLight : color.form.background)};
  border-radius: 4px;
  outline: none;
  transition: 0.3s;
  z-index: 2;
  &:hover {
    border: 1px solid ${color.text.black};
  }
  &:focus {
    border: 1px solid ${color.text.link};
    background: ${color.text.linkLight};
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    appearance: textfield;
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    appearance: textfield;
  }
  &::-webkit-clear-button {
    z-index: 1;
  }
`;
export const ErrorArea = styled.div<{
  isErrorDisplay: boolean;
}>`
  display: ${(props) => (props.isErrorDisplay ? "block" : "none")};
  white-space: nowrap;
  color: ${color.attention};
  margin: 2px 0;
`;
export const ErrorMessage = styled.span`
  font-size: ${font.size12};
`;

// 本体
const TextForm = ({
  type = "text",
  width = "200px",
  wrapWidth,
  value = "",
  placeholder = "",
  required = false,
  readOnly = false,
  onChange = () => null,
  onBlur = () => null,
  onFocus = () => null,
  isError = false,
  errorMessage = "",
  height,
}: {
  type?: "text" | "number" | "email" | "tel" | "url";
  width?: string;
  wrapWidth?: string;
  value?: string | number;
  placeholder?: string;
  required?: boolean;
  readOnly?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  isError?: boolean;
  errorMessage?: string;
  height?: string;
}) => {
  const [isBlurOnce, setIsBlurOnce] = useState(false);
  // 値が空の場合はフォーカスが外れてからエラーを表示する
  const isErrorDisplay = isError && ((value === "" && isBlurOnce) || (value !== "" && isBlurOnce));

  const onBlurForm = () => {
    setIsBlurOnce(true);
    onBlur();
  };

  const ifZeroReturnUndefined = (value: string | number) => (value === 0 ? undefined : value);

  const disableWheelChange = (e: React.WheelEvent<HTMLInputElement>) => {
    e.currentTarget.blur();
    e.stopPropagation();
  };

  return (
    <Wrapper wrapWidth={wrapWidth}>
      <Input
        type={type}
        width={width}
        value={ifZeroReturnUndefined(value)}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlurForm}
        required={required}
        readOnly={readOnly}
        isErrorDisplay={isErrorDisplay}
        onWheel={(e) => disableWheelChange(e)}
        height={height}
      />
      <ErrorArea isErrorDisplay={isErrorDisplay}>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </ErrorArea>
    </Wrapper>
  );
};

export default TextForm;
