import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Button from "components/Button";
import { Board, Title, TitleArea } from "components/LayoutParts";
import { useFindPlaceCodes } from "pages/AtPort/DandoriWorks/api";
import DataTable from "pages/AtPort/DandoriWorks/features/DataTable";

// パーツ定義
const Padding = styled.div`
  padding: 20px 0;
`;

const CreateWrap = styled.div`
  display: flex;
  flex: auto;
  justify-content: flex-end;
`;

const PlaceCode = () => {
  const navigate = useNavigate();
  const { data: places } = useFindPlaceCodes();

  const navigateCreatePlaceCode = () => navigate("/at_port/dandori_works/place/create");

  return (
    <Board>
      <TitleArea>
        <Title>連携プレース一覧</Title>
        <CreateWrap>
          <Button type="primary" onClick={navigateCreatePlaceCode} label="新規作成" width="160px" />
        </CreateWrap>
      </TitleArea>
      <Padding />
      <DataTable placeList={places} />
    </Board>
  );
};

export default PlaceCode;
