import styled from "styled-components";

import color from "constants/color";
import font from "constants/font";

const Wrap = styled.div`
  position: relative;
`;
const Label = styled.label<{ checked: boolean }>`
  padding-left: 30px;
  &:hover {
    cursor: pointer;
  }
  &:before {
    position: absolute;
    top: 2px;
    left: 8px;
    width: 5px;
    height: 10px;
    border-right: 3px solid ${color.white};
    border-bottom: 3px solid ${color.white};
    content: "";
    transform: rotate(45deg);
    z-index: 1;
  }
  &:hover:before {
    cursor: pointer;
  }
  &:after {
    position: absolute;
    top: -2px;
    left: 0;
    width: 22px;
    height: 22px;
    border-radius: 3px;
    content: "";
    border: solid 1px ${color.text.link};
    background-color: ${color.white};
  }
  &:hover:after {
    cursor: pointer;
  }
  &:not(:target):after {
    display: block;
    border: 1px solid ${color.form.border};
  }
  &:not(:target):hover:after {
    border: 1px solid ${color.text.black};
  }
`;
const HiddenInput = styled.input.attrs({ type: "checkbox" })`
  display: none;
  &:checked + ${Label}:before {
    opacity: 1;
  }
  &:checked + ${Label}:after {
    border: 1px solid ${color.text.link};
    background-color: ${color.text.link};
  }
  &:checked + ${Label}:hover:after {
    border: 1px solid ${color.text.linkDark};
    background-color: ${color.text.linkDark};
  }
`;

const Span = styled.span`
  font-size: ${font.size14};
`;

const CheckBox = ({
  id,
  isChecked,
  setIsChecked,
  label,
}: {
  id: string;
  isChecked: boolean;
  setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
}) => {
  const handleOnChange = () => setIsChecked((prev) => !prev);
  return (
    <Wrap>
      <HiddenInput id={id} checked={isChecked} onChange={handleOnChange} />
      <Label htmlFor={id} checked={isChecked}>
        <Span>{label}</Span>
      </Label>
    </Wrap>
  );
};

export default CheckBox;
