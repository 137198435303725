import axios from "axios";
import { Auth } from "firebase/auth";

export const createUser = async (auth: Auth, user: Omit<SalesforceUser, "id">) => {
  const token = await auth?.currentUser?.getIdToken();
  if (!token) return;
  const { status } = await axios
    .request({
      method: "post",
      url: `${process.env.REACT_APP_LANDIT_ADMIN_API_URL}/lessee/user`,
      data: user,
      headers: { Authorization: `Bearer ${token}` },
    })
    .catch((e) => {
      throw e;
    });
  return status;
};

export const findUser = async (auth: Auth): Promise<SalesforceUser[]> => {
  const token = await auth?.currentUser?.getIdToken();
  if (!token) return [];
  const response = await axios
    .request({
      method: "get",
      url: `${process.env.REACT_APP_LANDIT_ADMIN_API_URL}/lessee/user`,
      headers: { Authorization: `Bearer ${token}` },
    })
    .catch((e) => {
      throw e;
    });
  return response.data;
};
