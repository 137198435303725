import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import EmptyTable from "components/EmptyTable";
import { Table, Heading, FieldName, Row, FieldValueWithBorder } from "components/LayoutParts";
import Pagination from "components/Pagination";
import SortButton from "components/SortButton";
import { aimoParkingCompanyStatus } from "pages/AimoParking/Company/DataTable/utils";
import VendorList from "pages/AimoParking/component/VendorList";

const companyTableColumn = [
  {
    label: "ロゴ",
    flex: 12,
  },
  {
    label: "会社名",
    sortKey: "name",
    flex: 35,
  },
  {
    label: "販売先企業",
    flex: 35,
  },
  {
    label: "ステータス",
    flex: 11,
  },
];

const ImageWrap = styled.img`
  width: auto;
  height: 24px;
`;

const DataTable = ({
  companyList,
  totalPages,
  pageIndex,
  sortOption,
  setPageIndex,
  setSortOption,
}: {
  companyList: AimoParkingCompany[];
  totalPages: number;
  pageIndex: number;
  sortOption: SortOption<SortByAimoParkingCompany>;
  setPageIndex: React.Dispatch<React.SetStateAction<number>>;
  setSortOption: React.Dispatch<React.SetStateAction<SortOption<SortByAimoParkingCompany>>>;
}) => {
  const navigate = useNavigate();

  const onClickRow = (companyId: string) => navigate(`/aimo_parking/company/${companyId}`);

  const onClickAddCompany = () => navigate("/aimo_parking/company/create");

  const handleSetSort = (sortOption: SortOption<SortByAimoParkingCompany>) => {
    setPageIndex(1);
    setSortOption(sortOption);
  };

  return (
    <>
      <Table>
        <Heading>
          {companyTableColumn.map((column, i) => (
            <FieldName
              key={column.label}
              flex={column.flex}
              isSort={sortOption.sortByColumn === column.sortKey}
              lastColumn={i === companyTableColumn.length - 1}
            >
              {column.label}
              {column.sortKey && (
                <SortButton
                  currentSortOption={sortOption}
                  sortByKey={column.sortKey as SortByAimoParkingCompany}
                  setSortOption={handleSetSort}
                />
              )}
            </FieldName>
          ))}
        </Heading>
        {companyList.length > 0 ? (
          companyList.map((company) => (
            <Row key={company.id} onClick={() => onClickRow(company.id)} isDeleted={!!company.deletedAt}>
              <FieldValueWithBorder flex={companyTableColumn[0].flex}>
                <ImageWrap src={company.logo} />
              </FieldValueWithBorder>
              <FieldValueWithBorder flex={companyTableColumn[1].flex}>{company.name}</FieldValueWithBorder>
              <FieldValueWithBorder flex={companyTableColumn[2].flex}>
                <VendorList vendors={company.vendors} type="list" />
              </FieldValueWithBorder>
              <FieldValueWithBorder flex={companyTableColumn[3].flex}>
                {aimoParkingCompanyStatus(company.deletedAt)}
              </FieldValueWithBorder>
            </Row>
          ))
        ) : (
          <EmptyTable handleClick={onClickAddCompany} label="管理会社を追加" message="管理会社が見つかりません" />
        )}
      </Table>
      <Pagination totalPages={totalPages} pageIndex={pageIndex} setPageIndex={setPageIndex} />
    </>
  );
};

export default DataTable;
