import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Table, Heading, Row, FieldValue, FieldName } from "components/LayoutParts";
import Pagination from "components/Pagination";
import SortButton from "components/SortButton";
import color from "constants/color";
import { switchCompanyStatusDisplayValue, switchUserStatusDisplayValue } from "utils/statusSwitchUtil";

const FieldValueAttention = styled(FieldValue)`
  color: ${color.attention};
`;

const userTableColumn = [
  {
    label: "ID",
    sortKey: "id",
    flex: 1,
  },
  {
    label: "名前",
    sortKey: "name",
    flex: 2,
  },
  {
    label: "携帯番号",
    sortKey: "phoneNumber",
    flex: 2,
  },
  {
    label: "メールアドレス",
    sortKey: "email",
    flex: 4,
  },
  {
    label: "ナンバー",
    sortKey: "numberPlate",
    flex: 2,
  },
  {
    label: "会社名",
    sortKey: "companyName",
    flex: 8,
  },
  { label: "権限", sortKey: "role", flex: 1.5 },
  {
    label: "状態",
    sortKey: "status",
    flex: 1.5,
  },
  {
    label: "請求先状態",
    sortKey: "companyStatus",
    flex: 1.5,
  },
] as const;

const numberPlateDisplayValue = (numberPlate: NumberPlate): string => {
  return numberPlate
    ? `${numberPlate.number} ${numberPlate.region} ${numberPlate.hiragana} ${numberPlate.regionCode}`
    : "-";
};

const DataTable = ({
  userList,
  totalPages,
  pageIndex,
  sortOption,
  setPageIndex,
  setSortOption,
}: {
  userList: PitPortUser[];
  totalPages: number;
  pageIndex: number;
  sortOption: SortOption<SortByPitPortUser>;
  setPageIndex: React.Dispatch<React.SetStateAction<number>>;
  setSortOption: React.Dispatch<React.SetStateAction<SortOption<SortByPitPortUser>>>;
  searchKeyword: string;
  selectedFilter: PitPortUserDisplayFilterStatus;
}) => {
  const navigate = useNavigate();
  const onClickRow = (userId: number) => navigate(`/pit_port/user/${userId}`);

  return (
    <>
      <Table>
        <Heading>
          {userTableColumn.map((column) => (
            <FieldName key={column.label} flex={column.flex} isSort={sortOption.sortByColumn === column.sortKey}>
              {column.label}
              <SortButton currentSortOption={sortOption} sortByKey={column.sortKey} setSortOption={setSortOption} />
            </FieldName>
          ))}
        </Heading>
        {userList.map((user) => (
          <Row key={user.id} onClick={() => onClickRow(user.id)}>
            <FieldValue flex={userTableColumn[0].flex}>{user.id}</FieldValue>
            <FieldValue flex={userTableColumn[1].flex}>{user.lastName + user.firstName}</FieldValue>
            <FieldValue flex={userTableColumn[2].flex}>{user.phoneNumber}</FieldValue>
            <FieldValue flex={userTableColumn[3].flex}>{user.email}</FieldValue>
            <FieldValue flex={userTableColumn[4].flex}>{numberPlateDisplayValue(user.numberPlate)}</FieldValue>
            <FieldValue flex={userTableColumn[5].flex}>{user.company.name}</FieldValue>
            <FieldValue flex={userTableColumn[6].flex}>{user.companyUser.role === 2 ? "管理者" : "-"}</FieldValue>
            {user.status === 2 ? (
              // 承認待ちの場合に色をAttentionColorに変える
              <FieldValueAttention flex={userTableColumn[7].flex}>
                {switchUserStatusDisplayValue(user.status)}
              </FieldValueAttention>
            ) : (
              <FieldValue flex={userTableColumn[7].flex}>{switchUserStatusDisplayValue(user.status)}</FieldValue>
            )}
            <FieldValue flex={userTableColumn[8].flex}>
              {switchCompanyStatusDisplayValue(user.company.status)}
            </FieldValue>
          </Row>
        ))}
      </Table>
      <Pagination totalPages={totalPages} pageIndex={pageIndex} setPageIndex={setPageIndex} />
    </>
  );
};

export default DataTable;
