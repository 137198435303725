import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEmail } from "class-validator";
import React, { useState } from "react";
import styled from "styled-components";

import Button from "components/Button";
import TextForm from "components/TextForm";
import color from "constants/color";
import font from "constants/font";
import { useFirebaseContext } from "contexts/FirebaseContext";
import { createUser } from "hooks/atPortApi";
import { linkSalesforceAccount, linkSalesforceContact } from "pages/AtPort/utils";

// modalの中身
const Body = styled.div`
  padding: 20px;
`;
const SubtitleWrap = styled.div`
  width: 100%;
  padding: 10px 0px;
  display: flex;
`;
const Subtitle = styled.span`
  margin-bottom: 10px;
  font-size: ${font.size20};
  text-align: center;
`;
const RowWrap = styled.div`
  width: 100%;
  padding: 15px 0px;
  border-top: solid 1px ${color.border};
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Required = styled.span`
  min-width: 30px;
  background-color: ${color.warning};
  color: ${color.white};
  font-size: ${font.size12};
  text-align: center;
`;
const Label = styled.div`
  min-width: 120px;
  margin: 0 5px;
`;
const MessageArea = styled.div`
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${color.white};
  background-color: ${color.text.link};
`;
const Text = styled.div`
  font: ${font.size14};
  margin-bottom: 20px;
`;
const MessageText = styled.span`
  color: ${color.white};
  font: ${font.size14};
`;
const CreateUser = ({ fetch }: { fetch: () => Promise<void> }) => {
  const [accountId, setAccountId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [contactId, setContactId] = useState("");
  const [email, setEmail] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const { auth } = useFirebaseContext();
  const changeAccountId = (e: React.ChangeEvent<HTMLInputElement>) => setAccountId(e.target.value);
  const changeCompanyName = (e: React.ChangeEvent<HTMLInputElement>) => setCompanyName(e.target.value);
  const changeContactId = (e: React.ChangeEvent<HTMLInputElement>) => setContactId(e.target.value);
  const changeEmail = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);
  const isErrorAccountId = !accountId || !/^[a-zA-Z0-9]+$/.test(accountId);
  const isErrorCompanyName = !companyName;
  const isErrorContactId = !contactId || !/^[a-zA-Z0-9]+$/.test(contactId);
  const isErrorEmail = !isEmail(email);
  const canSubmit = !isErrorAccountId && !isErrorCompanyName && !isErrorContactId && !isErrorEmail;
  const save = async () => {
    const isSuccess = await createUser(auth, {
      accountId,
      companyName,
      contactId,
      email,
    });
    if (isSuccess) {
      fetch();
      setIsSuccess(true);
      setAccountId("");
      setCompanyName("");
      setContactId("");
      setEmail("");
    }
  };

  return (
    <Body>
      <SubtitleWrap>
        <Subtitle>アカウント新規作成</Subtitle>
      </SubtitleWrap>
      <Text>Salesforceと連携するアカウントを新規作成します</Text>
      {isSuccess && (
        <MessageArea>
          <FontAwesomeIcon icon={faCheckCircle} />
          <MessageText>作成しました</MessageText>
        </MessageArea>
      )}
      <RowWrap>
        <Required>必須</Required>
        <Label>顧客ID</Label>
        <div style={{ width: "330px", marginRight: "10px" }}>
          <TextForm
            type="text"
            value={accountId}
            placeholder="0015h00000dz4Ax"
            required
            onChange={changeAccountId}
            isError={isErrorAccountId}
            errorMessage="顧客IDを正しく入力してください"
          />
        </div>
        <Button type="secondary" label="存在チェック" onClick={() => linkSalesforceAccount(accountId)} />
      </RowWrap>
      <RowWrap>
        <Required>必須</Required>
        <Label>顧客名</Label>
        <div style={{ width: "330px" }}>
          <TextForm
            type="text"
            value={companyName}
            placeholder="ランディット株式会社"
            required
            onChange={changeCompanyName}
            isError={isErrorCompanyName}
            errorMessage="会社名を入力してください"
          />
        </div>
      </RowWrap>
      <RowWrap>
        <Required>必須</Required>
        <Label>担当者ID</Label>
        <div style={{ width: "330px", marginRight: "10px" }}>
          <TextForm
            type="text"
            value={contactId}
            placeholder="0035h00000SjUtt"
            required
            onChange={changeContactId}
            isError={isErrorContactId}
            errorMessage="担当者IDを正しく入力してください"
          />
        </div>
        <Button type="secondary" label="存在チェック" onClick={() => linkSalesforceContact(contactId)} />
      </RowWrap>
      <RowWrap>
        <Required>必須</Required>
        <Label>メールアドレス</Label>
        <div style={{ width: "330px" }}>
          <TextForm
            type="email"
            value={email}
            placeholder="test@example.com"
            required
            onChange={changeEmail}
            isError={isErrorEmail}
            errorMessage="メールアドレスを入力してください"
          />
        </div>
      </RowWrap>
      <div style={{ flex: 1, display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
        <Button type="primary" onClick={save} label="作成する" disabled={!canSubmit} width="160px" />
      </div>
    </Body>
  );
};

export default CreateUser;
