import styled from "styled-components";

import color from "constants/color";
import font from "constants/font";

export const Wrapper = styled.div`
  background-color: ${color.attention};
  font-size: ${font.size12};
  color: ${color.white};
  padding: 4px;
  border-radius: 4px;
`;

const WarningLabel = ({ label }: { label: string }) => {
  return <Wrapper>{label}</Wrapper>;
};

export default WarningLabel;
