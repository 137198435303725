import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEmail, isPhoneNumber } from "class-validator";
import React, { useCallback, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import Breadcrumb from "components/Breadcrumb";
import Button from "components/Button";
import CheckBox from "components/CheckBox";
import { Board, FormRow, TitleArea, AlertArea, ContentArea, FooterArea } from "components/LayoutParts";
import TextForm from "components/TextForm";
import UserModal, { UserModalConfig } from "components/UserModal";
import color from "constants/color";
import font from "constants/font";
import { useSwr } from "hooks/useSwr";
import ConfirmCompany from "pages/PitPort/Company/ConfirmCompany";
import {
  useReactivatePitPortUser,
  useRemoveOrBlockPitPortUser,
  useUpdatePitPortUser,
  useUpdatePitPortUserRole,
  useVerifyPitPortUser,
} from "pages/PitPort/User/api";

const Label = styled.div`
  min-width: 120px;
  margin: 0 5px;
`;
const CancelWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;
const SaveWrap = styled(CancelWrap)`
  align-items: center;
  justify-content: flex-end;
`;
const Annotation = styled.span`
  font-size: ${font.size12};
  color: ${color.text};
  margin-right: 16px;
`;
const DeleteWrap = styled.div`
  display: flex;
  flex: auto;
  justify-content: flex-end;
`;

const ButtonsWrap = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  gap: 12px;
  right: 32px;
`;

const initialUser: PitPortUserDetail = {
  id: 0,
  email: "",
  status: 0,
  firstName: "",
  lastName: "",
  firstNameFurigana: "",
  lastNameFurigana: "",
  phoneNumber: "",
  createdAt: "",
  uid: "",
  needToChangePassword: 0,
  numberPlate: {
    number: "",
    region: "",
    hiragana: "",
    regionCode: "",
  },
  createdBy: "",
  updateAt: "",
  companyUser: {
    companyId: 0,
    userId: 0,
    role: 1,
    status: 0,
    updatedBy: "",
    updateAt: "",
    deletedBy: "",
    deletedAt: "",
    createdBy: "",
    createdAt: "",
  },
  company: {
    id: 0,
    name: "",
    address: "",
    phoneNumber: "",
    email: "",
    status: 0,
    type: 0,
    propertySpaceIds: [],
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updateAt: "",
    deletedBy: "",
    deletedAt: "",
  },
};

const responseErrorMessageCases = {
  need_at_least_one_admin_user: "1つの企業に対して、管理者が1人以上必要となります。",
  user_has_a_reserved_parking: "利用中の予約があるユーザーであるため、削除または利用停止できません。",
  default_message: "時間をおいてもう一度お試しください",
};

const removeOrBlockUserErrorMessageMethod = (responseErrorMessage: string): string => {
  switch (responseErrorMessage) {
    case responseErrorMessageCases.need_at_least_one_admin_user:
      return responseErrorMessageCases.need_at_least_one_admin_user.replace("。", "");
    case responseErrorMessageCases.user_has_a_reserved_parking:
      return responseErrorMessageCases.user_has_a_reserved_parking.replace("。", "");
    default:
      return responseErrorMessageCases.default_message;
  }
};

const EditUser = () => {
  const params = useParams();
  const { data: originUser, error } = useSwr<PitPortUserDetailResponse>(`/user/${params.id}`);
  const user = originUser?.data ?? initialUser;

  const [lastName, setLastName] = useState(user.lastName);
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastNameFurigana, setLastNameFurigana] = useState(user.lastNameFurigana);
  const [firstNameFurigana, setFirstNameFurigana] = useState(user.firstNameFurigana);
  const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber);
  const [email, setEmail] = useState(user.email);
  const [status, setStatus] = useState(user.status);
  const [role, setRole] = useState(user.companyUser.role);
  const [company, setCompany] = useState<UpdateCompany>({
    id: user.company.id,
    name: user.company.name,
    address: user.company.address,
    phoneNumber: user.company.phoneNumber,
    email: user.company.email,
    status: user.company.status,
    type: user.company.type,
    propertySpaceIds: [],
  });
  const [isModalShow, setIsModalShow] = useState(false);
  const [ModalConfig, setModalConfig] = useState<UserModalConfig>({
    onClickOutside: () => {
      return;
    },
    subtitle: "完了しました",
    main: {
      buttonType: "secondary",
      onClick: () => {
        return;
      },
      label: "閉じる",
    },
  });

  const hideModal = () => setIsModalShow(false);

  const showModal = (config: UserModalConfig) => {
    setModalConfig(config);
    setIsModalShow(true);
  };

  const navigate = useNavigate();
  const { update } = useUpdatePitPortUser();
  const { verify } = useVerifyPitPortUser();

  const { updateRole } = useUpdatePitPortUserRole();
  const { reactivate } = useReactivatePitPortUser();
  const { removeOrBlock } = useRemoveOrBlockPitPortUser();

  const changeLastName = (e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value);
  const changeFirstName = (e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value);
  const changeLastNameFurigana = (e: React.ChangeEvent<HTMLInputElement>) => setLastNameFurigana(e.target.value);
  const changeFirstNameFurigana = (e: React.ChangeEvent<HTMLInputElement>) => setFirstNameFurigana(e.target.value);
  const changePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => setPhoneNumber(e.target.value);
  const changeEmail = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);
  const changeRole = () => {
    setRole((prev): 1 | 2 => (3 - prev) as 1 | 2);
  };

  const isErrorLastName = !(lastName.length > 0);
  const isErrorFirstName = !(firstName.length > 0);
  const isErrorLastNameFurigana = !/^[ァ-ヶー]+$/.test(lastNameFurigana);
  const isErrorFirstNameFurigana = !/^[ァ-ヶー]+$/.test(firstNameFurigana);
  const isErrorPhoneNumber = phoneNumber.includes("-") || !isPhoneNumber(phoneNumber, "JP");
  const isErrorEmail = !isEmail(email);
  const isErrorCompanyName = !(company.name.length > 0);
  const isErrorCompanyAddress = !(company.address.length > 0);
  const isErrorCompanyPhoneNumber = !isPhoneNumber(company.phoneNumber, "JP") || phoneNumber.includes("-");
  const isErrorCompanyEmail = !isEmail(company.email);
  const isUserDeleted = status === 5;

  const canSubmit =
    !isErrorLastName &&
    !isErrorFirstName &&
    !isErrorLastNameFurigana &&
    !isErrorFirstNameFurigana &&
    !isErrorPhoneNumber &&
    !isErrorEmail &&
    !isErrorCompanyName &&
    !isErrorCompanyAddress &&
    !isErrorCompanyPhoneNumber &&
    !isErrorCompanyEmail &&
    !isUserDeleted;

  const updateUser = useCallback(
    async (userId: number) => {
      const updateUserResponseStatus = await update(userId, {
        lastName,
        firstName,
        lastNameFurigana,
        firstNameFurigana,
        phoneNumber,
        email,
      });
      const updateRoleResponseStatus = await updateRole(userId, user.company.id, role);
      updateUserResponseStatus === 200 && updateRoleResponseStatus === 200
        ? showModal({
            onClickOutside: hideModal,
            subtitle: "変更内容を保存しました",
            main: {
              buttonType: "secondary",
              onClick: () => navigate("/pit_port/user"),
              label: "閉じる",
            },
          })
        : updateRoleResponseStatus !== 500
        ? showModal({
            onClickOutside: hideModal,
            subtitle: "送信に失敗しました",
            main: {
              buttonType: "secondary",
              onClick: hideModal,
              label: "閉じる",
            },
            errorMessage: "1つの企業に対して、管理者が1人以上必要となります",
          })
        : showModal({
            onClickOutside: hideModal,
            subtitle: "送信に失敗しました",
            main: {
              buttonType: "secondary",
              onClick: hideModal,
              label: "閉じる",
            },
            errorMessage: "時間をおいてもう一度お試しください",
          });
      updateRoleResponseStatus !== 200 && changeRole(); // リクエストが失敗した場合変更前の権限に戻す
    },
    [
      lastName,
      firstName,
      lastNameFurigana,
      firstNameFurigana,
      phoneNumber,
      email,
      role,
      user.company.id,
      update,
      navigate,
      updateRole,
    ]
  );

  const verifyUser = useCallback(
    async (userId: number, verifyStatus: 1 | 3) => {
      const status = await verify(userId, verifyStatus);
      if (status === 200) {
        verifyStatus === 1
          ? showModal({
              onClickOutside: hideModal,
              subtitle: "承認しました",
              main: {
                buttonType: "secondary",
                onClick: () => navigate("/pit_port/user"),
                label: "閉じる",
              },
            })
          : showModal({
              onClickOutside: hideModal,
              subtitle: "非承認にしました",
              main: {
                buttonType: "secondary",
                onClick: () => navigate("/pit_port/user"),
                label: "閉じる",
              },
            });
      } else {
        showModal({
          onClickOutside: hideModal,
          subtitle: "送信に失敗しました",
          main: {
            buttonType: "secondary",
            onClick: hideModal,
            label: "閉じる",
          },
          errorMessage: "時間をおいてもう一度お試しください",
        });
      }
    },
    [verify, navigate]
  );

  const removeOrBlockUser = useCallback(
    async (userId: number, status: RemoveOrBlockPitPortUserStatus) => {
      const { responseStatus, message } = await removeOrBlock(userId, status);
      responseStatus === 200
        ? showModal({
            onClickOutside: hideModal,
            subtitle: status === 5 ? "削除しました" : "利用停止しました",
            main: {
              buttonType: "secondary",
              onClick: () => navigate("/pit_port/user"),
              label: "閉じる",
            },
          })
        : showModal({
            onClickOutside: hideModal,
            subtitle: "送信に失敗しました",
            main: {
              buttonType: "secondary",
              onClick: hideModal,
              label: "閉じる",
            },
            errorMessage: removeOrBlockUserErrorMessageMethod(message),
          });
    },
    [removeOrBlock, navigate]
  );

  const reactivateUser = useCallback(
    async (userId: number, companyId: number) => {
      const status = await reactivate(userId, companyId);
      if (status === 200) {
        showModal({
          onClickOutside: hideModal,
          subtitle: "ユーザーの有効化に成功しました",
          main: {
            buttonType: "secondary",
            onClick: () => navigate("/pit_port/user"),
            label: "閉じる",
          },
        });
      } else {
        showModal({
          onClickOutside: hideModal,
          subtitle: "ユーザーの有効化に失敗しました",
          main: {
            buttonType: "secondary",
            onClick: hideModal,
            label: "閉じる",
          },
          errorMessage: "時間をおいてもう一度お試しください",
        });
      }
    },
    [reactivate, navigate]
  );

  const showRemoveModal = () => {
    setModalConfig({
      onClickOutside: hideModal,
      subtitle: "削除しますか?",
      main: {
        buttonType: "primary",
        onClick: () => {
          removeOrBlockUser(user.id, 5);
        },
        label: "削除する",
      },
      sub: {
        buttonType: "secondary",
        onClick: hideModal,
        label: "閉じる",
      },
    });
    setIsModalShow(true);
  };

  const showStopModal = () => {
    setModalConfig({
      onClickOutside: hideModal,
      subtitle: "このユーザーを利用停止しますか?",
      main: {
        buttonType: "primary",
        onClick: () => {
          removeOrBlockUser(user.id, 4);
        },
        label: "利用停止する",
      },
      sub: {
        buttonType: "secondary",
        onClick: hideModal,
        label: "閉じる",
      },
    });
    setIsModalShow(true);
  };

  const showUnverifyModal = () => {
    setModalConfig({
      onClickOutside: hideModal,
      subtitle: "非承認にしますか?",
      main: {
        buttonType: "primary",
        onClick: () => {
          verifyUser(user.id, 3);
        },
        label: "非承認にする",
      },
      sub: {
        buttonType: "secondary",
        onClick: hideModal,
        label: "閉じる",
      },
    });
    setIsModalShow(true);
  };

  const showReactivateModal = () => {
    setModalConfig({
      onClickOutside: hideModal,
      subtitle: "利用再開しますか?",
      main: {
        buttonType: "primary",
        onClick: () => {
          reactivateUser(user.id, user.company.id);
        },
        label: "利用再開する",
      },
      sub: {
        buttonType: "secondary",
        onClick: hideModal,
        label: "閉じる",
      },
    });
    setIsModalShow(true);
  };

  const showVerifiedModal = () => {
    verifyUser(user.id, 1);
  };

  const setup = useCallback(async () => {
    if (error) {
      showModal({
        onClickOutside: hideModal,
        subtitle: "送信に失敗しました",
        main: {
          buttonType: "secondary",
          onClick: hideModal,
          label: "閉じる",
        },
        errorMessage: "時間をおいてもう一度お試しください",
      });
    }
    setLastName(user.lastName);
    setFirstName(user.firstName);
    setLastNameFurigana(user.lastNameFurigana);
    setFirstNameFurigana(user.firstNameFurigana);
    setPhoneNumber(user.phoneNumber);
    setEmail(user.email);
    setRole(user.companyUser.role);
    setCompany(user.company);
    setStatus(user.status);
  }, [
    error,
    user,
    setLastName,
    setFirstName,
    setLastNameFurigana,
    setFirstNameFurigana,
    setPhoneNumber,
    setEmail,
    setRole,
    setCompany,
    setStatus,
  ]);

  useEffect(() => {
    if (!user) return;
    setup();
  }, [user, setup]);

  return (
    <Board>
      <TitleArea>
        <Breadcrumb
          currentPageName="ユーザを編集"
          breadcrumbItems={[{ pageName: "ユーザ", onClick: () => navigate("/pit_port/user") }]}
        />
        <ButtonsWrap>
          {status === 1 && (
            <DeleteWrap>
              <Button label="このユーザを利用停止" onClick={showStopModal} type="danger" width="180px" />
            </DeleteWrap>
          )}
          {[1, 3, 4].includes(status) && (
            <DeleteWrap>
              <Button label="このユーザを削除" onClick={showRemoveModal} type="danger" />
            </DeleteWrap>
          )}
          {[0, 4, 5].includes(status) && (
            <SaveWrap>
              <Button label="このユーザを利用再開" type="primary" onClick={showReactivateModal} width="180px" />
            </SaveWrap>
          )}
        </ButtonsWrap>
      </TitleArea>
      {status === 2 && (
        <AlertArea>
          <FontAwesomeIcon icon={faCircleExclamation} />
          <span style={{ color: color.text.black, marginLeft: "8px" }}>
            内容を確認して、承認作業を行なってください。
          </span>
        </AlertArea>
      )}
      {status === 5 && (
        <AlertArea>
          <FontAwesomeIcon icon={faCircleExclamation} />
          <span style={{ color: color.text.black, marginLeft: "8px" }}>このユーザは削除済みです。</span>
        </AlertArea>
      )}
      <ContentArea>
        <FormRow>
          <Label>名前</Label>
          <div style={{ marginRight: "24px" }}>姓</div>
          <TextForm
            type="text"
            width="200px"
            value={lastName}
            placeholder="例）工藤"
            required
            onChange={changeLastName}
            isError={isErrorLastName}
            errorMessage="姓を入力してください"
          />
          <div style={{ marginLeft: "32px", marginRight: "24px" }}>名</div>
          <TextForm
            type="text"
            width="200px"
            value={firstName}
            placeholder="例）敬三"
            required
            onChange={changeFirstName}
            isError={isErrorFirstName}
            errorMessage="名を入力してください"
          />
        </FormRow>
        <FormRow>
          <Label>フリガナ</Label>
          <div style={{ marginRight: "8px" }}>セイ</div>
          <TextForm
            type="text"
            width="200px"
            value={lastNameFurigana}
            placeholder="例）クドウ"
            required
            onChange={changeLastNameFurigana}
            isError={isErrorLastNameFurigana}
            errorMessage="セイを入力してください"
          />
          <div style={{ marginLeft: "32px", marginRight: "8px" }}>メイ</div>
          <TextForm
            type="text"
            width="200px"
            value={firstNameFurigana}
            placeholder="例）ケイゾウ"
            required
            onChange={changeFirstNameFurigana}
            isError={isErrorFirstNameFurigana}
            errorMessage="メイを入力してください"
          />
        </FormRow>
        <FormRow>
          <Label>携帯番号</Label>
          <TextForm
            type="text"
            width="350px"
            value={phoneNumber}
            placeholder="例）09000000000"
            required
            onChange={changePhoneNumber}
            isError={isErrorPhoneNumber}
            errorMessage="電話番号をハイフンなしで入力してください"
          />
        </FormRow>
        <FormRow>
          <Label>メールアドレス</Label>
          <TextForm
            type="text"
            width="350px"
            value={email}
            placeholder="例）info@landit.co.jp"
            required
            onChange={changeEmail}
            isError={isErrorEmail}
            errorMessage="メールアドレスを入力してください"
          />
        </FormRow>
        {status === 2 && (
          <FormRow>
            <Label>状態</Label>
            <Label>承認待ち</Label>
          </FormRow>
        )}
        <FormRow>
          <div style={{ marginTop: "40px" }}></div>
          <Label>権限</Label>
          <CheckBox label="管理者にする" isChecked={role === 2} setIsChecked={changeRole} id="admin-check" />
        </FormRow>
      </ContentArea>
      <ConfirmCompany company={company} isVisibleEditButton={status !== 5}></ConfirmCompany>
      <FooterArea>
        <CancelWrap>
          <Button type="secondary" onClick={() => navigate("/pit_port/user")} label="キャンセル" width="160px" />
        </CancelWrap>
        {status === 2 && (
          <SaveWrap>
            <Button type="danger" onClick={showUnverifyModal} label="このユーザの利用を非承認" width="208px" />
            <div style={{ marginLeft: "16px" }}></div>
            <Button type="primary" onClick={showVerifiedModal} label="このユーザの利用を承認" width="208px" />
          </SaveWrap>
        )}
        {[1, 3, 4].includes(status) && (
          <SaveWrap>
            <Annotation>追加するまで変更内容は反映されません</Annotation>
            <Button
              type="primary"
              onClick={() => updateUser(user.id)}
              label="変更内容を保存"
              disabled={!canSubmit}
              width="160px"
            />
          </SaveWrap>
        )}
        {status === 5 && <></>}
      </FooterArea>
      <UserModal isVisible={isModalShow} config={ModalConfig}></UserModal>
    </Board>
  );
};

export default EditUser;
