import { SYNC_PORT_IMPORT_CSV_ERROR } from "pages/SyncPort/constants/csvErrors";

export type SyncPortSupportCarModel =
  | "K_CAR"
  | "COMPACT_CAR"
  | "MID_SIZE_CAR"
  | "ONE_BOX_CAR"
  | "FULL_SIZE_CAR"
  | "TRUCK"
  | "MOTORCYCLE";

const SUPPORT_CAR_MODEL: SupportCarModelObject = {
  truck: "トラック・大型重量車両",
  fullSizeCar: "大型車・SUV",
  oneBoxCar: "ワンボックス",
  midSizeCar: "中型車",
  compactCar: "コンパクトカー",
  kCar: "軽自動車",
  motorcycle: "バイク",
} as const;

export const SUPPORT_CAR_MODEL_MAP: { [key: string]: SyncPortSupportCarModel } = {
  軽自動車: "K_CAR",
  コンパクトカー: "COMPACT_CAR",
  中型車: "MID_SIZE_CAR",
  ワンボックス: "ONE_BOX_CAR",
  "大型車・SUV": "FULL_SIZE_CAR",
  "トラック・大型重量車両": "TRUCK",
  バイク: "MOTORCYCLE",
};

export const convertSupportCarModelEnToJa = (supportCarModel: SupportCarModel): SupportCarSize => {
  return SUPPORT_CAR_MODEL[supportCarModel];
};

export const findLargestSupportCarModelFromObject = (
  supportCarModelObject: SupportCarModelObjectWithBoolean
): SupportCarSize => {
  if (supportCarModelObject.truck) return SUPPORT_CAR_MODEL.truck;
  if (supportCarModelObject.fullSizeCar) return SUPPORT_CAR_MODEL.fullSizeCar;
  if (supportCarModelObject.oneBoxCar) return SUPPORT_CAR_MODEL.oneBoxCar;
  if (supportCarModelObject.midSizeCar) return SUPPORT_CAR_MODEL.midSizeCar;
  if (supportCarModelObject.compactCar) return SUPPORT_CAR_MODEL.compactCar;
  if (supportCarModelObject.kCar) return SUPPORT_CAR_MODEL.kCar;
  if (supportCarModelObject.motorcycle) return SUPPORT_CAR_MODEL.motorcycle;
  return SUPPORT_CAR_MODEL.motorcycle;
};

export const findLargestSupportCarModelFromObjectFromStringifiedJson = (
  supportCarModelObjectString: string
): SupportCarSize => {
  const obj: SupportCarModelObjectWithBoolean = JSON.parse(supportCarModelObjectString);
  return findLargestSupportCarModelFromObject(obj);
};

/**
 * 対応車両を取得
 * @example
 * getSupportedCarModel("軽自動車")
 * => 'K_CAR'
 * getSupportedCarModel("コンパクトカー")
 * => 'COMPACT_CAR'
 * getSupportedCarModel("") // 空文字の場合、'K_CAR'
 * => 'K_CAR'
 * getSupportedCarModel("ｱｽﾌｧﾙﾄ") // 不正値の場合、null
 * => null
 */
export const getSupportedCarModel = (supportCarModel: string): SyncPortSupportCarModel => {
  if (!supportCarModel) {
    throw new Error(SYNC_PORT_IMPORT_CSV_ERROR.INVALID_SUPPORT_CAR_MODEL.errorCode);
  }
  return SUPPORT_CAR_MODEL_MAP[supportCarModel] ?? null;
};
