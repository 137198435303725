import { useParams } from "react-router-dom";

import { useSwr } from "hooks/syncPort/useSwr";
import { buildSortKeyPatch } from "pages/SyncPort/Company/features/utils/buildSortkey";
import { formatQueryParams } from "utils/stringUtils";

// 現状O (オー) が 0 (ゼロ) になっている
export type SyncPortSpaceGroupSortKey =
  | "monthlyRent"
  | "nmber0fFree"
  | "nmber0fFreeSchedule"
  | "nmber0fSpaces"
  | "nmber0fOccupied"
  | "isPublic"
  | "isAvailabilityPublic"
  | null;

export const useFindSpaceGroups = ({
  after,
  before,
  first,
  last,
  sortOption,
}: {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
  sortOption?: SortOption<SyncPortSpaceGroupSortKey>;
}) => {
  const { propertyId } = useParams();

  const { data, error } = useSwr<SyncPortSpaceGroupsResponse>(
    `/property/${propertyId}/space-groups?${formatQueryParams({
      after,
      before,
      first,
      last,
      // sortKeyに現状数字が含まれていて、buildSortKeyがうまくいかないのでpatchしたbuildSortKeyPatchを適用
      sortKey: buildSortKeyPatch<SyncPortSpaceGroupSortKey>(sortOption),
    })}`
  );
  return {
    data: data?.data,
    pageInfo: {
      hasNextPage: data?.pageInfo.hasNextPage ?? false,
      hasPreviousPage: data?.pageInfo.hasPreviousPage ?? false,
      startCursor: data?.pageInfo.startCursor,
      endCursor: data?.pageInfo.endCursor,
      totalPages: data?.pageInfo.totalPages,
    },
    error,
  };
};
