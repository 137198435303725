import { useNavigate } from "react-router-dom";

import { FieldName, FieldValueWithBorder, Heading, Row, Table } from "components/LayoutParts";
import Loading from "components/Loading";
import { timestampToDate } from "utils/dateTimeUtils";

const placeTableColumn = [
  {
    label: "現場コード",
    flex: 1,
  },
  {
    label: "顧客ID",
    flex: 1,
  },
  {
    label: "顧客名",
    flex: 1,
  },
  {
    label: "担当者ID",
    flex: 1,
  },
  {
    label: "作成日",
    flex: 1,
  },
] as const;

const DataTable = ({ placeList }: { placeList?: DandoriWorksPlaceCode[] }) => {
  const navigate = useNavigate();
  const onClickRow = (id: string) => navigate(`/at_port/dandori_works/place/${id}/edit`);

  return (
    <>
      <Table>
        <Heading>
          {placeTableColumn.map((column) => (
            <FieldName
              key={column.label}
              flex={column.flex}
              lastColumn={column.label === placeTableColumn[placeTableColumn.length - 1].label}
            >
              {column.label}
            </FieldName>
          ))}
        </Heading>
        {placeList == null ? (
          <Loading />
        ) : placeList.length > 0 ? (
          placeList.map((place) => (
            <Row key={place.id} onClick={() => onClickRow(place.id ?? "")}>
              <FieldValueWithBorder flex={placeTableColumn[0].flex}>{place.placeCode}</FieldValueWithBorder>
              <FieldValueWithBorder flex={placeTableColumn[1].flex}>{place.accountId}</FieldValueWithBorder>
              <FieldValueWithBorder flex={placeTableColumn[2].flex}>{place.companyName}</FieldValueWithBorder>
              <FieldValueWithBorder flex={placeTableColumn[3].flex}>{place.contactId}</FieldValueWithBorder>
              <FieldValueWithBorder flex={placeTableColumn[4].flex}>
                {place.createdAt && timestampToDate(place.createdAt._seconds)}
              </FieldValueWithBorder>
            </Row>
          ))
        ) : (
          <p>該当する現場が見つかりませんでした</p>
        )}
      </Table>
    </>
  );
};

export default DataTable;
