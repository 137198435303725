import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { sortDirection } from "constants/direction";
import {
  SYNC_PORT_SPACE_SORT_KEY,
  SyncPortCompanyPropertySpaceSortKey,
  useFindSpaces,
} from "pages/SyncPort/Company/Property/Space/features/api";
import DataTable from "pages/SyncPort/Company/Property/Space/features/DataTable";
import { jumpUpToElement } from "utils/animationUtils";

const Space = () => {
  const { propertyId } = useParams();
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [pagination, setPagination] = useState<GraphqlPageOption>({});
  const [sortOption, setSortOption] = useState<SortOption<SyncPortCompanyPropertySpaceSortKey>>({
    sortByColumn: null,
    direction: sortDirection.desc,
  });

  const sortKey = useMemo(() => {
    const { sortByColumn: column, direction } = sortOption;

    if (!column) return undefined;
    if (!direction) return undefined;

    const sortKeyMap = {
      ASC: {
        spaceAvailableStatus: SYNC_PORT_SPACE_SORT_KEY.SPACE_AVAILABLE_STATUS_ASC,
        name: SYNC_PORT_SPACE_SORT_KEY.NAME_ASC,
        status: SYNC_PORT_SPACE_SORT_KEY.STATUS_ASC,
      },
      DESC: {
        spaceAvailableStatus: SYNC_PORT_SPACE_SORT_KEY.SPACE_AVAILABLE_STATUS_DESC,
        name: SYNC_PORT_SPACE_SORT_KEY.NAME_DESC,
        status: SYNC_PORT_SPACE_SORT_KEY.STATUS_DESC,
      },
    };

    return sortKeyMap[direction][column];
  }, [sortOption]);

  const { data, pageInfo } = useFindSpaces({
    propertyId,
    after: pagination?.after,
    before: pagination?.before,
    first: pagination?.first,
    last: pagination?.last,
    sortKey,
  });

  useEffect(() => {
    setTotalPages(pageInfo?.totalPages ?? 1);
    return () => {
      jumpUpToElement("root");
    };
  }, [pageInfo?.endCursor, pageInfo?.startCursor, pageInfo?.totalPages]);

  return (
    <>
      <DataTable
        spaceList={data}
        totalPages={totalPages}
        pageIndex={currentPageIndex}
        setPageIndex={setCurrentPageIndex}
        pageInfo={pageInfo}
        setPagination={setPagination}
        setSortOption={setSortOption}
        sortOption={sortOption}
      />
    </>
  );
};

export default Space;
