import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import styled from "styled-components";

import { Wrapper, Input, ErrorArea, ErrorMessage } from "components/TextForm";
import color from "constants/color";
import font from "constants/font";

// パーツ定義
const SelectInput = styled(Input)`
  cursor: pointer;
`;
const OptionWrap = styled.div<{ width: string }>`
  width: calc(${(props) => props.width} - 2px);
  max-height: 400px;
  margin-top: 2px;
  position: absolute;
  background-color: ${color.text.black};
  border: solid 1px ${color.form.border};
  border-radius: 4px;
  z-index: 2;
  font-size: ${font.size14};
  overflow: scroll;
`;
const Option = styled.li`
  color: ${color.white};
  padding: 4px 16px;
  display: block;
  list-style: none;
  cursor: pointer;
  &:hover {
    background-color: ${color.text.linkLight};
    color: ${color.text.black};
  }
`;
const Select = ({
  choices,
  width = "100px",
  value,
  required = false,
  onSelect = () => null,
  onFocus = () => null,
  onBlur = () => null,
  isError = false,
  errorMessage = "",
}: {
  choices: string[];
  width?: string;
  value?: string;
  required?: boolean;
  onSelect?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  isError?: boolean;
  errorMessage?: string;
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const [isErrorDetectable, setIsErrorDetectable] = useState(false);

  // フォームがフォーカスされたらエラーを検出可能にする
  const onFocusForm = () => {
    setIsErrorDetectable(true);
    setIsOpened((prev) => !prev);
    onFocus();
  };

  const onClickChoice = (value: string) => {
    onSelect(value);
    setIsOpened(false);
  };

  return (
    <Wrapper onBlur={() => setIsOpened(false)}>
      <SelectInput
        type="text"
        width={width}
        value={value}
        onFocus={onFocusForm}
        onBlur={onBlur}
        required={required}
        readOnly
        isErrorDisplay={isError && isErrorDetectable}
      />
      <FontAwesomeIcon
        icon={faAngleDown}
        style={{
          position: "absolute" as const,
          top: "15px",
          right: "15px",
          cursor: "pointer",
        }}
        onClick={onFocusForm}
      />
      {isOpened && (
        <OptionWrap width={width}>
          {choices.map((choice) => (
            <Option onMouseDown={() => onClickChoice(choice)} key={choice}>
              {choice}
            </Option>
          ))}
        </OptionWrap>
      )}
      {errorMessage && (
        <ErrorArea isErrorDisplay={isError && isErrorDetectable}>
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </ErrorArea>
      )}
    </Wrapper>
  );
};

export default Select;
