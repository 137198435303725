import styled from "styled-components";

import { useMetabase } from "pages/PitPort/metabaseApi";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
`;

const Usage = () => {
  const { metabaseUrl } = useMetabase();
  return (
    <Wrapper>
      <Iframe title="PIT PORT dashboard" src={metabaseUrl} frameBorder={0} />
    </Wrapper>
  );
};

export default Usage;
