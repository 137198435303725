import { UserModalConfig } from "components/UserModal";

export const ALREADY_EXIST = (hideModal: () => void): UserModalConfig => ({
  onClickOutside: hideModal,
  subtitle: "送信に失敗しました",
  errorMessage: "既に同じ会社が存在します",
  main: {
    buttonType: "secondary",
    onClick: hideModal,
    label: "閉じる",
  },
});

export const ERROR = (hideModal: () => void): UserModalConfig => ({
  onClickOutside: hideModal,
  subtitle: "送信に失敗しました",
  errorMessage: "時間をおいてもう一度お試しください",
  main: {
    buttonType: "secondary",
    onClick: hideModal,
    label: "閉じる",
  },
});

export const SUCCESS = (onClick: () => void): UserModalConfig => ({
  onClickOutside: onClick,
  subtitle: "送信に成功しました",
  main: {
    buttonType: "primary",
    onClick,
    label: "閉じる",
  },
});
