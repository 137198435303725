import { useCallback } from "react";

import { useFirebaseContext } from "contexts/FirebaseContext";
import { usePitPortClient } from "hooks/api/usePitPortClient";

export const useCreateControlBox = () => {
  const { auth } = useFirebaseContext();
  const { client } = usePitPortClient();

  const create = useCallback(
    async (id: string) => {
      const token = await auth?.currentUser?.getIdToken();
      if (!token) return 401;
      const { status } = await client({
        method: "post",
        url: `/control-box`,
        data: {
          id,
        },
      });
      return status;
    },
    [client, auth?.currentUser]
  );
  return { create };
};

export const useRemoveControlBox = () => {
  const { auth } = useFirebaseContext();
  const { client } = usePitPortClient();

  const remove = useCallback(
    async (id: string) => {
      const token = await auth?.currentUser?.getIdToken();
      if (!token) return 400;
      const { status } = await client({
        method: "delete",
        url: `/control-box/${id}`,
      });
      return status;
    },
    [client, auth?.currentUser]
  );
  return { remove };
};

export const useConfirmControlBoxVersion = () => {
  const { auth } = useFirebaseContext();
  const { client } = usePitPortClient();

  const confirm = useCallback(
    async (id: string, propertyId: number | undefined, propertySpaceId: number | undefined) => {
      const token = await auth?.currentUser?.getIdToken();
      if (!token) return 400;
      const { status } = await client({
        method: "get",
        url: `/control-box/version/${id}?propertyId=${propertyId}&propertySpaceId=${propertySpaceId}`,
      });
      return status;
    },
    [client, auth?.currentUser]
  );
  return { confirm };
};

export const useUpdateVersionMain = () => {
  const { auth } = useFirebaseContext();
  const { client } = usePitPortClient();

  const updateMain = useCallback(
    async (id: string, propertyId: number | undefined, propertySpaceId: number | undefined) => {
      const token = await auth?.currentUser?.getIdToken();
      if (!token) return 400;
      const { status } = await client({
        method: "put",
        url: `/control-box/version/update-main/${id}`,
        data: { propertyId, propertySpaceId },
      });
      return status;
    },
    [client, auth?.currentUser]
  );
  return { updateMain };
};

export const useUpdateVersionStand = () => {
  const { auth } = useFirebaseContext();
  const { client } = usePitPortClient();

  const updateStand = useCallback(
    async (id: string, propertyId: number | undefined, propertySpaceId: number | undefined) => {
      const token = await auth?.currentUser?.getIdToken();
      if (!token) return 400;
      const { status } = await client({
        method: "put",
        url: `/control-box/version/update-stand/${id}`,
        data: { propertyId, propertySpaceId },
      });
      return status;
    },
    [client, auth?.currentUser]
  );
  return { updateStand };
};

type UpdateStandModeResponse = {
  status: number;
};

export const useUpdateAutoStandMode = () => {
  const { client } = usePitPortClient();

  const updateStandMode = useCallback(
    async (id: string, mode: PitPortStandMode) => {
      const { status } = await client<UpdateStandModeResponse>({
        method: "put",
        url: `/control-box/stand-mode/${id}`,
        data: { mode },
      });
      return status;
    },
    [client]
  );
  return { updateStandMode };
};
