import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import Button from "components/Button";
import { PageTop } from "components/LayoutParts";
import { sortDirection } from "constants/direction";
import font from "constants/font";
import { useDebounce } from "hooks/useDebounce";
import { SyncPortCompanyPropertySortKey, useFindProperties } from "pages/SyncPort/Company/Property/features/api";
import DataTable from "pages/SyncPort/Company/Property/features/DataTable";
import ActionArea from "pages/SyncPort/Company/Property/features/DataTable/ActionArea";
import { jumpUpToElement } from "utils/animationUtils";

const ButtonWrap = styled.div`
  margin-left: auto;
  position: relative;
  bottom: 5px;
`;

const PropertyTitle = styled.div`
  font-size: ${font.size24};
`;

const PropertyTitleWrap = styled.div`
  font-size: ${font.size24};
  display: flex;
  justify-content: space-between;
`;

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Property = () => {
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [pagination, setPagination] = useState<GraphqlPageOption>({});
  const [searchKeyword, setSearchKeyword] = useState("");
  const [sortOption, setSortOption] = useState<SortOption<SyncPortCompanyPropertySortKey>>({
    sortByColumn: null,
    direction: sortDirection.desc,
  });
  const { debouncedValue: debouncedSearchKeyword } = useDebounce(searchKeyword, 500);

  const router = useNavigate();
  const params = useParams();

  const { data, pageInfo } = useFindProperties({
    searchKeyword: debouncedSearchKeyword,
    after: pagination?.after,
    before: pagination?.before,
    first: pagination?.first,
    last: pagination?.last,
    sortOption,
  });

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPageIndex(1);
    setSearchKeyword(e.target.value);
  };

  const onClickPropertyAdd = () => {
    router(`/sync_port/company/${params.id}/property/create`);
  };

  useEffect(() => {
    setTotalPages(pageInfo?.totalPages ?? 1);
    return () => {
      jumpUpToElement("root");
    };
  }, [pageInfo?.endCursor, pageInfo?.startCursor, pageInfo?.totalPages]);

  return (
    <>
      <PageTop />
      <PropertyTitleWrap>
        <PropertyTitle>物件情報</PropertyTitle>
      </PropertyTitleWrap>
      <Wrap>
        <ActionArea searchKeyword={searchKeyword} onSearchChange={onSearchChange} />
        <ButtonWrap>
          <Button label="CSVで物件登録" onClick={onClickPropertyAdd} type="secondary" />
        </ButtonWrap>
      </Wrap>
      <DataTable
        propertyList={data}
        totalPages={totalPages}
        pageIndex={currentPageIndex}
        setPageIndex={setCurrentPageIndex}
        pageInfo={pageInfo}
        setPagination={setPagination}
        setSortOption={setSortOption}
        sortOption={sortOption}
      />
    </>
  );
};

export default Property;
