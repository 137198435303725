import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

import Button from "components/Button";
import Modal from "components/Modal";
import color from "constants/color";
import font from "constants/font";

const Body = styled.div`
  width: 400px;
  height: 250px;
  background-color: ${color.white};
`;
const SubtitleWrap = styled.div`
  position: relative;
  top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ErrorWrap = styled(SubtitleWrap)`
  background-color: ${color.attention};
  width: 100%;
  height: 98px;
  top: 0px;
  align-items: center;
`;
const Subtitle = styled.span`
  position: relative;
  font-size: ${font.size20};
  color: ${color.text.black};
`;
const ExclamationMark = styled(FontAwesomeIcon)`
  font-size: ${font.size20};
  color: ${color.white};
  padding-right: 8px;
`;
const MessageArea = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const MessageText = styled.span`
  position: relative;
  font-size: ${font.size16};
  color: ${color.text.black};
  margin: 24px;
`;

const ButtonWrap = styled.div<{ bottomSize?: string }>`
  position: absolute;
  display: flex;
  bottom: ${({ bottomSize }) => (bottomSize ? bottomSize : "24px")};
`;

export type UserModalConfig = Readonly<{
  onClickOutside: () => Promise<void> | void;
  subtitle: string;
  main: {
    buttonType: "primary" | "secondary" | "danger";
    onClick: () => Promise<void> | void;
    label: string;
  };
  sub?: {
    buttonType: "primary" | "secondary" | "danger";
    onClick: () => Promise<void> | void;
    label: string;
  };
  errorMessage?: string | JSX.Element;
}>;

const UserModal = ({ isVisible, config }: { isVisible: boolean; config: UserModalConfig }) => {
  return (
    <Modal isVisible={isVisible} onClickOutside={config.onClickOutside}>
      <Body>
        {config.errorMessage !== undefined ? (
          <MessageArea style={{ flexDirection: "column" }}>
            <ErrorWrap>
              <ExclamationMark icon={faCircleExclamation} />
              <Subtitle style={{ color: color.white }}>{config.subtitle}</Subtitle>
            </ErrorWrap>
            <MessageText>{config.errorMessage}</MessageText>
            {config.sub !== undefined ? (
              <ButtonWrap>
                <Button
                  type={config.sub.buttonType}
                  onClick={config.sub.onClick}
                  label={config.sub.label}
                  disabled={false}
                />
                <div style={{ marginLeft: "16px" }}></div>
                <Button
                  type={config.main.buttonType}
                  onClick={config.main.onClick}
                  label={config.main.label}
                  disabled={false}
                />
              </ButtonWrap>
            ) : (
              <ButtonWrap>
                <Button
                  type={config.main.buttonType}
                  onClick={config.main.onClick}
                  label={config.main.label}
                  disabled={false}
                />
              </ButtonWrap>
            )}
          </MessageArea>
        ) : (
          <MessageArea>
            <SubtitleWrap>
              {config.sub !== undefined && (
                <ExclamationMark icon={faCircleExclamation} style={{ color: color.warning }}></ExclamationMark>
              )}
              <Subtitle>{config.subtitle}</Subtitle>
            </SubtitleWrap>
            {config.sub !== undefined ? (
              <ButtonWrap bottomSize="40px">
                <Button
                  type={config.sub.buttonType}
                  onClick={config.sub.onClick}
                  label={config.sub.label}
                  disabled={false}
                />
                <div style={{ marginLeft: "16px" }}></div>
                <Button
                  type={config.main.buttonType}
                  onClick={config.main.onClick}
                  label={config.main.label}
                  disabled={false}
                />
              </ButtonWrap>
            ) : (
              <ButtonWrap bottomSize="40px">
                <Button
                  type={config.main.buttonType}
                  onClick={config.main.onClick}
                  label={config.main.label}
                  disabled={false}
                />
              </ButtonWrap>
            )}
          </MessageArea>
        )}
      </Body>
    </Modal>
  );
};

export default UserModal;
