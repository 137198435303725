import PlaceCode from "pages/AtPort/DandoriWorks";
import CreatePlaceCode from "pages/AtPort/DandoriWorks/CreatePlaceCode";
import EditPlaceCode from "pages/AtPort/DandoriWorks/EditPlaceCode";
import User from "pages/AtPort/User";
import EditUser from "pages/AtPort/User/EditUser";

const AtPort = {
  User,
  EditUser,
  PlaceCode,
  CreatePlaceCode,
  EditPlaceCode,
};

export default AtPort;
