import { useCallback } from "react";

import { useSwr } from "hooks/syncPort/useSwr";
import { useSyncPortClient } from "hooks/syncPort/useSyncPortClient";
import { formatQueryParams } from "utils/stringUtils";

export const useCreateUser = () => {
  const client = useSyncPortClient();

  const createUser = useCallback(
    async ({ lastName, firstName, lastNameKana, firstNameKana, email, companyId }: CreateUserParameter) => {
      const response = await client
        .client<{
          id: string;
          createdAt: string;
        }>({
          method: "POST",
          url: "/user",
          data: {
            lastName,
            firstName,
            lastNameKana,
            firstNameKana,
            email,
            propertyCompanyId: companyId,
          },
        })
        .catch((e) => {
          throw e;
        });
      return {
        data: response.data,
        status: response.status,
      };
    },
    [client]
  );

  return {
    createUser,
  };
};

export const useFindUserById = ({ userId }: { userId?: string }) => {
  const { data, error } = useSwr<SyncPortUser>(userId != null ? `/user/${userId}` : null);

  return {
    data,
    error,
  };
};

export const useFindAllUser = ({
  searchKeyword,
  after,
  before,
  first,
  last,
}: {
  searchKeyword?: string;
  after?: string;
  before?: string;
  first?: number;
  last?: number;
}) => {
  const { data, error } = useSwr<SyncPortUsersResponse>(
    `/user?${formatQueryParams({ keyword: searchKeyword, after, before, first, last })}`
  );

  return {
    data: data?.data,
    pageInfo: data?.pageInfo,
    error,
  };
};

export const useUpdateUser = () => {
  const client = useSyncPortClient();

  const updateUser = useCallback(
    async ({ id, lastName, firstName, lastNameKana, firstNameKana, email, companyId }: UpdateUserParameter) => {
      const response = await client
        .client<{ id: string; updatedAt: string }>({
          method: "PUT",
          url: `/user/${id}`,
          data: {
            lastName,
            firstName,
            lastNameKana,
            firstNameKana,
            email,
            propertyCompanyId: companyId,
          },
        })
        .catch((e) => {
          throw e;
        });
      return {
        data: response.data,
        status: response.status,
      };
    },
    [client]
  );

  return {
    updateUser,
  };
};

export const useDeleteUser = () => {
  const client = useSyncPortClient();

  const deleteUser = useCallback(
    async ({ userId }: { userId: string }) => {
      const response = await client
        .client<{ id: string; deletedAt: string }>({
          method: "DELETE",
          url: `/user/${userId}`,
        })
        .catch((e) => {
          throw e;
        });
      return {
        data: response.data,
        status: response.status,
      };
    },
    [client]
  );

  return {
    deleteUser,
  };
};
