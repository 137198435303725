import { useSwr } from "hooks/syncPort/useSwr";
import { SyncPortParkingType } from "pages/SyncPort/Company/Property/utils/convertParkingType";
import { formatQueryParams } from "utils/stringUtils";

type SupportCarModel = "truck" | "fullSizeCar" | "oneBoxCar" | "midSizeCar" | "compactCar" | "kCar" | "motorcycle";

export type SupportCarModelObject = {
  [K in SupportCarModel]: boolean;
};

export const SPACE_EMPTY_USAGE = [
  {
    contractor: { name: "", phoneNumber: "" },
    useStartDate: "",
    terminateDate: "",
  },
];

export type SyncPortCompanyPropertySpaceSortKey = "name" | "spaceAvailableStatus" | "status" | null;

export type SyncPortSpaceGroup = {
  id: string;
  remarks: string;
  isPublic: number;
  isAvailabilityPublic: number;
  monthlyRent: {
    value: number;
    isTaxExempt: boolean;
  };
  supportCarModel: SupportCarModelObject;
  feature: {
    type: SyncPortParkingType;
  };
  availability: {
    numberOfFree: number;
    numberOfFreeSchedule: number;
    numberOfFreeOccupied: number;
  };
};

export type PageInfo = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor?: string;
  endCursor?: string;
  totalPages?: number;
};

export type SpaceUsageAvailability = "EMPTY" | "FULL" | "AVAILABLE_SCHEDULE";

export type SyncPortSpace = {
  id: string;
  name: string;
  spaceAvailableStatus: SpaceUsageAvailability;
  status: number;
  usages: {
    useStartDate: string;
    terminateDate: string;
    contractor: {
      name: string;
      phoneNumber: string;
    };
  }[];
  spaceGroup: {
    id: string;
    supportCarModel: SupportCarModelObjectWithBoolean;
    monthlyRent: {
      value: number;
      isTaxExempt: boolean;
    };
  };
};

export type FindAllSyncPortSpacesByPropertyIdResponse = {
  data?: SyncPortSpace[];
  pageInfo: GraphqlPageInfo;
};

export const SYNC_PORT_SPACE_SORT_KEY = {
  NAME_ASC: "NAME_ASC",
  NAME_DESC: "NAME_DESC",
  SPACE_AVAILABLE_STATUS_ASC: "SPACE_AVAILABLE_STATUS_ASC",
  SPACE_AVAILABLE_STATUS_DESC: "SPACE_AVAILABLE_STATUS_DESC",
  STATUS_ASC: "STATUS_ASC",
  STATUS_DESC: "STATUS_DESC",
} as const;

export type SyncPortSpaceOrder = keyof typeof SYNC_PORT_SPACE_SORT_KEY;
export type SyncPortSpaceOrderValue = typeof SYNC_PORT_SPACE_SORT_KEY[SyncPortSpaceOrder];

export const useFindSpaces = ({
  propertyId,
  after,
  before,
  first,
  last,
  sortKey,
}: {
  propertyId?: string;
  after?: string;
  before?: string;
  first?: number;
  last?: number;
  sortKey?: SyncPortSpaceOrderValue;
}) => {
  const { data } = useSwr<FindAllSyncPortSpacesByPropertyIdResponse>(
    propertyId == null
      ? null
      : `/property/${propertyId}/spaces?${formatQueryParams({
          after,
          before,
          first,
          last,
          sortKey,
        })}`
  );

  return {
    data: data?.data,
    pageInfo: {
      hasNextPage: data?.pageInfo.hasNextPage ?? false,
      hasPreviousPage: data?.pageInfo.hasPreviousPage ?? false,
      startCursor: data?.pageInfo.startCursor,
      endCursor: data?.pageInfo.endCursor,
      totalPages: data?.pageInfo.totalPages,
    },
  };
};
