import { useCallback } from "react";

import { usePitPortClient } from "hooks/api/usePitPortClient";

export const useUpdateCompany = () => {
  const { client } = usePitPortClient();

  const update = useCallback(
    async (data: UpdateCompany) => {
      const { status } = await client({
        method: "put",
        url: `/company/${data.id}`,
        data,
      });
      return status;
    },
    [client]
  );
  return { update };
};

export const useRemoveCompany = () => {
  const { client } = usePitPortClient();

  const remove = useCallback(
    async (companyId: number) => {
      const { status } = await client({
        method: "delete",
        url: `/company/${companyId}`,
      });
      return status;
    },
    [client]
  );
  return { remove };
};

export const useRemoveCompanyPropertySpace = () => {
  const { client } = usePitPortClient();

  const companyPropertySpaceRemove = useCallback(
    async (companyId: number, companyPropertySpaceIds: number[]) => {
      const { status } = await client({
        method: "delete",
        url: `/company-property-space`,
        data: companyPropertySpaceIds.map((propertySpaceId) => ({
          companyId,
          propertySpaceId,
        })),
      });
      return status;
    },
    [client]
  );
  return { companyPropertySpaceRemove };
};

export const useImportPitPortCompanyUsers = () => {
  const { client } = usePitPortClient();

  const importCompanyUsers = useCallback(
    async (companyId: number, users: ImportPitPortCompanyUser[]) => {
      const { status } = await client({
        method: "post",
        url: `/user/import/${companyId}`,
        data: {
          companyId: companyId,
          users: users,
        },
      });
      return status;
    },
    [client]
  );
  return { importCompanyUsers };
};
