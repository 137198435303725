import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import color from "constants/color";
import font from "constants/font";

const NavLink = styled.div<{ isCurrent?: boolean }>`
  justify-content: flex-start;
  height: 18px;
  width: 202px;
  padding: 10px 0 10px 22px;
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.isCurrent ? color.table.headerBackground : color.white)};
  &:hover {
    cursor: pointer;
    transition: all 0.3s ease 0s;
    background-color: ${color.table.headerBackground};
  }
`;
const NavText = styled.span`
  font-size: ${font.size14};
  margin-left: 22px;
`;

const NavItem = ({
  label,
  onClick,
  icon,
  isCurrent = false,
}: {
  label: string;
  onClick: () => void;
  icon: IconProp;
  isCurrent?: boolean;
}) => {
  return (
    <NavLink isCurrent={isCurrent} onClick={onClick}>
      <FontAwesomeIcon icon={icon} className="fa-fw" />
      <NavText>{label}</NavText>
    </NavLink>
  );
};

export default NavItem;
