export const AIMO_PARKING_SYSTEM_NOTIFICATION_DESTINATION = {
  AIMO_PARKING: 0,
  PARK_FLOW: 1,
} as const;

export const AIMO_PARKING_SYSTEM_NOTIFICATION_DESTINATION_CHOICES = [
  AIMO_PARKING_SYSTEM_NOTIFICATION_DESTINATION.AIMO_PARKING,
  AIMO_PARKING_SYSTEM_NOTIFICATION_DESTINATION.PARK_FLOW,
] as const;

export type AimoParkingSystemNotificationDestination =
  typeof AIMO_PARKING_SYSTEM_NOTIFICATION_DESTINATION[keyof typeof AIMO_PARKING_SYSTEM_NOTIFICATION_DESTINATION];

export const AIMO_PARKING_SYSTEM_NOTIFICATION_DESTINATION_LABEL_CHOICES: Choice[] = [
  {
    label: "AIMO Parking",
    value: AIMO_PARKING_SYSTEM_NOTIFICATION_DESTINATION.AIMO_PARKING.toString() as "0",
  },
  {
    label: "PARK FLOW",
    value: AIMO_PARKING_SYSTEM_NOTIFICATION_DESTINATION.PARK_FLOW.toString() as "1",
  },
];
