// startからendまでの数字を返すGenerator関数
// 使い方: [...range(2, 5)] -> [2, 3, 4, 5]
export function* range(start: number, end: number): Generator<number> {
  for (let i = start; i <= end; i++) {
    yield i;
  }
}

/**
 * 配列のディープ比較
 * @example
 * const a = { a: 1, b: 2, c: { d: 3, e: 4 } };
 * const b = { a: 1, b: 2, c: { d: 3, e: 5 } };
 * isEqualArray(a, b); // false
 */
export const isEqualArray = <T>(a: Array<T>, b: Array<T>): boolean => {
  if (a == null || b == null || a.length !== b.length) {
    return false;
  }

  return JSON.stringify(a) === JSON.stringify(b);
};
