import styled from "styled-components";

import RoundedButton from "components/RoundedButton";
import SearchForm from "components/SearchForm";
import { AIMO_PARKING_USER_ROLE, AimoParkingUserRole } from "pages/AimoParking/constants/aimoParkingUserRole";

const Area = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;
const FilterWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0;
`;
const Text = styled.span`
  font-weight: bold;
  margin-right: 5px;
`;

const ActionArea = ({
  searchKeyword,
  selectedFilter,
  onSearchChange,
  onFilterButtonClick,
}: {
  searchKeyword: string;
  selectedFilter: AimoParkingUserRole;
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFilterButtonClick: (status: AimoParkingUserRole) => void;
}) => {
  return (
    <Area>
      <SearchForm width="350px" value={searchKeyword} placeholder="検索" onChange={onSearchChange} />
      <FilterWrap>
        <Text>絞り込み</Text>
        <RoundedButton
          label="すべて"
          isSelected={selectedFilter === AIMO_PARKING_USER_ROLE.ALL}
          onClick={() => onFilterButtonClick(AIMO_PARKING_USER_ROLE.ALL)}
        />
        <RoundedButton
          label="販売元管理者"
          isSelected={selectedFilter === AIMO_PARKING_USER_ROLE.VENDER__ADMIN}
          onClick={() => onFilterButtonClick(AIMO_PARKING_USER_ROLE.VENDER__ADMIN)}
          width="130px"
        />
        <RoundedButton
          label="システム管理者"
          isSelected={selectedFilter === AIMO_PARKING_USER_ROLE.SYSTEM_ADMIN}
          onClick={() => onFilterButtonClick(AIMO_PARKING_USER_ROLE.SYSTEM_ADMIN)}
          width="140px"
        />
        <RoundedButton
          label="運営管理者"
          isSelected={selectedFilter === AIMO_PARKING_USER_ROLE.OPERATION_ADMIN}
          onClick={() => onFilterButtonClick(AIMO_PARKING_USER_ROLE.OPERATION_ADMIN)}
          width="110px"
        />
        <RoundedButton
          label="一般管理者"
          isSelected={selectedFilter === AIMO_PARKING_USER_ROLE.GENERAL_ADMIN}
          onClick={() => onFilterButtonClick(AIMO_PARKING_USER_ROLE.GENERAL_ADMIN)}
          width="110px"
        />
        <RoundedButton
          label="コールセンター"
          isSelected={selectedFilter === AIMO_PARKING_USER_ROLE.CALL_CENTER}
          onClick={() => onFilterButtonClick(AIMO_PARKING_USER_ROLE.CALL_CENTER)}
          width="140px"
        />
      </FilterWrap>
    </Area>
  );
};

export default ActionArea;
