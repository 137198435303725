import { read, utils } from "xlsx";

/**
 * サンプルcsvのフォーマット
 * @param initObj
 * @returns csvData [[keys], [values]]
 */
export const convertObjectToCSV = (initObj: ImportPitPortUserCsvFormat) => {
  const csvData = [];

  // Add header row
  const header = Object.keys(initObj);
  csvData.push(header);

  // Add data rows
  const values = Object.values(initObj);
  csvData.push(values);

  return csvData;
};

/**
 * ファイルドラッグ時のエクセル読み込み処理
 * @param e
 * @returns data
 */
export const handleReadFileExcel = (e: ProgressEvent<FileReader>): string | void => {
  try {
    if (!e?.target?.result) return;

    /* Parse data */
    const bstr = e.target.result;
    const wb = read(bstr, { type: "binary" });

    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];

    /* Convert array of arrays */
    const data = utils.sheet_to_csv(ws);
    return data;
  } catch (error) {
    console.error(error);
  }
};

/**
 * csvをjson形式に変換する処理
 * @param csvString
 * @returns jsonData
 */
export const csvToJson = (
  csvString: string
): {
  [key: string]: string;
}[] => {
  const lines = csvString.split("\n");
  const keys = lines[0].split(",");
  const jsonData = [];

  for (let i = 1; i < lines.length; i++) {
    const values = lines[i].split(",");
    const row: { [key: string]: string } = {};

    for (let j = 0; j < keys.length; j++) {
      row[keys[j]] = values[j];
    }

    jsonData.push(row);
  }

  return jsonData;
};

/**
 * csvのヘッダーを取得する処理
 * @param csvString
 * @returns keys
 * */
export const getKeysCsv = (csvString: string): string[] => {
  const lines = csvString.split("\n");
  const keys = lines[0].split(",");
  return keys;
};

/**
 * 2つの配列が部分集合かどうかを判定する処理
 * @param subset
 * @param superset
 * @returns boolean
 * */
export const checkArraySubset = (subset: string[], superset: string[]): boolean => {
  for (let i = 0; i < subset.length; i++) {
    if (!superset.includes(subset[i])) {
      return false;
    }
  }
  return true;
};
