import React from "react";

import {
  spaceTableColumn,
  Wrap,
  InnerField,
  InnerItem,
  OverWrapFieldValue,
} from "pages/SyncPort/Company/Property/Space/features/DataTable";

const EmptyUsage = () => {
  return (
    <>
      <OverWrapFieldValue flex={spaceTableColumn[2].flex}>
        <Wrap>
          <InnerField lastColumn>
            <InnerItem />
          </InnerField>
        </Wrap>
      </OverWrapFieldValue>
      <OverWrapFieldValue flex={spaceTableColumn[3].flex}>
        <Wrap>
          <InnerField lastColumn>
            <InnerItem />
          </InnerField>
        </Wrap>
      </OverWrapFieldValue>
      <OverWrapFieldValue flex={spaceTableColumn[4].flex}>
        <Wrap>
          <InnerField lastColumn>
            <InnerItem />
          </InnerField>
        </Wrap>
      </OverWrapFieldValue>
      <OverWrapFieldValue flex={spaceTableColumn[5].flex}>
        <Wrap>
          <InnerField lastColumn>
            <InnerItem />
          </InnerField>
        </Wrap>
      </OverWrapFieldValue>
    </>
  );
};

export default EmptyUsage;
