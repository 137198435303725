import styled from "styled-components";

import Button from "components/Button";
import color from "constants/color";
import font from "constants/font";

const CompanyMemberListEmpty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
  border: 1px solid ${color.border};
  border-top: none;
  background-color: ${color.white};
  height: 100px;
`;

const CompanyMemberListEmptyText = styled.div`
  font-size: ${font.size16};
  margin-bottom: 16px;
`;

const EmptyTable = ({ message, label, handleClick }: { message: string; label: string; handleClick?: () => void }) => {
  return (
    <CompanyMemberListEmpty>
      <CompanyMemberListEmptyText>{message}</CompanyMemberListEmptyText>
      {handleClick && <Button label={label} type="secondary" width="200px" onClick={handleClick} />}
    </CompanyMemberListEmpty>
  );
};

export default EmptyTable;
