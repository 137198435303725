import React, { useEffect, useState } from "react";

import { Board, TitleArea, Title, PageTop } from "components/LayoutParts";
import Loading from "components/Loading";
import UserModal, { UserModalConfig } from "components/UserModal";
import { sortDirection } from "constants/direction";
import { useSwr } from "hooks/useSwr";
import DataTable from "pages/PitPort/Company/DataTable";
import ActionArea from "pages/PitPort/Company/DataTable/ActionArea";
import { displayFilterStatus, DISPLAY_FILTER_KEY } from "pages/PitPort/Company/DataTable/displayFilterCompany";
import { jumpUpToElement } from "utils/animationUtils";
import { formatQueryParams } from "utils/stringUtils";

const Company = () => {
  const [originList, setOriginList] = useState<Company[]>([]);
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [isModalShow, setIsModalShow] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedFilter, setSelectedFilter] = useState<string[]>(displayFilterStatus());
  const [sortOption, setSortOption] = useState<SortOption<SortByPitPortCompany>>({
    sortByColumn: "id",
    direction: sortDirection.desc,
  });

  const hideModal = () => setIsModalShow(false);
  const showModal = () => setIsModalShow(true);

  const userModalConfig: UserModalConfig = {
    onClickOutside: hideModal,
    subtitle: "送信に失敗しました",
    main: {
      buttonType: "secondary",
      onClick: hideModal,
      label: "閉じる",
    },
    errorMessage: "時間をおいてもう一度お試しください",
  };

  const { data, error } = useSwr<PitPortCompaniesResponse>(
    `/company?${formatQueryParams({
      status: selectedFilter,
      pageIndex: currentPageIndex,
      pageSize: 100,
      orderBy: sortOption.sortByColumn,
      orderType: sortOption.direction,
      keyword: searchKeyword,
    })}`
  );

  const onFilterButtonClick = (status: string[]) => {
    setSelectedFilter(status);
    localStorage.setItem(DISPLAY_FILTER_KEY, JSON.stringify(status));
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPageIndex(1);
    setSearchKeyword(e.target.value);
  };

  useEffect(() => {
    jumpUpToElement("page-top");
    if (error) {
      showModal();
      return;
    }
    if (!data) return;
    setTotalPages(data.totalPages);
    setOriginList(data.data);
  }, [data, error]);

  return (
    <>
      <Board>
        <PageTop />
        <TitleArea>
          <Title>請求先</Title>
        </TitleArea>
        <ActionArea
          searchKeyword={searchKeyword}
          selectedFilter={selectedFilter}
          onSearchChange={onSearchChange}
          onFilterButtonClick={onFilterButtonClick}
        />
        {data ? (
          <DataTable
            companyList={originList}
            totalPages={totalPages}
            pageIndex={currentPageIndex}
            sortOption={sortOption}
            setPageIndex={setCurrentPageIndex}
            setSortOption={setSortOption}
          />
        ) : (
          <Loading />
        )}
      </Board>
      <UserModal isVisible={isModalShow} config={userModalConfig}></UserModal>
    </>
  );
};

export default Company;
