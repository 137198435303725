import React from "react";
import styled from "styled-components";

import font from "constants/font";

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
`;

const Wrap = styled.div`
  font-size: ${font.size24};
`;

const ZipPlaceHolder = ({ size }: { size?: number }) => {
  const sizeMb = size ? (size / 1024 / 1024).toFixed(1) : undefined;
  return (
    <Container>
      <Wrap>.ZIP</Wrap>
      {size && `${sizeMb}MB`}
    </Container>
  );
};

export default ZipPlaceHolder;
