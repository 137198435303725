import queryString from "query-string";

/**
 * 数値をカンマ区切りする
 * @param value
 * @param suffix
 * @returns
 */
export const convertNumberToLocaleString = (value: number, suffix = "円") => {
  return `${value.toLocaleString()}${suffix}`;
};

/**
 * オブジェクトからQueryParameterを生成
 */
export const formatQueryParams = (params: object): string => {
  return queryString.stringify(params);
};

/**
 * 2桁の文字数字に変換する
 * @param num ex) 1
 * @returns 01
 */
export const twoDigits = (num?: string | number): string => {
  if (num === undefined) {
    return "00";
  }
  if (typeof num === "string") {
    num = Number(num);
  }

  return num < 10 ? `0${num}` : `${num}`;
};

/**
 * 文字列が空かどうか
 * @example
 * isEmptyString(" "); // true
 * isEmptyString(""); // true
 * isEmptyString("a"); // false
 */
export const isEmptyString = (value: string): boolean => {
  return typeof value === "string" && value.trim() === "";
};

/**
 * 文字列を安全に取得する
 * @example
 * safeString(" "); // ""
 * safeString(""); // ""
 * safeString("", true); // undefined
 * safeString("a"); // "a"
 */
export const safeString = (value: string, undefinedWhenEmpty = false): string | undefined => {
  return isEmptyString(value) ? (undefinedWhenEmpty ? undefined : "") : value.trim();
};
