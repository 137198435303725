import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEmail, isPhoneNumber } from "class-validator";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import Breadcrumb from "components/Breadcrumb";
import Button from "components/Button";
import CheckBox from "components/CheckBox";
import {
  Board,
  FieldName,
  FieldValue,
  FormRow,
  Heading,
  Row,
  Table,
  Title,
  TitleArea,
  AlertArea,
  ContentArea,
  FooterArea,
} from "components/LayoutParts";
import RadioButton from "components/RadioButton";
import SampleCsv from "components/SampleCsv";
import TextForm from "components/TextForm";
import UserModal, { UserModalConfig } from "components/UserModal";
import color from "constants/color";
import font from "constants/font";
import { useDebounce } from "hooks/useDebounce";
import { useSwr } from "hooks/useSwr";
import { useRemoveCompany, useUpdateCompany, useRemoveCompanyPropertySpace } from "pages/PitPort/Company/api";
import ImportCompanyUsers from "pages/PitPort/Company/ImportCompanyUsers";
import { useRemovePitPortUser } from "pages/PitPort/User/api";
import PropertySpacesTable from "pages/PitPort/User/DataTable/PropertySpacesTable";
import SearchArea from "pages/PitPort/User/DataTable/SearchArea";
import { switchUserStatusDisplayValue } from "utils/statusSwitchUtil";
import { formatQueryParams } from "utils/stringUtils";

const DeleteWrap = styled.div`
  display: flex;
  flex: auto;
  justify-content: flex-end;
`;
const Label = styled.div`
  min-width: 120px;
  margin: 0 5px;
`;
const CancelWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;
const SaveWrap = styled(CancelWrap)`
  margin-right: 30px;
  align-items: center;
  justify-content: flex-end;
`;
const Annotation = styled.span`
  font-size: ${font.size12};
  color: ${color.text};
  margin-right: 16px;
`;
const TableWithMarginBottom = styled(Table)`
  margin-bottom: 28px;
`;
export const CompanyUserTitleArea = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px 0 10px 0;
`;
const CsvArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;
export const LimitedPropertySpaceArea = styled.div`
  display: flex;
  justify-content: start;
  margin-bottom: 10px;
`;
export const SubTitle = styled.span`
  font-size: ${font.size16};
  font-weight: bold;
  line-height: 40px;
`;
export const FieldValueWithBorder = styled(FieldValue)<{ lastColumn?: boolean }>`
  border-right: ${(props) => (props.lastColumn ? "none" : `solid 1px ${color.border}`)};
  min-height: 20px;
`;
export const RelatedPropertySpaceArea = styled.div`
  margin-bottom: 10px;
`;
export const CheckBoxArea = styled(FieldValueWithBorder)`
  display: flex;
  justify-content: center;
`;
export const MarginBottom = styled.div`
  margin-bottom: 16px;
`;
export const NoProperty = styled(Row)`
  display: flex;
  justify-content: center;
  padding: 16px;
`;
enum propertyType {
  NORMAL = 0,
  LIMITED = 1,
}

const initialCompany: Company = {
  id: 0,
  name: "",
  address: "",
  phoneNumber: "",
  email: "",
  totalMembers: 0,
  status: 0,
  type: 0,
};

const EditCompanyUser = () => {
  const [originList, setOriginList] = useState<SpaceWithProperty[]>([]); // TODO: stateを使って変数を渡す必要ない。修正が必要。
  const params = useParams();
  const { data: originCompany, error } = useSwr<CompanyResponse>(`/company/${params.id}`);
  const company = originCompany?.data ?? initialCompany;

  const companyId = company.id === 0 ? null : `/user?${formatQueryParams({ companyId: company.id })}`;

  const { data: users } = useSwr<PitPortUsersResponse>(companyId);
  const [checkedIds, setCheckedIds] = useState<number[]>([]);
  const [name, setName] = useState(company.name);
  const [address, setAddress] = useState(company.address);
  const [phoneNumber, setPhoneNumber] = useState(company.phoneNumber);
  const [email, setEmail] = useState(company.email);
  const [status, setStatus] = useState(company.status);
  const [type, setType] = useState(company.type);
  const [selectedPropertyIds, setSelectedPropertyIds] = useState<number[]>([]);
  const [isModalShow, setIsModalShow] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const { debouncedValue: debouncedSearchKeyword } = useDebounce(searchKeyword, 500);
  const [ModalConfig, setModalConfig] = useState<UserModalConfig>({
    onClickOutside: () => {
      return;
    },
    subtitle: "完了しました",
    main: {
      buttonType: "secondary",
      onClick: () => {
        return;
      },
      label: "閉じる",
    },
  });

  const showModal = useCallback((config: UserModalConfig) => {
    setModalConfig(config);
    setIsModalShow(true);
  }, []);
  const hideModal = useCallback(() => {
    setIsModalShow(false);
  }, []);

  const handleCheckboxChange = (id: number, isChecked: boolean) => {
    if (isChecked) {
      setCheckedIds((prev) => [...prev, id]);
    } else {
      setCheckedIds((prev) => prev.filter((item) => item !== id));
    }
  };

  const changeName = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value);
  const changeAddress = (e: React.ChangeEvent<HTMLInputElement>) => setAddress(e.target.value);
  const changePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => setPhoneNumber(e.target.value);
  const changeEmail = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);

  const isErrorCompanyName = !(name.length > 0);
  const isErrorCompanyAddress = !(address.length > 0);
  const isErrorCompanyPhoneNumber = !isPhoneNumber(phoneNumber, "JP") || phoneNumber.includes("-");
  const isErrorCompanyEmail = !isEmail(email);
  const canSubmit = !isErrorCompanyName && !isErrorCompanyAddress && !isErrorCompanyPhoneNumber && !isErrorCompanyEmail;

  // TODO: 書き方的によくない。修正が必要。
  const requestUrl = debouncedSearchKeyword.trim()
    ? `/property-space?${formatQueryParams({
        type: propertyType.LIMITED,
        keyword: debouncedSearchKeyword,
      })}`
    : null;

  const { data } = useSwr<{ data: SpaceWithProperty[] }>(requestUrl);

  const { data: companyPropertySpaces } = useSwr<{ data: CompanyPropertySpace[] }>(
    `/company-property-space?companyId=${params.id}`
  );
  const [relatedPropertySpaces, setRelatedPropertySpaces] = useState<CompanyPropertySpace[]>(
    companyPropertySpaces?.data
  );

  useEffect(() => {
    if (!data) return;
    setOriginList(data.data);
  }, [data]);

  const { companyPropertySpaceRemove } = useRemoveCompanyPropertySpace();
  const { update: updateCompany } = useUpdateCompany();
  const { remove: removeUser } = useRemovePitPortUser();
  const { remove } = useRemoveCompany();
  const [userList, setUserList] = useState<PitPortUser[]>([]);
  const navigate = useNavigate();
  const onClickRow = (userId: number) => navigate(`/pit_port/user/${userId}`);
  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearchKeyword(e.target.value);
  useEffect(() => {
    if (companyPropertySpaces && companyPropertySpaces.data) {
      setRelatedPropertySpaces(companyPropertySpaces.data);
    }
  }, [companyPropertySpaces]);
  const update = useCallback(
    async (companyId: number) => {
      if (type === propertyType.NORMAL && relatedPropertySpaces.length > 0) {
        showModal({
          onClickOutside: hideModal,
          subtitle: "登録済みの区画が存在します",
          main: {
            buttonType: "secondary",
            onClick: hideModal,
            label: "閉じる",
          },
          errorMessage: "登録済みの区画を解除してください",
        });
        return;
      }
      const updateCompanyResult = await updateCompany({
        id: companyId,
        name,
        address,
        phoneNumber,
        email,
        status,
        type,
        propertySpaceIds: selectedPropertyIds,
      });

      updateCompanyResult === 200
        ? showModal({
            onClickOutside: hideModal,
            subtitle: "変更内容を保存しました",
            main: {
              buttonType: "secondary",
              onClick: () => navigate("/pit_port/company"),
              label: "閉じる",
            },
          })
        : showModal({
            onClickOutside: hideModal,
            subtitle: "送信に失敗しました",
            main: {
              buttonType: "secondary",
              onClick: hideModal,
              label: "閉じる",
            },
            errorMessage: "時間をおいてもう一度お試しください",
          });
    },
    [
      name,
      address,
      phoneNumber,
      email,
      status,
      type,
      navigate,
      updateCompany,
      selectedPropertyIds,
      relatedPropertySpaces,
      showModal,
      hideModal,
    ]
  );
  const removePropertySpaces = useCallback(async () => {
    if (checkedIds.length === 0) {
      showModal({
        onClickOutside: hideModal,
        subtitle: "エラー",
        main: {
          buttonType: "secondary",
          onClick: hideModal,
          label: "閉じる",
        },
        errorMessage: "削除する区画を選択してください。",
      });
      return;
    }

    companyPropertySpaceRemove(company.id, checkedIds)
      .then((status) => {
        if (status === 200) {
          setRelatedPropertySpaces((prevSpaces) =>
            prevSpaces.filter((space) => !checkedIds.includes(space.propertySpaceId))
          );
          setCheckedIds([]);
          showModal({
            onClickOutside: hideModal,
            subtitle: "成功",
            main: {
              buttonType: "secondary",
              onClick: hideModal,
              label: "閉じる",
            },
            errorMessage: "選択した区画を限定対象から外しました。",
          });
        } else {
          showModal({
            onClickOutside: hideModal,
            subtitle: "エラー",
            main: {
              buttonType: "secondary",
              onClick: hideModal,
              label: "閉じる",
            },
            errorMessage: "区画の削除に失敗しました。",
          });
        }
      })
      .catch((error) => {
        console.error(error);
        showModal({
          onClickOutside: hideModal,
          subtitle: "エラー",
          main: {
            buttonType: "secondary",
            onClick: hideModal,
            label: "閉じる",
          },
          errorMessage: "処理中にエラーが発生しました。",
        });
      });
  }, [companyPropertySpaceRemove, company.id, checkedIds, showModal, hideModal]);

  const removeCompany = useCallback(
    async (companyId: number, userIds: number[]) => {
      const result = await remove(companyId);
      /**
       * 請求先(Company)に紐づくユーザー(PitPortUser)も削除する。
       */
      const removeUsersResult = await Promise.all(userIds.map((userId) => removeUser(userId)));

      result === 200 && removeUsersResult.every((result) => result === 200)
        ? showModal({
            onClickOutside: hideModal,
            subtitle: "削除しました",
            main: {
              buttonType: "secondary",
              onClick: () => navigate("/pit_port/company"),
              label: "閉じる",
            },
          })
        : showModal({
            onClickOutside: hideModal,
            subtitle: "送信に失敗しました",
            main: {
              buttonType: "secondary",
              onClick: hideModal,
              label: "閉じる",
            },
            errorMessage: "時間をおいてもう一度お試しください",
          });
    },
    [remove, navigate, removeUser, showModal, hideModal]
  );

  const showRemoveModal = () => {
    setModalConfig({
      onClickOutside: hideModal,
      subtitle: "削除しますか?",
      main: {
        buttonType: "primary",
        onClick: () => {
          removeCompany(
            company.id,
            userList.map((user) => user.id)
          );
        },
        label: "削除する",
      },
      sub: {
        buttonType: "secondary",
        onClick: hideModal,
        label: "閉じる",
      },
    });
    setIsModalShow(true);
  };

  const setUp = useCallback(() => {
    if (error) {
      showModal({
        onClickOutside: hideModal,
        subtitle: "送信に失敗しました",
        main: {
          buttonType: "secondary",
          onClick: hideModal,
          label: "閉じる",
        },
        errorMessage: "時間をおいてもう一度お試しください",
      });
      return;
    }
    setName(company.name);
    setAddress(company.address);
    setPhoneNumber(company.phoneNumber);
    setEmail(company.email);
    setStatus(company.status);

    fetch;
    setUserList(users.data);
  }, [
    error,
    company,
    users,
    setUserList,
    setName,
    setAddress,
    setPhoneNumber,
    setEmail,
    setStatus,
    showModal,
    hideModal,
  ]);

  useEffect(() => {
    if (!company || !users) return;
    setUp();
  }, [company, users, setUp]);

  useEffect(() => {
    setType(company.type);
  }, [company.type]);

  return (
    <>
      <Board>
        <TitleArea>
          <Breadcrumb
            currentPageName="請求先を編集"
            breadcrumbItems={[{ pageName: "請求先", onClick: () => navigate("/pit_port/company") }]}
          />
          {status !== 5 && (
            <DeleteWrap>
              <Button label="この請求先を削除" onClick={showRemoveModal} type="danger" />
            </DeleteWrap>
          )}
        </TitleArea>
        {status === 5 && (
          <AlertArea>
            <FontAwesomeIcon icon={faCircleExclamation} />
            <span style={{ color: color.text.black, marginLeft: "8px" }}>この請求先は削除済みです。</span>
          </AlertArea>
        )}
        <ContentArea>
          <FormRow>
            <div style={{ marginRight: "134px" }}>会社名</div>
            <TextForm
              type="text"
              width="350px"
              value={name}
              placeholder="例）ニースン不動産"
              onChange={changeName}
              required
              isError={isErrorCompanyName}
              errorMessage="会社名を入力してください"
            />
          </FormRow>
          <FormRow>
            <div style={{ marginRight: "150px" }}>住所</div>
            <TextForm
              type="text"
              width="540px"
              value={address}
              placeholder="例）京都府京都市東山区三条通南二筋目白川筋西入ル二丁目北木之元"
              onChange={changeAddress}
              required
              isError={isErrorCompanyAddress}
              errorMessage="住所を入力してください"
            />
          </FormRow>
          <FormRow>
            <div style={{ marginRight: "118px" }}>電話番号</div>
            <TextForm
              type="text"
              width="350px"
              value={phoneNumber}
              placeholder="例）0368224474"
              onChange={changePhoneNumber}
              required
              isError={isErrorCompanyPhoneNumber}
              errorMessage="電話番号をハイフンなしで入力してください"
            />
          </FormRow>
          <FormRow>
            <div style={{ marginRight: "24px" }}>請求先メールアドレス</div>
            <TextForm
              type="text"
              width="350px"
              value={email}
              placeholder="例）info@landit.co.jp"
              onChange={changeEmail}
              required
              isError={isErrorCompanyEmail}
              errorMessage="請求先メールアドレスを入力してください"
            />
          </FormRow>
          {status !== 5 && (
            <>
              <FormRow>
                <Label>状態</Label>
                <div style={{ marginRight: "50px" }}></div>
                <RadioButton
                  id="status"
                  value={status}
                  choices={[
                    {
                      label: "利用中",
                      value: 1,
                    },
                    {
                      label: "利用停止",
                      value: 0,
                    },
                  ]}
                  onChange={(e) => setStatus(Number(e.target.value) === 1 ? 1 : 0)}
                />
              </FormRow>
              <FormRow>
                <Label>種別</Label>
                <div style={{ marginRight: "50px" }}></div>
                <RadioButton
                  id={`company-type`}
                  value={type}
                  choices={[
                    {
                      label: "一般",
                      value: 0,
                    },
                    {
                      label: "限定",
                      value: 1,
                    },
                    {
                      label: "併用",
                      value: 2,
                    },
                  ]}
                  onChange={(e) => {
                    const newType = Number(e.target.value);
                    setType(newType);
                    if (newType === 0) {
                      setSelectedPropertyIds([]);
                    }
                  }}
                />
              </FormRow>
            </>
          )}
        </ContentArea>
        <CompanyUserTitleArea>
          <Title>この請求先のユーザ</Title>
          <CsvArea>
            <SampleCsv fontSize={font.size14} color={color.text.link} />
            <ImportCompanyUsers companyId={company.id} label={"CSVからユーザ一括登録"} />
          </CsvArea>
        </CompanyUserTitleArea>
        <Table>
          <Heading>
            <FieldName flex={0.5}>ID</FieldName>
            <FieldName flex={1}>名前</FieldName>
            <FieldName flex={1}>携帯番号</FieldName>
            <FieldName flex={1.5}>メールアドレス</FieldName>
            <FieldName flex={0.5}>権限</FieldName>
            <FieldName flex={0.3}>状態</FieldName>
          </Heading>
          {userList.map((user) => (
            <Row key={user.id} onClick={() => onClickRow(user.id)}>
              <FieldValue flex={0.5}>{user.id}</FieldValue>
              <FieldValue flex={1}>{`${user.lastName}${user.firstName}`}</FieldValue>
              <FieldValue flex={1}>{user.phoneNumber}</FieldValue>
              <FieldValue flex={1.5}>{user.email}</FieldValue>
              <FieldValue flex={0.5}>{user.companyUser.role === 2 ? "管理者" : "-"}</FieldValue>
              <FieldValue flex={0.3}>{switchUserStatusDisplayValue(user.status)}</FieldValue>
            </Row>
          ))}
        </Table>
        <TableWithMarginBottom />
        <LimitedPropertySpaceArea>
          <Title>限定区画</Title>
        </LimitedPropertySpaceArea>
        <RelatedPropertySpaceArea>
          <SubTitle>登録済み区画</SubTitle>
          <Table>
            <Heading>
              <FieldName flex={0.2}> </FieldName>
              <FieldName flex={0.5}>駐車場ID</FieldName>
              <FieldName flex={1.5}>駐車場名</FieldName>
              <FieldName flex={2}>住所</FieldName>
              <FieldName flex={0.5}>区画ID</FieldName>
              <FieldName flex={1}>区画名</FieldName>
            </Heading>
            {Array.isArray(relatedPropertySpaces) && relatedPropertySpaces.length !== 0 ? (
              relatedPropertySpaces.map((propertySpace: CompanyPropertySpace) => (
                <Row key={propertySpace.propertySpaceId}>
                  <CheckBoxArea flex={0.2}>
                    <CheckBox
                      id={`related-${propertySpace.propertySpaceId.toString()}` ?? ""}
                      isChecked={checkedIds.includes(propertySpace.propertySpaceId ?? 0)}
                      setIsChecked={() =>
                        handleCheckboxChange(
                          propertySpace.propertySpaceId ?? 0,
                          !checkedIds.includes(propertySpace.propertySpaceId ?? 0)
                        )
                      }
                      label=""
                    />
                  </CheckBoxArea>
                  <FieldValueWithBorder flex={0.5}>{propertySpace.propertySpace.property.id}</FieldValueWithBorder>
                  <FieldValueWithBorder flex={1.5}>{propertySpace.propertySpace.property.name}</FieldValueWithBorder>
                  <FieldValueWithBorder flex={2}>{propertySpace.propertySpace.property.address}</FieldValueWithBorder>
                  <FieldValueWithBorder flex={0.5}>{propertySpace.propertySpaceId}</FieldValueWithBorder>
                  <FieldValueWithBorder flex={1}>{propertySpace.propertySpace.name}</FieldValueWithBorder>
                </Row>
              ))
            ) : (
              <NoProperty>登録済み区画がありません</NoProperty>
            )}
          </Table>
          <MarginBottom />
          <Button
            type="secondary"
            label="選択した区画を限定対象から外す"
            width="260px"
            onClick={removePropertySpaces}
          />
        </RelatedPropertySpaceArea>
        {type !== 0 && (
          <>
            <SubTitle>新規追加区画</SubTitle>
            <PropertySpacesTable spacesWithProperty={{ data: originList }} selectedPropertyIds={selectedPropertyIds} />
            <SearchArea
              searchKeyword={searchKeyword}
              onSearchChange={onSearchChange}
              spacesWithProperty={originList}
              setSelectedPropertyIds={setSelectedPropertyIds}
              isLoading={!originList}
            />
          </>
        )}
        <FooterArea>
          <CancelWrap>
            <Button type="secondary" onClick={() => navigate("/pit_port/company")} label="キャンセル" width="160px" />
          </CancelWrap>
          {status !== 5 && (
            <SaveWrap>
              <Annotation>追加するまで変更内容は反映されません</Annotation>
              <Button
                type="primary"
                onClick={() => update(company.id)}
                label="変更内容を保存"
                disabled={!canSubmit}
                width="160px"
              />
            </SaveWrap>
          )}
        </FooterArea>
        <UserModal isVisible={isModalShow} config={ModalConfig}></UserModal>
      </Board>
    </>
  );
};

export default EditCompanyUser;
