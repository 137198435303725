import Company from "pages/PitPort/Company";
import EditCompany from "pages/PitPort/Company/EditCompany";
import ControlBox from "pages/PitPort/ControlBox";
import CreateControlBox from "pages/PitPort/ControlBox/CreateControlBox";
import EditControlBox from "pages/PitPort/ControlBox/EditControlBox";
import Property from "pages/PitPort/Property";
import CreateProperty from "pages/PitPort/Property/CreateProperty";
import EditProperty from "pages/PitPort/Property/EditProperty";
import Usage from "pages/PitPort/Usage";
import User from "pages/PitPort/User";
import CreateUser from "pages/PitPort/User/CreateUser";
import EditUser from "pages/PitPort/User/EditUser";

const PitPort = {
  User,
  CreateUser,
  EditUser,
  Company,
  EditCompany,
  Property,
  EditProperty,
  CreateProperty,
  CreateControlBox,
  EditControlBox,
  Usage,
  ControlBox,
};

export default PitPort;
