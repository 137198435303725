/**
 * クリップボードにテキストをコピーする関数
 * @param text コピーしたいテキスト
 * @param setState コピー後の状態をセットする関数
 * @returns
 */
export const copyOnClipboard = (text: string, setState?: void): void => {
  navigator.clipboard.writeText(text);
  setState;
};

/**
 *
 * スプレッドシートにペーストした時に、下記のようなスプレッドシートで使用可能になるテキストを作成し返却する関数
 * "名前\tカナ\メールアドレス\n example\t \ex@ex.com\example\t \ex@ex.com"
 * @param label: string[]  ヘッダーのラベル
 * @param data  { [key: number]: string }[]  データ
 * labelとデータ内のオブジェクトの数が一致しない場合はエラーを返す
 * @returns string
 */
export const createCsvTextForClipboard = ({
  label,
  data,
}: {
  label: string[];
  data: { [key: number]: string }[];
}): string => {
  if (label.length !== Object.entries(data[0]).length)
    throw new Error("labelの数とdata内のオブジェクトのkeyの数が一致しません");
  const labelText = label.join(`\t`) + `\n`;
  const dataText = data
    .map((item) =>
      Object.entries(item)
        .map((obj) => obj[1])
        .join(`\t`)
    )
    .join(`\n`);
  return `${labelText}${dataText}`;
};
