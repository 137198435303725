import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Breadcrumb from "components/Breadcrumb";
import Button from "components/Button";
import { Board as BaseBoard, ContentArea, FooterArea, FormRow, TitleArea } from "components/LayoutParts";
import TextForm from "components/TextForm";
import UserModal, { UserModalConfig } from "components/UserModal";
import color from "constants/color";
import font from "constants/font";
import { useCreateControlBox } from "pages/PitPort/ControlBox/api";
// パーツ定義
const Board = styled(BaseBoard)`
  margin-bottom: 88px;
`;
const Label = styled.div`
  min-width: 160px;
  margin: 0 5px;
`;
const CancelWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;
const SaveWrap = styled(CancelWrap)`
  align-items: center;
  justify-content: flex-end;
`;
const Annotation = styled.span`
  font-size: ${font.size12};
  color: ${color.text};
  margin-right: 16px;
`;
const CreateControlBox = () => {
  const [boxId, setBoxId] = useState<string>("");

  const [isModalShow, setIsModalShow] = useState<boolean>(false);
  const [modalConfig, setModalConfig] = useState<UserModalConfig>({
    onClickOutside: () => {
      return;
    },
    subtitle: "完了しました",
    main: {
      buttonType: "secondary",
      onClick: () => {
        return;
      },
      label: "閉じる",
    },
  });

  const showModal = (config: UserModalConfig) => {
    setModalConfig(config);
    setIsModalShow(true);
  };
  const hideModal = () => {
    setIsModalShow(false);
  };

  const navigate = useNavigate();
  const { create } = useCreateControlBox();
  const changeBoxId = (e: React.ChangeEvent<HTMLInputElement>) => setBoxId(e.target.value);

  const isErrorBoxId = !/^[a-z0-9]{12}$/.test(boxId);
  const canSubmit = !isErrorBoxId;

  const save = useCallback(async () => {
    const status = await create(boxId);
    switch (status) {
      case 201:
        showModal({
          onClickOutside: hideModal,
          subtitle: "登録しました",
          main: {
            buttonType: "secondary",
            onClick: () => navigate("/pit_port/control_box"),
            label: "閉じる",
          },
        });
        break;
      case 409:
        showModal({
          onClickOutside: hideModal,
          subtitle: "登録に失敗しました",
          main: {
            buttonType: "secondary",
            onClick: hideModal,
            label: "閉じる",
          },
          errorMessage: "既に登録されている制御BOX IDです",
        });
        break;
      default:
        showModal({
          onClickOutside: hideModal,
          subtitle: "登録に失敗しました",
          main: {
            buttonType: "secondary",
            onClick: hideModal,
            label: "閉じる",
          },
          errorMessage: "時間をおいてもう一度お試しください",
        });
    }
  }, [create, boxId, navigate]);

  return (
    <Board>
      <TitleArea>
        <Breadcrumb
          currentPageName="制御BOXを追加"
          breadcrumbItems={[{ pageName: "制御BOX", onClick: () => navigate("/pit_port/control_box") }]}
        />
      </TitleArea>
      <ContentArea>
        <FormRow>
          <Label>制御BOX ID</Label>
          <TextForm
            type="text"
            width="240px"
            value={boxId}
            placeholder="a1b2c3d4e5f6"
            required
            onChange={changeBoxId}
            isError={isErrorBoxId}
            errorMessage="半角英小文字と数字12文字で入力してください"
          />
        </FormRow>
      </ContentArea>
      <FooterArea>
        <CancelWrap>
          <Button type="secondary" onClick={() => navigate("/pit_port/control_box")} label="キャンセル" width="160px" />
        </CancelWrap>
        <SaveWrap>
          <Annotation>追加するまで変更内容は反映されません</Annotation>
          <Button type="primary" onClick={save} label="この制御BOXを追加" disabled={!canSubmit} width="200px" />
        </SaveWrap>
      </FooterArea>
      <UserModal isVisible={isModalShow} config={modalConfig} />
    </Board>
  );
};

export default CreateControlBox;
