import React from "react";
import styled from "styled-components";

import color from "constants/color";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const StatusDot = styled.span<{ isAcceptable: boolean }>`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: ${({ isAcceptable }) => (isAcceptable ? color.attention : color.form.border)};
`;

const StatusText = styled.span<{ isAcceptable: boolean }>`
  color: ${({ isAcceptable }) => (isAcceptable ? color.text.black : color.text.disabled)};
`;

const AcceptableTag = ({ isAcceptable }: { isAcceptable: boolean }) => {
  return (
    <Container>
      <StatusDot isAcceptable={isAcceptable} />
      <StatusText isAcceptable={isAcceptable}>{isAcceptable ? "受付中" : "受付停止"}</StatusText>
    </Container>
  );
};

export default AcceptableTag;
