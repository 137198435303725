import { useState, useCallback } from "react";
import styled from "styled-components";

import Loading from "components/Loading";
import color from "constants/color";

// パーツ定義
const BaseButton = styled.button<{
  buttonType: "primary" | "secondary" | "danger";
  width: string;
  height: string;
  disabled: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 7px 16px;

  width: ${(props) => props.width};
  height: ${(props) => props.height};

  background: ${(props) =>
    props.disabled ? color.text.disabled : props.buttonType === "primary" ? color.text.link : color.white};
  border: 1px solid
    ${(props) =>
      props.disabled ? color.text.disabled : props.buttonType === "danger" ? color.warning : color.text.link};
  border-radius: 4px;
  color: ${(props) =>
    props.disabled
      ? color.white
      : props.buttonType === "primary"
      ? color.white
      : props.buttonType === "secondary"
      ? color.text.link
      : color.warning};
  font-weight: ${(props) => (props.buttonType === "primary" ? "bold" : "normal")};

  &:hover {
    cursor: pointer;
    background: ${(props) =>
      props.disabled
        ? color.text.disabled
        : props.buttonType === "primary"
        ? color.text.linkDark
        : props.buttonType === "secondary"
        ? color.text.linkLight
        : color.attentionLight};
    border: 1px solid
      ${(props) =>
        props.disabled
          ? color.text.disabled
          : props.buttonType === "primary"
          ? color.text.linkDark
          : props.buttonType === "secondary"
          ? color.text.link
          : color.warning};
  }
`;

// 本体
const Button = ({
  label,
  onClick,
  type = "primary",
  width = "160px",
  height = "40px",
  disabled = false,
  isLoading = false,
}: {
  label: string;
  onClick: () => Promise<void> | void;
  type?: "primary" | "secondary" | "danger";
  width?: string;
  height?: string;
  disabled?: boolean;
  isLoading?: boolean;
}) => {
  const [isBusy, setIsBusy] = useState(false);

  const handler = useCallback(async () => {
    if (!disabled && !isBusy) {
      setIsBusy(true);
      try {
        await onClick();
        setIsBusy(false);
      } catch (error) {
        setIsBusy(false);
      }
    }
  }, [onClick, disabled, isBusy]);

  return (
    <BaseButton buttonType={type} width={width} height={height} disabled={disabled || isBusy} onClick={handler}>
      <span style={{ display: "block" }}>{isBusy || isLoading ? <Loading size="s" /> : <div>{label}</div>}</span>
    </BaseButton>
  );
};

export default Button;
