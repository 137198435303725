import axios from "axios";
import { Auth } from "firebase/auth";
import useSWR, { KeyedMutator } from "swr";

import { useFirebaseContext } from "contexts/FirebaseContext";

export type AxiosErrorData = {
  error: string;
  message: string;
  statusCode: number;
};

type AxiosError = {
  response?: {
    data?: AxiosErrorData;
  };
};

const isDevelopEnv = ["local", "dev", "staging"].includes(process.env.REACT_APP_ENV ?? "");

/**
 * swr で api を取得するのに使用する
 * @param path: string
 * @param auth: Auth
 * @returns
 */
const fetcher = async (path: string | null, auth: Auth) => {
  if (!path) return;
  const token = await auth?.currentUser?.getIdToken();

  axios.interceptors.response.use(
    (response) => {
      if (isDevelopEnv) console.log("レスポンスデータ：", response.data);
      return response;
    },
    (error: AxiosError) => {
      return Promise.reject(error);
    }
  );

  const response = await axios
    .get(`${process.env.REACT_APP_LANDIT_ADMIN_API_URL}/lessee` + path, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .catch((error: AxiosError) => {
      if (isDevelopEnv) console.error("エラー：", error?.response?.data ?? error);
      throw error;
    });

  return response.data;
};
type swrOptions = {
  revalidateIfStale?: boolean;
  revalidateOnFocus?: boolean;
  revalidateOnReconnect?: boolean;
  onErrorRetry?: (error: AxiosError) => void;
};

/**
 * useSWR を使用する
 * @param url
 * @returns
 */
/**
 * https://swr.vercel.app/ja/docs/options
 * SalesforceのAPIに回数制限があるのでデフォルトでは再検証系はfalseにする
 */
export const useSwr = <T,>(
  url: string | null,
  options?: swrOptions
): {
  data?: T;
  error?: AxiosError;
  mutate: KeyedMutator<T>;
  isLoading?: boolean;
  isValidating?: boolean;
} => {
  const { auth } = useFirebaseContext();
  const { data, error, mutate, isValidating } = useSWR([url, auth], fetcher, {
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: false,
    ...options,
  });

  return { data, error, mutate, isValidating };
};
