import { useCallback } from "react";

import { useAimoParkingClient } from "hooks/aimoParking/useAimoParkingClient";
import { GS_PATH } from "pages/AimoParking/constants/gsPath";
import { extractFileExtension, uploadFileCloudStorage } from "pages/AimoParking/utils/aimoParkingFileUploadUtil";

/**
 *
 * @param uploadDestination
 * uploadFiles関数を提供するhook
 * 引数のdestinationがgcsのフォルダのパスになる
 * 例: companyの場合はgs://aimo-parking/company/...にアップロードされる
 * @returns { uploadFiles }
 *
 * @example
 * const { uploadFiles } = useUploadAimoParkingFile({ uploadDestination: "company" });
 * const gsPaths = await uploadFiles({ files: [file1, file2] });
 * console.log(gsPaths); // ["gs://path/to/file","gs://path/to/file"]
 */
export const useUploadAimoParkingFile = ({ uploadDestination }: { uploadDestination: "company" | "ai-software" }) => {
  const genFileName = (uuid: string, extension: string) => `${uuid}.${extension}`;

  const UPLOAD_PATH = `${GS_PATH}/${uploadDestination}`;

  const genGsFileName = useCallback(
    (fileName: string) => {
      return `gs://${UPLOAD_PATH}/${fileName}`;
    },
    [UPLOAD_PATH]
  );

  const client = useAimoParkingClient();

  const generateSignedUrl = useCallback(
    async ({ fileName }: { fileName: string }) => {
      const { data } = await client
        .execute<{ url: string }>({
          method: "POST",
          url: `/${uploadDestination}/generate/signed-url`,
          data: {
            fileName,
          },
        })
        .catch((e) => {
          throw e;
        });
      return data.url;
    },
    [client, uploadDestination]
  );

  const uploadFileToCloudStorage = useCallback(
    async ({ file, signedUrl }: { signedUrl: string; file: File }): Promise<void> => {
      return await uploadFileCloudStorage({
        signedUrl,
        file,
      }).catch((e) => {
        throw e;
      });
    },
    []
  );

  /**
   * File[]を引数にとってアップロードした後gcsのファイルパスの配列を返す関数
   * @param files File[]
   * @returns string[]
   *  */
  const uploadFiles = useCallback(
    async ({ files }: { files: File[] }) => {
      return Promise.all(
        files.map(async (file) => {
          const uuid = crypto.randomUUID();
          const fileName = genFileName(uuid, extractFileExtension(file.name));
          const signedUrl = await generateSignedUrl({ fileName: `${uploadDestination}/${fileName}` });
          await uploadFileToCloudStorage({ signedUrl, file: file });
          return genGsFileName(fileName);
        })
      ).catch((e) => {
        throw e;
      });
    },
    [generateSignedUrl, uploadFileToCloudStorage, genGsFileName, uploadDestination]
  );

  return { uploadFiles };
};
