export type SyncPortDailyRateType = "NOT_POSSIBLE" | "ONLY_FIRST_MONTH" | "ONLY_END_MONTH" | "ALL_POSSIBLE";

export const DAILY_RATE_TYPE_MAP: { [key: string]: SyncPortDailyRateType } = {
  全期間日割不可: "NOT_POSSIBLE",
  開始月のみ日割り可能: "ONLY_FIRST_MONTH",
  終了月のみ日割り可能: "ONLY_END_MONTH",
  全期間日割り可能: "ALL_POSSIBLE",
};

/**
 * 駐車場タイプを翻訳
 * @param type DailyRateType
 * @return
 */
export const convertDailyRateType = (type: DailyRateType): DailyRateTypeText => {
  switch (type) {
    case 0:
      return "全期間日割不可";
    case 1:
      return "全期間日割可";
    case 2:
      return "開始月のみ日割り可能";
    case 3:
      return "終了月のみ日割り可能";
  }
};

/**
 * 駐車場タイプを取得
 * @example
 * getDailyRateType("全期間日割不可")
 * => 'NOT_POSSIBLE'
 * getDailyRateType("全期間日割り可能")
 * => 'ALL_POSSIBLE'
 * getDailyRateType("")
 * => 'NOT_POSSIBLE'
 */
export const getDailyRateType = (dayRateType: string): SyncPortDailyRateType => {
  return DAILY_RATE_TYPE_MAP[dayRateType] ?? DAILY_RATE_TYPE_MAP["全期間日割不可"];
};
