import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useRef, useEffect, Dispatch, SetStateAction, useCallback, ChangeEvent } from "react";
import { InView } from "react-intersection-observer";
import styled from "styled-components";

import { Wrapper } from "components/TextForm";
import color from "constants/color";
import font from "constants/font";

export const Input = styled.input<{
  isErrorDisplay?: boolean;
  position?: "center" | "right" | "left";
  width: string;
}>`
  display: block;
  text-align: ${({ position }) => position ?? "left"};
  font-size: ${font.size16};
  box-sizing: border-box;
  width: ${(props) => props.width};
  padding: 12px 16px;
  height: 48px;
  -webkit-appearance: none;
  border: 1px solid ${({ isErrorDisplay }) => (isErrorDisplay ? color.attention : color.form.border)};
  background-color: ${({ isErrorDisplay }) => (isErrorDisplay ? color.attentionLight : color.form.background)};
  border-radius: 4px;
  outline: none;
  transition: 0.3s;
  z-index: 2;
  &::placeholder {
    color: ${color.text.disabled};
  }
  &:hover {
    border: 1px solid ${color.text.black};
  }
  &:focus {
    border: 1px solid ${color.text.link};
    background-color: ${color.text.linkLight};
    box-shadow: 0 0 6px rgba(0, 98, 177, 0.25);
    &::-webkit-input-placeholder {
      color: transparent;
    }
    &::-moz-placeholder {
      color: transparent;
    }
    &::-ms-input-placeholder {
      color: transparent;
    }
    &::placeholder {
      color: transparent;
    }
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
  }
  &::-webkit-clear-button {
    z-index: 1;
  }
`;

// パーツ定義
export const SelectInput = styled(Input)`
  cursor: pointer;
  font-size: ${font.size14};
  ::placeholder {
    color: ${color.text.black};
  }
`;

const OptionWrap = styled.ul<{ width: string }>`
  width: calc(${(props) => props.width} - 2px);
  max-height: 400px;
  margin-top: 4px;
  padding: 0;
  position: absolute;
  background-color: ${color.white};
  border: solid 1px ${color.form.border};
  border-radius: 4px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
  z-index: 10;
  font-size: ${font.size14};
  overflow-y: auto;
`;
const Option = styled.li<{ disabled?: boolean }>`
  color: ${color.text.black};
  padding: 4px 16px;
  display: block;
  list-style: none;
  cursor: pointer;
  &:hover {
    background-color: ${color.text.link};
    color: ${color.white};
  }
  ${(props) =>
    props.disabled &&
    `
    color: ${color.text.disabled};
    cursor: default;
    pointer-events: none;
    &:hover {
      background-color: ${color.white};
      color: ${color.text.gray};
    }`}
`;
const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  z-index: 2;
`;

const Wrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
`;

type Choice = { key?: number | string; label: string; value: string; disabled?: boolean };

/**
 * セレクトコンポーネント (swr前提)
 * @param choices 選択肢 (useSwrより返却された配列を加工したもの)
 * @param value 初期値
 * @param onChange 選択時のコールバック
 * @param width 幅
 * @param placeHolder プレースホルダー
 * @param validating 読み込み中かどうか
 * @param setPageIndex ページインデックスのセット関数
 * @param hasMore さらに読み込むかどうか
 */
const AimoParkingSearchableSelect = ({
  choices,
  onChange = () => null,
  width = "100px",
  placeHolder,
  search,
  setSearch,
  validating = false,
  setPageIndex,
  hasMore = false,
}: {
  choices: Choice[];
  onChange?: (value: Choice | null) => void;
  width?: string;
  placeHolder?: string;
  search?: string;
  setSearch: (value: string) => void;
  validating?: boolean;
  setPageIndex?: Dispatch<SetStateAction<number>>;
  hasMore?: boolean;
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const [selected, setSelected] = useState<Choice | null>(null);
  const [choiceList, setChoiceList] = useState<Choice[]>([]);

  const formRef = useRef<HTMLDivElement>(null);

  const resetChoiceList = () => {
    setPageIndex && setPageIndex(1);
    setChoiceList([]);
    !search && onChange(null);
  };

  const onClickChoice = (value: Choice) => {
    resetChoiceList();
    setSelected(value);
    setSearch(value.label);
    onChange(value);
    setIsOpened(false);
  };

  const onClickForm = () =>
    setIsOpened((prev) => {
      if (!prev && !selected) setChoiceList(choices);
      return !prev;
    });

  const handleSetSearch = (value: string) => {
    resetChoiceList();
    setSearch(value);
  };

  const handleUnSelect = () => {
    selected && setSelected(null);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    resetChoiceList();
    handleSetSearch(e.target.value);
    !isOpened && setIsOpened(true);
    handleUnSelect();
  };

  const handleLoadMore = (inView: boolean) => {
    if (inView) {
      setPageIndex && setPageIndex((prev) => prev + 1);
    }
  };

  const removeDup = useCallback((array: Choice[]) => {
    return array.filter((v, i, self) => self.findIndex((t) => t.value === v.value) === i);
  }, []);

  useEffect(() => {
    setChoiceList((prev) => [...prev, ...choices]);
    if (choices.length === 0 && search) setChoiceList([]);
  }, [choices, setChoiceList, hasMore, search]);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      // 選択肢が閉じている場合 または クリックイベントの対象がフォームの場合は何もしない
      if (!isOpened || formRef.current?.contains(event.target as Node)) return;

      resetChoiceList();
      handleUnSelect();
      return setIsOpened(false);
    };

    window.addEventListener("click", handleClick);
    return () => window.removeEventListener("click", handleClick);
  });

  return (
    <Wrapper ref={formRef}>
      <Wrap>
        <SelectInput
          type="text"
          width={width}
          value={search}
          onChange={handleChange}
          onClick={onClickForm}
          placeholder={placeHolder}
          onBlur={() => !selected && setSearch("")}
        />
        <Icon icon={faAngleDown} onClick={onClickForm} />
      </Wrap>
      <OptionWrap width={width} hidden={!isOpened}>
        {choiceList.length > 0
          ? removeDup(choiceList).map((choice) => (
              <Option
                onMouseDown={() => onClickChoice(choice)}
                key={choice.key ?? choice.value}
                disabled={choice.disabled}
                role="button"
              >
                {choice.label}
              </Option>
            ))
          : validating && <Option disabled>読み込み中...</Option>}
        {hasMore && (
          <InView as="div" onChange={handleLoadMore}>
            <Option disabled>読み込み中...</Option>
          </InView>
        )}
        {!validating && choiceList.length === 0 && <Option disabled>検索結果が見つかりません</Option>}
      </OptionWrap>
    </Wrapper>
  );
};

export default AimoParkingSearchableSelect;
