import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import Breadcrumb from "components/Breadcrumb";
import Button from "components/Button";
import {
  Board as BaseBoard,
  TitleArea,
  FooterArea,
  ContentArea as BaseContentArea,
  FormRow,
} from "components/LayoutParts";
import Loading from "components/Loading";
import TextForm from "components/TextForm";
import UserModal, { UserModalConfig } from "components/UserModal";
import color from "constants/color";
import font from "constants/font";
import { useDeleteUser, useFindUserById, useUpdateUser } from "pages/SyncPort/User/features/api";
import { ALREADY_EXIST, ERROR, SUCCESS } from "pages/SyncPort/User/features/utils/modalTemplate";

// パーツ定義
const CancelWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;
const SaveWrap = styled(CancelWrap)`
  align-items: center;
  justify-content: flex-end;
`;
const Annotation = styled.span`
  font-size: ${font.size12};
  color: ${color.text};
  margin-right: 16px;
`;
const Label = styled.div`
  min-width: 160px;
  margin: 0 5px;
`;
const ContentArea = styled(BaseContentArea)`
  margin-bottom: auto;
`;
const Value = styled.div`
  font-size: ${font.size16};
`;
const DeleteButtonWrap = styled.div`
  margin-left: auto;
`;

const EditUser = () => {
  // フォームの値
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastNameFurigana, setLastNameFurigana] = useState("");
  const [firstNameFurigana, setFirstNameFurigana] = useState("");
  const [email, setEmail] = useState("");

  const onChangeLastName = (e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value);
  const onChangeFirstName = (e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value);
  const onChangeLastNameFurigana = (e: React.ChangeEvent<HTMLInputElement>) => setLastNameFurigana(e.target.value);
  const onChangeFirstNameFurigana = (e: React.ChangeEvent<HTMLInputElement>) => setFirstNameFurigana(e.target.value);
  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);

  const navigate = useNavigate();

  const [modalConfig, setModalConfig] = useState<UserModalConfig>({
    onClickOutside: () => {
      return;
    },
    subtitle: "完了しました",
    main: {
      buttonType: "secondary",
      onClick: () => {
        return;
      },
      label: "閉じる",
    },
  });
  const [isVisible, setIsVisible] = useState(false);
  const showModal = (modalConfig?: UserModalConfig) => {
    modalConfig != null ? setModalConfig(modalConfig) : null;
    setIsVisible(true);
  };

  const hideModal = () => {
    setIsVisible(false);
  };

  const { id } = useParams();
  const { data: userDetail } = useFindUserById({
    userId: id ?? "",
  });
  const { updateUser } = useUpdateUser();
  const onSubmit = useCallback(async () => {
    const { status } = await updateUser({
      id: userDetail?.id ?? "",
      lastName,
      firstName,
      lastNameKana: lastNameFurigana,
      firstNameKana: firstNameFurigana,
      email,
      companyId: userDetail?.propertyManageCompany.id ?? "",
    });

    if (status !== 200) {
      if (status === 409) {
        return showModal(ALREADY_EXIST(() => hideModal()));
      }
      showModal(ERROR(() => hideModal()));
      return;
    }

    showModal(
      SUCCESS(() => {
        hideModal();
        navigate(`/sync_port/company/${userDetail?.propertyManageCompany.id}/user`);
      })
    );
  }, [
    updateUser,
    userDetail?.id,
    userDetail?.propertyManageCompany.id,
    lastName,
    firstName,
    lastNameFurigana,
    firstNameFurigana,
    email,
    navigate,
  ]);

  const { deleteUser } = useDeleteUser();
  const onDeleteUser = useCallback(async () => {
    const { status } = await deleteUser({
      userId: userDetail?.id ?? "",
    });

    if (status !== 200) {
      showModal(ERROR(() => hideModal()));
      return;
    }

    showModal(
      SUCCESS(() => {
        hideModal();
        navigate(`/sync_port/company/${userDetail?.propertyManageCompany.id}`);
      })
    );
  }, [deleteUser, userDetail?.id, userDetail?.propertyManageCompany.id, navigate]);

  // バリデーション
  const isErrorLastName = lastName.length > 20 || lastName === "";
  const isErrorFirstName = firstName.length > 20 || firstName === "";
  const isErrorLastNameFurigana = !/^[ァ-ヶー]+$/.test(lastNameFurigana) || lastNameFurigana === "";
  const isErrorFirstNameFurigana = !/^[ァ-ヶー]+$/.test(firstNameFurigana) || firstNameFurigana === "";
  const isErrorEmail = !/^[\w\-._+]+@[\w\-._]+\.[A-Za-z]+$/.test(email);
  const isError =
    isErrorLastName || isErrorFirstName || isErrorLastNameFurigana || isErrorFirstNameFurigana || isErrorEmail;

  useEffect(() => {
    if (userDetail != null) {
      setLastName(userDetail.lastName);
      setFirstName(userDetail.firstName);
      setLastNameFurigana(userDetail.lastNameKana);
      setFirstNameFurigana(userDetail.firstNameKana);
      setEmail(userDetail.email);
    }
  }, [userDetail]);

  return (
    <BaseBoard>
      <TitleArea>
        <Breadcrumb
          currentPageName="ユーザを編集"
          breadcrumbItems={[
            {
              pageName:
                userDetail != null
                  ? `${userDetail?.propertyManageCompany.name} ${userDetail?.propertyManageCompany.branchName ?? ""}`
                  : "読み込み中...",
              onClick: () => navigate(`/sync_port/company/${userDetail?.propertyManageCompany.id}`),
            },
          ]}
        />
        <DeleteButtonWrap>
          <Button
            type="danger"
            onClick={() => onDeleteUser()}
            label="このユーザを削除"
            disabled={userDetail == null || userDetail.deletedAt != null}
            width="160px"
          />
        </DeleteButtonWrap>
      </TitleArea>
      {userDetail == null ? (
        <Loading />
      ) : (
        <ContentArea>
          <FormRow>
            <Label>名前</Label>
            <div style={{ marginRight: "24px" }}>姓</div>
            <TextForm
              type="text"
              width="200px"
              value={lastName}
              placeholder="例）工藤"
              required
              onChange={onChangeLastName}
              isError={isErrorLastName}
              errorMessage="姓を入力してください"
            />
            <div style={{ marginLeft: "32px", marginRight: "24px" }}>名</div>
            <TextForm
              type="text"
              width="200px"
              value={firstName}
              placeholder="例）敬三"
              required
              onChange={onChangeFirstName}
              isError={isErrorFirstName}
              errorMessage="名を入力してください"
            />
          </FormRow>
          <FormRow>
            <Label>名前（カナ）</Label>
            <div style={{ marginRight: "8px" }}>セイ</div>
            <TextForm
              type="text"
              width="200px"
              value={lastNameFurigana}
              placeholder="例）クドウ"
              required
              onChange={onChangeLastNameFurigana}
              isError={isErrorLastNameFurigana}
              errorMessage="セイを入力してください"
            />
            <div style={{ marginLeft: "32px", marginRight: "8px" }}>メイ</div>
            <TextForm
              type="text"
              width="200px"
              value={firstNameFurigana}
              placeholder="例）ケイゾウ"
              required
              onChange={onChangeFirstNameFurigana}
              isError={isErrorFirstNameFurigana}
              errorMessage="メイを入力してください"
            />
          </FormRow>
          <FormRow>
            <Label>メールアドレス</Label>
            <TextForm
              type="text"
              width="832px"
              value={email}
              placeholder="例）info@landit.co.jp"
              required
              onChange={onChangeEmail}
              errorMessage="メールアドレスを正しく入力してください"
              isError={isErrorEmail}
            />
          </FormRow>
          <FormRow>
            <Label>ユーザID</Label>
            <Value>{userDetail.id}</Value>
          </FormRow>
        </ContentArea>
      )}
      <FooterArea>
        <CancelWrap>
          <Button type="secondary" onClick={() => navigate("/sync_port/user")} label="キャンセル" width="160px" />
        </CancelWrap>
        <SaveWrap>
          <Annotation>保存するまで変更内容は反映されません</Annotation>
          <Button type="primary" onClick={() => onSubmit()} label="変更内容を保存" disabled={isError} width="160px" />
        </SaveWrap>
      </FooterArea>
      <UserModal isVisible={isVisible} config={modalConfig} />
    </BaseBoard>
  );
};

export default EditUser;
