import { useCallback } from "react";
import { useParams } from "react-router-dom";

import { useSwr } from "hooks/syncPort/useSwr";
import { useSyncPortClient } from "hooks/syncPort/useSyncPortClient";
import { buildSortKey } from "pages/SyncPort/Company/features/utils/buildSortkey";
import { formatQueryParams } from "utils/stringUtils";
import { extractPathFromUrl, getExtension, uploadFileCloudStorage } from "utils/syncPortUploadFileUtil";

export type SyncPortCompanyPropertySortKey =
  | "name"
  | "address"
  | "controlNumber"
  | "personInCharge"
  | "nmberOfFree"
  | "nmberOfFreeSchedule"
  | null;

export const useFindProperties = ({
  searchKeyword,
  after,
  before,
  first,
  last,
  sortOption,
}: {
  searchKeyword: string;
  after?: string;
  before?: string;
  first?: number;
  last?: number;
  sortOption?: SortOption<SyncPortCompanyPropertySortKey>;
}) => {
  const { id: companyId } = useParams();

  const { data, error } = useSwr<SyncPortPropertiesResponse>(
    `/property?${formatQueryParams({
      keyword: searchKeyword,
      after,
      before,
      first,
      last,
      companyId,
      order: buildSortKey<SyncPortCompanyPropertySortKey>(sortOption),
    })}`
  );

  return { data: data?.data, pageInfo: data?.pageInfo, error };
};

export const useFindPropertyById = ({ propertyId }: { propertyId?: string }) => {
  const { data, error, mutate } = useSwr<SyncPortProperty>(propertyId != null ? `/property/${propertyId}` : null);

  return {
    data,
    error,
    mutate,
  };
};

type UpdateFor = "property" | "spaceGroup";

const switchUrlAndPath = ({
  type,
}: {
  type: UpdateFor;
}): { path: string; genSignedUrlEndPoint: string; updateEndPoint: (id: string) => string } => {
  return type === "property"
    ? {
        path: "property/",
        genSignedUrlEndPoint: "/property/image/signed-url/generate",
        updateEndPoint: (propertyId: string) => `/property/${propertyId}/update-images`,
      }
    : {
        path: "spaceGroup/",
        genSignedUrlEndPoint: "/space-group/parking-guide/signed-url/generate",
        updateEndPoint: (spaceGroupId: string) => `/space-group/${spaceGroupId}/update-parking-guide`,
      };
};

export const useUpdateSyncPortImage = (type: UpdateFor) => {
  const client = useSyncPortClient();

  const switchByType = switchUrlAndPath({ type });

  const generateSignedUrl = useCallback(
    async ({ fileName, uuid }: { fileName: string; uuid: string }) => {
      const { data } = await client
        .client<{ url: string }>({
          method: "POST",
          url: switchByType.genSignedUrlEndPoint,
          data: {
            fileName: `${switchByType.path}${uuid}.${getExtension(fileName)}`,
          },
        })
        .catch((e) => {
          console.error(JSON.stringify(e));
          throw new Error(e);
        });
      return data.url;
    },
    [client, switchByType]
  );

  const uploadFileToCloudStorage = useCallback(
    async ({ file, signedUrl }: { signedUrl: string; file: File }): Promise<void> => {
      return await uploadFileCloudStorage({
        signedUrl,
        file,
      }).catch((e) => {
        console.error(e);
        throw new Error(e);
      });
    },
    []
  );

  const uploadImages = useCallback(
    async ({ images }: { images: File[] }) => {
      return Promise.all(
        images.map(async (image) => {
          const uuid = `${crypto.randomUUID()}`;
          const signedUrl = await generateSignedUrl({ fileName: image.name, uuid });
          await uploadFileToCloudStorage({ signedUrl, file: image });
          return `${switchByType.path}${uuid}.${getExtension(image.name)}`;
        })
      ).catch((e) => {
        console.error(e);
        throw new Error(e);
      });
    },
    [generateSignedUrl, uploadFileToCloudStorage, switchByType]
  );

  const updateImages = useCallback(
    async ({ newImages, id, currentImageUrls }: { newImages: File[]; id: string; currentImageUrls: string[] }) => {
      const paths = await uploadImages({ images: newImages });
      const updatedImages = {
        images: [
          ...currentImageUrls.map((url) => ({ storagePath: extractPathFromUrl(url) })),
          ...paths.map((path) => ({
            storagePath: path,
          })),
        ],
      };
      const { data } = await client
        .client<string>({
          method: "PUT",
          url: switchByType.updateEndPoint(id),
          data: updatedImages,
        })
        .catch((e) => {
          console.error(JSON.stringify(e));
          throw new Error(e);
        });
      return data;
    },
    [client, uploadImages, switchByType]
  );

  return { updateImages };
};

export const useFindSpaceGroupById = ({ spaceGroupId }: { spaceGroupId?: string }) => {
  const { data, mutate } = useSwr<SyncPortSpaceGroupDetail>(
    spaceGroupId != null ? `/space-group/${spaceGroupId}` : null
  );

  return {
    data,
    mutate,
  };
};
