import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import TextButton from "components/TextButton";

const Wrap = styled.div<{ type: "list" | "detail" }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: ${({ type }) => (type === "list" ? "10px 0 10px 4px" : "0")};
`;

const VendorList = ({ vendors, type = "list" }: { vendors: AimoParkingVendors; type?: "list" | "detail" }) => {
  const navigate = useNavigate();
  const onClickRow = (companyId: string) => navigate(`/aimo_parking/company/${companyId}`);

  if (!vendors) return null;
  return (
    <Wrap type={type}>
      {vendors.map((vendor, index) => (
        <TextButton key={`company_${index}`} label={vendor.name} onClick={() => onClickRow(vendor.id)} />
      ))}
    </Wrap>
  );
};

export default VendorList;
