import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import Button from "components/Button";
import { ContentArea as BaseContextArea } from "components/LayoutParts";
import color from "constants/color";
import font from "constants/font";
import { useFindCompanyById } from "pages/AimoParking/Company/api";
import VendorList from "pages/AimoParking/component/VendorList";
import { detectIsVendor } from "pages/AimoParking/utils/detectIsVendorUtil";

const CompanyTitle = styled.div`
  font-size: ${font.size24};
`;

const CompanyTitleWrap = styled.div`
  font-size: ${font.size24};
  display: flex;
  justify-content: space-between;
  padding-bottom: 21px;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ContentArea = styled(BaseContextArea)`
  display: flex;
  flex-direction: row;
  gap: 32px;
  flex: 1;
  padding: 32px;
`;

const FormWrap = styled.div`
  flex: 1;
`;

export const FormRow = styled.div<{ minHeight?: string; hideBorder?: boolean }>`
  width: 98%;
  padding: 16px 0px;
  margin: 0 16px;
  border-bottom: solid 1px ${color.border};
  border-bottom: ${({ hideBorder }) => (hideBorder ? "none" : `auto`)};
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: ${(props) => props.minHeight};
`;

const Label = styled.div`
  min-width: 200px;
  margin: 0 5px;
  margin-right: 18px;
`;
const Value = styled.div`
  font-size: ${font.size16};
`;

const ImageWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageArea = styled.img`
  width: 130px;
  object-fit: cover;
`;

const SbPaymentTitle = styled.div`
  font-size: ${font.size16};
  color: ${color.text};
  margin: 32px 0 16px 20px;
`;

// パーツ定義
const PropertyDetail = () => {
  const navigate = useNavigate();
  const { id: companyId } = useParams();
  const { data: company } = useFindCompanyById({ id: companyId as string });

  const isVendor = detectIsVendor(company?.vendors);
  if (company == null) return null;

  return (
    <>
      <CompanyTitleWrap>
        <CompanyTitle>会社情報</CompanyTitle>
        <Button
          type="secondary"
          onClick={() => navigate(`/aimo_parking/company/${company?.id}/edit`)}
          label="会社情報を編集"
          width="160px"
        />
      </CompanyTitleWrap>
      <Wrap>
        <ContentArea>
          <ImageWrap>
            <ImageArea src={company.logo} />
          </ImageWrap>
          <FormWrap>
            <FormRow>
              <Label>会社名</Label>
              <Value>{company.name}</Value>
            </FormRow>
            <FormRow>
              <Label>企業インボイス番号</Label>
              <Value>{company.invoiceNumber}</Value>
            </FormRow>
            <FormRow hideBorder={!isVendor}>
              <Label>販売先企業</Label>
              <Value>
                <VendorList vendors={company.vendors} type="detail" />
              </Value>
            </FormRow>
            {isVendor && (
              <>
                {/* TODO sbペイメント情報についてはデザインがわかり次第に修正する */}
                <SbPaymentTitle>SBペイメント情報</SbPaymentTitle>
                <FormRow>
                  <Label>企業識別ID</Label>
                  <Value>{company.paymentInfo?.merchantId}</Value>
                </FormRow>
                <FormRow>
                  <Label>サービス識別ID</Label>
                  <Value>{company.paymentInfo?.serviceId}</Value>
                </FormRow>
                <FormRow hideBorder>
                  <Label>ハッシュキー</Label>
                  <Value>{company.paymentInfo?.hashKey}</Value>
                </FormRow>
              </>
            )}
          </FormWrap>
        </ContentArea>
        <div />
      </Wrap>
    </>
  );
};

export default PropertyDetail;
