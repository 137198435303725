import { v4 as uuidGen } from "uuid";

/**
 * ランダムなファイル名を生成する
 */
export const generateRandomFileName = (file: File): string => `${uuidGen()}.${file.type.split("/")[1]}`;

/**
 * gs://bucketName/fileName.png を公開URLに変換する
 */
export const convertToPublicURL = (fileName: string): string =>
  `https://storage.googleapis.com/${process.env.REACT_APP_PIT_PORT_ASSETS_BUCKET}/${fileName}`;

/**
 * サインされたURLからGCSのパスを取得する
 * @example
 * https://storage.googleapis.com/aimo-dev-asia-northeast1-api-images/company/images.png?X-Go
 * getGCSPathFromSignedURL(signedURL) => gs://aimo-dev-asia-northeast1-api-images/company/images.png
 */
export const getGCSPathFromSignedURL = (signedURL: string): string => {
  const toRemove = "https://storage.googleapis.com/";
  // ?とその後ろの文字列を削除
  const path = signedURL.split("?")[0];
  return `gs://${path.replace(toRemove, "")}`;
};
