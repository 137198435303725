import { useState, useCallback } from "react";
import styled from "styled-components";

import color from "constants/color";

// パーツ定義
const BaseButton = styled.button<{
  width: string;
  height: string;
  isSelected: boolean;
  disabled: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 7px 16px;
  margin: 2px;

  width: ${(props) => props.width};
  height: ${(props) => props.height};

  background: ${(props) => (props.disabled ? color.text.disabled : props.isSelected ? color.text.link : color.white)};
  border: 1px solid ${(props) => (props.disabled ? color.text.disabled : color.text.link)};
  border-radius: 10000px;
  color: ${(props) => (props.disabled ? color.white : props.isSelected ? color.white : color.text.link)};
  font-weight: "normal";

  &:hover {
    cursor: pointer;
    background: ${(props) =>
      props.disabled ? color.text.disabled : props.isSelected ? color.text.link : color.text.linkLight};
  }
`;

// 本体
const RoundedButton = ({
  label,
  isSelected,
  onClick,
  width = "100px",
  height = "31px",
  disabled = false,
}: {
  label: string;
  isSelected: boolean;
  onClick: () => Promise<void> | void;
  width?: string;
  height?: string;
  disabled?: boolean;
}) => {
  const [isBusy, setIsBusy] = useState(false);

  const handler = useCallback(async () => {
    if (!disabled && !isBusy) {
      setIsBusy(true);
      await onClick();
      setIsBusy(false);
    }
  }, [disabled, isBusy, onClick]);

  return (
    <BaseButton isSelected={isSelected} width={width} height={height} disabled={disabled || isBusy} onClick={handler}>
      {label}
    </BaseButton>
  );
};

export default RoundedButton;
