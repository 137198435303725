import React from "react";
import styled from "styled-components";

import color from "constants/color";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3列の設定 */
  grid-gap: 10px; /* 画像間の隙間 */
`;

const ContentContainer = styled.div`
  background-color: ${color.background}; /* 白色背景 */
  padding-top: 90%; /* 10:9のアスペクト比 */
  position: relative; /* 子要素の絶対配置の基点 */
  width: 100%; /* コンテナの幅を親に合わせる */
  height: 0; /* コンテナの高さを padding-top で制御 */
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* 画像をコンテナにフィットさせるが、アスペクト比を保持 */
`;

// ImageGrid コンポーネント
const PropertyImagesPreview = ({ images }: { images: SyncPortPropertyImage[] }) => {
  // 画像がない場合のプレースホルダーを生成する関数
  const renderPlaceholders = () => {
    const placeholders = [];
    for (let i = 0; i < 6; i++) {
      placeholders.push(<ContentContainer key={`placeholder-${i}`} />);
    }
    return placeholders;
  };

  return (
    <Grid>
      {images.length > 0
        ? images.map((value, index) => (
            <ContentContainer key={index}>
              <Image src={value.url} alt={value.name} />
            </ContentContainer>
          ))
        : renderPlaceholders()}
    </Grid>
  );
};

export default PropertyImagesPreview;
