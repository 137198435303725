export const PARKING_AVAILABILITY = {
  FULL: {
    key: "FULL",
    text: "満車",
  },
  EMPTY: {
    key: "EMPTY",
    text: "空車",
  },
  AVAILABLE_SCHEDULE: {
    key: "AVAILABLE_SCHEDULE",
    text: "空き予定",
  },
};
