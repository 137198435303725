import queryString from "query-string";
import React, { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import Breadcrumb from "components/Breadcrumb";
import Button from "components/Button";
import {
  Board as BaseBoard,
  TitleArea,
  FooterArea,
  ContentArea as BaseContentArea,
  FormRow,
} from "components/LayoutParts";
import TextForm from "components/TextForm";
import UserModal, { UserModalConfig } from "components/UserModal";
import color from "constants/color";
import font from "constants/font";
import { useFindCompanyById } from "pages/SyncPort/Company/features/api";
import { useCreateUser } from "pages/SyncPort/User/features/api";
import { ALREADY_EXIST, ERROR, SUCCESS } from "pages/SyncPort/User/features/utils/modalTemplate";

// パーツ定義
const CancelWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;
const SaveWrap = styled(CancelWrap)`
  align-items: center;
  justify-content: flex-end;
`;
const Annotation = styled.span`
  font-size: ${font.size12};
  color: ${color.text};
  margin-right: 16px;
`;
const Label = styled.div`
  min-width: 160px;
  margin: 0 5px;
`;
const ContentArea = styled(BaseContentArea)`
  margin-bottom: auto;
`;

const CreateUser = () => {
  // フォームの値
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastNameKana, setLastNameKana] = useState("");
  const [firstNameKana, setFirstNameKana] = useState("");
  const [email, setEmail] = useState("");

  const onChangeLastName = (e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value);
  const onChangeFirstName = (e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value);
  const onChangeLastNameFurigana = (e: React.ChangeEvent<HTMLInputElement>) => setLastNameKana(e.target.value);
  const onChangeFirstNameFurigana = (e: React.ChangeEvent<HTMLInputElement>) => setFirstNameKana(e.target.value);
  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);

  const navigate = useNavigate();

  const [modalConfig, setModalConfig] = useState<UserModalConfig>({
    onClickOutside: () => {
      return;
    },
    subtitle: "完了しました",
    main: {
      buttonType: "secondary",
      onClick: () => {
        return;
      },
      label: "閉じる",
    },
  });
  const [isVisible, setIsVisible] = useState(false);
  const showModal = (modalConfig?: UserModalConfig) => {
    modalConfig != null ? setModalConfig(modalConfig) : null;
    setIsVisible(true);
  };

  const hideModal = () => {
    setIsVisible(false);
  };

  // クエリパラメータから会社IDを取得
  const search = useLocation().search;
  const query = queryString.parse(search);
  const companyId = query["companyId"];

  const { data: companyDetail } = useFindCompanyById({
    companyId: typeof companyId == "string" ? companyId : undefined,
  });
  const { createUser } = useCreateUser();
  const onSubmit = useCallback(async () => {
    const { status } = await createUser({
      lastName,
      firstName,
      lastNameKana: lastNameKana,
      firstNameKana: firstNameKana,
      email,
      companyId: typeof companyId == "string" ? companyId : "",
    });

    if (status !== 201) {
      if (status === 409) {
        return showModal(ALREADY_EXIST(() => hideModal()));
      }
      showModal(ERROR(() => hideModal()));
      return;
    }

    showModal(
      SUCCESS(() => {
        hideModal();
        navigate(`/sync_port/company/${companyId}`);
      })
    );
  }, [createUser, lastName, firstName, lastNameKana, firstNameKana, email, navigate, companyId]);

  // バリデーション
  const isErrorLastName = lastName.length > 20 || lastName === "";
  const isErrorFirstName = firstName.length > 20 || firstName === "";
  const isErrorLastNameFurigana = !/^[ァ-ヶー]+$/.test(lastNameKana) || lastNameKana === "";
  const isErrorFirstNameFurigana = !/^[ァ-ヶー]+$/.test(firstNameKana) || firstNameKana === "";
  const isErrorEmail = !/^[\w\-._+]+@[\w\-._]+\.[A-Za-z]+$/.test(email);
  const isError =
    isErrorLastName || isErrorFirstName || isErrorLastNameFurigana || isErrorFirstNameFurigana || isErrorEmail;

  return (
    <BaseBoard>
      <TitleArea>
        <Breadcrumb
          currentPageName="ユーザを追加"
          breadcrumbItems={[
            {
              pageName: companyDetail?.name ?? "読み込み中...",
              onClick: () => navigate(`/sync_port/company/${companyId}`),
            },
          ]}
        />
      </TitleArea>
      <ContentArea>
        <FormRow>
          <Label>名前</Label>
          <div style={{ marginRight: "24px" }}>姓</div>
          <TextForm
            type="text"
            width="200px"
            value={lastName}
            placeholder="例）工藤"
            required
            onChange={onChangeLastName}
            isError={isErrorLastName}
            errorMessage="姓を入力してください"
          />
          <div style={{ marginLeft: "32px", marginRight: "24px" }}>名</div>
          <TextForm
            type="text"
            width="200px"
            value={firstName}
            placeholder="例）敬三"
            required
            onChange={onChangeFirstName}
            isError={isErrorFirstName}
            errorMessage="名を入力してください"
          />
        </FormRow>
        <FormRow>
          <Label>名前（カナ）</Label>
          <div style={{ marginRight: "8px" }}>セイ</div>
          <TextForm
            type="text"
            width="200px"
            value={lastNameKana}
            placeholder="例）クドウ"
            required
            onChange={onChangeLastNameFurigana}
            isError={isErrorLastNameFurigana}
            errorMessage="セイを入力してください"
          />
          <div style={{ marginLeft: "32px", marginRight: "8px" }}>メイ</div>
          <TextForm
            type="text"
            width="200px"
            value={firstNameKana}
            placeholder="例）ケイゾウ"
            required
            onChange={onChangeFirstNameFurigana}
            isError={isErrorFirstNameFurigana}
            errorMessage="メイを入力してください"
          />
        </FormRow>
        <FormRow>
          <Label>メールアドレス</Label>
          <TextForm
            type="text"
            width="832px"
            value={email}
            placeholder="例）info@landit.co.jp"
            required
            onChange={onChangeEmail}
            errorMessage="メールアドレスを正しく入力してください"
            isError={isErrorEmail}
          />
        </FormRow>
      </ContentArea>
      <FooterArea>
        <CancelWrap>
          <Button type="secondary" onClick={() => navigate("/sync_port/company")} label="キャンセル" width="160px" />
        </CancelWrap>
        <SaveWrap>
          <Annotation>追加するまで変更内容は反映されません</Annotation>
          <Button type="primary" onClick={() => onSubmit()} label="このユーザを追加" disabled={isError} width="160px" />
        </SaveWrap>
      </FooterArea>
      <UserModal isVisible={isVisible} config={modalConfig} />
    </BaseBoard>
  );
};

export default CreateUser;
