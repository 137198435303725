export const AIMO_PARKING_SYSTEM_NOTIFICATION_DESTINATION_NUMBER = {
  AIMO_PARKING: 0,
  PARK_FLOW: 1,
} as const;

export const AIMO_PARKING_SYSTEM_NOTIFICATION_DESTINATION = {
  AIMO_PARKING: "Aimo Parking",
  PARK_FLOW: "PARK FLOW",
} as const;

export type AimoParkingSystemNotificationDestination =
  typeof AIMO_PARKING_SYSTEM_NOTIFICATION_DESTINATION_NUMBER[keyof typeof AIMO_PARKING_SYSTEM_NOTIFICATION_DESTINATION_NUMBER];

export const systemNotificationDisplaySwitcher = (destination: AimoParkingSystemNotificationDestination) => {
  switch (destination) {
    case AIMO_PARKING_SYSTEM_NOTIFICATION_DESTINATION_NUMBER.AIMO_PARKING:
      return AIMO_PARKING_SYSTEM_NOTIFICATION_DESTINATION.AIMO_PARKING;
    case AIMO_PARKING_SYSTEM_NOTIFICATION_DESTINATION_NUMBER.PARK_FLOW:
      return AIMO_PARKING_SYSTEM_NOTIFICATION_DESTINATION.PARK_FLOW;
  }
};
