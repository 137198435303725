import { useCallback } from "react";

import { useAimoParkingClient } from "hooks/aimoParking/useAimoParkingClient";
import { useSwr } from "hooks/aimoParking/useSwr";
import { AIMO_PARKING_PAGINATION_DEFAULT_SIZE } from "pages/AimoParking/constants/pagination";
import { formatQueryParams } from "utils/stringUtils";

export const useFindAllUser = ({
  keyword,
  order,
  orderBy,
  pageIndex,
  pageSize = AIMO_PARKING_PAGINATION_DEFAULT_SIZE,
  role,
  companyId,
}: {
  pageIndex?: number;
  orderBy?: string;
  order?: string;
  keyword?: string;
  pageSize?: number;
  role?: AimoParkingUserRole;
  companyId?: string;
}) => {
  const { data, error } = useSwr<AimoParkingUsersResponse>(
    `/user?${formatQueryParams({
      keyword,
      pageSize,
      pageIndex,
      role,
      orderBy,
      order,
      companyId,
    })}`
  );

  return {
    data: data?.data,
    error,
    pageInfo: data?.pagination && {
      hasMore: data.pagination.hasMore,
      pageIndex: data.pagination.pageIndex,
      totalPages: data.pagination.totalPages,
      totalItems: data.pagination.totalItems,
    },
  };
};

export const useFindUserById = ({ userId }: { userId: string }) => {
  const { data, error } = useSwr<AimoParkingUser>(`/user/${userId}`);

  return { data, error };
};

export const useCreateUser = () => {
  const client = useAimoParkingClient();

  const create = useCallback(
    async (data: AimoParkingCreateUserParameter) => {
      return await client.execute({
        method: "POST",
        url: "/user",
        data,
      });
    },
    [client]
  );

  return { create };
};

export const useUpdateUser = () => {
  const client = useAimoParkingClient();

  const update = useCallback(
    async (userId: string, data: AimoParkingUpdateUserParameter) => {
      return await client.execute({
        method: "PUT",
        url: `/user/${userId}`,
        data,
      });
    },
    [client]
  );

  return { update };
};

export const useRemoveUser = () => {
  const client = useAimoParkingClient();

  const remove = useCallback(
    async (userId: string) => {
      return await client.execute({
        method: "DELETE",
        url: `/user/${userId}`,
      });
    },
    [client]
  );

  return { remove };
};
