/**
 * 販売元企業か販売先起業家をVendor配列より判定するUtil関数
 * vendorsが配列の場合には販売元企業と判定する
 * vendors===nullの場合には販売先企業と判定する
 * @param vendors
 * @returns
 * @example
 * const vendors = null
 * const isVendor = detectIsVendor(vendors)
 * console.log(isVendor) // true
 *
 * const vendors = []
 * const isVendor = detectIsVendor(vendors)
 * console.log(isVendor) // false
 *
 * const vendors = [{id: "abcdefg", name: "vendor"}]
 * const isVendor = detectIsVendor(vendors)
 * console.log(isVendor) // false
 */
export const detectIsVendor = (vendors?: AimoParkingVendors) => {
  if (vendors === null) return true;
  return false;
};
