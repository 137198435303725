import React from "react";
import styled from "styled-components";

import { ContentArea as BaseContextArea } from "components/LayoutParts";
import PublicTag from "components/PublicTag";
import color from "constants/color";
import font from "constants/font";

const Label = styled.div`
  min-width: 160px;
  margin: 0 5px;
`;
const Value = styled.div`
  font-size: ${font.size16};
`;
const FormWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
`;
const Wrap = styled.div`
  display: flex;
  flex: 1;
  background-color: ${color.white};
  border-bottom: 1px solid ${color.border};
`;
const ContentArea = styled(BaseContextArea)`
  flex-direction: row;
  margin-bottom: 0;
  height: 264px;
  display: flex;
  flex: 1;
  border-bottom: 0;
`;
const FormRowWrap = styled.div`
  display: flex;
  flex-direction: row;
`;
export const FormRow = styled.div<{ minHeight?: string; hideBorder?: boolean }>`
  width: 100%;
  padding: 14px 0px;
  margin: 0 10px;
  border-bottom: solid 1px ${color.border};
  border-bottom: ${({ hideBorder }) => (hideBorder ? "none" : `auto`)};
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: ${(props) => props.minHeight};
`;

const PropertyInfo = ({ propertyDetail }: { propertyDetail: SyncPortProperty }) => {
  return (
    <Wrap>
      <ContentArea>
        <FormWrap>
          <FormRowWrap>
            <FormRow>
              <Label>所在地</Label>
              <Value>{propertyDetail.address}</Value>
            </FormRow>
          </FormRowWrap>
          <FormRowWrap>
            <FormRow>
              <Label>物件No.</Label>
              <Value>{propertyDetail.controlNumber}</Value>
            </FormRow>
          </FormRowWrap>
          <FormRowWrap>
            <FormRow>
              <Label>担当者</Label>
              <Value>{propertyDetail.officer.name}</Value>
            </FormRow>
          </FormRowWrap>
          <FormRowWrap>
            <FormRow>
              <Label>備考</Label>
              <Value>{propertyDetail.remarks}</Value>
            </FormRow>
          </FormRowWrap>
          <FormRowWrap>
            <FormRow hideBorder>
              <Label>公開設定</Label>
              <PublicTag isPublic={propertyDetail.isPublic} />
            </FormRow>
          </FormRowWrap>
        </FormWrap>
      </ContentArea>
    </Wrap>
  );
};

export default PropertyInfo;
