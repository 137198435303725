import { useNavigate } from "react-router-dom";

import { FieldName, FieldValueWithBorder, Heading, Row, Table } from "components/LayoutParts";
import Loading from "components/Loading";
import PaginationForGraphql from "components/PaginationForGraphql";

const companyTableColumn = [
  {
    label: "名前",
    flex: 1,
  },
  {
    label: "メールアドレス",
    flex: 1,
  },
  {
    label: "会社名・支店/支店名",
    flex: 1,
  },
] as const;

const DataTable = ({
  userList,
  totalPages,
  pageIndex,
  setPageIndex,
  pageInfo,
  setPagination,
}: {
  userList?: SyncPortUser[];
  totalPages: number;
  pageIndex: number;
  setPageIndex: React.Dispatch<React.SetStateAction<number>>;
  pageInfo?: GraphqlPageInfo;
  setPagination: React.Dispatch<React.SetStateAction<GraphqlPageOption>>;
}) => {
  const navigate = useNavigate();
  const onClickRow = (id: string) => navigate(`/sync_port/user/${id}/edit`);

  return (
    <>
      <Table>
        <Heading>
          {companyTableColumn.map((column) => (
            <FieldName
              key={column.label}
              flex={column.flex}
              lastColumn={column.label === companyTableColumn[companyTableColumn.length - 1].label}
            >
              {column.label}
            </FieldName>
          ))}
        </Heading>
        {userList == null ? (
          <Loading />
        ) : userList.length > 0 ? (
          userList.map((user) => (
            <Row key={user.id} onClick={() => onClickRow(user.id)}>
              <FieldValueWithBorder
                flex={companyTableColumn[0].flex}
              >{`${user.lastName} ${user.firstName}`}</FieldValueWithBorder>
              <FieldValueWithBorder flex={companyTableColumn[1].flex}>{user.email}</FieldValueWithBorder>
              <FieldValueWithBorder flex={companyTableColumn[2].flex} lastColumn>
                {user.propertyManageCompany.name} {user.propertyManageCompany.branchName}
              </FieldValueWithBorder>
            </Row>
          ))
        ) : (
          <p>該当するユーザが見つかりませんでした</p>
        )}
      </Table>
      <PaginationForGraphql
        totalPageCount={totalPages}
        currentPageNumber={pageIndex}
        setCurrentPageNumber={setPageIndex}
        setPagination={setPagination}
        startCursor={pageInfo?.startCursor ?? ""}
        endCursor={pageInfo?.endCursor ?? ""}
        hasNextPage={pageInfo?.hasNextPage ?? false}
        hasPreviousPage={pageInfo?.hasPreviousPage ?? false}
      />
    </>
  );
};

export default DataTable;
