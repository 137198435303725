import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Button from "components/Button";
import { TitleArea, FooterArea, ContentArea as BaseContentArea, FormRow, Board } from "components/LayoutParts";
import TextForm from "components/TextForm";
import UploadForm from "components/UploadForm";
import UserModal, { UserModalConfig } from "components/UserModal";
import color from "constants/color";
import font from "constants/font";
import { useDebounce } from "hooks/useDebounce";
import { useUploadAimoParkingFile } from "pages/AimoParking/api";
import { useCreateCompany, useFindAllCompany } from "pages/AimoParking/Company/api";
import AimoParkingSearchableSelect from "pages/AimoParking/component/AimoParkingSearchableSelect";
import { FAILED_MODAL_CONFIG, INITIAL_MODAL_CONFIG, SUCCEED_MODAL_CONFIG } from "pages/AimoParking/modalTemplate";

// パーツ定義
const CancelWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;
const Wrap = styled.div`
  margin-top: 10px;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  flex: 1;
`;
const ContentWrap = styled.div`
  gap: 16px;
`;
const ContentArea = styled(BaseContentArea)`
  display: flex;
`;
const SaveWrap = styled(CancelWrap)`
  align-items: center;
  justify-content: flex-end;
`;
const Annotation = styled.span`
  font-size: ${font.size12};
  color: ${color.text};
  margin-right: 16px;
`;
const Label = styled.div<{ top?: boolean }>`
  min-width: 200px;
  margin: 0 5px;
  padding-right: 18px;
  align-self: ${({ top }) => (top ? "flex-start" : "center")};
  margin-top: ${({ top }) => (top ? "14px" : "0")};
`;
const CurrentPageWrap = styled.p`
  font-size: ${font.size24};
  font-weight: bold;
  line-height: 40px;
`;
const UploadWrap = styled.div`
  width: 520px;
`;

const SbPaymentTitle = styled.div`
  font-size: ${font.size16};
  color: ${color.text};
  margin: 32px 0 16px 4px;
`;

const CreateCompany = () => {
  // フォームの値
  const [name, setName] = useState("");
  const [images, setImages] = useState<File[]>([]);
  const [merchantId, setMerchantId] = useState("");
  const [serviceId, setServiceId] = useState("");
  const [hashKey, setHashKey] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [parentCompany, setParentCompany] = useState<Choice | null>(null);
  const [modalConfig, setModalConfig] = useState<UserModalConfig>(INITIAL_MODAL_CONFIG);
  const [isVisible, setIsVisible] = useState(false);
  const [searchWord, setSearchWord] = useState("");
  const [pageIndex, setPageIndex] = useState(1);

  const navigate = useNavigate();
  const { create } = useCreateCompany();
  const { debouncedValue: debouncedSearchKeyword } = useDebounce(searchWord, 500);
  const { uploadFiles } = useUploadAimoParkingFile({ uploadDestination: "company" });
  const {
    data: companyList,
    isValidating,
    pageInfo,
  } = useFindAllCompany({ keyword: debouncedSearchKeyword, pageIndex, pageSize: 50 });

  const changeName = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value);
  const changeMerchantId = (e: React.ChangeEvent<HTMLInputElement>) => setMerchantId(e.target.value);
  const changeServiceId = (e: React.ChangeEvent<HTMLInputElement>) => setServiceId(e.target.value);
  const changeHashKey = (e: React.ChangeEvent<HTMLInputElement>) => setHashKey(e.target.value);
  const changeInvoiceNumber = (e: React.ChangeEvent<HTMLInputElement>) => setInvoiceNumber(e.target.value);

  // バリデーション
  const isVendor = !!parentCompany?.value;
  const isErrorName = name === "";
  const isErrorLogo = images.length === 0;
  const isErrorMerchantId = isVendor && merchantId.length === 0;
  const isErrorServiceId = isVendor && serviceId.length === 0;
  const isErrorHashKey = isVendor && hashKey.length === 0;
  const isErrorInvoiceNumber = !/^T\d{13}$/.test(invoiceNumber);
  const isSubmit =
    isErrorName || isErrorInvoiceNumber || isErrorLogo || isErrorMerchantId || isErrorServiceId || isErrorHashKey;

  const onSubmit = useCallback(async () => {
    const uploadImageResponse = await uploadFiles({ files: images }).catch((e) => {
      setModalConfig(FAILED_MODAL_CONFIG({ onClose: () => setIsVisible(false) }));
      setIsVisible(true);
      throw e;
    });

    if (!uploadImageResponse) return;

    const response = await create({
      invoiceNumber,
      logo: uploadImageResponse[0],
      name,
      paymentInfo: {
        hashKey,
        merchantId,
        serviceId,
      },
      parentCompanyId: parentCompany?.value ?? undefined,
    }).catch((e) => {
      setModalConfig(FAILED_MODAL_CONFIG({ onClose: () => setIsVisible(false) }));
      setIsVisible(true);
      throw e;
    });

    if (response.status === 201) {
      setModalConfig(
        SUCCEED_MODAL_CONFIG({ message: "追加しました", onClose: () => navigate("/aimo_parking/company") })
      );
      setIsVisible(true);
    }
  }, [
    create,
    invoiceNumber,
    name,
    parentCompany?.value,
    navigate,
    images,
    uploadFiles,
    hashKey,
    merchantId,
    serviceId,
  ]);

  return (
    <Board>
      <TitleArea>
        <CurrentPageWrap>管理会社を追加</CurrentPageWrap>
      </TitleArea>
      <Wrap>
        <ContentWrap>
          <ContentArea>
            <FormRow>
              <Label>会社名</Label>
              <TextForm
                type="text"
                width="832px"
                value={name}
                placeholder="例）ランドイット不動産"
                required
                onChange={changeName}
                errorMessage="会社名を入力してください"
                isError={isErrorName}
              />
            </FormRow>
            <FormRow>
              <Label top>ロゴ</Label>
              <UploadWrap>
                <UploadForm unUploadedImages={images} setUnUploadedImages={setImages} single />
              </UploadWrap>
            </FormRow>
            <FormRow>
              <Label>企業インボイス番号</Label>
              <TextForm
                type="text"
                width="350px"
                value={invoiceNumber}
                placeholder="例）T0123456789012"
                required
                onChange={changeInvoiceNumber}
                errorMessage="T以後13桁の英数字で入力して下さい"
                isError={isErrorInvoiceNumber}
              />
            </FormRow>
            {isVendor && (
              <>
                {/* TODO sbペイメント情報についてはデザインがわかり次第に修正する */}
                <SbPaymentTitle>SBペイメント情報</SbPaymentTitle>
                <FormRow>
                  <Label>企業識別ID</Label>
                  <TextForm
                    type="text"
                    width="350px"
                    value={merchantId}
                    placeholder="例）h8Xz3bW9v2F7Yk4mNpQ6L1VrTs5A8G0d"
                    required
                    onChange={changeMerchantId}
                    errorMessage="企業識別IDを入力してください"
                    isError={isErrorMerchantId}
                  />
                </FormRow>
                <FormRow>
                  <Label>サービス識別ID</Label>
                  <TextForm
                    type="text"
                    width="350px"
                    value={serviceId}
                    placeholder="例）h8Xz3bW9v2F7Yk4mNpQ6L1VrTs5A8G0d"
                    required
                    onChange={changeServiceId}
                    errorMessage="サービス識別IDを入力してください"
                    isError={isErrorServiceId}
                  />
                </FormRow>
                <FormRow>
                  <Label>ハッシュキー</Label>
                  <TextForm
                    type="text"
                    width="350px"
                    value={hashKey}
                    placeholder="例）h8Xz3bW9v2F7Yk4mNpQ6L1VrTs5A8G0d"
                    required
                    onChange={changeHashKey}
                    errorMessage="ハッシュキーを入力してください"
                    isError={isErrorHashKey}
                  />
                </FormRow>
              </>
            )}
          </ContentArea>
          <ContentArea>
            <FormRow>
              <Label>販売元企業</Label>
              <AimoParkingSearchableSelect
                choices={companyList?.map((company) => ({ label: company.name, value: company.id })) ?? []}
                onChange={(v) => setParentCompany(v)}
                width="380px"
                placeHolder="選択してください（任意）"
                search={searchWord}
                setSearch={setSearchWord}
                validating={isValidating}
                hasMore={pageInfo?.hasMore}
                setPageIndex={setPageIndex}
              />
            </FormRow>
          </ContentArea>
        </ContentWrap>
        <FooterArea>
          <CancelWrap>
            <Button
              type="secondary"
              onClick={() => navigate("/aimo_parking/company")}
              label="キャンセル"
              width="160px"
            />
          </CancelWrap>
          <SaveWrap>
            <Annotation>追加するまで変更内容は反映されません</Annotation>
            <Button type="primary" onClick={onSubmit} label="この管理会社を追加" disabled={isSubmit} width="160px" />
          </SaveWrap>
        </FooterArea>
      </Wrap>
      <UserModal isVisible={isVisible} config={modalConfig} />
    </Board>
  );
};

export default CreateCompany;
