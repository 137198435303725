import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import styled from "styled-components";

import { Wrapper, Input, ErrorArea, ErrorMessage } from "components/TextForm";
import color from "constants/color";

// パーツ定義
const PasswordIconWrap = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 15px;
  right: ${(props) => (props.visible ? "14" : "15")}px;
  color: ${color.form.border};
  cursor: pointer;
  &:hover {
    color: ${color.form.border};
  }
`;
const PasswordInput = styled(Input)`
  padding-right: 40px;
`;

// 本体
const PasswordForm = ({
  width = "200px",
  value = "",
  placeholder = "",
  required = false,
  readOnly = false,
  onChange = () => null,
  onBlur = () => null,
  onFocus = () => null,
  isError = false,
  errorMessage = "",
}: {
  width?: string;
  value?: string;
  placeholder?: string;
  required?: boolean;
  readOnly?: boolean;
  pattern?: RegExp;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  isError?: boolean;
  errorMessage?: string;
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isErrorDetectable, setIsErrorDetectable] = useState(false);
  const togglePasswordVisible = () => setIsPasswordVisible(!isPasswordVisible);

  // フォーカスが外れたらエラーを検出可能にする
  const onBlurForm = () => {
    setIsErrorDetectable(true);
    onBlur();
  };

  return (
    <Wrapper>
      <PasswordInput
        type={isPasswordVisible ? "text" : "password"}
        width={width}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlurForm}
        required={required}
        readOnly={readOnly}
        isErrorDisplay={isError && isErrorDetectable}
      />
      <PasswordIconWrap visible={isPasswordVisible} onClick={togglePasswordVisible}>
        <FontAwesomeIcon icon={isPasswordVisible ? faEyeSlash : faEye} />
      </PasswordIconWrap>
      <ErrorArea isErrorDisplay={isError && isErrorDetectable}>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </ErrorArea>
    </Wrapper>
  );
};

export default PasswordForm;
