export const AIMO_PARKING_USER_ROLE = {
  VENDER__ADMIN: 0,
  SYSTEM_ADMIN: 1,
  OPERATION_ADMIN: 2,
  GENERAL_ADMIN: 3,
  CALL_CENTER: 4,
  ALL: "ALL",
} as const;

export const AIMO_PARKING_USER_ROLE_CHOICES = [
  AIMO_PARKING_USER_ROLE.VENDER__ADMIN,
  AIMO_PARKING_USER_ROLE.SYSTEM_ADMIN,
  AIMO_PARKING_USER_ROLE.OPERATION_ADMIN,
  AIMO_PARKING_USER_ROLE.GENERAL_ADMIN,
  AIMO_PARKING_USER_ROLE.CALL_CENTER,
] as const;

export type AimoParkingUserRole = typeof AIMO_PARKING_USER_ROLE[keyof typeof AIMO_PARKING_USER_ROLE];

export const AIMO_PARKING_USER_ROLE_LABEL = {
  [AIMO_PARKING_USER_ROLE.VENDER__ADMIN]: "販売元管理者",
  [AIMO_PARKING_USER_ROLE.SYSTEM_ADMIN]: "システム管理者",
  [AIMO_PARKING_USER_ROLE.OPERATION_ADMIN]: "運営管理者",
  [AIMO_PARKING_USER_ROLE.GENERAL_ADMIN]: "一般管理者",
  [AIMO_PARKING_USER_ROLE.CALL_CENTER]: "コールセンター",
} as const;

export const AIMO_PARKING_USER_ROLE_LABEL_CHOICES = (isVendor: boolean) => [
  {
    label: "販売元管理者",
    value: AIMO_PARKING_USER_ROLE.VENDER__ADMIN,
    disabled: isVendor,
  },
  {
    label: "システム管理者",
    value: AIMO_PARKING_USER_ROLE.SYSTEM_ADMIN,
    disabled: !isVendor,
  },
  {
    label: "運営管理者",
    value: AIMO_PARKING_USER_ROLE.OPERATION_ADMIN,
    disabled: !isVendor,
  },
  {
    label: "一般管理者",
    value: AIMO_PARKING_USER_ROLE.GENERAL_ADMIN,
    disabled: !isVendor,
  },
  {
    label: "コールセンター",
    value: AIMO_PARKING_USER_ROLE.CALL_CENTER,
    disabled: !isVendor,
  },
];
