import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Button from "components/Button";
import { Board, PageTop, Title, TitleArea } from "components/LayoutParts";
import Loading from "components/Loading";
import { useDebounce } from "hooks/useDebounce";
import { useFindAllCompany } from "pages/AimoParking/Company/api";
import DataTable from "pages/AimoParking/Company/DataTable";
import ActionArea from "pages/AimoParking/Company/DataTable/ActionArea";
import { AIMO_PARKING_COMPANY_STATUS, AimoParkingCompanyStatus } from "pages/AimoParking/constants/companyStatus";
import { jumpUpToElement } from "utils/animationUtils";

const OverWrapTitleArea = styled(TitleArea)`
  margin-bottom: -8px;
`;

const ButtonWrap = styled.div`
  margin-left: auto;
`;

const Company = () => {
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(1);
  const [sortOption, setSortOption] = useState<SortOption<SortByAimoParkingCompany>>({
    sortByColumn: undefined,
    direction: undefined,
  });
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedFilter, setSelectedFilter] = useState<AimoParkingCompanyStatus>(AIMO_PARKING_COMPANY_STATUS.ALL);

  const navigate = useNavigate();
  const { debouncedValue: debouncedSearchKeyword } = useDebounce(searchKeyword, 500);
  const { data, pageInfo } = useFindAllCompany({
    keyword: debouncedSearchKeyword,
    pageIndex: currentPageIndex,
    orderBy: sortOption.sortByColumn,
    order: sortOption.direction,
  });

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPageIndex(1);
    setSearchKeyword(e.target.value);
  };

  const onFilterButtonClick = (status: AimoParkingCompanyStatus) => {
    setCurrentPageIndex(1);
    setSelectedFilter(status);
  };

  const onClickCompanyAdd = () => {
    navigate("/aimo_parking/company/create");
  };

  useEffect(() => {
    setTotalPages(pageInfo?.totalPages ?? 1);
    return () => {
      jumpUpToElement("root");
    };
  }, [pageInfo?.totalPages]);

  return (
    <Board>
      <PageTop />
      <OverWrapTitleArea>
        <Title>管理会社</Title>
        <ButtonWrap>
          <Button label="管理会社を追加" onClick={onClickCompanyAdd} type="primary" />
        </ButtonWrap>
      </OverWrapTitleArea>
      <ActionArea
        searchKeyword={searchKeyword}
        onSearchChange={onSearchChange}
        selectedFilter={selectedFilter}
        onFilterButtonClick={onFilterButtonClick}
      />
      {data ? (
        <DataTable
          companyList={data}
          totalPages={totalPages}
          pageIndex={currentPageIndex}
          sortOption={sortOption}
          setPageIndex={setCurrentPageIndex}
          setSortOption={setSortOption}
        />
      ) : (
        <Loading />
      )}
    </Board>
  );
};

export default Company;
