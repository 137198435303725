import styled from "styled-components";

const Area = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProgramVersion = ({ mainVersion, standVersion }: { mainVersion: string; standVersion: string }) => {
  return (
    <Area>
      <div>{`最新メインプログラム: ver ${mainVersion}`}</div>
      <div>{`最新スタンドプログラム: ver ${standVersion}`}</div>
    </Area>
  );
};

export default ProgramVersion;
