const domain =
  process.env.REACT_APP_LANDIT_ADMIN_PROJECT_ID === "at-port-admin"
    ? "landit.lightning.force.com"
    : process.env.REACT_APP_LANDIT_ADMIN_PROJECT_ID === "at-port-admin-stg"
    ? "landit--stg.sandbox.lightning.force.com"
    : "landit--dev.sandbox.lightning.force.com";
export const linkSalesforceAccount = (accountId: string) => {
  if (accountId.length > 0) {
    window.open(`https://${domain}/lightning/r/Account/${accountId}/view`, "_blank");
  }
};
export const linkSalesforceContact = (contactId: string) => {
  if (contactId.length > 0) {
    window.open(`https://${domain}/lightning/r/Contact/${contactId}/view`, "_blank");
  }
};
