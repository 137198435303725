import React, { useState } from "react";
import styled from "styled-components";

import { Wrapper, ErrorArea, ErrorMessage } from "components/TextForm";
import color from "constants/color";
import font from "constants/font";

// パーツ定義
const Textarea = styled.textarea<{
  minHeight: number;
  width: string;
  isErrorDisplay: boolean;
}>`
  display: block;
  font-size: ${font.size14};
  box-sizing: border-box;
  width: ${(props) => props.width};
  padding: 12px 16px;
  height: 48px;
  appearance: none;
  border: 1px solid ${(props) => (props.isErrorDisplay ? color.warning : color.form.border)};
  background-color: ${(props) => (props.isErrorDisplay ? color.attentionLight : color.form.background)};
  border-radius: 4px;
  outline: none;
  transition: 0.3s;
  min-height: ${(props) => props.minHeight}px;
  resize: vertical;
  &:hover {
    border: 1px solid ${color.form.border};
  }
  &:focus {
    border: 1px solid ${color.text.link};
    background: ${color.text.linkLight};
  }
`;

// 本体
const TextareaForm = ({
  width = "200px",
  value = "",
  placeholder = "",
  required = false,
  readOnly = false,
  onChange = () => null,
  onBlur = () => null,
  onFocus = () => null,
  isError = false,
  errorMessage = "",
  minHeight = 0,
}: {
  width?: string;
  value?: string;
  placeholder?: string;
  required?: boolean;
  readOnly?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  isError?: boolean;
  errorMessage?: string;
  minHeight?: number;
}) => {
  const [isErrorDetectable, setIsErrorDetectable] = useState(false);

  // フォーカスが外れたらエラーを検出可能にする
  const onBlurForm = () => {
    setIsErrorDetectable(true);
    onBlur();
  };

  return (
    <Wrapper>
      <Textarea
        width={width}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlurForm}
        required={required}
        readOnly={readOnly}
        isErrorDisplay={isError && isErrorDetectable}
        minHeight={minHeight}
      />
      <ErrorArea isErrorDisplay={isError && isErrorDetectable}>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </ErrorArea>
    </Wrapper>
  );
};

export default TextareaForm;
