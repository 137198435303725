import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Button from "components/Button";
import { ContentArea as BaseContextArea } from "components/LayoutParts";
import color from "constants/color";
import font from "constants/font";

const Label = styled.div`
  min-width: 160px;
  margin: 0 5px;
`;
const Value = styled.div`
  font-size: ${font.size16};
`;
const LinkValue = styled(Value)`
  color: ${color.text.link};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
const FormWrap = styled.div`
  flex: 1;
`;
const ContentArea = styled(BaseContextArea)`
  flex-direction: row;
  margin-bottom: 0;
`;
const FormRowWrap = styled.div`
  display: flex;
  flex-direction: row;
`;
export const FormRow = styled.div<{ minHeight?: string; hideBorder?: boolean }>`
  width: 100%;
  padding: 16px 0px;
  margin: 0 16px;
  border-bottom: solid 1px ${color.border};
  border-bottom: ${({ hideBorder }) => (hideBorder ? "none" : `auto`)};
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: ${(props) => props.minHeight};
`;

const CompanyTitle = styled.div`
  font-size: ${font.size24};
  /* margin-bottom: 16px; */
`;
const CompanyTitleWrap = styled.div`
  font-size: ${font.size24};
  display: flex;
  justify-content: space-between;
  padding-bottom: 21px;
`;

const onClickCompanyHp = (url: string) => {
  window.open(url, "_blank");
};

const CompanyInfo = ({ companyDetail }: { companyDetail: SyncPortCompany }) => {
  const navigate = useNavigate();

  return (
    <>
      <CompanyTitleWrap>
        <CompanyTitle>会社情報</CompanyTitle>
        <Button
          type="secondary"
          onClick={() => navigate(`/sync_port/company/${companyDetail?.id}/edit`)}
          label="会社情報を編集"
          width="160px"
        />
      </CompanyTitleWrap>
      <ContentArea>
        <FormWrap>
          <FormRowWrap>
            <FormRow>
              <Label>会社名</Label>
              <Value>{companyDetail.name}</Value>
            </FormRow>
            <FormRow>
              <Label>支店名 / 支社名</Label>
              <Value>{companyDetail.branchName ?? ""}</Value>
            </FormRow>
          </FormRowWrap>
          <FormRowWrap>
            <FormRow>
              <Label>住所</Label>
              <Value>{companyDetail.address}</Value>
            </FormRow>
            <FormRow>
              <Label>会社HP</Label>
              <LinkValue
                onClick={() => {
                  if (companyDetail.companyHp != null) {
                    onClickCompanyHp(companyDetail.companyHp);
                  }
                }}
              >
                {companyDetail.companyHp}
              </LinkValue>
            </FormRow>
          </FormRowWrap>
          <FormRowWrap>
            <FormRow>
              <Label>電話番号</Label>
              <Value>{companyDetail.phoneNumber}</Value>
            </FormRow>
            <FormRow>
              <Label>営業時間</Label>
              <Value>
                {companyDetail.openingTime == null || companyDetail.openingTime == ""
                  ? "未設定"
                  : `${companyDetail.openingTime} ~ ${companyDetail.closingTime}`}
              </Value>
            </FormRow>
          </FormRowWrap>
          <FormRowWrap>
            <FormRow hideBorder>
              <Label>FAX</Label>
              <Value>{companyDetail.faxNumber}</Value>
            </FormRow>
            <FormRow hideBorder>
              <Label>管理会社ID</Label>
              <Value>{companyDetail.id}</Value>
            </FormRow>
          </FormRowWrap>
        </FormWrap>
      </ContentArea>
    </>
  );
};

export default CompanyInfo;
