import axios from "axios";

/**
 * 署名付きURLでファイルをアップロード関数
 * @param signedUrl 署名付きURL
 * @param file アップロードするファイル
 * @returns void
 */
export const uploadFileCloudStorage = async ({ signedUrl, file }: { signedUrl: string; file: File }): Promise<void> => {
  await axios.put(signedUrl, file, { headers: { "Content-Type": file.type } }).catch((error) => {
    throw new Error(JSON.stringify(error));
  });
};

/**
 * URLをデコードしてクエリパラメータを削除する関数
 * @example
    https://storage.googleapis.com/property-master-bucket-backend/example/example.svg?q=%E3%82%A2%E3%82%A4%E3%82%A6%E3%82%A8%E3%82%AA
   ↓
    https://storage.googleapis.com/property-master-bucket-backend/example/example.svg
 */
const removeQuery = (url: string): string => {
  const decodedUrl = decodeURIComponent(url);
  const urlWithoutQuery = decodedUrl.split("?")[0];
  return urlWithoutQuery;
};

/**
 * パスのみを抽出する関数
 * @example
 * https://storage.googleapis.com/property-master-bucket-backend/example/example.svg
 * ↓
 * example/example.svg
 */
const removeSubstringBeforeSecondLastSlash = (url: string): string => {
  const parts = url.split("/");
  if (parts.length >= 3) {
    return parts.slice(-2).join("/");
  }
  return url;
};

/**
 * URLからパスを抽出する関数
 * @example
 * https://storage.googleapis.com/property-master-bucket-backend/example/example.svg?q=%E3%82%A2%E3%82%A4%E3%82%A6%E3%82%A8%E3%82%AA
 * ↓
 * example/example.svg
 */
export const extractPathFromUrl = (url: string): string => {
  const queryRemovedUrl = removeQuery(url);
  const path = removeSubstringBeforeSecondLastSlash(queryRemovedUrl);
  return path;
};

/**
 * ファイル名から拡張子を取得する関数
 * @example
 * example.svg
 * ↓
 * svg
 */
export const getExtension = (fileName: string): string => {
  return fileName.split(".").pop() ?? "";
};
