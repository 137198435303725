import Company from "pages/SyncPort/Company";
import CompanyDetail from "pages/SyncPort/Company/CompanyDetail";
import CompanyUserInfo from "pages/SyncPort/Company/CompanyUserInfo";
import TitleTabArea from "pages/SyncPort/Company/component/TitleTabArea";
import CreateCompany from "pages/SyncPort/Company/CreateCompany";
import EditCompany from "pages/SyncPort/Company/EditCompany";
import ImportProperty from "pages/SyncPort/Company/ImportProperty";
import Property from "pages/SyncPort/Company/Property";
import Space from "pages/SyncPort/Company/Property/Space";
import SpaceGroup from "pages/SyncPort/Company/Property/SpaceGroup";
import PropertyDetail from "pages/SyncPort/Company/PropertyDetail";
import SpaceGroupDetail from "pages/SyncPort/Company/SpaceGroupDetail";
import UploadParkingGuide from "pages/SyncPort/Company/UploadParkingGuide";
import UploadPropertyImage from "pages/SyncPort/Company/UploadPropertyImage";
import User from "pages/SyncPort/User";
import CreateUser from "pages/SyncPort/User/CreateUser";
import EditUser from "pages/SyncPort/User/EditUser";

const SyncPort = {
  Company,
  CreateCompany,
  CompanyDetail,
  EditCompany,
  CreateUser,
  EditUser,
  User,
  TitleTabArea,
  CompanyUserInfo,
  Property,
  PropertyDetail,
  CreateProperty: ImportProperty,
  Space,
  SpaceGroup,
  UploadPropertyImage,
  SpaceGroupDetail,
  UploadParkingGuide,
};

export default SyncPort;
