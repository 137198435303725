import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Breadcrumb from "components/Breadcrumb";
import Button from "components/Button";
import { Board, TitleArea, ContentArea, FormRow, FooterArea } from "components/LayoutParts";
import TextForm from "components/TextForm";
import UploadForm from "components/UploadForm";
import UserModal, { UserModalConfig } from "components/UserModal";
import color from "constants/color";
import font from "constants/font";
import { useUpdateAiSoftwareVersion } from "pages/AimoParking/AiSoftware/api";
import AimoParkingSelectDate, { AimoParkingSelectDateType } from "pages/AimoParking/component/AimoParkingSelectDate";
import {
  CONFIRM_MODAL_CONFIG,
  FAILED_MODAL_CONFIG,
  INITIAL_MODAL_CONFIG,
  SUCCEED_MODAL_CONFIG,
} from "pages/AimoParking/modalTemplate";
import { isDateValid } from "utils/dateTimeUtils";

const Label = styled.div<{ top?: boolean }>`
  min-width: 200px;
  margin: 0 5px;
  padding-right: 18px;
  align-self: ${({ top }) => (top ? "flex-start" : "center")};
  margin-top: ${({ top }) => (top ? "14px" : "0")};
`;

const Wrap = styled.div`
  margin-top: 10px;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  flex: 1;
`;

const CancelWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;

const SaveWrap = styled(CancelWrap)`
  align-items: center;
  justify-content: flex-end;
`;
const Annotation = styled.span`
  font-size: ${font.size12};
  color: ${color.text};
  margin-right: 16px;
`;

const UploadWrap = styled.div`
  width: 520px;
`;

const UpdateVersion = () => {
  const [aiVersion, setAiVersion] = useState<string>("");
  const [images, setImages] = useState<File[]>([]);
  const [date, setDate] = useState<AimoParkingSelectDateType>({ year: null, month: null, day: null });
  const [isVisible, setIsVisible] = useState(false);
  const [modalConfig, setModalConfig] = useState<UserModalConfig>(INITIAL_MODAL_CONFIG);

  const navigate = useNavigate();
  const { update } = useUpdateAiSoftwareVersion();

  const handleChangeAiVersion = (e: React.ChangeEvent<HTMLInputElement>) => setAiVersion(e.target.value);

  const isErrorAiVersion = !/^v\d+\.\d+\.\d+$/.test(aiVersion);
  const isErrorDate = !isDateValid({
    day: Number(date.day),
    month: Number(date.month),
    year: Number(date.year),
  });
  const isErrorImage = images.length !== 1 || images[0].type !== "application/zip";
  const canSubmit = !isErrorDate && !isErrorAiVersion && !isErrorImage;

  const onSubmit = useCallback(async () => {
    const dayjsDate = dayjs(`${Number(date.year)}-${Number(date.month)}-${Number(date.day)}`);
    const response = await update({
      file: images[0],
      version: aiVersion,
      releaseDate: dayjsDate.toDate(),
    }).catch((e) => {
      setModalConfig(FAILED_MODAL_CONFIG({ onClose: () => setIsVisible(false) }));
      setIsVisible(true);
      throw e;
    });

    if (response.status === 201) {
      setModalConfig(
        SUCCEED_MODAL_CONFIG({
          message: "送信しました",
          onClose: () => navigate(`/aimo_parking/ai_software`),
        })
      );
      setIsVisible(true);
    }
  }, [aiVersion, date, images, update, navigate]);

  const handleOpenSubmitModal = useCallback(() => {
    setModalConfig(
      CONFIRM_MODAL_CONFIG({
        negative: () => setIsVisible(false),
        positive: onSubmit,
        message: "AIバージョンを更新しますか？",
      })
    );
    setIsVisible(true);
  }, [onSubmit, setModalConfig, setIsVisible]);

  const setUp = useCallback(() => {
    const currentDate = dayjs();
    const addZero = (num: string) => (num.length === 1 ? `0${num}` : num);
    setDate({
      year: addZero(currentDate.year().toString()),
      month: addZero((currentDate.month() + 1).toString()),
      day: addZero(currentDate.date().toString()),
    });
  }, []);

  useEffect(() => {
    setUp();
  }, [setUp]);

  return (
    <Board>
      <TitleArea>
        <Breadcrumb
          currentPageName={`AIバージョンを更新`}
          breadcrumbItems={[
            {
              pageName: `AIバージョン`,
              onClick: () => navigate(`/aimo_parking/ai_software`),
            },
          ]}
        />
      </TitleArea>
      <Wrap>
        <ContentArea>
          <FormRow>
            <Label>バージョン</Label>
            <TextForm
              value={aiVersion}
              width="350px"
              onChange={handleChangeAiVersion}
              errorMessage="v1.0.1のような形式で入力してください"
              placeholder="バージョンを入力"
              isError={isErrorAiVersion}
            />
          </FormRow>
          <FormRow>
            <Label top>ZIP</Label>
            <UploadWrap>
              <UploadForm accept="application/zip" unUploadedImages={images} setUnUploadedImages={setImages} single />
            </UploadWrap>
          </FormRow>
          <FormRow>
            <Label>リリース日時</Label>
            <AimoParkingSelectDate
              date={date}
              setDate={setDate}
              isError={isErrorDate}
              errorMessage="リリース日時が不正です"
            />
          </FormRow>
        </ContentArea>
        <FooterArea>
          <CancelWrap>
            <Button
              type="secondary"
              onClick={() => navigate(`/aimo_parking/ai_software`)}
              label="キャンセル"
              width="160px"
            />
          </CancelWrap>
          <SaveWrap>
            <Annotation>更新するまで入力内容は反映されません</Annotation>
            <Button
              type="primary"
              onClick={handleOpenSubmitModal}
              label="AIバージョンを更新"
              disabled={!canSubmit}
              width="191px"
            />
          </SaveWrap>
        </FooterArea>
      </Wrap>
      <UserModal isVisible={isVisible} config={modalConfig} />
    </Board>
  );
};

export default UpdateVersion;
