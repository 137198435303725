import { isJSON } from "class-validator";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Breadcrumb from "components/Breadcrumb";
import Button from "components/Button";
import { Board, ContentArea, FooterArea, FormRow, TitleArea } from "components/LayoutParts";
import RadioButton from "components/RadioButton";
import TextareaForm from "components/TextareaForm";
import TextButton from "components/TextButton";
import TextForm from "components/TextForm";
import UserModal, { UserModalConfig } from "components/UserModal";
import color from "constants/color";
import font from "constants/font";
import {
  AIMO_PARKING_EDGE_DEVICE_DETECTION_CHOICES,
  AimoParkingEdgeDeviceDetection,
} from "pages/AimoParking/constants/aimoParkingDetection";
import { useCreateEdgeDevice } from "pages/AimoParking/EdgeDevice/api";
import {
  CONFIRM_MODAL_CONFIG,
  FAILED_MODAL_CONFIG,
  INITIAL_MODAL_CONFIG,
  SUCCEED_MODAL_CONFIG,
} from "pages/AimoParking/modalTemplate";
import { extractErrorCode } from "pages/AimoParking/utils/extractErrorCodeUtil";
import { formatEmptyJson } from "pages/AimoParking/utils/formatEmptyJsonUtil";

const Label = styled.div<{ top?: boolean }>`
  min-width: 200px;
  margin: 0 5px;
  padding-right: 18px;
  align-self: ${({ top }) => (top ? "flex-start" : "center")};
  margin-top: ${({ top }) => (top ? "14px" : "0")};
`;

const Wrap = styled.div`
  margin-top: 10px;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  flex: 1;
`;

const CancelWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;

const SaveWrap = styled(CancelWrap)`
  align-items: center;
  justify-content: flex-end;
`;
const Annotation = styled.span`
  font-size: ${font.size12};
  color: ${color.text};
  margin-right: 16px;
`;

const CreateEdgeDevice = () => {
  const [serialNumber, setSerialNumber] = useState<string>("");
  const [apiKey, setApiKey] = useState<string>("");
  const [nvr, setNvr] = useState<string>("");
  const [setting, setSetting] = useState<string>();
  const [detectionType, setDetectionType] = useState<AimoParkingEdgeDeviceDetection>();
  const [modalConfig, setModalConfig] = useState<UserModalConfig>(INITIAL_MODAL_CONFIG);
  const [isVisible, setIsVisible] = useState(false);
  const [settingErrorMessage, setSettingErrorMessage] = useState<string>();

  const navigate = useNavigate();
  const { create } = useCreateEdgeDevice();

  const isErrorSetting = !isJSON(setting) || setting === "" || formatEmptyJson(setting ?? "{}") === "{}";
  const isErrorDetectionType = detectionType === undefined;
  const isErrorSerialNumber = !serialNumber || serialNumber === "";
  const isErrorApiKey = !apiKey || apiKey.length !== 128;
  const canSubmit = !isErrorSetting && !isErrorSerialNumber && !isErrorApiKey && !isErrorDetectionType;

  const handleChangeSerialNumber = (e: React.ChangeEvent<HTMLInputElement>) => setSerialNumber(e.target.value);
  const handleChangeNvr = (e: React.ChangeEvent<HTMLInputElement>) => setNvr(e.target.value);
  const handleChangeApiKey = (e: React.ChangeEvent<HTMLInputElement>) => setApiKey(e.target.value);
  const handleChangeSetting = (e: React.ChangeEvent<HTMLTextAreaElement>) => setSetting(e.target.value);

  const onSubmit = useCallback(async () => {
    const response = await create({
      apiKey,
      nvr,
      detectionType: detectionType as AimoParkingEdgeDeviceDetection,
      setting: JSON.parse(setting ?? ""),
      serialNumber,
    }).catch((e) => {
      const error = extractErrorCode(e);
      error?.errorMessage && setSettingErrorMessage(error.errorMessage);
      setModalConfig(FAILED_MODAL_CONFIG({ onClose: () => setIsVisible(false) }));
      setIsVisible(true);
      throw e;
    });

    if (response.status === 201) {
      setSettingErrorMessage(undefined);
      setModalConfig(
        SUCCEED_MODAL_CONFIG({
          message: "登録しました",
          onClose: () => navigate(`/aimo_parking/edge_device`),
        })
      );
      setIsVisible(true);
    }
  }, [create, navigate, setModalConfig, setting, setIsVisible, apiKey, serialNumber, nvr, detectionType]);

  const onOpenSettingEditer = () => {
    window.open(`/editer.html`, "_blank", "width=800,height=600");
  };

  const handleOpenSubmitModal = useCallback(() => {
    setModalConfig(
      CONFIRM_MODAL_CONFIG({
        negative: () => setIsVisible(false),
        positive: onSubmit,
        message: "エッジデバイスを追加しますか？",
      })
    );
    setIsVisible(true);
  }, [onSubmit, setModalConfig, setIsVisible]);

  return (
    <Board>
      <TitleArea>
        <Breadcrumb
          currentPageName={`エッジデバイスを追加`}
          breadcrumbItems={[
            {
              pageName: `エッジデバイス`,
              onClick: () => navigate(`/aimo_parking/edge_device`),
            },
          ]}
        />
      </TitleArea>
      <Wrap>
        <ContentArea>
          <FormRow>
            <Label top>シリアルナンバー</Label>
            <TextForm
              value={serialNumber}
              onChange={handleChangeSerialNumber}
              width="350px"
              errorMessage={"シリアルナンバーを入力してください"}
              isError={isErrorSerialNumber}
              placeholder="シリアルナンバーを入力"
            />
          </FormRow>
          <FormRow>
            <Label>APIキー</Label>
            <TextForm
              value={apiKey}
              onChange={handleChangeApiKey}
              width="350px"
              errorMessage={"128字のAPIキーを入力してください"}
              isError={isErrorApiKey}
              placeholder="API Keyを入力"
            />
          </FormRow>
          <FormRow>
            <Label>検知タイプ</Label>
            <RadioButton
              choices={AIMO_PARKING_EDGE_DEVICE_DETECTION_CHOICES}
              onChange={(v) => setDetectionType(Number(v.target.value) as AimoParkingEdgeDeviceDetection)}
              id="aimo-parking-detection"
              value={String(detectionType)}
            />
          </FormRow>
          <FormRow>
            <Label top>設定JSON</Label>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextareaForm
                value={setting}
                onChange={handleChangeSetting}
                minHeight={400}
                width="812px"
                errorMessage={settingErrorMessage ?? "正しい設定JSONを入力してください"}
                isError={!!settingErrorMessage || isErrorSetting}
                placeholder="設定JSONを入力"
              />
              <div
                style={{
                  marginTop: "16px",
                }}
              />
              <TextButton label="設定ツールを開く" onClick={onOpenSettingEditer} />
            </div>
          </FormRow>
          <FormRow>
            <Label>NVR</Label>
            <TextForm
              value={nvr}
              onChange={handleChangeNvr}
              width="350px"
              errorMessage={"NVRを入力してください"}
              isError={false}
              placeholder="NVRを入力"
            />
          </FormRow>
        </ContentArea>
        <FooterArea>
          <CancelWrap>
            <Button
              type="secondary"
              onClick={() => navigate(`/aimo_parking/edge_device`)}
              label="キャンセル"
              width="160px"
            />
          </CancelWrap>
          <SaveWrap>
            <Annotation>追加するまで入力内容は反映されません</Annotation>
            <Button
              type="primary"
              onClick={handleOpenSubmitModal}
              label="エッジデバイスを追加"
              disabled={!canSubmit}
              width="191px"
            />
          </SaveWrap>
        </FooterArea>
      </Wrap>
      <UserModal isVisible={isVisible} config={modalConfig} />
    </Board>
  );
};

export default CreateEdgeDevice;
