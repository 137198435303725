import { useParams, useNavigate, Outlet } from "react-router-dom";
import styled from "styled-components";

import Button from "components/Button";
import { ContentArea as BaseContentArea, Board as BaseBoard } from "components/LayoutParts";
import Loading from "components/Loading";
import color from "constants/color";
import font from "constants/font";
import PropertyImagesPreview from "pages/SyncPort/Company/component/PropertyImagesPreview";
import PropertyInfo from "pages/SyncPort/Company/component/PropertyInfo";
import TitleTabArea from "pages/SyncPort/Company/Property/component/TitleTabArea";
import { useFindPropertyById } from "pages/SyncPort/Company/Property/features/api";

const Board = styled(BaseBoard)`
  padding: 0px;
  overflow: hidden;
  flex-shrink: 0;
`;

const PropertyTitleArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PropertyTitle = styled.div`
  font-size: ${font.size24};
  font-weight: bold;
`;

const ContentArea = styled(BaseContentArea)`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  padding: 0px;
  gap: 10px;
  background-color: ${color.background};
  border: none;
  flex: 1;
`;

const PropertyInfoArea = styled.div`
  display: flex;
  flex: 7.3;
`;

const PropertyImagesPreviewArea = styled.div`
  flex-direction: column;
  gap: 14px;
  padding: 16px 32px 0 32px;
  background-color: ${color.white};
  border: solid 1px ${color.border};
  flex: 3.2;
  max-height: 100%;
  flex-shrink: 0;
  max-width: 320px;
`;

const ImageTitleArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ImageTitle = styled.div`
  font-size: ${font.size16};
`;

const ImagesArea = styled.div`
  margin-top: 16px;
  background-color: ${color.white};
  border: none;
  margin-bottom: 20px;
`;

// パーツ定義
const PropertyDetail = () => {
  const { id, propertyId } = useParams();
  const navigate = useNavigate();

  const { data } = useFindPropertyById({ propertyId });

  return (
    <>
      {data == null ? (
        <Loading />
      ) : (
        <Board>
          <PropertyTitleArea>
            <PropertyTitle>{data.name}</PropertyTitle>
          </PropertyTitleArea>
          <ContentArea>
            <PropertyInfoArea>
              <PropertyInfo propertyDetail={data} />
            </PropertyInfoArea>
            <PropertyImagesPreviewArea>
              <ImageTitleArea>
                <ImageTitle>画像</ImageTitle>
                <Button
                  label="画像を登録・変更"
                  onClick={() => navigate(`/sync_port/company/${id}/property/${propertyId}/image`)}
                  type={"secondary"}
                />
              </ImageTitleArea>
              <ImagesArea>
                <PropertyImagesPreview images={data.images} />
              </ImagesArea>
            </PropertyImagesPreviewArea>
          </ContentArea>
        </Board>
      )}
      <TitleTabArea />
      <Outlet />
    </>
  );
};

export default PropertyDetail;
