import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import Button from "components/Button";
import CopyOnClipboard from "components/CopyOnClipboard";
import { FieldName, FieldValueWithBorder, Heading, Row, Table } from "components/LayoutParts";
import Loading from "components/Loading";
import color from "constants/color";
import font from "constants/font";
import { useFindCompanyMembers } from "pages/SyncPort/Company/features/api";
import { copyOnClipboard, createCsvTextForClipboard } from "utils/clipboardUtil";

const CompanyTitle = styled.div`
  font-size: ${font.size24};
`;
const CompanyTitleWrap = styled.div`
  font-size: ${font.size24};
  display: flex;
  justify-content: space-between;
`;
const CompanyMemberArea = styled.div`
  margin-top: 16px;
`;
const CompanyMemberListEmpty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0;
  border: 1px solid ${color.border};
  border-top: none;
  background-color: ${color.white};
`;
const CompanyMemberListEmptyText = styled.div`
  font-size: ${font.size16};
  margin-bottom: 16px;
`;
const ButtonsWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
`;

const CompanyUserInfo = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { data: companyMembers } = useFindCompanyMembers({
    companyId: id ?? "",
  });

  const onClickRow = (id: string) => {
    navigate(`/sync_port/user/${id}/edit`);
  };
  const onClickAddMember = () => {
    navigate(`/sync_port/user/create?companyId=${id}`);
  };

  const fullName = (member: SyncPortCompanyMember) => `${member.lastName} ${member.firstName}`;
  const fullNameKana = (member: SyncPortCompanyMember) => `${member.lastNameKana ?? ""} ${member.firstNameKana ?? ""}`;

  const onClickCopy = () => {
    const dataArray =
      companyMembers?.map((member) => ({
        name: fullName(member),
        kana: fullNameKana(member),
        email: member.email,
      })) ?? [];
    const csvText = createCsvTextForClipboard({ label: ["名前", "名前（カナ）", "メールアドレス"], data: dataArray });
    copyOnClipboard(csvText);
  };

  return (
    <>
      <CompanyTitleWrap>
        <CompanyTitle>ユーザ情報</CompanyTitle>
        <ButtonsWrap>
          {companyMembers && companyMembers?.length > 0 && (
            <CopyOnClipboard
              onClick={onClickCopy}
              afterLabel="ユーザ情報をコピーしました"
              beforeLabel="すべてのユーザ情報をコピー"
            />
          )}
          <Button type="primary" onClick={onClickAddMember} label="ユーザを追加" width="160px" />
        </ButtonsWrap>
      </CompanyTitleWrap>
      <CompanyMemberArea>
        <Table>
          <Heading>
            <FieldName flex={1}>名前</FieldName>
            <FieldName flex={1}>名前（カナ）</FieldName>
            <FieldName flex={1} lastColumn>
              メールアドレス
            </FieldName>
          </Heading>
          {companyMembers == null ? (
            <Loading />
          ) : companyMembers.length > 0 ? (
            companyMembers.map((member) => (
              <Row key={member.id} onClick={() => onClickRow(member.id)}>
                <FieldValueWithBorder flex={1}>{fullName(member)}</FieldValueWithBorder>
                <FieldValueWithBorder flex={1}>{fullNameKana(member)}</FieldValueWithBorder>
                <FieldValueWithBorder flex={1} lastColumn>
                  {member.email}
                </FieldValueWithBorder>
              </Row>
            ))
          ) : (
            <CompanyMemberListEmpty>
              <CompanyMemberListEmptyText>所属するユーザがいません</CompanyMemberListEmptyText>
              <Button label="所属するユーザを追加" type="secondary" width="200px" onClick={() => onClickAddMember()} />
            </CompanyMemberListEmpty>
          )}
        </Table>
      </CompanyMemberArea>
    </>
  );
};

export default CompanyUserInfo;
