import React from "react";
import styled from "styled-components";

import color from "constants/color";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const StatusDot = styled.span<{ isPublic: boolean }>`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: ${({ isPublic }) => (isPublic ? color.attention : color.form.border)};
`;

const StatusText = styled.span<{ isPublic: boolean }>`
  color: ${({ isPublic }) => (isPublic ? color.text.black : color.text.disabled)};
`;

const PublicTag = ({ isPublic }: { isPublic: boolean }) => {
  return (
    <Container>
      <StatusDot isPublic={isPublic} />
      <StatusText isPublic={isPublic}>{isPublic ? "公開" : "非公開"}</StatusText>
    </Container>
  );
};

export default PublicTag;
