import axios from "axios";
import { useCallback, useEffect, useState } from "react";

import { CLOUD_FUNCTIONS } from "constants/gcp";
import { useFirebaseContext } from "contexts/FirebaseContext";

export const useMetabase = () => {
  const [metabaseUrl, setMetabaseUrl] = useState("");
  const { auth } = useFirebaseContext();

  const getUrl = useCallback(async () => {
    const token = await auth?.currentUser?.getIdToken();
    if (!token) return false;
    const response = await axios
      .request({
        method: "get",
        url: `${CLOUD_FUNCTIONS}/pitportMetabase`,
        params: { dashboardId: 1 },
        headers: { Authorization: `Bearer ${token}` },
      })
      .catch((e) => {
        throw e;
      });
    setMetabaseUrl(response.data);
  }, [auth?.currentUser]);

  useEffect(() => {
    getUrl();
  }, [getUrl]);

  return { metabaseUrl };
};
