import { CSVLink } from "react-csv";

import { SAMPLE_CSV } from "constants/file";
import { convertObjectToCSV } from "utils/dataFileUtil";

const SampleCsv = ({ fontSize, color }: { fontSize: string; color: string }) => {
  const finalObjSample = {
    ...SAMPLE_CSV,
    [""]: "",
    ["※ 携帯番号、車の分類番号及び登録番号は、最初に’を入れて頂けると0から入力可能です。"]: "",
    ["※ 管理権限は管理者ユーザーの場合のみ、管理者と入力してください。"]: "",
  };

  return (
    <CSVLink
      filename={"サンプルCSV.csv"}
      data={convertObjectToCSV(finalObjSample)}
      style={{ fontSize: fontSize, color: color, textUnderlineOffset: "none", textDecoration: "none" }}
    >
      CSVテンプレートをダウンロード
    </CSVLink>
  );
};

export default SampleCsv;
