import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useCallback } from "react";

import { useFirebaseContext } from "contexts/FirebaseContext";

export const useSyncPortClient = () => {
  const { auth } = useFirebaseContext();
  const client = useCallback(
    async <T,>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
      const token = await auth.currentUser?.getIdToken();
      if (!token) throw new Error("401 Unauthorized");

      const response = await axios
        .request<T>({
          method: config.method,
          url: `${process.env.REACT_APP_LANDIT_ADMIN_API_URL}/syncport${config.url}`,
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          params: config.params ?? undefined,
          data: config.data ?? undefined,
        })
        .catch((e) => {
          if (axios.isAxiosError(e) && e.response) {
            return e.response;
          }
          throw e;
        });

      return response;
    },
    [auth?.currentUser]
  );
  return { client };
};
