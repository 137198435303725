import AiSoftware from "pages/AimoParking/AiSoftware";
import UpdateVersion from "pages/AimoParking/AiSoftware/UpdateVersion";
import Company from "pages/AimoParking/Company";
import CompanyDetail from "pages/AimoParking/Company/CompanyDetail";
import CompanyUser from "pages/AimoParking/Company/CompanyUser";
import CreateCompany from "pages/AimoParking/Company/CreateCompany";
import EditCompany from "pages/AimoParking/Company/EditCompany";
import TitleTabArea from "pages/AimoParking/component/TitleTabArea";
import EdgeDevice from "pages/AimoParking/EdgeDevice";
import CreateEdgeDevice from "pages/AimoParking/EdgeDevice/CreateEdgeDevice";
import EdgeDeviceDetail from "pages/AimoParking/EdgeDevice/EdgeDeviceDetail";
import EditEdgeDevice from "pages/AimoParking/EdgeDevice/EditEdgeDevice";
import SystemNotification from "pages/AimoParking/SystemNotification";
import CreateSystemNotification from "pages/AimoParking/SystemNotification/CreateSystemNotification";
import SystemNotificationDetail from "pages/AimoParking/SystemNotification/SystemNotificationDetail";
import User from "pages/AimoParking/User";
import CreateUser from "pages/AimoParking/User/CreateUser";
import EditUser from "pages/AimoParking/User/EditUser";

const AimoParking = {
  Company,
  CompanyDetail,
  TitleTabArea,
  CreateCompany,
  EditCompany,
  User,
  CreateUser,
  EditUser,
  SystemNotification,
  SystemNotificationDetail,
  CreateSystemNotification,
  CompanyUser,
  EdgeDevice,
  EditEdgeDevice,
  CreateEdgeDevice,
  EdgeDeviceDetail,
  AiSoftware,
  UpdateVersion,
};

export default AimoParking;
