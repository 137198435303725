export const AIMO_PARKING_EDGE_DEVICE_DETECTION = {
  PARKING_SPACE_DETECTION: 0, // 車室検知
  ENTRANCE_EXIT_DETECTION: 1, // 出入口検知
} as const;

export type AimoParkingEdgeDeviceDetection =
  typeof AIMO_PARKING_EDGE_DEVICE_DETECTION[keyof typeof AIMO_PARKING_EDGE_DEVICE_DETECTION];

export const AIMO_PARKING_EDGE_DEVICE_DETECTION_CHOICES = [
  { label: "車室検知", value: AIMO_PARKING_EDGE_DEVICE_DETECTION.PARKING_SPACE_DETECTION.toString(), id: "0" },
  {
    label: "出入口検知",
    value: AIMO_PARKING_EDGE_DEVICE_DETECTION.ENTRANCE_EXIT_DETECTION.toString(),
    id: "1",
  },
];
