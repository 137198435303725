import { useState, useCallback } from "react";
import styled from "styled-components";

import color from "constants/color";

const TextLink = styled.span<{
  buttonType: "normal" | "danger";
  disabled: boolean;
}>`
  color: ${(props) => (props.buttonType === "normal" ? color.text.link : color.warning)};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const TextButton = ({
  label,
  onClick,
  type = "normal",
  disabled = false,
}: {
  label: string;
  onClick: () => Promise<void> | void;
  type?: "normal" | "danger";
  disabled?: boolean;
}) => {
  const [isBusy, setIsBusy] = useState(false);

  const handler = useCallback(async () => {
    if (!disabled && !isBusy) {
      setIsBusy(true);
      await onClick();
      setIsBusy(false);
    }
  }, [onClick, disabled, isBusy]);

  return (
    <TextLink
      buttonType={type}
      onClick={(e) => {
        e.stopPropagation();
        handler();
      }}
      disabled={disabled || isBusy}
    >
      {label}
    </TextLink>
  );
};

export default TextButton;
