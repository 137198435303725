import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import Breadcrumb from "components/Breadcrumb";
import Button from "components/Button";
import { Board as BaseBoard, ContentArea, FormRow, TitleArea, FieldValue, FooterArea } from "components/LayoutParts";
import ModeSelector from "components/ModeSelector";
import UserModal, { UserModalConfig } from "components/UserModal";
import WarningLabel from "components/WarningLabel";
import color from "constants/color";
import { PITPORT_AUTO_STAND_MODE_LABEL, PITPORT_AUTO_STAND_STATUS_LABEL } from "constants/stand";
import { useSwr } from "hooks/useSwr";
import {
  useRemoveControlBox,
  useConfirmControlBoxVersion,
  useUpdateVersionMain,
  useUpdateVersionStand,
  useUpdateAutoStandMode,
} from "pages/PitPort/ControlBox/api";

// パーツ定義
const Board = styled(BaseBoard)`
  margin-bottom: 88px;
`;
const HeaderButtonWrap = styled.div`
  display: flex;
  flex: auto;
  justify-content: flex-end;
  gap: 16px;
  align-items: center;
`;
const Label = styled.div`
  min-width: 120px;
  margin: 0 5px;
`;
const CancelWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;
const UpdateWrap = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;
const initialControlBox = {
  id: "000000000000",
  registeredNumber: 0,
  version: 2.0,
  status: 0,
  versionStatus: null,
  lastConfirmedUpdateAt: "2021-08-31T06:00:00.000Z",
  lastUpdateAt: "2021-08-31T06:00:00.000Z",
  createdAt: "2021-08-31T06:00:00.000Z",
  stand: null,
  propertySpace: null,
  property: null,
};
const RowWrap = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-right: 8px;
`;

const ConfirmButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-right: 8px;
`;

const StyledLabel = styled(Label)`
  flex: 1;
`;

const StyledFieldValue = styled.div`
  flex: 3;
`;

enum controlBoxStatus {
  オフライン = 0,
  稼働中 = 1,
}

const displayStandStatus = (standStatus: number | null) => {
  switch (standStatus) {
    case 0:
      return PITPORT_AUTO_STAND_STATUS_LABEL.DOWN;
    case 1:
      return PITPORT_AUTO_STAND_STATUS_LABEL.UP;
    default:
      return "";
  }
};

type ModalConfigType = {
  subtitle: string;
  mainAction?: () => void;
  errorMessage?: string;
};

const ControlBoxDetailRow = ({ label, value }: { label: string; value: React.ReactNode; minHeight?: string }) => {
  return (
    <FormRow>
      <StyledLabel>{label}</StyledLabel>
      <StyledFieldValue>{value}</StyledFieldValue>
    </FormRow>
  );
};

const EditControlBox = () => {
  const navigate = useNavigate();
  const { updateMain } = useUpdateVersionMain();
  const { updateStand } = useUpdateVersionStand();
  const { updateStandMode } = useUpdateAutoStandMode();
  const { confirm } = useConfirmControlBoxVersion();
  const { remove } = useRemoveControlBox();
  const params = useParams();
  const { data: controlBoxWithStand, error } = useSwr<PitPortControlBoxResponse>(`/control-box/${params.id}`);
  const controlBox = controlBoxWithStand?.data ?? initialControlBox;

  const isMainLatest = controlBox.version === process.env.REACT_APP_MAIN_VERSION;
  const isStandLatest = controlBox.stand?.version === process.env.REACT_APP_STAND_VERSION;

  const propertyId = controlBox.property?.id;
  const propertySpaceId = controlBox.propertySpace?.id;
  const [selectedMode, setSelectedMode] = useState<PitPortStandMode | null>(null);
  useEffect(() => {
    if (controlBox.stand?.mode != null) {
      setSelectedMode(controlBox.stand?.mode);
    }
  }, [controlBox.stand?.mode]);
  const [isModalShow, setIsModalShow] = useState(false);
  const [ModalConfig, setModalConfig] = useState<UserModalConfig>({
    onClickOutside: () => {
      return;
    },
    subtitle: "完了しました",
    main: {
      buttonType: "secondary",
      onClick: () => {
        return;
      },
      label: "閉じる",
    },
  });

  const handleSelectMode = (modeName: string) => {
    const modeMap: { [key: string]: PitPortStandMode } = {
      [PITPORT_AUTO_STAND_MODE_LABEL.ACCIDENTAL_PARKING_PREVENTION]: 1,
      [PITPORT_AUTO_STAND_MODE_LABEL.PARKING_TIME_SETTING]: 2,
      [PITPORT_AUTO_STAND_MODE_LABEL.MAINTENANCE]: 3,
      [PITPORT_AUTO_STAND_MODE_LABEL.PUSH_BUTTON]: 4,
      [PITPORT_AUTO_STAND_MODE_LABEL.HYBRID]: 5,
      [PITPORT_AUTO_STAND_MODE_LABEL.CASHLESS]: 6,
    };
    const modeNumber = modeMap[modeName];
    setSelectedMode(modeNumber);
  };

  const deleteObject = useMemo(
    () => ({
      "200": {
        subtitle: "削除しました",
        mainAction: () => navigate("/pit_port/control_box"),
      },
      "404": {
        subtitle: "削除に失敗しました",
        errorMessage: "制御BOXが見つかりません",
      },
      "403": {
        subtitle: "削除に失敗しました",
        errorMessage: "区画が紐付いています",
      },
      default: {
        subtitle: "削除に失敗しました",
        errorMessage: "時間をおいてもう一度お試しください",
      },
    }),
    [navigate]
  );

  const confirmObject = useMemo(
    () => ({
      "200": {
        subtitle: "取得開始しました",
        mainAction: () => window.location.reload(),
      },
      default: {
        subtitle: "取得に失敗しました",
        errorMessage: "時間をおいてもう一度お試しください",
      },
    }),
    []
  );

  const updateObject = useMemo(
    () => ({
      "200": {
        subtitle: "更新を開始しました",
        mainAction: () => window.location.reload(),
      },
      default: {
        subtitle: "更新に失敗しました",
        errorMessage: "時間をおいてもう一度お試しください",
      },
    }),
    []
  );

  const showModal = useCallback((config: UserModalConfig) => {
    setModalConfig(config);
    setIsModalShow(true);
  }, []);

  const hideModal = useCallback(() => {
    setIsModalShow(false);
  }, []);

  const setUp = useCallback(() => {
    if (error) {
      showModal({
        onClickOutside: hideModal,
        subtitle: "送信に失敗しました",
        main: {
          buttonType: "secondary",
          onClick: hideModal,
          label: "閉じる",
        },
        errorMessage: "時間をおいてもう一度お試しください",
      });
      return;
    }
  }, [error, showModal, hideModal]);

  useEffect(() => {
    setUp();
  }, [controlBox, setUp]);

  const onClickSubmit = useCallback(async () => {
    if (controlBox.stand?.mode == null || selectedMode == null) {
      showModal({
        onClickOutside: hideModal,
        subtitle: "更新に失敗しました",
        main: {
          buttonType: "secondary",
          onClick: hideModal,
          label: "閉じる",
        },
        errorMessage: "スタンドモードが設定されていません",
      });
      return;
    }

    const status = await updateStandMode(controlBox.id, selectedMode);
    status === 200
      ? showModal({
          onClickOutside: hideModal,
          subtitle: "更新しました",
          main: {
            buttonType: "secondary",
            onClick: () => navigate("/pit_port/control_box"),
            label: "閉じる",
          },
        })
      : showModal({
          onClickOutside: hideModal,
          subtitle: "更新に失敗しました",
          main: {
            buttonType: "secondary",
            onClick: hideModal,
            label: "閉じる",
          },
          errorMessage: "時間をおいてもう一度お試しください",
        });
  }, [controlBox, showModal, hideModal, navigate, updateStandMode, selectedMode]);

  // 削除ボタン
  const onClickRemove = useCallback(async () => {
    const result = await remove(controlBox.id);
    const modalConfigs: { [key: string]: ModalConfigType } = deleteObject;
    const config = modalConfigs[String(result)] || modalConfigs.default;
    showModal({
      onClickOutside: hideModal,
      subtitle: config.subtitle,
      main: {
        buttonType: "secondary",
        onClick: config.mainAction || hideModal,
        label: "閉じる",
      },
      errorMessage: config.errorMessage,
    });
  }, [remove, controlBox.id, showModal, hideModal, deleteObject]);

  // バージョン取得ボタン
  const onClickConfirm = useCallback(async () => {
    if (!propertyId || !propertySpaceId) {
      showModal({
        onClickOutside: hideModal,
        subtitle: "取得に失敗しました",
        main: {
          buttonType: "secondary",
          onClick: hideModal,
          label: "閉じる",
        },
        errorMessage: "駐車場と区画が紐付いていません",
      });
      return;
    }
    const result = await confirm(controlBox.id, propertyId, propertySpaceId);
    const modalConfigs: { [key: string]: ModalConfigType } = confirmObject;
    const config = modalConfigs[String(result)] || modalConfigs.default;
    showModal({
      onClickOutside: hideModal,
      subtitle: config.subtitle,
      main: {
        buttonType: "secondary",
        onClick: config.mainAction || hideModal,
        label: "閉じる",
      },
      errorMessage: config.errorMessage,
    });
  }, [confirm, controlBox.id, propertyId, propertySpaceId, showModal, hideModal, confirmObject]);

  // Mainバージョン更新ボタン
  const onClickUpdateMain = useCallback(async () => {
    if (!propertyId || !propertySpaceId) {
      showModal({
        onClickOutside: hideModal,
        subtitle: "更新に失敗しました",
        main: {
          buttonType: "secondary",
          onClick: hideModal,
          label: "閉じる",
        },
        errorMessage: "駐車場と区画が紐付いていません",
      });
      return;
    }
    const result = await updateMain(controlBox.id, propertyId, propertySpaceId);
    const modalConfigs: { [key: string]: ModalConfigType } = updateObject;
    const config = modalConfigs[String(result)] || modalConfigs.default;
    showModal({
      onClickOutside: hideModal,
      subtitle: config.subtitle,
      main: {
        buttonType: "secondary",
        onClick: config.mainAction || hideModal,
        label: "閉じる",
      },
      errorMessage: config.errorMessage,
    });
  }, [updateMain, controlBox.id, propertyId, propertySpaceId, showModal, hideModal, updateObject]);

  // Standバージョン更新ボタン
  const onClickUpdateStand = useCallback(async () => {
    if (!propertyId || !propertySpaceId) {
      showModal({
        onClickOutside: hideModal,
        subtitle: "更新に失敗しました",
        main: {
          buttonType: "secondary",
          onClick: hideModal,
          label: "閉じる",
        },
        errorMessage: "駐車場と区画が紐付いていません",
      });
      return;
    }
    const result = await updateStand(controlBox.id, propertyId, propertySpaceId);
    const modalConfigs: { [key: string]: ModalConfigType } = updateObject;
    const config = modalConfigs[String(result)] || modalConfigs.default;
    showModal({
      onClickOutside: hideModal,
      subtitle: config.subtitle,
      main: {
        buttonType: "secondary",
        onClick: config.mainAction || hideModal,
        label: "閉じる",
      },
      errorMessage: config.errorMessage,
    });
  }, [updateStand, controlBox.id, propertyId, propertySpaceId, showModal, hideModal, updateObject]);

  // 削除確認モーダル
  const showRemoveModal = () => {
    setModalConfig({
      onClickOutside: hideModal,
      subtitle: "削除しますか？",
      main: {
        buttonType: "danger",
        onClick: () => {
          onClickRemove();
        },
        label: "削除",
      },
      sub: {
        buttonType: "secondary",
        onClick: hideModal,
        label: "キャンセル",
      },
      errorMessage: "削除したら元に戻せません",
    });
    setIsModalShow(true);
  };

  // バージョン取得確認モーダル
  const showConfirmModal = () => {
    if (controlBox.propertySpace?.status === 1) {
      setModalConfig({
        onClickOutside: hideModal,
        subtitle: "駐車場を利用中です！",
        main: {
          buttonType: "secondary",
          onClick: () => {
            onClickConfirm();
          },
          label: "取得",
        },
        sub: {
          buttonType: "danger",
          onClick: hideModal,
          label: "キャンセル",
        },
        errorMessage: (
          <>
            <p>本当にバージョン取得を行いますか？</p>
            <p>（通常動作に影響が出る可能性があります）</p>
          </>
        ),
      });
      setIsModalShow(true);
    } else {
      setModalConfig({
        onClickOutside: hideModal,
        subtitle: "バージョン情報を取得しますか？",
        main: {
          buttonType: "primary",
          onClick: () => {
            onClickConfirm();
          },
          label: "取得",
        },
        sub: {
          buttonType: "secondary",
          onClick: hideModal,
          label: "キャンセル",
        },
      });
      setIsModalShow(true);
    }
  };

  // Mainバージョン更新確認モーダル
  const showUpdateMainModal = () => {
    setModalConfig({
      onClickOutside: hideModal,
      subtitle: "メインプログラムを更新しますか？",
      main: {
        buttonType: "primary",
        onClick: () => {
          onClickUpdateMain();
        },
        label: "更新",
      },
      sub: {
        buttonType: "secondary",
        onClick: hideModal,
        label: "キャンセル",
      },
    });
    setIsModalShow(true);
  };

  // Standバージョン更新確認モーダル
  const showUpdateStandModal = () => {
    setModalConfig({
      onClickOutside: hideModal,
      subtitle: "スタンドプログラムを更新しますか？",
      main: {
        buttonType: "primary",
        onClick: () => {
          onClickUpdateStand();
        },
        label: "更新",
      },
      sub: {
        buttonType: "secondary",
        onClick: hideModal,
        label: "キャンセル",
      },
    });
    setIsModalShow(true);
  };
  // バージョン取得ボタン切り替えロジック
  const isMoreThan15Minutes = (): boolean | undefined => {
    if (!controlBox.lastUpdatedAt) {
      return true;
    }
    const lastUpdatedAt = new Date(controlBox.lastUpdatedAt);
    const now = new Date();
    const difference = now.getTime() - lastUpdatedAt.getTime();
    return difference > 15 * 60 * 1000;
  };

  // main要更新ラベルと更新ボタンのラッパー
  const UpdateMainButtonWithLabel = () => {
    return (
      <ConfirmButtonWrapper>
        <WarningLabel label="要更新" />
        <Button
          type="secondary"
          onClick={showUpdateMainModal}
          label="更新"
          width="80px"
          height="30px"
          disabled={!isMoreThan15Minutes()}
        />
      </ConfirmButtonWrapper>
    );
  };

  // stand要更新ラベルと更新ボタンのラッパー
  const UpdateStandButtonWithLabel = () => {
    return (
      <ConfirmButtonWrapper>
        <WarningLabel label="要更新" />
        <Button
          type="secondary"
          onClick={showUpdateStandModal}
          label="更新"
          width="80px"
          height="30px"
          disabled={!isMoreThan15Minutes()}
        />
      </ConfirmButtonWrapper>
    );
  };

  // バージョン情報
  const VersionField = ({
    label,
    color,
    isLatest,
    type,
  }: {
    label: string | null;
    color: string;
    isLatest: boolean;
    type?: 1 | 2;
  }) => {
    const lastConfirmedUpdateAt = controlBox.lastConfirmedUpdateAt
      ? new Date(controlBox.lastConfirmedUpdateAt).toLocaleString("ja-JP")
      : "";
    const lastUpdatedAt = controlBox.lastUpdatedAt ? new Date(controlBox.lastUpdatedAt).toLocaleString("ja-JP") : "";
    return (
      <FieldValue flex={3} style={{ color: color }}>
        <RowWrap>
          {label && <p>{label}</p>}
          {type === 1 && !isLatest && <UpdateMainButtonWithLabel />}
          {type === 2 && !isLatest && <UpdateStandButtonWithLabel />}
        </RowWrap>
        <RowWrap>
          （取得日：{lastConfirmedUpdateAt}、更新日時：{lastUpdatedAt}）
        </RowWrap>
      </FieldValue>
    );
  };

  // メインプログラム行
  const MainVersionField = ({ controlBox }: { controlBox: ControlBoxWithStand }) => {
    switch (controlBox.versionStatus) {
      case 1:
        return VersionField({ label: "取得中", color: color.attention, isLatest: true });
      case 2:
        return VersionField({ label: "更新中", color: color.attention, isLatest: true });
      default:
        return VersionField({ label: controlBox.version, color: color.text.black, isLatest: isMainLatest, type: 1 });
    }
  };

  // スタンドプログラム行
  const StandVersionField = ({ controlBox }: { controlBox: ControlBoxWithStand }) => {
    switch (controlBox.stand?.versionStatus) {
      case 1:
        return VersionField({ label: "取得中", color: color.attention, isLatest: true });
      case 2:
        return VersionField({ label: "更新中", color: color.attention, isLatest: true });
      default:
        return VersionField({
          label: controlBox.stand?.version || null,
          color: color.text.black,
          isLatest: isStandLatest,
          type: 2,
        });
    }
  };

  return (
    <Board>
      <TitleArea>
        <Breadcrumb
          currentPageName="制御BOXを編集"
          breadcrumbItems={[{ pageName: "制御BOX", onClick: () => navigate(`/pit_port/control_box`) }]}
        />
        <HeaderButtonWrap>
          <Button
            label="バージョンを取得"
            type="secondary"
            onClick={showConfirmModal}
            // disabled={!isMoreThan15Minutes()}
          />
          <Button
            type="danger"
            onClick={showRemoveModal}
            label="この制御BOXを削除"
            width="180px"
            disabled={!!(controlBox.propertySpace || controlBox.stand)}
          />
        </HeaderButtonWrap>
      </TitleArea>
      <ContentArea>
        <ControlBoxDetailRow label="制御BOX ID" value={controlBox.id} />
        <FormRow>
          <StyledLabel>メインプログラム</StyledLabel>
          <MainVersionField controlBox={controlBox} />
        </FormRow>
        <FormRow>
          <StyledLabel>スタンドプログラム</StyledLabel>
          <StandVersionField controlBox={controlBox} />
        </FormRow>
        <ControlBoxDetailRow label="駐車場" value={controlBox.property?.name} />
        <ControlBoxDetailRow label="区画" value={controlBox.propertySpace?.name} />
        <ControlBoxDetailRow label="ステータス" value={controlBoxStatus[controlBox.status]} />
        <ControlBoxDetailRow label="追加日時" value={controlBox.createdAt} />
        <ControlBoxDetailRow label="スタンド状態" value={displayStandStatus(controlBox.stand?.updownStatus ?? null)} />
        <FormRow>
          <ControlBoxDetailRow
            label="スタンドモード"
            value={<ModeSelector selectedMode={selectedMode} onSelectMode={handleSelectMode} />}
          />
        </FormRow>
      </ContentArea>
      <FooterArea>
        <CancelWrap>
          <Button type="secondary" onClick={() => navigate("/pit_port/control_box")} label="キャンセル" width="160px" />
        </CancelWrap>
        <UpdateWrap>
          <Button type="primary" onClick={onClickSubmit} label="編集内容を保存" width="160px" />
        </UpdateWrap>
      </FooterArea>
      <UserModal isVisible={isModalShow} config={ModalConfig}></UserModal>
    </Board>
  );
};

export default EditControlBox;
