import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useRef, useEffect } from "react";
import styled from "styled-components";

import { Wrapper } from "components/TextForm";
import color from "constants/color";
import font from "constants/font";
import { SelectInput } from "pages/AimoParking/component/AimoParkingSearchableSelect";

const OptionWrap = styled.ul<{ width: string }>`
  width: calc(${(props) => props.width} - 2px);
  max-height: 400px;
  margin-top: 4px;
  padding: 0;
  position: absolute;
  background-color: ${color.white};
  border: solid 1px ${color.form.border};
  border-radius: 4px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
  z-index: 2;
  font-size: ${font.size14};
  overflow-y: auto;
`;
const Option = styled.li<{ disabled?: boolean }>`
  color: ${color.text.black};
  padding: 4px 16px;
  display: block;
  list-style: none;
  cursor: pointer;
  &:hover {
    background-color: ${color.text.link};
    color: ${color.white};
  }
  ${(props) =>
    props.disabled &&
    `
    color: ${color.text.disabled};
    cursor: default;
    pointer-events: none;
    &:hover {
      background-color: ${color.white};
      color: ${color.text.gray};
    }`}
`;
const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  z-index: 5;
`;
const Wrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
const Placeholder = styled.div`
  position: absolute;
  z-index: 2;
  pointer-events: none;
  left: 14px;
  font-size: ${font.size14};
`;

type Choice = { key?: number | string; label: string; value: string; disabled?: boolean };

/**
 * セレクトコンポーネント
 * @param choices 選択肢
 * @param value 初期値
 * @param onChange 選択時のコールバック
 * @param width 幅
 */
const AimoParkingSelect = ({
  choices,
  value,
  onChange = () => null,
  width = "100px",
  placeholder,
}: {
  choices: Choice[];
  value?: Choice | null;
  onChange?: (value: Choice) => void;
  width?: string;
  placeholder?: string;
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const formRef = useRef<HTMLDivElement>(null);

  const onClickChoice = (value: Choice) => {
    onChange(value);
    setIsOpened(false);
  };

  const onClickForm = () => setIsOpened((prev) => !prev);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      // 選択肢が閉じている場合 または クリックイベントの対象がフォームの場合は何もしない
      if (!isOpened || formRef.current?.contains(event.target as Node)) return;

      // 上記以外の場合に、選択肢を閉じる
      return setIsOpened(false);
    };

    window.addEventListener("click", handleClick);
    return () => window.removeEventListener("click", handleClick);
  });

  return (
    <Wrapper ref={formRef}>
      <Wrap>
        <SelectInput type="text" width={width} value={value?.label} readOnly onClick={onClickForm} />
        {!value && <Placeholder>{placeholder}</Placeholder>}
      </Wrap>
      <Icon icon={faAngleDown} onClick={onClickForm} />
      <OptionWrap width={width} hidden={!isOpened}>
        {choices.map((choice) => (
          <Option onClick={() => onClickChoice(choice)} key={choice.key ?? choice.value} disabled={choice.disabled}>
            {choice.label}
          </Option>
        ))}
      </OptionWrap>
    </Wrapper>
  );
};

export default AimoParkingSelect;
