import { useNavigate } from "react-router-dom";

import EmptyTable from "components/EmptyTable";
import { Table, Heading, FieldName, Row, FieldValueWithBorder } from "components/LayoutParts";
import Pagination from "components/Pagination";
import AimoParkingEdgeDeviceStatusSwitcher from "pages/AimoParking/component/AimoParkingEdgeDeviceStatusSwitcher";
import { AIMO_PARKING_EDGE_DEVICE_STATUS } from "pages/AimoParking/utils/aimoParkingEdgeDeviceStatusUtil";
import { dateFormat } from "utils/dateTimeUtils";

const edgeDeviceTableColumn = [
  {
    label: "シリアルナンバー",
    flex: 41,
  },
  {
    label: "駐車場名",
    sortKey: "name",
    flex: 41,
  },
  {
    label: "ステータス",
    flex: 12,
  },
  {
    label: "登録日",
    flex: 12,
  },
];

const DataTable = ({
  edgeDeviceList,
  totalPages,
  pageIndex,
  setPageIndex,
}: {
  edgeDeviceList: AimoParkingEdgeDeviceSummary[];
  totalPages: number;
  pageIndex: number;
  setPageIndex: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const navigate = useNavigate();

  const onClickRow = (edgeDeviceId: string) => navigate(`/aimo_parking/edge_device/${edgeDeviceId}`);

  const onClickAddEdgeDevice = () => navigate("/aimo_parking/edge_device/create");

  return (
    <>
      <Table>
        <Heading>
          {edgeDeviceTableColumn.map((column, i) => (
            <FieldName key={column.label} flex={column.flex} lastColumn={i === edgeDeviceTableColumn.length - 1}>
              {column.label}
            </FieldName>
          ))}
        </Heading>
        {edgeDeviceList.length > 0 ? (
          edgeDeviceList.map((edgeDevice) => (
            <Row
              key={edgeDevice.id}
              onClick={() => onClickRow(edgeDevice.id)}
              isDeleted={edgeDevice.status === AIMO_PARKING_EDGE_DEVICE_STATUS.REMOVED}
            >
              <FieldValueWithBorder flex={edgeDeviceTableColumn[0].flex}>
                {edgeDevice.serialNumber}
              </FieldValueWithBorder>
              <FieldValueWithBorder flex={edgeDeviceTableColumn[1].flex}>
                {edgeDevice.property?.name}
              </FieldValueWithBorder>
              <FieldValueWithBorder flex={edgeDeviceTableColumn[2].flex}>
                <AimoParkingEdgeDeviceStatusSwitcher status={edgeDevice.status} />
              </FieldValueWithBorder>
              <FieldValueWithBorder flex={edgeDeviceTableColumn[3].flex}>
                {dateFormat(edgeDevice.createdAt, "YYYY/MM/DD")}
              </FieldValueWithBorder>
            </Row>
          ))
        ) : (
          <EmptyTable
            handleClick={onClickAddEdgeDevice}
            label="エッジデバイスを登録"
            message="エッジデバイスが見つかりません"
          />
        )}
      </Table>
      <Pagination totalPages={totalPages} pageIndex={pageIndex} setPageIndex={setPageIndex} />
    </>
  );
};

export default DataTable;
