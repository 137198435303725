import React from "react";
import styled from "styled-components";

import Select from "components/Select";
import { PITPORT_AUTO_STAND_MODE_LABEL } from "constants/stand";

const SelectWrapper = styled.div`
  position: relative;
  width: 200px;
  height: auto;
`;

type ModeSelectorProps = {
  selectedMode: PitPortStandMode | null;
  onSelectMode: (modeName: string) => void;
};

const modeLabels: { [key: number]: string } = {
  1: PITPORT_AUTO_STAND_MODE_LABEL.ACCIDENTAL_PARKING_PREVENTION,
  2: PITPORT_AUTO_STAND_MODE_LABEL.PARKING_TIME_SETTING,
  3: PITPORT_AUTO_STAND_MODE_LABEL.MAINTENANCE,
  4: PITPORT_AUTO_STAND_MODE_LABEL.PUSH_BUTTON,
  5: PITPORT_AUTO_STAND_MODE_LABEL.HYBRID,
  6: PITPORT_AUTO_STAND_MODE_LABEL.CASHLESS,
};

const displayStandMode = (standMode: PitPortStandMode | null) => {
  return standMode !== null ? modeLabels[standMode] : "";
};

const ModeSelector = ({ selectedMode, onSelectMode }: ModeSelectorProps) => {
  return (
    <SelectWrapper>
      <Select
        choices={Object.values(PITPORT_AUTO_STAND_MODE_LABEL)}
        width="200px"
        value={displayStandMode(selectedMode)}
        onSelect={onSelectMode}
      />
    </SelectWrapper>
  );
};

export default ModeSelector;
