import React from "react";
import styled from "styled-components";

import { FieldName, Heading, FieldValueWithBorder, Row } from "components/LayoutParts";

interface PropertySpace {
  id?: number;
  propertyId: number;
  property: {
    name: string;
    address: string;
  };
  name: string;
}

interface PropertySpacesTableProps {
  spacesWithProperty: {
    data: PropertySpace[];
  };
  selectedPropertyIds: number[];
}

export const Title = styled.span`
  font-size: 24px;
  font-weight: bold;
  line-height: 40px;
`;

export const Table = styled.div`
  width: 100%;
`;

export const NoProperty = styled(Row)`
  display: flex;
  justify-content: center;
  padding: 16px;
`;

const PropertySpacesTable: React.FC<PropertySpacesTableProps> = ({ spacesWithProperty, selectedPropertyIds }) => {
  return (
    <Table>
      <Heading>
        <FieldName flex={0.5}>駐車場ID</FieldName>
        <FieldName flex={1.5}>駐車場名</FieldName>
        <FieldName flex={2}>住所</FieldName>
        <FieldName flex={0.5}>区画ID</FieldName>
        <FieldName flex={1}>区画名</FieldName>
      </Heading>
      {spacesWithProperty?.data?.filter((propertySpace) => selectedPropertyIds.includes(propertySpace.id || 0)).length >
      0 ? (
        spacesWithProperty.data
          .filter((propertySpace) => selectedPropertyIds.includes(propertySpace.id || 0))
          .map((propertySpace) => (
            <Row key={propertySpace.id}>
              <FieldValueWithBorder flex={0.5}>{propertySpace.propertyId}</FieldValueWithBorder>
              <FieldValueWithBorder flex={1.5}>{propertySpace.property.name}</FieldValueWithBorder>
              <FieldValueWithBorder flex={2}>{propertySpace.property.address}</FieldValueWithBorder>
              <FieldValueWithBorder flex={0.5}>{propertySpace.id}</FieldValueWithBorder>
              <FieldValueWithBorder flex={1}>{propertySpace.name}</FieldValueWithBorder>
            </Row>
          ))
      ) : (
        <NoProperty>区画が選択されていません</NoProperty>
      )}
    </Table>
  );
};

export default PropertySpacesTable;
