import { useNavigate } from "react-router-dom";

import { FieldName, FieldValue, Heading, Row, Table } from "components/LayoutParts";
import Pagination from "components/Pagination";
import SortButton from "components/SortButton";

const propertyTableColumn = [
  {
    label: "ID",
    sortKey: "id",
    flex: 1,
  },
  {
    label: "駐車場名",
    sortKey: "name",
    flex: 4,
  },
  {
    label: "住所",
    sortKey: "address",
    flex: 8,
  },
  {
    label: "状態",
    sortKey: "status",
    flex: 2,
  },
] as const;

const displayPropertyStatus = (status: number) => {
  switch (status) {
    case 0:
      return "利用不可";
    case 1:
      return "利用可";
    case 5:
      return "削除済み";
    default:
      return "";
  }
};

// 本体
const DataTable = ({
  propertyList,
  totalPages,
  pageIndex,
  sortOption,
  setPageIndex,
  setSortOption,
}: {
  propertyList: Property[];
  totalPages: number;
  pageIndex: number;
  sortOption: SortOption<SortByPitPortProperty>;
  setPageIndex: React.Dispatch<React.SetStateAction<number>>;
  setSortOption: React.Dispatch<React.SetStateAction<SortOption<SortByPitPortProperty>>>;
}) => {
  const navigate = useNavigate();
  const onClickRow = (propertyId: string) => navigate(`/pit_port/property/${propertyId}`);

  return (
    <>
      <Table>
        <Heading>
          {propertyTableColumn.map((column) => (
            <FieldName key={column.label} flex={column.flex} isSort={sortOption.sortByColumn === column.sortKey}>
              {column.label}
              <SortButton currentSortOption={sortOption} sortByKey={column.sortKey} setSortOption={setSortOption} />
            </FieldName>
          ))}
        </Heading>
        {propertyList.map((property) => (
          <Row key={property.id} onClick={() => onClickRow(property.id)}>
            <FieldValue flex={propertyTableColumn[0].flex}>{property.id}</FieldValue>
            <FieldValue flex={propertyTableColumn[1].flex}>{property.name}</FieldValue>
            <FieldValue flex={propertyTableColumn[2].flex}>{property.address}</FieldValue>
            <FieldValue flex={propertyTableColumn[3].flex}>{displayPropertyStatus(property.status)}</FieldValue>
          </Row>
        ))}
      </Table>
      <Pagination totalPages={totalPages} pageIndex={pageIndex} setPageIndex={setPageIndex} />
    </>
  );
};

export default DataTable;
